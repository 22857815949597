import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { ProductComment } from 'services';

import { head } from 'lodash';
import { CommentHeader } from 'components/comment/comment/comment-header/comment-header';
import { CommentBody } from 'components/comment/comment/comment-body/comment-body';
import { CommentFooter } from 'components/comment/comment/comment-footer/comment-footer';
import { AddNewComment } from 'components/comment/add-comment/add-new-comment';
import { Container, Grid, SubCommentsShowMoreButton } from './style';
import { useProductCommentsStore } from '../../../../store/product-comments';
import { ProductCommentWithChildren } from '../../../../store/product-comments/store';

interface ItemProps {
  data: ProductCommentWithChildren;
  isSub?: boolean;
}

const ProductCommentsItem: React.FC<ItemProps> = observer(({ data, isSub }) => {
  const productCommentsStore = useProductCommentsStore();

  const [visibleReply, setVisibleReply] = useState(false);
  const [subCommentsCount, setSubCommentsCount] = useState(10);

  useEffect(() => {
    if (data.childrenComments) {
      productCommentsStore.addSubComments(data.childrenComments);
    }
  }, [data.childrenComments]);

  const addSubComment = (comment: string) => {
    productCommentsStore.addComment({
      content: comment,
      product: data.product,
      parentComment: isSub ? data.parentComment : data.id,
    });
    setVisibleReply(false);
  };

  const fetchNextSubComments = useMemo(() => {
    return () => {
      if (data.id) {
        setSubCommentsCount(subCommentsCount + 1);
        productCommentsStore.fetchSubComments({
          productId: +data.product,
          parentId: data.id,
          count: subCommentsCount + 1,
        });
      }
      setSubCommentsCount(subCommentsCount + 6);
    };
  }, [subCommentsCount, data.id, data.product, productCommentsStore]);

  const isShowMoreReplyButton =
    Number(data?.childrenCommentsCount) > 5 &&
    data.id &&
    Number(head(productCommentsStore.comments.filter((comment) => comment.id === data.id))?.children?.length) <
      Number(data?.childrenCommentsCount);

  const handleReplyClick = useCallback(() => {
    setVisibleReply(!visibleReply);
  }, [visibleReply]);

  const renderChildrens = (childrens: ProductComment[]) => {
    return childrens.map((c) => <ProductCommentsItem data={c} isSub={true} />);
  };

  const onLike = useCallback(() => {
    if (data.id) {
      data.isLikedByUser
        ? productCommentsStore.toggleLikeDelete(data.id, isSub ? data.parentComment : undefined)
        : productCommentsStore.toggleLikePost(data.id, isSub ? data.parentComment : undefined);
    }
  }, [data.isLikedByUser, data.id, data.parentComment]);

  return (
    <>
      <Container isSub={isSub} hasChildren={Boolean(data.childrenCommentsCount)}>
        <Grid>
          <CommentHeader profile={data.profile} createDate={data.created} />

          <CommentBody content={data.content} />

          <CommentFooter
            visible={visibleReply}
            onReply={handleReplyClick}
            likesCount={data.likesCount}
            commentId={data.id}
            parentCommentId={data.parentComment}
            isLikedByUser={data.isLikedByUser}
            isSub={isSub}
            onLike={onLike}
          />
        </Grid>
      </Container>

      {data.id &&
        renderChildrens(head(
          productCommentsStore.comments.filter((comment) => comment.id === data.id))?.children || [])}

      {isShowMoreReplyButton && (
        <SubCommentsShowMoreButton onClick={fetchNextSubComments}>ПОКАЗАТЬ БОЛЬШЕ ОТВЕТОВ</SubCommentsShowMoreButton>
      )}

      {visibleReply && (
        <AddNewComment onAddNewComment={addSubComment} isSub={true} toName={data.profile?.firstName ?? ''} />
      )}
    </>
  );
});
export default ProductCommentsItem;
