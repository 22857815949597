import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const ModalContainer = styled.div`
  display: none;
  z-index: 999;
  position: absolute;
`;
export const Container = styled.div<{ isMobile?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  position: relative;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      :hover {
        img {
          opacity: 0.2;
        }
        ${ModalContainer} {
          display: block;
        }
      }
    `};

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    width: 140px;
    height: 217px;
  }
  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 328px;
    height: 390px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 790px;
    height: 952px;
  }
`;

export const Img = styled.img<{ isMobile?: boolean }>`
  border-radius: ${({ isMobile }) => (isMobile ? '8px' : 'none')};
  object-fit: cover;
  position: relative;

  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    width: 140px;
    height: 192px;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    width: 328px;
    height: 390px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 790px;
    height: 952px;
  }
`;

export const Year = styled.div`
  width: 100%;
  color: ${COLORS.azure};
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

export const IconBox = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;
