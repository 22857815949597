import React, { useCallback, useMemo, useState } from 'react';
import { ServicesBannerValue, SubstitutedCategoryType } from 'web';
import { PropsWithSectionOptions } from 'components/renderers/blocks';
import Section from 'components/base-ui/section';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceType, useQueryParams } from 'utils/hooks';
import { Button } from 'shared/components';
import { ButtonTypes } from 'shared/components/button/types';
import { useDynamicApplicationStore } from 'store';
import { ApplicationFormIntegrationEnum, DynamicApplication } from 'services';
import { useChip } from 'components/goods/item/chip';
import { useSubscribe } from 'components/services/utils';
import { BaseModal } from 'components/base-ui/base-modal';
import { Feedback } from 'components/feedback';
import {
  BannerWrapper,
  Content,
  ImageWrapper,
  Image,
  Title,
  Description,
  Columns,
  ButtonsWrapper,
  BottomWrapper,
  BottomTitle,
  BottomText,
} from './styles';
import { Column } from '../column';

type BannerProps = PropsWithSectionOptions<{
  value: ServicesBannerValue;
}>;

export const Banner: React.FC<BannerProps> = ({ value, options }) => {
  const device = useDeviceType();
  const [trigger, Notify] = useChip();
  const { categoryType } = useQueryParams<{ categoryType: SubstitutedCategoryType; productId: string }>();
  const dynamicApplicationStore = useDynamicApplicationStore();
  const [mainSide, setMainSide] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [subscribe, Chip] = useSubscribe({
    havePage: true,
    subscribeFromPage: value.subscribeFromPage,
    link: value.buttonLink?.link,
    buttonAction: value.buttonAction,
    promocode: categoryType === 'media',
  });

  const showColumns = useMemo(() => {
    return (device === 'laptop' || device === 'desktop') && value.textColumns?.length > 0 && mainSide;
  }, [value.textColumns, device, mainSide]);

  const toggleSide = () => setMainSide(!mainSide);

  const handleFormWithoutFields = useCallback(() => {
    if (!value.dynamicApplicationForm) return;
    dynamicApplicationStore.createDynamicApplication({ form: value.dynamicApplicationForm } as DynamicApplication);
    trigger();
  }, [value.dynamicApplicationForm]);

  const handleShowForm = useCallback(() => {
    if (!value.dynamicApplicationForm) return;
    if (
      dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.MoreTv ||
      dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.Litres
    ) {
      handleFormWithoutFields();
    } else {
      setShowForm(true);
    }
  }, [dynamicApplicationStore.integrationType]);

  const onButtonClick = useCallback(() => {
    if (value.buttonAction === 'link') {
      subscribe();
    } else {
      handleShowForm();
    }
  }, [value.buttonAction, subscribe]);

  const notifyText = React.useMemo(() => {
    if (dynamicApplicationStore.error) {
      return 'Не удалось отправить промокод';
    }
    if (dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.Litres) {
      return 'Логин и пароль для входа в Литрес отправлены вам на почту';
    }
    if (dynamicApplicationStore.integrationType === ApplicationFormIntegrationEnum.MoreTv) {
      return 'Промокод отправлен вам на почту';
    }
    return '';
  }, [dynamicApplicationStore.error, dynamicApplicationStore.integrationType]);

  return (
    <Section noFirstOfTypeOffset compact>
      {Chip && <Chip />}
      {Notify && <Notify content={notifyText} error={Boolean(dynamicApplicationStore.error)} />}
      <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
        <Feedback formId={value.dynamicApplicationForm || null} />
      </BaseModal>
      <BannerWrapper>
        <Content>
          {mainSide && <Title>{value.header}</Title>}
          <Description>
            <HtmlRenderer html={mainSide ? value.preview : value.secondStateDescription} />
          </Description>

          <ButtonsWrapper>
            {mainSide && value.subscribeFromPage && <Button onClick={onButtonClick}>{value.text}</Button>}
            {Boolean(value.additionalDescription?.text) && (
              <Button suffix={mainSide} type={ButtonTypes.Secondary} onClick={toggleSide}>
                {mainSide ? 'Подробнее' : 'Назад'}
              </Button>
            )}
          </ButtonsWrapper>
        </Content>
        <ImageWrapper>
          <Image src={value.image} />
        </ImageWrapper>
      </BannerWrapper>
      {showColumns && (
        <Columns>
          {value.textColumns.map((col, ind) => (
            <Column key={ind} header={col.header} text={col.text} width={Math.floor(100 / value.textColumns.length)} />
          ))}
        </Columns>
      )}
      <BottomWrapper>
        {value.additionalDescription?.header && <BottomTitle>{value.additionalDescription?.header}</BottomTitle>}
        <BottomText w={value.additionalDescription?.header ? 70 : 100}>
          <HtmlRenderer html={value.additionalDescription.text} />
        </BottomText>
      </BottomWrapper>
    </Section>
  );
};
