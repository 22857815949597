import React, { useMemo } from 'react';
import { useNewsCommentsStore } from 'store';
import { observer } from 'mobx-react-lite';
import { Container, Like, LikeIcon, ReplyButton } from './styles';

interface Props {
  visible: boolean;
  onReply: () => void;
  likesCount?: number;
  commentId?: number;
  parentCommentId?: number | null;
  isLikedByUser?: boolean;
  isSub?: boolean;
  onLike: () => void;
}

export const CommentFooter: React.FC<Props> = observer((props) => {
  const replyButtonText = useMemo(() => (props.visible ? 'Промолчать' : 'Ответить'), [props.visible]);

  return (
    <Container>
      <ReplyButton onClick={props.onReply}>{replyButtonText}</ReplyButton>
      <Like role="button" onClick={props.onLike}>
        <LikeIcon name="iconThumb" />
        {props.likesCount ?? 0}
      </Like>
    </Container>
  );
});
