import React from 'react';
import styled from 'styled-components';
import Banner from 'components/news/item/banner';
import BaseSection from 'components/base-ui/section';
import Actions from 'components/news/item/actions';

const Section = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

interface NewsBannerProps {
  image: string;
  onShowLikeModal?: (id: number) => void;
}

const NewsBanner: React.FC<NewsBannerProps> = ({ image, onShowLikeModal }) => {
  return (
    <>
      <Section>
        <Banner image={image} />
      </Section>

      <BaseSection marginless paddingless compact noMinHeight>
        <Actions onShowLikeModal={onShowLikeModal} />
      </BaseSection>
    </>
  );
};

export default NewsBanner;
