/* tslint:disable */
/* eslint-disable */
/**
 * marketApi
 * Api for market
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AboutProjectPage
 */
export interface AboutProjectPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof AboutProjectPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof AboutProjectPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof AboutProjectPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface AlcoholContentsLimit
 */
export interface AlcoholContentsLimit {
    /**
     * 
     * @type {string}
     * @memberof AlcoholContentsLimit
     */
    minAlcoholContent?: string;
    /**
     * 
     * @type {string}
     * @memberof AlcoholContentsLimit
     */
    maxAlcoholContent?: string;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    id?: number;
    /**
     * 
     * @type {Profile}
     * @memberof Application
     */
    profile?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    email?: string;
    /**
     * 
     * @type {Array<ApplicationProduct>}
     * @memberof Application
     */
    applicationProducts: Array<ApplicationProduct>;
    /**
     * 
     * @type {Shop}
     * @memberof Application
     */
    shopObject?: Shop;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    phone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    shop?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationForm
 */
export interface ApplicationForm {
    /**
     * 
     * @type {number}
     * @memberof ApplicationForm
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    form: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    integration?: ApplicationFormIntegrationEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    submitText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    emailSubject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationForm
     */
    emailHeader?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationFormIntegrationEnum {
    Leningradcenter = 'leningradcenter',
    BusTour = 'bus_tour',
    Sale = 'sale',
    MoreTv = 'more_tv',
    Litres = 'litres'
}

/**
 * 
 * @export
 * @interface ApplicationProduct
 */
export interface ApplicationProduct {
    /**
     * 
     * @type {number}
     * @memberof ApplicationProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProduct
     */
    product?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProduct
     */
    count: number;
    /**
     * 
     * @type {ShortProduct}
     * @memberof ApplicationProduct
     */
    productObject?: ShortProduct;
}
/**
 * 
 * @export
 * @interface AttributeForProduct
 */
export interface AttributeForProduct {
    /**
     * 
     * @type {number}
     * @memberof AttributeForProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AttributeForProduct
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AttributeForProduct
     */
    sortOrder?: number | null;
}
/**
 * 
 * @export
 * @interface AwardBusiness
 */
export interface AwardBusiness {
    /**
     * 
     * @type {number}
     * @memberof AwardBusiness
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AwardBusiness
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AwardNominations
 */
export interface AwardNominations {
    /**
     * 
     * @type {number}
     * @memberof AwardNominations
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AwardNominations
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof AwardNominations
     */
    isAloneWinner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AwardNominations
     */
    showNominee?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AwardNominations
     */
    canTeam?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AwardNominations
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface AwardParticipants
 */
export interface AwardParticipants {
    /**
     * 
     * @type {number}
     * @memberof AwardParticipants
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    descriptionSmall?: string;
    /**
     * 
     * @type {Array<ParticipantItems>}
     * @memberof AwardParticipants
     */
    items: Array<ParticipantItems>;
    /**
     * 
     * @type {number}
     * @memberof AwardParticipants
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    status?: AwardParticipantsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    type?: AwardParticipantsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipants
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AwardParticipants
     */
    award: number;
    /**
     * 
     * @type {number}
     * @memberof AwardParticipants
     */
    nomination: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AwardParticipantsStatusEnum {
    Nominee = 'nominee',
    Winner = 'winner'
}
/**
    * @export
    * @enum {string}
    */
export enum AwardParticipantsTypeEnum {
    Alone = 'alone',
    Team = 'team'
}

/**
 * 
 * @export
 * @interface AwardParticipantsList
 */
export interface AwardParticipantsList {
    /**
     * 
     * @type {number}
     * @memberof AwardParticipantsList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipantsList
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipantsList
     */
    descriptionSmall?: string;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipantsList
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AwardParticipantsList
     */
    projectName: string;
}
/**
 * 
 * @export
 * @interface AwardWinners
 */
export interface AwardWinners {
    /**
     * 
     * @type {AwardBusiness}
     * @memberof AwardWinners
     */
    business: AwardBusiness;
    /**
     * 
     * @type {Awards}
     * @memberof AwardWinners
     */
    award: Awards;
    /**
     * 
     * @type {string}
     * @memberof AwardWinners
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof AwardWinners
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AwardWinners
     */
    subTitle: string;
    /**
     * 
     * @type {string}
     * @memberof AwardWinners
     */
    text: string;
}
/**
 * 
 * @export
 * @interface Awards
 */
export interface Awards {
    /**
     * 
     * @type {number}
     * @memberof Awards
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Awards
     */
    year: string;
    /**
     * 
     * @type {string}
     * @memberof Awards
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof Awards
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Awards
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface BadRequest
 */
export interface BadRequest {
    /**
     * 
     * @type {string}
     * @memberof BadRequest
     */
    error: string;
}
/**
 * 
 * @export
 * @interface BaningProductsCallback
 */
export interface BaningProductsCallback {
    /**
     * 
     * @type {string}
     * @memberof BaningProductsCallback
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface BankingApplication
 */
export interface BankingApplication {
    /**
     * 
     * @type {number}
     * @memberof BankingApplication
     */
    id?: number;
    /**
     * 
     * @type {Profile}
     * @memberof BankingApplication
     */
    profile?: Profile;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    email?: string;
    /**
     * 
     * @type {Shop}
     * @memberof BankingApplication
     */
    shopObject?: Shop;
    /**
     * 
     * @type {ShortBankingProduct}
     * @memberof BankingApplication
     */
    productObject?: ShortBankingProduct;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    phone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BankingApplication
     */
    shop?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BankingApplication
     */
    product?: string | null;
}
/**
 * 
 * @export
 * @interface BankingProduct
 */
export interface BankingProduct {
    /**
     * 
     * @type {number}
     * @memberof BankingProduct
     */
    id?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof BankingProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof BankingProduct
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof BankingProduct
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    price?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankingProduct
     */
    haveComment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    idFromBank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    productLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    nameFromBank?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankingProduct
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BankingProduct
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    page?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BankingProduct
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BankingProduct
     */
    category?: string | null;
}
/**
 * 
 * @export
 * @interface BankingProductLink
 */
export interface BankingProductLink {
    /**
     * 
     * @type {string}
     * @memberof BankingProductLink
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof BankingProductLink
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface BankingProductPaginateSerialaizer
 */
export interface BankingProductPaginateSerialaizer {
    /**
     * 
     * @type {number}
     * @memberof BankingProductPaginateSerialaizer
     */
    count: number;
    /**
     * 
     * @type {Array<BankingProduct>}
     * @memberof BankingProductPaginateSerialaizer
     */
    results?: Array<BankingProduct>;
}
/**
 * 
 * @export
 * @interface BasePage
 */
export interface BasePage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof BasePage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof BasePage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof BasePage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface BaseProduct
 */
export interface BaseProduct {
    /**
     * 
     * @type {number}
     * @memberof BaseProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    page?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseProduct
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    price?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseProduct
     */
    haveComment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    externalLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    authType?: BaseProductAuthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BaseProduct
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseProduct
     */
    buttonName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseProduct
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseProduct
     */
    parent?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseProduct
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {Set<number>}
     * @memberof BaseProduct
     */
    documents?: Set<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum BaseProductAuthTypeEnum {
    SogazInsurance = 'sogaz_insurance',
    WineMarket = 'wine_market'
}

/**
 * 
 * @export
 * @interface CaptchaImage
 */
export interface CaptchaImage {
    /**
     * 
     * @type {string}
     * @memberof CaptchaImage
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof Cart
     */
    products?: Array<CartProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof Cart
     */
    hasWine: boolean;
}
/**
 * 
 * @export
 * @interface CartInfo
 */
export interface CartInfo {
    /**
     * 
     * @type {number}
     * @memberof CartInfo
     */
    count: number;
    /**
     * 
     * @type {boolean}
     * @memberof CartInfo
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface CartProduct
 */
export interface CartProduct {
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    id?: number;
    /**
     * 
     * @type {ProductRead}
     * @memberof CartProduct
     */
    product?: ProductRead;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof CartProduct
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id?: number;
    /**
     * 
     * @type {CategoryPage}
     * @memberof Category
     */
    page: CategoryPage;
    /**
     * 
     * @type {Array<Subcategory>}
     * @memberof Category
     */
    subcategories: Array<Subcategory>;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    cardType: CategoryCardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    logo?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof Category
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    preview?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    mapFrame?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    specificType?: CategorySpecificTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}
/**
    * @export
    * @enum {string}
    */
export enum CategorySpecificTypeEnum {
    Default = 'default',
    Wine = 'wine',
    Medicine = 'medicine'
}

/**
 * 
 * @export
 * @interface CategoryPage
 */
export interface CategoryPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof CategoryPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof CategoryPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface Child
 */
export interface Child {
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    sex?: ChildSexEnum;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    birthday?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    id?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ChildSexEnum {
    Male = 'male',
    Female = 'female'
}

/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {number}
     * @memberof City
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface CloseErrorScreen
 */
export interface CloseErrorScreen {
    /**
     * 
     * @type {number}
     * @memberof CloseErrorScreen
     */
    screen: number;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    icon?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    inMainPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    inProjectPage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    parent?: number | null;
}
/**
 * 
 * @export
 * @interface CreatorsPage
 */
export interface CreatorsPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof CreatorsPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof CreatorsPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CreatorsPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {number}
     * @memberof DateRange
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    endDate: string;
}
/**
 * 
 * @export
 * @interface DefaultImage
 */
export interface DefaultImage {
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultImage
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultImage
     */
    file?: string;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof DefaultImage
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    focalPointX?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    focalPointY?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    focalPointWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    focalPointHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    fileSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DefaultImage
     */
    fileHash?: string;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    collection?: number;
    /**
     * 
     * @type {number}
     * @memberof DefaultImage
     */
    uploadedByUser?: number | null;
}
/**
 * 
 * @export
 * @interface Direction
 */
export interface Direction {
    /**
     * 
     * @type {number}
     * @memberof Direction
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Direction
     */
    category: string;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    fileSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    fileHash?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    collection?: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    uploadedByUser?: number | null;
}
/**
 * 
 * @export
 * @interface DynamicApplication
 */
export interface DynamicApplication {
    /**
     * 
     * @type {number}
     * @memberof DynamicApplication
     */
    id?: number;
    /**
     * 
     * @type {Profile}
     * @memberof DynamicApplication
     */
    profile?: Profile;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    fieldsData?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DynamicApplication
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DynamicApplication
     */
    shop?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DynamicApplication
     */
    form: number | null;
}
/**
 * 
 * @export
 * @interface EmailModal
 */
export interface EmailModal {
    /**
     * 
     * @type {string}
     * @memberof EmailModal
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailModal
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface ErrorScreenConfigModel
 */
export interface ErrorScreenConfigModel {
    /**
     * 
     * @type {number}
     * @memberof ErrorScreenConfigModel
     */
    id?: number;
    /**
     * 
     * @type {ErrorScreenModel}
     * @memberof ErrorScreenConfigModel
     */
    errorScreen?: ErrorScreenModel;
    /**
     * 
     * @type {number}
     * @memberof ErrorScreenConfigModel
     */
    product?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ErrorScreenConfigModel
     */
    category?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ErrorScreenConfigModel
     */
    page?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenConfigModel
     */
    content?: ErrorScreenConfigModelContentEnum;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenConfigModel
     */
    needShow?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ErrorScreenConfigModelContentEnum {
    MoreTvPromoCodee = 'MORE_TV_PROMO_CODEE',
    Cart = 'CART',
    Profile = 'PROFILE'
}

/**
 * 
 * @export
 * @interface ErrorScreenModel
 */
export interface ErrorScreenModel {
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenModel
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenModel
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenModel
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface ErrorScreenRelated
 */
export interface ErrorScreenRelated {
    /**
     * 
     * @type {number}
     * @memberof ErrorScreenRelated
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenRelated
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenRelated
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenRelated
     */
    detail: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorScreenRelated
     */
    updated?: string;
}
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {number}
     * @memberof Feedback
     */
    id?: number;
    /**
     * 
     * @type {FeedbackTopic}
     * @memberof Feedback
     */
    topic: FeedbackTopic;
    /**
     * 
     * @type {Profile}
     * @memberof Feedback
     */
    author?: Profile;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    page?: string | null;
}
/**
 * 
 * @export
 * @interface FeedbackTopic
 */
export interface FeedbackTopic {
    /**
     * 
     * @type {number}
     * @memberof FeedbackTopic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackTopic
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface FinanceCategory
 */
export interface FinanceCategory {
    /**
     * 
     * @type {number}
     * @memberof FinanceCategory
     */
    id?: number;
    /**
     * 
     * @type {CategoryPage}
     * @memberof FinanceCategory
     */
    page: CategoryPage;
    /**
     * 
     * @type {string}
     * @memberof FinanceCategory
     */
    logo?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof FinanceCategory
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof FinanceCategory
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FinanceCategory
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinanceCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FinanceCategory
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinanceCategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinanceCategory
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FinanceCategory
     */
    cardType?: FinanceCategoryCardTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FinanceCategory
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FinanceCategory
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FinanceCategory
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FinanceCategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}

/**
 * 
 * @export
 * @interface FooterContacts
 */
export interface FooterContacts {
    /**
     * 
     * @type {boolean}
     * @memberof FooterContacts
     */
    isTitle?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FooterContacts
     */
    name: string;
}
/**
 * 
 * @export
 * @interface FooterLink
 */
export interface FooterLink {
    /**
     * 
     * @type {number}
     * @memberof FooterLink
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof FooterLink
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FooterLink
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FooterLink
     */
    link: string;
    /**
     * 
     * @type {number}
     * @memberof FooterLink
     */
    column?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FooterLink
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FooterLink
     */
    applicationForm?: number | null;
}
/**
 * 
 * @export
 * @interface FooterSocials
 */
export interface FooterSocials {
    /**
     * 
     * @type {number}
     * @memberof FooterSocials
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FooterSocials
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FooterSocials
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterSocials
     */
    hoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof FooterSocials
     */
    actionType?: FooterSocialsActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FooterSocials
     */
    link?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FooterSocials
     */
    applicationForm?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FooterSocialsActionTypeEnum {
    Link = 'link',
    ApplicationForm = 'application_form'
}

/**
 * 
 * @export
 * @interface GlobalApplicaltion
 */
export interface GlobalApplicaltion {
    /**
     * 
     * @type {number}
     * @memberof GlobalApplicaltion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalApplicaltion
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalApplicaltion
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalApplicaltion
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalApplicaltion
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalApplicaltion
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalApplicaltion
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalApplicaltion
     */
    profile: number;
    /**
     * 
     * @type {number}
     * @memberof GlobalApplicaltion
     */
    shop?: number | null;
}
/**
 * 
 * @export
 * @interface GlobalCategory
 */
export interface GlobalCategory {
    /**
     * 
     * @type {number}
     * @memberof GlobalCategory
     */
    id?: number;
    /**
     * 
     * @type {CategoryPage}
     * @memberof GlobalCategory
     */
    page: CategoryPage;
    /**
     * 
     * @type {Array<Subcategory>}
     * @memberof GlobalCategory
     */
    subcategories?: Array<Subcategory>;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    categoryType: GlobalCategoryCategoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    logo?: string;
    /**
     * 
     * @type {number}
     * @memberof GlobalCategory
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalCategory
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalCategory
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    cardType?: GlobalCategoryCardTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalCategory
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategory
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum GlobalCategoryCategoryTypeEnum {
    Catalog = 'catalog',
    Insurance = 'insurance',
    Finance = 'finance',
    Media = 'media',
    Guide = 'guide',
    Medicine = 'medicine'
}
/**
    * @export
    * @enum {string}
    */
export enum GlobalCategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}

/**
 * 
 * @export
 * @interface GlobalCategoryPage
 */
export interface GlobalCategoryPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof GlobalCategoryPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof GlobalCategoryPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalCategoryPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface GuideCategory
 */
export interface GuideCategory {
    /**
     * 
     * @type {number}
     * @memberof GuideCategory
     */
    id?: number;
    /**
     * 
     * @type {MainGuidePage}
     * @memberof GuideCategory
     */
    page: MainGuidePage;
    /**
     * 
     * @type {string}
     * @memberof GuideCategory
     */
    logo?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof GuideCategory
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof GuideCategory
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GuideCategory
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuideCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GuideCategory
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuideCategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuideCategory
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuideCategory
     */
    cardType?: GuideCategoryCardTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GuideCategory
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GuideCategory
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GuideCategory
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum GuideCategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}

/**
 * 
 * @export
 * @interface GuideComment
 */
export interface GuideComment {
    /**
     * 
     * @type {number}
     * @memberof GuideComment
     */
    id?: number;
    /**
     * 
     * @type {ProfileDataForComment}
     * @memberof GuideComment
     */
    profile?: ProfileDataForComment;
    /**
     * 
     * @type {number}
     * @memberof GuideComment
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GuideComment
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuideComment
     */
    childrenCommentsCount?: string;
    /**
     * 
     * @type {GuideCommentChildren}
     * @memberof GuideComment
     */
    childrenComments?: GuideCommentChildren[];
    /**
     * 
     * @type {string}
     * @memberof GuideComment
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof GuideComment
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof GuideComment
     */
    guide: string;
    /**
     * 
     * @type {number}
     * @memberof GuideComment
     */
    parentComment?: number | null;
}
/**
 * 
 * @export
 * @interface GuideCommentChildren
 */
export interface GuideCommentChildren {
    /**
     * 
     * @type {number}
     * @memberof GuideCommentChildren
     */
    id?: number;
    /**
     * 
     * @type {ProfileDataForComment}
     * @memberof GuideCommentChildren
     */
    profile?: ProfileDataForComment;
    /**
     * 
     * @type {number}
     * @memberof GuideCommentChildren
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GuideCommentChildren
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuideCommentChildren
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof GuideCommentChildren
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof GuideCommentChildren
     */
    guide: string;
    /**
     * 
     * @type {number}
     * @memberof GuideCommentChildren
     */
    parentComment?: number | null;
}
/**
 * 
 * @export
 * @interface GuideDirection
 */
export interface GuideDirection {
    /**
     * 
     * @type {number}
     * @memberof GuideDirection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GuideDirection
     */
    name: string;
}
/**
 * 
 * @export
 * @interface GuidePage
 */
export interface GuidePage {
    /**
     * 
     * @type {number}
     * @memberof GuidePage
     */
    id?: number;
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof GuidePage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof GuidePage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof GuidePage
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof GuidePage
     */
    publishedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GuidePage
     */
    likesCount?: number;
    /**
     * 
     * @type {Array<ProfileLike>}
     * @memberof GuidePage
     */
    likeAuthors?: Array<ProfileLike>;
    /**
     * 
     * @type {boolean}
     * @memberof GuidePage
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GuidePage
     */
    isInUserFavorites?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuidePage
     */
    averageRating?: string;
    /**
     * 
     * @type {string}
     * @memberof GuidePage
     */
    userRating?: string;
    /**
     * 
     * @type {Array<RelatedGuidePage>}
     * @memberof GuidePage
     */
    relatedGuides: Array<RelatedGuidePage>;
    /**
     * 
     * @type {Array<GuideDirection>}
     * @memberof GuidePage
     */
    directions?: Array<GuideDirection>;
    /**
     * 
     * @type {number}
     * @memberof GuidePage
     */
    commentsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GuidePage
     */
    viewsCount?: number;
}
/**
 * 
 * @export
 * @interface GuidePagePaginateSerialaizer
 */
export interface GuidePagePaginateSerialaizer {
    /**
     * 
     * @type {number}
     * @memberof GuidePagePaginateSerialaizer
     */
    count: number;
    /**
     * 
     * @type {Array<GuidePage>}
     * @memberof GuidePagePaginateSerialaizer
     */
    results?: Array<GuidePage>;
}
/**
 * 
 * @export
 * @interface GuideRating
 */
export interface GuideRating {
    /**
     * 
     * @type {string}
     * @memberof GuideRating
     */
    profile?: string;
    /**
     * 
     * @type {number}
     * @memberof GuideRating
     */
    ratingValue: number;
    /**
     * 
     * @type {string}
     * @memberof GuideRating
     */
    guide: string;
}
/**
 * 
 * @export
 * @interface Hobby
 */
export interface Hobby {
    /**
     * 
     * @type {number}
     * @memberof Hobby
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof InlineResponse200
     */
    results: Array<Activity>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Application>}
     * @memberof InlineResponse2001
     */
    results: Array<Application>;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20010
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof InlineResponse20010
     */
    results: Array<Company>;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20011
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Direction>}
     * @memberof InlineResponse20011
     */
    results: Array<Direction>;
}
/**
 * 
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20012
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20012
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Document>}
     * @memberof InlineResponse20012
     */
    results: Array<Document>;
}
/**
 * 
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20013
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20013
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<DynamicApplication>}
     * @memberof InlineResponse20013
     */
    results: Array<DynamicApplication>;
}
/**
 * 
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20014
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20014
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20014
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ErrorScreenConfigModel>}
     * @memberof InlineResponse20014
     */
    results: Array<ErrorScreenConfigModel>;
}
/**
 * 
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20015
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20015
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20015
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Feedback>}
     * @memberof InlineResponse20015
     */
    results: Array<Feedback>;
}
/**
 * 
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20016
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20016
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<FeedbackTopic>}
     * @memberof InlineResponse20016
     */
    results: Array<FeedbackTopic>;
}
/**
 * 
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20017
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20017
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20017
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<FooterLink>}
     * @memberof InlineResponse20017
     */
    results: Array<FooterLink>;
}
/**
 * 
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20018
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20018
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<FooterSocials>}
     * @memberof InlineResponse20018
     */
    results: Array<FooterSocials>;
}
/**
 * 
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20019
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20019
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GlobalApplicaltion>}
     * @memberof InlineResponse20019
     */
    results: Array<GlobalApplicaltion>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ApplicationForm>}
     * @memberof InlineResponse2002
     */
    results: Array<ApplicationForm>;
}
/**
 * 
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20020
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20020
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20020
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GlobalCategory>}
     * @memberof InlineResponse20020
     */
    results: Array<GlobalCategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20021
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20021
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20021
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<BaseProduct>}
     * @memberof InlineResponse20021
     */
    results: Array<BaseProduct>;
}
/**
 * 
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20022
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20022
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20022
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GuideComment>}
     * @memberof InlineResponse20022
     */
    results: Array<GuideComment>;
}
/**
 * 
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20023
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20023
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20023
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProfileLike>}
     * @memberof InlineResponse20023
     */
    results: Array<ProfileLike>;
}
/**
 * 
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20024
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20024
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20024
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GuideDirection>}
     * @memberof InlineResponse20024
     */
    results: Array<GuideDirection>;
}
/**
 * 
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20025
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20025
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20025
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GuidePage>}
     * @memberof InlineResponse20025
     */
    results: Array<GuidePage>;
}
/**
 * 
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20026
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20026
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20026
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<GuideRating>}
     * @memberof InlineResponse20026
     */
    results: Array<GuideRating>;
}
/**
 * 
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20027
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20027
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Hobby>}
     * @memberof InlineResponse20027
     */
    results: Array<Hobby>;
}
/**
 * 
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20028
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20028
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20028
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<DefaultImage>}
     * @memberof InlineResponse20028
     */
    results: Array<DefaultImage>;
}
/**
 * 
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20029
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20029
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20029
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<InsuranceProduct>}
     * @memberof InlineResponse20029
     */
    results: Array<InsuranceProduct>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2003
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<BankingApplication>}
     * @memberof InlineResponse2003
     */
    results: Array<BankingApplication>;
}
/**
 * 
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20030
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20030
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20030
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MainBanner>}
     * @memberof InlineResponse20030
     */
    results: Array<MainBanner>;
}
/**
 * 
 * @export
 * @interface InlineResponse20031
 */
export interface InlineResponse20031 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20031
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20031
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20031
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MediaApplication>}
     * @memberof InlineResponse20031
     */
    results: Array<MediaApplication>;
}
/**
 * 
 * @export
 * @interface InlineResponse20032
 */
export interface InlineResponse20032 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20032
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20032
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20032
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MediaProduct>}
     * @memberof InlineResponse20032
     */
    results: Array<MediaProduct>;
}
/**
 * 
 * @export
 * @interface InlineResponse20033
 */
export interface InlineResponse20033 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20033
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20033
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20033
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MedicineCenter>}
     * @memberof InlineResponse20033
     */
    results: Array<MedicineCenter>;
}
/**
 * 
 * @export
 * @interface InlineResponse20034
 */
export interface InlineResponse20034 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20034
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20034
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20034
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MedicineDirection>}
     * @memberof InlineResponse20034
     */
    results: Array<MedicineDirection>;
}
/**
 * 
 * @export
 * @interface InlineResponse20035
 */
export interface InlineResponse20035 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20035
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20035
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20035
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MedicineProgram>}
     * @memberof InlineResponse20035
     */
    results: Array<MedicineProgram>;
}
/**
 * 
 * @export
 * @interface InlineResponse20036
 */
export interface InlineResponse20036 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20036
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20036
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20036
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<NewsComment>}
     * @memberof InlineResponse20036
     */
    results: Array<NewsComment>;
}
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20037
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20037
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20037
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<NewsPage>}
     * @memberof InlineResponse20037
     */
    results: Array<NewsPage>;
}
/**
 * 
 * @export
 * @interface InlineResponse20038
 */
export interface InlineResponse20038 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20038
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20038
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20038
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<NewsSection>}
     * @memberof InlineResponse20038
     */
    results: Array<NewsSection>;
}
/**
 * 
 * @export
 * @interface InlineResponse20039
 */
export interface InlineResponse20039 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20039
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20039
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20039
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<OurPeopleArticle>}
     * @memberof InlineResponse20039
     */
    results: Array<OurPeopleArticle>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<BankingProduct>}
     * @memberof InlineResponse2004
     */
    results: Array<BankingProduct>;
}
/**
 * 
 * @export
 * @interface InlineResponse20040
 */
export interface InlineResponse20040 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20040
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20040
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20040
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<PageContent>}
     * @memberof InlineResponse20040
     */
    results: Array<PageContent>;
}
/**
 * 
 * @export
 * @interface InlineResponse20041
 */
export interface InlineResponse20041 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20041
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20041
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20041
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<PortalDirectionItem>}
     * @memberof InlineResponse20041
     */
    results: Array<PortalDirectionItem>;
}
/**
 * 
 * @export
 * @interface InlineResponse20042
 */
export interface InlineResponse20042 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20042
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20042
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20042
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProductAttribute>}
     * @memberof InlineResponse20042
     */
    results: Array<ProductAttribute>;
}
/**
 * 
 * @export
 * @interface InlineResponse20043
 */
export interface InlineResponse20043 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20043
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20043
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20043
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProductRating>}
     * @memberof InlineResponse20043
     */
    results: Array<ProductRating>;
}
/**
 * 
 * @export
 * @interface InlineResponse20044
 */
export interface InlineResponse20044 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20044
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20044
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20044
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProductList>}
     * @memberof InlineResponse20044
     */
    results: Array<ProductList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20045
 */
export interface InlineResponse20045 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20045
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20045
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20045
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProductComment>}
     * @memberof InlineResponse20045
     */
    results: Array<ProductComment>;
}
/**
 * 
 * @export
 * @interface InlineResponse20046
 */
export interface InlineResponse20046 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20046
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20046
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20046
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Profile>}
     * @memberof InlineResponse20046
     */
    results: Array<Profile>;
}
/**
 * 
 * @export
 * @interface InlineResponse20047
 */
export interface InlineResponse20047 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20047
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20047
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20047
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AwardBusiness>}
     * @memberof InlineResponse20047
     */
    results: Array<AwardBusiness>;
}
/**
 * 
 * @export
 * @interface InlineResponse20048
 */
export interface InlineResponse20048 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20048
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20048
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20048
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AwardParticipantsList>}
     * @memberof InlineResponse20048
     */
    results: Array<AwardParticipantsList>;
}
/**
 * 
 * @export
 * @interface InlineResponse20049
 */
export interface InlineResponse20049 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20049
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20049
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20049
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AwardWinners>}
     * @memberof InlineResponse20049
     */
    results: Array<AwardWinners>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<BasePage>}
     * @memberof InlineResponse2005
     */
    results: Array<BasePage>;
}
/**
 * 
 * @export
 * @interface InlineResponse20050
 */
export interface InlineResponse20050 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20050
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20050
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20050
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Awards>}
     * @memberof InlineResponse20050
     */
    results: Array<Awards>;
}
/**
 * 
 * @export
 * @interface InlineResponse20051
 */
export interface InlineResponse20051 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20051
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20051
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20051
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Magazine>}
     * @memberof InlineResponse20051
     */
    results: Array<Magazine>;
}
/**
 * 
 * @export
 * @interface InlineResponse20052
 */
export interface InlineResponse20052 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20052
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20052
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20052
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<MagazineArticle>}
     * @memberof InlineResponse20052
     */
    results: Array<MagazineArticle>;
}
/**
 * 
 * @export
 * @interface InlineResponse20053
 */
export interface InlineResponse20053 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20053
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20053
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20053
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProjectCards>}
     * @memberof InlineResponse20053
     */
    results: Array<ProjectCards>;
}
/**
 * 
 * @export
 * @interface InlineResponse20054
 */
export interface InlineResponse20054 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20054
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20054
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20054
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<RestaurantSchedule>}
     * @memberof InlineResponse20054
     */
    results: Array<RestaurantSchedule>;
}
/**
 * 
 * @export
 * @interface InlineResponse20055
 */
export interface InlineResponse20055 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20055
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20055
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20055
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Shop>}
     * @memberof InlineResponse20055
     */
    results: Array<Shop>;
}
/**
 * 
 * @export
 * @interface InlineResponse20056
 */
export interface InlineResponse20056 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20056
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20056
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20056
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Subcategory>}
     * @memberof InlineResponse20056
     */
    results: Array<Subcategory>;
}
/**
 * 
 * @export
 * @interface InlineResponse20057
 */
export interface InlineResponse20057 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20057
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20057
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<TourSchedule>}
     * @memberof InlineResponse20057
     */
    results: Array<TourSchedule>;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof InlineResponse2006
     */
    results: Array<CartProduct>;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Category>}
     * @memberof InlineResponse2007
     */
    results: Array<Category>;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<CategoryPage>}
     * @memberof InlineResponse2008
     */
    results: Array<CategoryPage>;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<City>}
     * @memberof InlineResponse2009
     */
    results: Array<City>;
}
/**
 * 
 * @export
 * @interface InsuranceCategory
 */
export interface InsuranceCategory {
    /**
     * 
     * @type {number}
     * @memberof InsuranceCategory
     */
    id?: number;
    /**
     * 
     * @type {CategoryPage}
     * @memberof InsuranceCategory
     */
    page: CategoryPage;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCategory
     */
    logo?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof InsuranceCategory
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCategory
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCategory
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCategory
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCategory
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCategory
     */
    cardType?: InsuranceCategoryCardTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceCategory
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsuranceCategory
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCategory
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InsuranceCategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}

/**
 * 
 * @export
 * @interface InsuranceProduct
 */
export interface InsuranceProduct {
    /**
     * 
     * @type {number}
     * @memberof InsuranceProduct
     */
    id?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof InsuranceProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof InsuranceProduct
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    externalLink?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceProduct
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    price?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsuranceProduct
     */
    haveComment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    created?: string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceProduct
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    buttonName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsuranceProduct
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsuranceProduct
     */
    parent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    page?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsuranceProduct
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsuranceProduct
     */
    category?: string | null;
}
/**
 * 
 * @export
 * @interface InsuranceProductPaginateSerialaizer
 */
export interface InsuranceProductPaginateSerialaizer {
    /**
     * 
     * @type {number}
     * @memberof InsuranceProductPaginateSerialaizer
     */
    count: number;
    /**
     * 
     * @type {Array<InsuranceProduct>}
     * @memberof InsuranceProductPaginateSerialaizer
     */
    results?: Array<InsuranceProduct>;
}
/**
 * 
 * @export
 * @interface LdapToken
 */
export interface LdapToken {
    /**
     * 
     * @type {string}
     * @memberof LdapToken
     */
    ldap: string;
}
/**
 * 
 * @export
 * @interface Magazine
 */
export interface Magazine {
    /**
     * 
     * @type {number}
     * @memberof Magazine
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Magazine
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof Magazine
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof Magazine
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof Magazine
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Magazine
     */
    pdfFile?: string;
}
/**
 * 
 * @export
 * @interface MagazineArticle
 */
export interface MagazineArticle {
    /**
     * 
     * @type {number}
     * @memberof MagazineArticle
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MagazineArticle
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MagazineArticle
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof MagazineArticle
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof MagazineArticle
     */
    image?: string;
    /**
     * 
     * @type {Magazine}
     * @memberof MagazineArticle
     */
    magazine: Magazine;
    /**
     * 
     * @type {string}
     * @memberof MagazineArticle
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof MagazineArticle
     */
    pdf?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MagazineArticle
     */
    likesCount?: number;
    /**
     * 
     * @type {Array<ProfileLike>}
     * @memberof MagazineArticle
     */
    likeAuthors?: Array<ProfileLike>;
    /**
     * 
     * @type {boolean}
     * @memberof MagazineArticle
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MagazineArticle
     */
    isInUserFavorites?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MagazineArticle
     */
    thumpsUpCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MagazineArticle
     */
    isUserGetThumbsUp?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MagazineArticle
     */
    viewsCount?: number;
}
/**
 * 
 * @export
 * @interface MagazineArticlePaginate
 */
export interface MagazineArticlePaginate {
    /**
     * 
     * @type {number}
     * @memberof MagazineArticlePaginate
     */
    count: number;
    /**
     * 
     * @type {Array<MagazineArticle>}
     * @memberof MagazineArticlePaginate
     */
    results?: Array<MagazineArticle>;
}
/**
 * 
 * @export
 * @interface MainBanner
 */
export interface MainBanner {
    /**
     * 
     * @type {number}
     * @memberof MainBanner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MainBanner
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof MainBanner
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof MainBanner
     */
    buttonText: string;
    /**
     * 
     * @type {string}
     * @memberof MainBanner
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof MainBanner
     */
    scrollingSpeed: number;
    /**
     * 
     * @type {number}
     * @memberof MainBanner
     */
    order?: number;
}
/**
 * 
 * @export
 * @interface MainGuidePage
 */
export interface MainGuidePage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MainGuidePage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof MainGuidePage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof MainGuidePage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface MainNewsPage
 */
export interface MainNewsPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MainNewsPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof MainNewsPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof MainNewsPage
     */
    content: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof MainNewsPage
     */
    errorScreen?: ErrorScreenRelated;
}
/**
 * 
 * @export
 * @interface MainPage
 */
export interface MainPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MainPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof MainPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof MainPage
     */
    content: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof MainPage
     */
    errorScreen?: ErrorScreenRelated;
}
/**
 * 
 * @export
 * @interface MarketsSertializer
 */
export interface MarketsSertializer {
    /**
     * 
     * @type {string}
     * @memberof MarketsSertializer
     */
    name: string;
}
/**
 * 
 * @export
 * @interface MediaApplication
 */
export interface MediaApplication {
    /**
     * 
     * @type {number}
     * @memberof MediaApplication
     */
    id?: number;
    /**
     * 
     * @type {Profile}
     * @memberof MediaApplication
     */
    profile?: Profile;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    email?: string;
    /**
     * 
     * @type {Shop}
     * @memberof MediaApplication
     */
    shopObject?: Shop;
    /**
     * 
     * @type {ShortMediaProduct}
     * @memberof MediaApplication
     */
    productObject?: ShortMediaProduct;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    phone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaApplication
     */
    shop?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaApplication
     */
    product?: string | null;
}
/**
 * 
 * @export
 * @interface MediaCategory
 */
export interface MediaCategory {
    /**
     * 
     * @type {number}
     * @memberof MediaCategory
     */
    id?: number;
    /**
     * 
     * @type {MediaCategoryPage}
     * @memberof MediaCategory
     */
    page: MediaCategoryPage;
    /**
     * 
     * @type {string}
     * @memberof MediaCategory
     */
    logo?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof MediaCategory
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof MediaCategory
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MediaCategory
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MediaCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaCategory
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MediaCategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaCategory
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaCategory
     */
    cardType?: MediaCategoryCardTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MediaCategory
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MediaCategory
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaCategory
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaCategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}

/**
 * 
 * @export
 * @interface MediaCategoryPage
 */
export interface MediaCategoryPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MediaCategoryPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof MediaCategoryPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof MediaCategoryPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface MediaProduct
 */
export interface MediaProduct {
    /**
     * 
     * @type {number}
     * @memberof MediaProduct
     */
    id?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof MediaProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof MediaProduct
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof MediaProduct
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    price?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MediaProduct
     */
    haveComment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    externalLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    authType?: MediaProductAuthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MediaProduct
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    buttonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    specialService?: MediaProductSpecialServiceEnum;
    /**
     * 
     * @type {number}
     * @memberof MediaProduct
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MediaProduct
     */
    parent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    page?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaProduct
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MediaProduct
     */
    category?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaProductAuthTypeEnum {
    SogazInsurance = 'sogaz_insurance',
    WineMarket = 'wine_market'
}
/**
    * @export
    * @enum {string}
    */
export enum MediaProductSpecialServiceEnum {
    MoreTv = 'more_tv',
    SportExpress = 'sport_express'
}

/**
 * 
 * @export
 * @interface MediaProductPaginateSerialaizer
 */
export interface MediaProductPaginateSerialaizer {
    /**
     * 
     * @type {number}
     * @memberof MediaProductPaginateSerialaizer
     */
    count: number;
    /**
     * 
     * @type {Array<MediaProduct>}
     * @memberof MediaProductPaginateSerialaizer
     */
    results?: Array<MediaProduct>;
}
/**
 * 
 * @export
 * @interface MedicineCategory
 */
export interface MedicineCategory {
    /**
     * 
     * @type {number}
     * @memberof MedicineCategory
     */
    id: number;
    /**
     * 
     * @type {MedicineCategoryPage}
     * @memberof MedicineCategory
     */
    page: MedicineCategoryPage;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategory
     */
    logo?: string;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof MedicineCategory
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof MedicineCategory
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineCategory
     */
    hasFilter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineCategory
     */
    hasBasket?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategory
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategory
     */
    cardType?: MedicineCategoryCardTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineCategory
     */
    forMarketMenu?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedicineCategory
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategory
     */
    productPage?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicineCategoryCardTypeEnum {
    Grid = 'grid',
    List = 'list'
}

/**
 * 
 * @export
 * @interface MedicineCategoryPage
 */
export interface MedicineCategoryPage {
    /**
     * 
     * @type {number}
     * @memberof MedicineCategoryPage
     */
    id: number;
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MedicineCategoryPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof MedicineCategoryPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineCategoryPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface MedicineCenter
 */
export interface MedicineCenter {
    /**
     * 
     * @type {number}
     * @memberof MedicineCenter
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenter
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicineCenter
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenter
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenter
     */
    headerText: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenter
     */
    mainText: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineCenter
     */
    spoiler?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenter
     */
    type?: MedicineCenterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenter
     */
    address?: string | null;
    /**
     * Поле заполняется только для клиник
     * @type {string}
     * @memberof MedicineCenter
     */
    headerProgram?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineCenter
     */
    needTravelLine?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MedicineCenterTypeEnum {
    Clinic = 'clinic',
    Sanatorium = 'sanatorium'
}

/**
 * 
 * @export
 * @interface MedicineCenterShort
 */
export interface MedicineCenterShort {
    /**
     * 
     * @type {number}
     * @memberof MedicineCenterShort
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenterShort
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineCenterShort
     */
    address?: string | null;
}
/**
 * 
 * @export
 * @interface MedicineDirection
 */
export interface MedicineDirection {
    /**
     * 
     * @type {number}
     * @memberof MedicineDirection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MedicineDirection
     */
    name: string;
}
/**
 * 
 * @export
 * @interface MedicineProgram
 */
export interface MedicineProgram {
    /**
     * 
     * @type {number}
     * @memberof MedicineProgram
     */
    id: number;
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MedicineProgram
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    description: string;
    /**
     * 
     * @type {MedicineCenterShort}
     * @memberof MedicineProgram
     */
    center: MedicineCenterShort;
    /**
     * Поле заполняется только для санаториев
     * @type {boolean}
     * @memberof MedicineProgram
     */
    hasResidence?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    presentation?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineProgram
     */
    hasPrice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedicineProgram
     */
    formPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    salePrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    mainPrice?: string | null;
    /**
     * Поле заполняется только для санаториев
     * @type {string}
     * @memberof MedicineProgram
     */
    periodPrice?: string | null;
    /**
     * Поле заполняется только для клиник
     * @type {string}
     * @memberof MedicineProgram
     */
    btnPrice?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedicineProgram
     */
    hasContact?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedicineProgram
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    headerContact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    phoneContact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    textContact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    btnContact?: string | null;
    /**
     * 
     * @type {Array<MedicineDirection>}
     * @memberof MedicineProgram
     */
    directions?: Array<MedicineDirection>;
    /**
     * 
     * @type {Array<MedicineUsp>}
     * @memberof MedicineProgram
     */
    usps?: Array<MedicineUsp>;
    /**
     * 
     * @type {Array<MedicineProgramSimple>}
     * @memberof MedicineProgram
     */
    specials?: Array<MedicineProgramSimple>;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgram
     */
    content?: string;
}
/**
 * 
 * @export
 * @interface MedicineProgramSimple
 */
export interface MedicineProgramSimple {
    /**
     * 
     * @type {number}
     * @memberof MedicineProgramSimple
     */
    id: number;
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof MedicineProgramSimple
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MedicineProgramSimple
     */
    image?: string;
    /**
     * 
     * @type {MedicineCenterShort}
     * @memberof MedicineProgramSimple
     */
    center: MedicineCenterShort;
}
/**
 * 
 * @export
 * @interface MedicineUsp
 */
export interface MedicineUsp {
    /**
     * 
     * @type {number}
     * @memberof MedicineUsp
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MedicineUsp
     */
    name: string;
}
/**
 * 
 * @export
 * @interface NewAboutProjectPage
 */
export interface NewAboutProjectPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof NewAboutProjectPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof NewAboutProjectPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewAboutProjectPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface NewsComment
 */
export interface NewsComment {
    /**
     * 
     * @type {number}
     * @memberof NewsComment
     */
    id?: number;
    /**
     * 
     * @type {ProfileDataForComment}
     * @memberof NewsComment
     */
    profile?: ProfileDataForComment;
    /**
     * 
     * @type {number}
     * @memberof NewsComment
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewsComment
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsComment
     */
    childrenCommentsCount?: string;
    /**
     * 
     * @type {NewsCommentChildren}
     * @memberof NewsComment
     */
    childrenComments?: NewsCommentChildren[];
    /**
     * 
     * @type {string}
     * @memberof NewsComment
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NewsComment
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsComment
     */
    news: string;
    /**
     * 
     * @type {number}
     * @memberof NewsComment
     */
    parentComment?: number | null;
}
/**
 * 
 * @export
 * @interface NewsCommentChildren
 */
export interface NewsCommentChildren {
    /**
     * 
     * @type {number}
     * @memberof NewsCommentChildren
     */
    id?: number;
    /**
     * 
     * @type {ProfileDataForComment}
     * @memberof NewsCommentChildren
     */
    profile?: ProfileDataForComment;
    /**
     * 
     * @type {number}
     * @memberof NewsCommentChildren
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewsCommentChildren
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsCommentChildren
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof NewsCommentChildren
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsCommentChildren
     */
    news: string;
    /**
     * 
     * @type {number}
     * @memberof NewsCommentChildren
     */
    parentComment?: number | null;
}
/**
 * 
 * @export
 * @interface NewsPage
 */
export interface NewsPage {
    /**
     * 
     * @type {number}
     * @memberof NewsPage
     */
    id?: number;
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof NewsPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof NewsPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof NewsPage
     */
    likesCount?: number;
    /**
     * 
     * @type {Array<ProfileLike>}
     * @memberof NewsPage
     */
    likeAuthors?: Array<ProfileLike>;
    /**
     * 
     * @type {boolean}
     * @memberof NewsPage
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsPage
     */
    isInUserFavorites?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsPage
     */
    publishedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsPage
     */
    viewsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NewsPage
     */
    commentsCount?: number;
    /**
     * 
     * @type {Array<NewsSection>}
     * @memberof NewsPage
     */
    sections?: Array<NewsSection>;
}
/**
 * 
 * @export
 * @interface NewsPagePaginateSerialaizer
 */
export interface NewsPagePaginateSerialaizer {
    /**
     * 
     * @type {number}
     * @memberof NewsPagePaginateSerialaizer
     */
    count: number;
    /**
     * 
     * @type {Array<NewsPage>}
     * @memberof NewsPagePaginateSerialaizer
     */
    results?: Array<NewsPage>;
}
/**
 * 
 * @export
 * @interface NewsSection
 */
export interface NewsSection {
    /**
     * 
     * @type {number}
     * @memberof NewsSection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof NewsSection
     */
    name: string;
}
/**
 * 
 * @export
 * @interface OauthToken
 */
export interface OauthToken {
    /**
     * 
     * @type {string}
     * @memberof OauthToken
     */
    key: string;
}
/**
 * 
 * @export
 * @interface OurPeopleArticle
 */
export interface OurPeopleArticle {
    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticle
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticle
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticle
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticle
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticle
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticle
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticle
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OurPeopleArticle
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticle
     */
    thumpsUpCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OurPeopleArticle
     */
    isUserGetThumbsUp?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticle
     */
    viewsCount?: number;
}
/**
 * 
 * @export
 * @interface PageContent
 */
export interface PageContent {
    /**
     * 
     * @type {number}
     * @memberof PageContent
     */
    id?: number;
    /**
     * 
     * @type {Array<PageContentItem>}
     * @memberof PageContent
     */
    items?: Array<PageContentItem>;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface PageContentItem
 */
export interface PageContentItem {
    /**
     * 
     * @type {string}
     * @memberof PageContentItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PageContentItem
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof PageContentItem
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ParticipantItems
 */
export interface ParticipantItems {
    /**
     * 
     * @type {number}
     * @memberof ParticipantItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantItems
     */
    name: string;
    /**
     * 
     * @type {AwardBusiness}
     * @memberof ParticipantItems
     */
    company?: AwardBusiness;
    /**
     * 
     * @type {string}
     * @memberof ParticipantItems
     */
    image?: string | null;
}
/**
 * 
 * @export
 * @interface PortalDirectionItem
 */
export interface PortalDirectionItem {
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionItem
     */
    id?: number;
    /**
     * 
     * @type {Array<PortalDirectionSubItem>}
     * @memberof PortalDirectionItem
     */
    subItems?: Array<PortalDirectionSubItem>;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItem
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItem
     */
    link: string;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionItem
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionItem
     */
    orderMainPage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionItem
     */
    orderProjectPage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItem
     */
    preview?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItem
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PortalDirectionItem
     */
    forMainPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortalDirectionItem
     */
    forProjectPage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortalDirectionItem
     */
    forTopMenu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortalDirectionItem
     */
    forRightMenu?: boolean;
}
/**
 * 
 * @export
 * @interface PortalDirectionSubItem
 */
export interface PortalDirectionSubItem {
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionSubItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionSubItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionSubItem
     */
    link: string;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionSubItem
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionSubItem
     */
    orderMainPage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionSubItem
     */
    orderProjectPage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionSubItem
     */
    icon?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PortalDirectionSubItem
     */
    parentItem: number;
}
/**
 * 
 * @export
 * @interface Preferences
 */
export interface Preferences {
    /**
     * 
     * @type {number}
     * @memberof Preferences
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    showChildren?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Preferences
     */
    showHobbies?: boolean;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    page?: string;
    /**
     * 
     * @type {Array<ProductComment>}
     * @memberof Product
     */
    comments: Array<ProductComment>;
    /**
     * 
     * @type {Array<PropertyForProducts>}
     * @memberof Product
     */
    properties: Array<PropertyForProducts>;
    /**
     * 
     * @type {Array<RelatedGuidePage>}
     * @memberof Product
     */
    relatedGuides?: Array<RelatedGuidePage>;
    /**
     * 
     * @type {Array<RelatedProduct>}
     * @memberof Product
     */
    relatedProducts?: Array<RelatedProduct>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Product
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    price?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    haveComment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    created?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    discountPrice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    subcategory?: number | null;
    /**
     * 
     * @type {ErrorScreenRelated}
     * @memberof Product
     */
    errorScreen?: ErrorScreenRelated;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    userRating?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    averageRating?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {Array<ProfileLike>}
     * @memberof Product
     */
    likeAuthors?: Array<ProfileLike>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isInUserFavorites?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    buttonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    marketsPrefix?: string | null;
    /**
     * 
     * @type {Array<MarketsSertializer>}
     * @memberof Product
     */
    markets: Array<MarketsSertializer>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    marketsPostfix?: string | null;
    /**
     * 
     * @type {Array<Direction>}
     * @memberof Product
     */
    directions?: Array<Direction>;
}
/**
 * 
 * @export
 * @interface ProductAttribute
 */
export interface ProductAttribute {
    /**
     * 
     * @type {number}
     * @memberof ProductAttribute
     */
    id?: number;
    /**
     * 
     * @type {Array<PropertyProductAttribute>}
     * @memberof ProductAttribute
     */
    properties: Array<PropertyProductAttribute>;
    /**
     * 
     * @type {number}
     * @memberof ProductAttribute
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductAttribute
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttribute
     */
    category?: string | null;
}
/**
 * 
 * @export
 * @interface ProductComment
 */
export interface ProductComment {
    /**
     * 
     * @type {number}
     * @memberof ProductComment
     */
    id?: number;
    /**
     * 
     * @type {ProfileDataForComment}
     * @memberof ProductComment
     */
    profile?: ProfileDataForComment;
    /**
     * 
     * @type {number}
     * @memberof ProductComment
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductComment
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductComment
     */
    childrenCommentsCount?: string;
    /**
     * 
     * @type {ProductCommentChildren}
     * @memberof ProductComment
     */
    childrenComments?: ProductCommentChildren[];
    /**
     * 
     * @type {string}
     * @memberof ProductComment
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ProductComment
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductComment
     */
    product: string;
    /**
     * 
     * @type {number}
     * @memberof ProductComment
     */
    parentComment?: number | null;
}
/**
 * 
 * @export
 * @interface ProductCommentChildren
 */
export interface ProductCommentChildren {
    /**
     * 
     * @type {number}
     * @memberof ProductCommentChildren
     */
    id?: number;
    /**
     * 
     * @type {ProfileDataForComment}
     * @memberof ProductCommentChildren
     */
    profile?: ProfileDataForComment;
    /**
     * 
     * @type {number}
     * @memberof ProductCommentChildren
     */
    likesCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCommentChildren
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentChildren
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentChildren
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCommentChildren
     */
    product: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCommentChildren
     */
    parentComment?: number | null;
}
/**
 * 
 * @export
 * @interface ProductList
 */
export interface ProductList {
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    discountPrice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductList
     */
    isLikedByUser?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    userRating?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof ProductList
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    category?: string | null;
    /**
     * 
     * @type {Array<PropertyForProducts>}
     * @memberof ProductList
     */
    properties: Array<PropertyForProducts>;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    pageId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    discountPriceOrPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    averageRating?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    buttonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    marketsPrefix?: string | null;
    /**
     * 
     * @type {Array<MarketsSertializer>}
     * @memberof ProductList
     */
    markets: Array<MarketsSertializer>;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    marketsPostfix?: string | null;
    /**
     * 
     * @type {Array<Direction>}
     * @memberof ProductList
     */
    directions?: Array<Direction>;
    /**
     * 
     * @type {string}
     * @memberof ProductList
     */
    externalLink?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductList
     */
    likesCount?: number;
    /**
     * 
     * @type {Array<ProfileLike>}
     * @memberof ProductList
     */
    likeAuthors?: Array<ProfileLike>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductList
     */
    isInUserFavorites?: boolean;
}
/**
 * 
 * @export
 * @interface ProductPagePreferences
 */
export interface ProductPagePreferences {
    /**
     * 
     * @type {number}
     * @memberof ProductPagePreferences
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPagePreferences
     */
    articlesTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPagePreferences
     */
    mayInterestTitle?: string | null;
}
/**
 * 
 * @export
 * @interface ProductPaginateSerialaizer
 */
export interface ProductPaginateSerialaizer {
    /**
     * 
     * @type {number}
     * @memberof ProductPaginateSerialaizer
     */
    count: number;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductPaginateSerialaizer
     */
    results?: Array<Product>;
}
/**
 * 
 * @export
 * @interface ProductPriceLimit
 */
export interface ProductPriceLimit {
    /**
     * 
     * @type {string}
     * @memberof ProductPriceLimit
     */
    minPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPriceLimit
     */
    maxPrice?: string;
}
/**
 * 
 * @export
 * @interface ProductRating
 */
export interface ProductRating {
    /**
     * 
     * @type {string}
     * @memberof ProductRating
     */
    profile?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRating
     */
    ratingValue: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRating
     */
    product: string;
}
/**
 * 
 * @export
 * @interface ProductRead
 */
export interface ProductRead {
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    id?: number;
    /**
     * 
     * @type {Array<PropertyProductAttribute>}
     * @memberof ProductRead
     */
    properties?: Array<PropertyProductAttribute>;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    price?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRead
     */
    haveComment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    externalLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    authType?: ProductReadAuthTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    buttonName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    discountPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    relatedGuides?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    relatedProducts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    marketsPrefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    marketsPostfix?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    polymorphicCtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    parent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    page?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRead
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRead
     */
    subcategory?: number | null;
    /**
     * 
     * @type {Set<number>}
     * @memberof ProductRead
     */
    documents?: Set<number>;
    /**
     * 
     * @type {Set<number>}
     * @memberof ProductRead
     */
    directions?: Set<number>;
    /**
     * 
     * @type {Set<number>}
     * @memberof ProductRead
     */
    markets?: Set<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductReadAuthTypeEnum {
    SogazInsurance = 'sogaz_insurance',
    WineMarket = 'wine_market'
}

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    firstName?: string;
    /**
     * 
     * @type {User}
     * @memberof Profile
     */
    user?: User;
    /**
     * 
     * @type {City}
     * @memberof Profile
     */
    city?: City;
    /**
     * 
     * @type {Array<Child>}
     * @memberof Profile
     */
    children?: Array<Child>;
    /**
     * 
     * @type {Array<Hobby>}
     * @memberof Profile
     */
    hobbies?: Array<Hobby>;
    /**
     * 
     * @type {Activity}
     * @memberof Profile
     */
    activity?: Activity;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    phone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    publicOfferAgree?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    sex?: ProfileSexEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileSexEnum {
    Male = 'male',
    Female = 'female'
}

/**
 * 
 * @export
 * @interface ProfileDataForComment
 */
export interface ProfileDataForComment {
    /**
     * 
     * @type {string}
     * @memberof ProfileDataForComment
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataForComment
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileDataForComment
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface ProfileLike
 */
export interface ProfileLike {
    /**
     * 
     * @type {number}
     * @memberof ProfileLike
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileLike
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileLike
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileLike
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface ProjectCards
 */
export interface ProjectCards {
    /**
     * 
     * @type {string}
     * @memberof ProjectCards
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCards
     */
    color: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCards
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCards
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCards
     */
    link: string;
}
/**
 * 
 * @export
 * @interface ProjectsArticlePage
 */
export interface ProjectsArticlePage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof ProjectsArticlePage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof ProjectsArticlePage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsArticlePage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ProjectsAwardPage
 */
export interface ProjectsAwardPage {
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof ProjectsAwardPage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof ProjectsAwardPage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectsAwardPage
     */
    content: string;
}
/**
 * 
 * @export
 * @interface PromoCode
 */
export interface PromoCode {
    /**
     * 
     * @type {string}
     * @memberof PromoCode
     */
    promocode: string;
}
/**
 * 
 * @export
 * @interface PropertyForProducts
 */
export interface PropertyForProducts {
    /**
     * 
     * @type {number}
     * @memberof PropertyForProducts
     */
    id?: number;
    /**
     * 
     * @type {AttributeForProduct}
     * @memberof PropertyForProducts
     */
    attribute: AttributeForProduct;
    /**
     * 
     * @type {string}
     * @memberof PropertyForProducts
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyForProducts
     */
    sortOrder?: number | null;
}
/**
 * 
 * @export
 * @interface PropertyProductAttribute
 */
export interface PropertyProductAttribute {
    /**
     * 
     * @type {number}
     * @memberof PropertyProductAttribute
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyProductAttribute
     */
    attribute?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyProductAttribute
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyProductAttribute
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PropertyProductAttribute
     */
    attributeId?: number;
}
/**
 * 
 * @export
 * @interface RelatedGuidePage
 */
export interface RelatedGuidePage {
    /**
     * 
     * @type {number}
     * @memberof RelatedGuidePage
     */
    id?: number;
    /**
     * Заголовок страницы видимый всем
     * @type {string}
     * @memberof RelatedGuidePage
     */
    title: string;
    /**
     * Имя страницы, которое будет отображаться в URL, например http://domain.com/blog/[my-slug]/
     * @type {string}
     * @memberof RelatedGuidePage
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedGuidePage
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedGuidePage
     */
    lastPublishedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedGuidePage
     */
    averageRating?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedGuidePage
     */
    userRating?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedGuidePage
     */
    isLikedByUser?: string;
}
/**
 * 
 * @export
 * @interface RelatedProduct
 */
export interface RelatedProduct {
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    discountPrice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    discountPercent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    fullDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    fullDescriptionSecondState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    isLikedByUser?: string;
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    userRating?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof RelatedProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    sortOrder?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    category?: string | null;
    /**
     * 
     * @type {Array<PropertyForProducts>}
     * @memberof RelatedProduct
     */
    properties: Array<PropertyForProducts>;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    pageId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    applicationForm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    discountPriceOrPrice: number;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    averageRating?: string;
    /**
     * 
     * @type {number}
     * @memberof RelatedProduct
     */
    alcoholContent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    buttonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    marketsPrefix?: string | null;
    /**
     * 
     * @type {Array<MarketsSertializer>}
     * @memberof RelatedProduct
     */
    markets: Array<MarketsSertializer>;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    marketsPostfix?: string | null;
    /**
     * 
     * @type {Array<Direction>}
     * @memberof RelatedProduct
     */
    directions?: Array<Direction>;
    /**
     * 
     * @type {string}
     * @memberof RelatedProduct
     */
    externalLink?: string;
}
/**
 * 
 * @export
 * @interface RestaurantSchedule
 */
export interface RestaurantSchedule {
    /**
     * 
     * @type {string}
     * @memberof RestaurantSchedule
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSchedule
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSchedule
     */
    dayType: RestaurantScheduleDayTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RestaurantSchedule
     */
    date?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum RestaurantScheduleDayTypeEnum {
    Weekday = 'weekday',
    Weekend = 'weekend',
    Holiday = 'holiday'
}

/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {ProductPaginateSerialaizer}
     * @memberof SearchResult
     */
    products?: ProductPaginateSerialaizer;
    /**
     * 
     * @type {BankingProductPaginateSerialaizer}
     * @memberof SearchResult
     */
    bankingProducts?: BankingProductPaginateSerialaizer;
    /**
     * 
     * @type {MediaProductPaginateSerialaizer}
     * @memberof SearchResult
     */
    mediaProducts?: MediaProductPaginateSerialaizer;
    /**
     * 
     * @type {InsuranceProductPaginateSerialaizer}
     * @memberof SearchResult
     */
    insuranceProducts?: InsuranceProductPaginateSerialaizer;
    /**
     * 
     * @type {NewsPagePaginateSerialaizer}
     * @memberof SearchResult
     */
    newsPages?: NewsPagePaginateSerialaizer;
    /**
     * 
     * @type {GuidePagePaginateSerialaizer}
     * @memberof SearchResult
     */
    guidePages?: GuidePagePaginateSerialaizer;
    /**
     * 
     * @type {MagazineArticlePaginate}
     * @memberof SearchResult
     */
    magazineArticle?: MagazineArticlePaginate;
}
/**
 * 
 * @export
 * @interface Shop
 */
export interface Shop {
    /**
     * 
     * @type {number}
     * @memberof Shop
     */
    id?: number;
    /**
     * 
     * @type {City}
     * @memberof Shop
     */
    city: City;
    /**
     * 
     * @type {string}
     * @memberof Shop
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Shop
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Shop
     */
    workBeginning: string;
    /**
     * 
     * @type {string}
     * @memberof Shop
     */
    workEnding: string;
    /**
     * 
     * @type {string}
     * @memberof Shop
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Shop
     */
    category?: string | null;
}
/**
 * 
 * @export
 * @interface ShortBankingProduct
 */
export interface ShortBankingProduct {
    /**
     * 
     * @type {number}
     * @memberof ShortBankingProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortBankingProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortBankingProduct
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortBankingProduct
     */
    category?: string;
}
/**
 * 
 * @export
 * @interface ShortMediaProduct
 */
export interface ShortMediaProduct {
    /**
     * 
     * @type {number}
     * @memberof ShortMediaProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortMediaProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortMediaProduct
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortMediaProduct
     */
    category?: string;
}
/**
 * 
 * @export
 * @interface ShortProduct
 */
export interface ShortProduct {
    /**
     * 
     * @type {number}
     * @memberof ShortProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShortProduct
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortProduct
     */
    discountPrice?: string | null;
}
/**
 * 
 * @export
 * @interface Signin
 */
export interface Signin {
    /**
     * 
     * @type {string}
     * @memberof Signin
     */
    emailCode: string;
    /**
     * 
     * @type {string}
     * @memberof Signin
     */
    email: string;
}
/**
 * 
 * @export
 * @interface Signup
 */
export interface Signup {
    /**
     * 
     * @type {string}
     * @memberof Signup
     */
    email: string;
}
/**
 * 
 * @export
 * @interface SmallBanner
 */
export interface SmallBanner {
    /**
     * 
     * @type {number}
     * @memberof SmallBanner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SmallBanner
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof SmallBanner
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SmallBanner
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof SmallBanner
     */
    image?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmallBanner
     */
    showsBlock: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SmallBanner
     */
    buttonsVisibility: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmallBanner
     */
    content: string;
}
/**
 * 
 * @export
 * @interface Subcategory
 */
export interface Subcategory {
    /**
     * 
     * @type {number}
     * @memberof Subcategory
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    imageThumbnail?: string;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    mailText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    category: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    key: string;
}
/**
 * 
 * @export
 * @interface TourSchedule
 */
export interface TourSchedule {
    /**
     * 
     * @type {string}
     * @memberof TourSchedule
     */
    name?: string | null;
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof TourSchedule
     */
    ranges: Array<DateRange>;
}
/**
 * 
 * @export
 * @interface UpdateCartProduct
 */
export interface UpdateCartProduct {
    /**
     * 
     * @type {number}
     * @memberof UpdateCartProduct
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCartProduct
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCartProduct
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCartProduct
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * Обязательное поле. Не более 150 символов. Только буквы, цифры и символы @/./+/-/_.
     * @type {string}
     * @memberof User
     */
    username: string;
}
/**
 * 
 * @export
 * @interface UserAgreement
 */
export interface UserAgreement {
    /**
     * 
     * @type {number}
     * @memberof UserAgreement
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAgreement
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    patronymic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    birthday?: string | null;
}
/**
 * 
 * @export
 * @interface UserVideoHistory
 */
export interface UserVideoHistory {
    /**
     * 
     * @type {number}
     * @memberof UserVideoHistory
     */
    videoId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserVideoHistory
     */
    resolutionId: number;
    /**
     * 
     * @type {string}
     * @memberof UserVideoHistory
     */
    timeCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserVideoHistory
     */
    status?: UserVideoHistoryStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserVideoHistoryStatusEnum {
    InProgress = 'in_progress',
    Viewed = 'viewed'
}

/**
 * 
 * @export
 * @interface WineCartInstruction
 */
export interface WineCartInstruction {
    /**
     * 
     * @type {string}
     * @memberof WineCartInstruction
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof WineCartInstruction
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof WineCartInstruction
     */
    wineCardInstruction: string;
    /**
     * 
     * @type {string}
     * @memberof WineCartInstruction
     */
    note: string;
}

/**
 * AboutProjectPageApi - axios parameter creator
 * @export
 */
export const AboutProjectPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutProjectPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/about_project_page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AboutProjectPageApi - functional programming interface
 * @export
 */
export const AboutProjectPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AboutProjectPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutProjectPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutProjectPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AboutProjectPageApi - factory interface
 * @export
 */
export const AboutProjectPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AboutProjectPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutProjectPageList(options?: any): AxiosPromise<AboutProjectPage> {
            return localVarFp.aboutProjectPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AboutProjectPageApi - object-oriented interface
 * @export
 * @class AboutProjectPageApi
 * @extends {BaseAPI}
 */
export class AboutProjectPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AboutProjectPageApi
     */
    public aboutProjectPageList(options?: any) {
        return AboutProjectPageApiFp(this.configuration).aboutProjectPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityCreate: async (data: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('activityCreate', 'data', data)
            const localVarPath = `/activity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activityDelete', 'id', id)
            const localVarPath = `/activity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/activity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPartialUpdate: async (id: number, data: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activityPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('activityPartialUpdate', 'data', data)
            const localVarPath = `/activity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activityRead', 'id', id)
            const localVarPath = `/activity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUpdate: async (id: number, data: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activityUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('activityUpdate', 'data', data)
            const localVarPath = `/activity/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityCreate(data: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityPartialUpdate(id: number, data: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityUpdate(id: number, data: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityCreate(data: Activity, options?: any): AxiosPromise<Activity> {
            return localVarFp.activityCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.activityDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.activityList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityPartialUpdate(id: number, data: Activity, options?: any): AxiosPromise<Activity> {
            return localVarFp.activityPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityRead(id: number, options?: any): AxiosPromise<Activity> {
            return localVarFp.activityRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профессиональная деятельность.
         * @param {Activity} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUpdate(id: number, data: Activity, options?: any): AxiosPromise<Activity> {
            return localVarFp.activityUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activityCreate operation in ActivityApi.
 * @export
 * @interface ActivityApiActivityCreateRequest
 */
export interface ActivityApiActivityCreateRequest {
    /**
     * 
     * @type {Activity}
     * @memberof ActivityApiActivityCreate
     */
    readonly data: Activity
}

/**
 * Request parameters for activityDelete operation in ActivityApi.
 * @export
 * @interface ActivityApiActivityDeleteRequest
 */
export interface ActivityApiActivityDeleteRequest {
    /**
     * A unique integer value identifying this Профессиональная деятельность.
     * @type {number}
     * @memberof ActivityApiActivityDelete
     */
    readonly id: number
}

/**
 * Request parameters for activityList operation in ActivityApi.
 * @export
 * @interface ActivityApiActivityListRequest
 */
export interface ActivityApiActivityListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ActivityApiActivityList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ActivityApiActivityList
     */
    readonly limit?: number
}

/**
 * Request parameters for activityPartialUpdate operation in ActivityApi.
 * @export
 * @interface ActivityApiActivityPartialUpdateRequest
 */
export interface ActivityApiActivityPartialUpdateRequest {
    /**
     * A unique integer value identifying this Профессиональная деятельность.
     * @type {number}
     * @memberof ActivityApiActivityPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Activity}
     * @memberof ActivityApiActivityPartialUpdate
     */
    readonly data: Activity
}

/**
 * Request parameters for activityRead operation in ActivityApi.
 * @export
 * @interface ActivityApiActivityReadRequest
 */
export interface ActivityApiActivityReadRequest {
    /**
     * A unique integer value identifying this Профессиональная деятельность.
     * @type {number}
     * @memberof ActivityApiActivityRead
     */
    readonly id: number
}

/**
 * Request parameters for activityUpdate operation in ActivityApi.
 * @export
 * @interface ActivityApiActivityUpdateRequest
 */
export interface ActivityApiActivityUpdateRequest {
    /**
     * A unique integer value identifying this Профессиональная деятельность.
     * @type {number}
     * @memberof ActivityApiActivityUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Activity}
     * @memberof ActivityApiActivityUpdate
     */
    readonly data: Activity
}

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {ActivityApiActivityCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityCreate(requestParameters: ActivityApiActivityCreateRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiActivityDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityDelete(requestParameters: ActivityApiActivityDeleteRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiActivityListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityList(requestParameters: ActivityApiActivityListRequest = {}, options?: any) {
        return ActivityApiFp(this.configuration).activityList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiActivityPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityPartialUpdate(requestParameters: ActivityApiActivityPartialUpdateRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiActivityReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityRead(requestParameters: ActivityApiActivityReadRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActivityApiActivityUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityUpdate(requestParameters: ActivityApiActivityUpdateRequest, options?: any) {
        return ActivityApiFp(this.configuration).activityUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationCreate: async (data: Application, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('applicationCreate', 'data', data)
            const localVarPath = `/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationDelete', 'id', id)
            const localVarPath = `/application/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationList: async (profile?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/application/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationPartialUpdate: async (id: number, data: Application, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('applicationPartialUpdate', 'data', data)
            const localVarPath = `/application/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationRead', 'id', id)
            const localVarPath = `/application/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationUpdate: async (id: number, data: Application, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('applicationUpdate', 'data', data)
            const localVarPath = `/application/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationCreate(data: Application, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationList(profile?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationList(profile, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationPartialUpdate(id: number, data: Application, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationUpdate(id: number, data: Application, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationCreate(data: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.applicationCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationList(profile?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.applicationList(profile, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationPartialUpdate(id: number, data: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.applicationPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationRead(id: number, options?: any): AxiosPromise<Application> {
            return localVarFp.applicationRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка.
         * @param {Application} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationUpdate(id: number, data: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.applicationUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applicationCreate operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationCreateRequest
 */
export interface ApplicationApiApplicationCreateRequest {
    /**
     * 
     * @type {Application}
     * @memberof ApplicationApiApplicationCreate
     */
    readonly data: Application
}

/**
 * Request parameters for applicationDelete operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationDeleteRequest
 */
export interface ApplicationApiApplicationDeleteRequest {
    /**
     * A unique integer value identifying this Заявка.
     * @type {number}
     * @memberof ApplicationApiApplicationDelete
     */
    readonly id: number
}

/**
 * Request parameters for applicationList operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationListRequest
 */
export interface ApplicationApiApplicationListRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationApiApplicationList
     */
    readonly profile?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApplicationApiApplicationList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApplicationApiApplicationList
     */
    readonly limit?: number
}

/**
 * Request parameters for applicationPartialUpdate operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationPartialUpdateRequest
 */
export interface ApplicationApiApplicationPartialUpdateRequest {
    /**
     * A unique integer value identifying this Заявка.
     * @type {number}
     * @memberof ApplicationApiApplicationPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Application}
     * @memberof ApplicationApiApplicationPartialUpdate
     */
    readonly data: Application
}

/**
 * Request parameters for applicationRead operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationReadRequest
 */
export interface ApplicationApiApplicationReadRequest {
    /**
     * A unique integer value identifying this Заявка.
     * @type {number}
     * @memberof ApplicationApiApplicationRead
     */
    readonly id: number
}

/**
 * Request parameters for applicationUpdate operation in ApplicationApi.
 * @export
 * @interface ApplicationApiApplicationUpdateRequest
 */
export interface ApplicationApiApplicationUpdateRequest {
    /**
     * A unique integer value identifying this Заявка.
     * @type {number}
     * @memberof ApplicationApiApplicationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Application}
     * @memberof ApplicationApiApplicationUpdate
     */
    readonly data: Application
}

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationApiApplicationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationCreate(requestParameters: ApplicationApiApplicationCreateRequest, options?: any) {
        return ApplicationApiFp(this.configuration).applicationCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationApiApplicationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationDelete(requestParameters: ApplicationApiApplicationDeleteRequest, options?: any) {
        return ApplicationApiFp(this.configuration).applicationDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationApiApplicationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationList(requestParameters: ApplicationApiApplicationListRequest = {}, options?: any) {
        return ApplicationApiFp(this.configuration).applicationList(requestParameters.profile, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationApiApplicationPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationPartialUpdate(requestParameters: ApplicationApiApplicationPartialUpdateRequest, options?: any) {
        return ApplicationApiFp(this.configuration).applicationPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationApiApplicationReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationRead(requestParameters: ApplicationApiApplicationReadRequest, options?: any) {
        return ApplicationApiFp(this.configuration).applicationRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationApiApplicationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public applicationUpdate(requestParameters: ApplicationApiApplicationUpdateRequest, options?: any) {
        return ApplicationApiFp(this.configuration).applicationUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationFormsApi - axios parameter creator
 * @export
 */
export const ApplicationFormsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [title] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFormsList: async (slug?: string, title?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/application_forms/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Форма для заявки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFormsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationFormsRead', 'id', id)
            const localVarPath = `/application_forms/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationFormsApi - functional programming interface
 * @export
 */
export const ApplicationFormsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationFormsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [title] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationFormsList(slug?: string, title?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationFormsList(slug, title, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Форма для заявки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationFormsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationFormsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationFormsApi - factory interface
 * @export
 */
export const ApplicationFormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationFormsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [title] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFormsList(slug?: string, title?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.applicationFormsList(slug, title, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Форма для заявки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationFormsRead(id: number, options?: any): AxiosPromise<ApplicationForm> {
            return localVarFp.applicationFormsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applicationFormsList operation in ApplicationFormsApi.
 * @export
 * @interface ApplicationFormsApiApplicationFormsListRequest
 */
export interface ApplicationFormsApiApplicationFormsListRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormsApiApplicationFormsList
     */
    readonly slug?: string

    /**
     * 
     * @type {string}
     * @memberof ApplicationFormsApiApplicationFormsList
     */
    readonly title?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApplicationFormsApiApplicationFormsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApplicationFormsApiApplicationFormsList
     */
    readonly limit?: number
}

/**
 * Request parameters for applicationFormsRead operation in ApplicationFormsApi.
 * @export
 * @interface ApplicationFormsApiApplicationFormsReadRequest
 */
export interface ApplicationFormsApiApplicationFormsReadRequest {
    /**
     * A unique integer value identifying this Форма для заявки.
     * @type {number}
     * @memberof ApplicationFormsApiApplicationFormsRead
     */
    readonly id: number
}

/**
 * ApplicationFormsApi - object-oriented interface
 * @export
 * @class ApplicationFormsApi
 * @extends {BaseAPI}
 */
export class ApplicationFormsApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationFormsApiApplicationFormsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationFormsApi
     */
    public applicationFormsList(requestParameters: ApplicationFormsApiApplicationFormsListRequest = {}, options?: any) {
        return ApplicationFormsApiFp(this.configuration).applicationFormsList(requestParameters.slug, requestParameters.title, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationFormsApiApplicationFormsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationFormsApi
     */
    public applicationFormsRead(requestParameters: ApplicationFormsApiApplicationFormsReadRequest, options?: any) {
        return ApplicationFormsApiFp(this.configuration).applicationFormsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationProductsApi - axios parameter creator
 * @export
 */
export const ApplicationProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/application_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationProductsDelete', 'id', id)
            const localVarPath = `/application_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productCategoryBasecategoryPtr] Категория
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsExport: async (productCategoryBasecategoryPtr?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/application_products/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (productCategoryBasecategoryPtr !== undefined) {
                localVarQueryParameter['product__category__basecategory_ptr'] = productCategoryBasecategoryPtr;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productCategoryBasecategoryPtr] Категория
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsList: async (productCategoryBasecategoryPtr?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/application_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (productCategoryBasecategoryPtr !== undefined) {
                localVarQueryParameter['product__category__basecategory_ptr'] = productCategoryBasecategoryPtr;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsPartialUpdate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationProductsPartialUpdate', 'id', id)
            const localVarPath = `/application_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationProductsRead', 'id', id)
            const localVarPath = `/application_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsUpdate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('applicationProductsUpdate', 'id', id)
            const localVarPath = `/application_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationProductsApi - functional programming interface
 * @export
 */
export const ApplicationProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [productCategoryBasecategoryPtr] Категория
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsExport(productCategoryBasecategoryPtr?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsExport(productCategoryBasecategoryPtr, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [productCategoryBasecategoryPtr] Категория
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsList(productCategoryBasecategoryPtr?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsList(productCategoryBasecategoryPtr, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsPartialUpdate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsPartialUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationProductsUpdate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationProductsUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationProductsApi - factory interface
 * @export
 */
export const ApplicationProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsCreate(options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [productCategoryBasecategoryPtr] Категория
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsExport(productCategoryBasecategoryPtr?: number, page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsExport(productCategoryBasecategoryPtr, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [productCategoryBasecategoryPtr] Категория
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsList(productCategoryBasecategoryPtr?: number, page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsList(productCategoryBasecategoryPtr, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsPartialUpdate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsPartialUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продуктовая заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationProductsUpdate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.applicationProductsUpdate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applicationProductsDelete operation in ApplicationProductsApi.
 * @export
 * @interface ApplicationProductsApiApplicationProductsDeleteRequest
 */
export interface ApplicationProductsApiApplicationProductsDeleteRequest {
    /**
     * A unique integer value identifying this Продуктовая заявка.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for applicationProductsExport operation in ApplicationProductsApi.
 * @export
 * @interface ApplicationProductsApiApplicationProductsExportRequest
 */
export interface ApplicationProductsApiApplicationProductsExportRequest {
    /**
     * Категория
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsExport
     */
    readonly productCategoryBasecategoryPtr?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsExport
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsExport
     */
    readonly limit?: number
}

/**
 * Request parameters for applicationProductsList operation in ApplicationProductsApi.
 * @export
 * @interface ApplicationProductsApiApplicationProductsListRequest
 */
export interface ApplicationProductsApiApplicationProductsListRequest {
    /**
     * Категория
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsList
     */
    readonly productCategoryBasecategoryPtr?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsList
     */
    readonly limit?: number
}

/**
 * Request parameters for applicationProductsPartialUpdate operation in ApplicationProductsApi.
 * @export
 * @interface ApplicationProductsApiApplicationProductsPartialUpdateRequest
 */
export interface ApplicationProductsApiApplicationProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Продуктовая заявка.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsPartialUpdate
     */
    readonly id: number
}

/**
 * Request parameters for applicationProductsRead operation in ApplicationProductsApi.
 * @export
 * @interface ApplicationProductsApiApplicationProductsReadRequest
 */
export interface ApplicationProductsApiApplicationProductsReadRequest {
    /**
     * A unique integer value identifying this Продуктовая заявка.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for applicationProductsUpdate operation in ApplicationProductsApi.
 * @export
 * @interface ApplicationProductsApiApplicationProductsUpdateRequest
 */
export interface ApplicationProductsApiApplicationProductsUpdateRequest {
    /**
     * A unique integer value identifying this Продуктовая заявка.
     * @type {number}
     * @memberof ApplicationProductsApiApplicationProductsUpdate
     */
    readonly id: number
}

/**
 * ApplicationProductsApi - object-oriented interface
 * @export
 * @class ApplicationProductsApi
 * @extends {BaseAPI}
 */
export class ApplicationProductsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsCreate(options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProductsApiApplicationProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsDelete(requestParameters: ApplicationProductsApiApplicationProductsDeleteRequest, options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProductsApiApplicationProductsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsExport(requestParameters: ApplicationProductsApiApplicationProductsExportRequest = {}, options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsExport(requestParameters.productCategoryBasecategoryPtr, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProductsApiApplicationProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsList(requestParameters: ApplicationProductsApiApplicationProductsListRequest = {}, options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsList(requestParameters.productCategoryBasecategoryPtr, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProductsApiApplicationProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsPartialUpdate(requestParameters: ApplicationProductsApiApplicationProductsPartialUpdateRequest, options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsPartialUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProductsApiApplicationProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsRead(requestParameters: ApplicationProductsApiApplicationProductsReadRequest, options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProductsApiApplicationProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationProductsApi
     */
    public applicationProductsUpdate(requestParameters: ApplicationProductsApiApplicationProductsUpdateRequest, options?: any) {
        return ApplicationProductsApiFp(this.configuration).applicationProductsUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BankingApplicationsApi - axios parameter creator
 * @export
 */
export const BankingApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsCreate: async (data: BankingApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingApplicationsCreate', 'data', data)
            const localVarPath = `/banking_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingApplicationsDelete', 'id', id)
            const localVarPath = `/banking_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsList: async (profile?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/banking_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsPartialUpdate: async (id: number, data: BankingApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingApplicationsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingApplicationsPartialUpdate', 'data', data)
            const localVarPath = `/banking_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingApplicationsRead', 'id', id)
            const localVarPath = `/banking_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsUpdate: async (id: number, data: BankingApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingApplicationsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingApplicationsUpdate', 'data', data)
            const localVarPath = `/banking_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankingApplicationsApi - functional programming interface
 * @export
 */
export const BankingApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankingApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingApplicationsCreate(data: BankingApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingApplicationsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingApplicationsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingApplicationsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingApplicationsList(profile?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingApplicationsList(profile, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingApplicationsPartialUpdate(id: number, data: BankingApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingApplicationsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingApplicationsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingApplicationsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingApplicationsUpdate(id: number, data: BankingApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingApplicationsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankingApplicationsApi - factory interface
 * @export
 */
export const BankingApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankingApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsCreate(data: BankingApplication, options?: any): AxiosPromise<BankingApplication> {
            return localVarFp.bankingApplicationsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bankingApplicationsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsList(profile?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.bankingApplicationsList(profile, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsPartialUpdate(id: number, data: BankingApplication, options?: any): AxiosPromise<BankingApplication> {
            return localVarFp.bankingApplicationsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsRead(id: number, options?: any): AxiosPromise<BankingApplication> {
            return localVarFp.bankingApplicationsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на банковские услуги.
         * @param {BankingApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingApplicationsUpdate(id: number, data: BankingApplication, options?: any): AxiosPromise<BankingApplication> {
            return localVarFp.bankingApplicationsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bankingApplicationsCreate operation in BankingApplicationsApi.
 * @export
 * @interface BankingApplicationsApiBankingApplicationsCreateRequest
 */
export interface BankingApplicationsApiBankingApplicationsCreateRequest {
    /**
     * 
     * @type {BankingApplication}
     * @memberof BankingApplicationsApiBankingApplicationsCreate
     */
    readonly data: BankingApplication
}

/**
 * Request parameters for bankingApplicationsDelete operation in BankingApplicationsApi.
 * @export
 * @interface BankingApplicationsApiBankingApplicationsDeleteRequest
 */
export interface BankingApplicationsApiBankingApplicationsDeleteRequest {
    /**
     * A unique integer value identifying this Заявка на банковские услуги.
     * @type {number}
     * @memberof BankingApplicationsApiBankingApplicationsDelete
     */
    readonly id: number
}

/**
 * Request parameters for bankingApplicationsList operation in BankingApplicationsApi.
 * @export
 * @interface BankingApplicationsApiBankingApplicationsListRequest
 */
export interface BankingApplicationsApiBankingApplicationsListRequest {
    /**
     * 
     * @type {string}
     * @memberof BankingApplicationsApiBankingApplicationsList
     */
    readonly profile?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BankingApplicationsApiBankingApplicationsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BankingApplicationsApiBankingApplicationsList
     */
    readonly limit?: number
}

/**
 * Request parameters for bankingApplicationsPartialUpdate operation in BankingApplicationsApi.
 * @export
 * @interface BankingApplicationsApiBankingApplicationsPartialUpdateRequest
 */
export interface BankingApplicationsApiBankingApplicationsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Заявка на банковские услуги.
     * @type {number}
     * @memberof BankingApplicationsApiBankingApplicationsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BankingApplication}
     * @memberof BankingApplicationsApiBankingApplicationsPartialUpdate
     */
    readonly data: BankingApplication
}

/**
 * Request parameters for bankingApplicationsRead operation in BankingApplicationsApi.
 * @export
 * @interface BankingApplicationsApiBankingApplicationsReadRequest
 */
export interface BankingApplicationsApiBankingApplicationsReadRequest {
    /**
     * A unique integer value identifying this Заявка на банковские услуги.
     * @type {number}
     * @memberof BankingApplicationsApiBankingApplicationsRead
     */
    readonly id: number
}

/**
 * Request parameters for bankingApplicationsUpdate operation in BankingApplicationsApi.
 * @export
 * @interface BankingApplicationsApiBankingApplicationsUpdateRequest
 */
export interface BankingApplicationsApiBankingApplicationsUpdateRequest {
    /**
     * A unique integer value identifying this Заявка на банковские услуги.
     * @type {number}
     * @memberof BankingApplicationsApiBankingApplicationsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BankingApplication}
     * @memberof BankingApplicationsApiBankingApplicationsUpdate
     */
    readonly data: BankingApplication
}

/**
 * BankingApplicationsApi - object-oriented interface
 * @export
 * @class BankingApplicationsApi
 * @extends {BaseAPI}
 */
export class BankingApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {BankingApplicationsApiBankingApplicationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApplicationsApi
     */
    public bankingApplicationsCreate(requestParameters: BankingApplicationsApiBankingApplicationsCreateRequest, options?: any) {
        return BankingApplicationsApiFp(this.configuration).bankingApplicationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingApplicationsApiBankingApplicationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApplicationsApi
     */
    public bankingApplicationsDelete(requestParameters: BankingApplicationsApiBankingApplicationsDeleteRequest, options?: any) {
        return BankingApplicationsApiFp(this.configuration).bankingApplicationsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingApplicationsApiBankingApplicationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApplicationsApi
     */
    public bankingApplicationsList(requestParameters: BankingApplicationsApiBankingApplicationsListRequest = {}, options?: any) {
        return BankingApplicationsApiFp(this.configuration).bankingApplicationsList(requestParameters.profile, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingApplicationsApiBankingApplicationsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApplicationsApi
     */
    public bankingApplicationsPartialUpdate(requestParameters: BankingApplicationsApiBankingApplicationsPartialUpdateRequest, options?: any) {
        return BankingApplicationsApiFp(this.configuration).bankingApplicationsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingApplicationsApiBankingApplicationsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApplicationsApi
     */
    public bankingApplicationsRead(requestParameters: BankingApplicationsApiBankingApplicationsReadRequest, options?: any) {
        return BankingApplicationsApiFp(this.configuration).bankingApplicationsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingApplicationsApiBankingApplicationsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingApplicationsApi
     */
    public bankingApplicationsUpdate(requestParameters: BankingApplicationsApiBankingApplicationsUpdateRequest, options?: any) {
        return BankingApplicationsApiFp(this.configuration).bankingApplicationsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BankingProductsApi - axios parameter creator
 * @export
 */
export const BankingProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BaningProductsCallback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsCallbackCreate: async (data: BaningProductsCallback, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingProductsCallbackCreate', 'data', data)
            const localVarPath = `/banking_products/callback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsCreate: async (data: BankingProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingProductsCreate', 'data', data)
            const localVarPath = `/banking_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingProductsDelete', 'id', id)
            const localVarPath = `/banking_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsLinkList: async (id: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingProductsLinkList', 'id', id)
            const localVarPath = `/banking_products/{id}/link/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsList: async (name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/banking_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (priceGt !== undefined) {
                localVarQueryParameter['price__gt'] = priceGt;
            }

            if (priceLt !== undefined) {
                localVarQueryParameter['price__lt'] = priceLt;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceGte !== undefined) {
                localVarQueryParameter['price__gte'] = priceGte;
            }

            if (priceLte !== undefined) {
                localVarQueryParameter['price__lte'] = priceLte;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsPartialUpdate: async (id: number, data: BankingProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingProductsPartialUpdate', 'data', data)
            const localVarPath = `/banking_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingProductsRead', 'id', id)
            const localVarPath = `/banking_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsUpdate: async (id: number, data: BankingProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bankingProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('bankingProductsUpdate', 'data', data)
            const localVarPath = `/banking_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankingProductsApi - functional programming interface
 * @export
 */
export const BankingProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankingProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BaningProductsCallback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsCallbackCreate(data: BaningProductsCallback, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaningProductsCallback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsCallbackCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsCreate(data: BankingProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsLinkList(id: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingProductLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsLinkList(id, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsList(name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsList(name, priceGt, priceLt, price, priceGte, priceLte, category, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsPartialUpdate(id: number, data: BankingProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankingProductsUpdate(id: number, data: BankingProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankingProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bankingProductsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankingProductsApi - factory interface
 * @export
 */
export const BankingProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankingProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {BaningProductsCallback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsCallbackCreate(data: BaningProductsCallback, options?: any): AxiosPromise<BaningProductsCallback> {
            return localVarFp.bankingProductsCallbackCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsCreate(data: BankingProduct, options?: any): AxiosPromise<BankingProduct> {
            return localVarFp.bankingProductsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bankingProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsLinkList(id: number, page?: number, limit?: number, options?: any): AxiosPromise<BankingProductLink> {
            return localVarFp.bankingProductsLinkList(id, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsList(name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.bankingProductsList(name, priceGt, priceLt, price, priceGte, priceLte, category, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsPartialUpdate(id: number, data: BankingProduct, options?: any): AxiosPromise<BankingProduct> {
            return localVarFp.bankingProductsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsRead(id: number, options?: any): AxiosPromise<BankingProduct> {
            return localVarFp.bankingProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Банковская услуга.
         * @param {BankingProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankingProductsUpdate(id: number, data: BankingProduct, options?: any): AxiosPromise<BankingProduct> {
            return localVarFp.bankingProductsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bankingProductsCallbackCreate operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsCallbackCreateRequest
 */
export interface BankingProductsApiBankingProductsCallbackCreateRequest {
    /**
     * 
     * @type {BaningProductsCallback}
     * @memberof BankingProductsApiBankingProductsCallbackCreate
     */
    readonly data: BaningProductsCallback
}

/**
 * Request parameters for bankingProductsCreate operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsCreateRequest
 */
export interface BankingProductsApiBankingProductsCreateRequest {
    /**
     * 
     * @type {BankingProduct}
     * @memberof BankingProductsApiBankingProductsCreate
     */
    readonly data: BankingProduct
}

/**
 * Request parameters for bankingProductsDelete operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsDeleteRequest
 */
export interface BankingProductsApiBankingProductsDeleteRequest {
    /**
     * A unique integer value identifying this Банковская услуга.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for bankingProductsLinkList operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsLinkListRequest
 */
export interface BankingProductsApiBankingProductsLinkListRequest {
    /**
     * A unique integer value identifying this Банковская услуга.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsLinkList
     */
    readonly id: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsLinkList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsLinkList
     */
    readonly limit?: number
}

/**
 * Request parameters for bankingProductsList operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsListRequest
 */
export interface BankingProductsApiBankingProductsListRequest {
    /**
     * 
     * @type {string}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly priceGt?: number

    /**
     * 
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly priceLt?: number

    /**
     * 
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly priceGte?: number

    /**
     * 
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly priceLte?: number

    /**
     * 
     * @type {string}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly category?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsList
     */
    readonly limit?: number
}

/**
 * Request parameters for bankingProductsPartialUpdate operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsPartialUpdateRequest
 */
export interface BankingProductsApiBankingProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Банковская услуга.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BankingProduct}
     * @memberof BankingProductsApiBankingProductsPartialUpdate
     */
    readonly data: BankingProduct
}

/**
 * Request parameters for bankingProductsRead operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsReadRequest
 */
export interface BankingProductsApiBankingProductsReadRequest {
    /**
     * A unique integer value identifying this Банковская услуга.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for bankingProductsUpdate operation in BankingProductsApi.
 * @export
 * @interface BankingProductsApiBankingProductsUpdateRequest
 */
export interface BankingProductsApiBankingProductsUpdateRequest {
    /**
     * A unique integer value identifying this Банковская услуга.
     * @type {number}
     * @memberof BankingProductsApiBankingProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BankingProduct}
     * @memberof BankingProductsApiBankingProductsUpdate
     */
    readonly data: BankingProduct
}

/**
 * BankingProductsApi - object-oriented interface
 * @export
 * @class BankingProductsApi
 * @extends {BaseAPI}
 */
export class BankingProductsApi extends BaseAPI {
    /**
     * 
     * @param {BankingProductsApiBankingProductsCallbackCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsCallbackCreate(requestParameters: BankingProductsApiBankingProductsCallbackCreateRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsCallbackCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsCreate(requestParameters: BankingProductsApiBankingProductsCreateRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsDelete(requestParameters: BankingProductsApiBankingProductsDeleteRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsLinkListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsLinkList(requestParameters: BankingProductsApiBankingProductsLinkListRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsLinkList(requestParameters.id, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsList(requestParameters: BankingProductsApiBankingProductsListRequest = {}, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsList(requestParameters.name, requestParameters.priceGt, requestParameters.priceLt, requestParameters.price, requestParameters.priceGte, requestParameters.priceLte, requestParameters.category, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsPartialUpdate(requestParameters: BankingProductsApiBankingProductsPartialUpdateRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsRead(requestParameters: BankingProductsApiBankingProductsReadRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BankingProductsApiBankingProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankingProductsApi
     */
    public bankingProductsUpdate(requestParameters: BankingProductsApiBankingProductsUpdateRequest, options?: any) {
        return BankingProductsApiFp(this.configuration).bankingProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BasePageApi - axios parameter creator
 * @export
 */
export const BasePageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageCreate: async (data: BasePage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('basePageCreate', 'data', data)
            const localVarPath = `/base_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('basePageDelete', 'id', id)
            const localVarPath = `/base_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/base_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePagePartialUpdate: async (id: number, data: BasePage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('basePagePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('basePagePartialUpdate', 'data', data)
            const localVarPath = `/base_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('basePageRead', 'id', id)
            const localVarPath = `/base_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageUpdate: async (id: number, data: BasePage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('basePageUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('basePageUpdate', 'data', data)
            const localVarPath = `/base_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasePageApi - functional programming interface
 * @export
 */
export const BasePageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BasePageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async basePageCreate(data: BasePage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.basePageCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async basePageDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.basePageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async basePageList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.basePageList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async basePagePartialUpdate(id: number, data: BasePage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.basePagePartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async basePageRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.basePageRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async basePageUpdate(id: number, data: BasePage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.basePageUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BasePageApi - factory interface
 * @export
 */
export const BasePageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BasePageApiFp(configuration)
    return {
        /**
         * 
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageCreate(data: BasePage, options?: any): AxiosPromise<BasePage> {
            return localVarFp.basePageCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.basePageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2005> {
            return localVarFp.basePageList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePagePartialUpdate(id: number, data: BasePage, options?: any): AxiosPromise<BasePage> {
            return localVarFp.basePagePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageRead(id: number, options?: any): AxiosPromise<BasePage> {
            return localVarFp.basePageRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Базовая страница.
         * @param {BasePage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        basePageUpdate(id: number, data: BasePage, options?: any): AxiosPromise<BasePage> {
            return localVarFp.basePageUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for basePageCreate operation in BasePageApi.
 * @export
 * @interface BasePageApiBasePageCreateRequest
 */
export interface BasePageApiBasePageCreateRequest {
    /**
     * 
     * @type {BasePage}
     * @memberof BasePageApiBasePageCreate
     */
    readonly data: BasePage
}

/**
 * Request parameters for basePageDelete operation in BasePageApi.
 * @export
 * @interface BasePageApiBasePageDeleteRequest
 */
export interface BasePageApiBasePageDeleteRequest {
    /**
     * A unique integer value identifying this Базовая страница.
     * @type {number}
     * @memberof BasePageApiBasePageDelete
     */
    readonly id: number
}

/**
 * Request parameters for basePageList operation in BasePageApi.
 * @export
 * @interface BasePageApiBasePageListRequest
 */
export interface BasePageApiBasePageListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof BasePageApiBasePageList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof BasePageApiBasePageList
     */
    readonly limit?: number
}

/**
 * Request parameters for basePagePartialUpdate operation in BasePageApi.
 * @export
 * @interface BasePageApiBasePagePartialUpdateRequest
 */
export interface BasePageApiBasePagePartialUpdateRequest {
    /**
     * A unique integer value identifying this Базовая страница.
     * @type {number}
     * @memberof BasePageApiBasePagePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BasePage}
     * @memberof BasePageApiBasePagePartialUpdate
     */
    readonly data: BasePage
}

/**
 * Request parameters for basePageRead operation in BasePageApi.
 * @export
 * @interface BasePageApiBasePageReadRequest
 */
export interface BasePageApiBasePageReadRequest {
    /**
     * A unique integer value identifying this Базовая страница.
     * @type {number}
     * @memberof BasePageApiBasePageRead
     */
    readonly id: number
}

/**
 * Request parameters for basePageUpdate operation in BasePageApi.
 * @export
 * @interface BasePageApiBasePageUpdateRequest
 */
export interface BasePageApiBasePageUpdateRequest {
    /**
     * A unique integer value identifying this Базовая страница.
     * @type {number}
     * @memberof BasePageApiBasePageUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BasePage}
     * @memberof BasePageApiBasePageUpdate
     */
    readonly data: BasePage
}

/**
 * BasePageApi - object-oriented interface
 * @export
 * @class BasePageApi
 * @extends {BaseAPI}
 */
export class BasePageApi extends BaseAPI {
    /**
     * 
     * @param {BasePageApiBasePageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasePageApi
     */
    public basePageCreate(requestParameters: BasePageApiBasePageCreateRequest, options?: any) {
        return BasePageApiFp(this.configuration).basePageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BasePageApiBasePageDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasePageApi
     */
    public basePageDelete(requestParameters: BasePageApiBasePageDeleteRequest, options?: any) {
        return BasePageApiFp(this.configuration).basePageDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BasePageApiBasePageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasePageApi
     */
    public basePageList(requestParameters: BasePageApiBasePageListRequest = {}, options?: any) {
        return BasePageApiFp(this.configuration).basePageList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BasePageApiBasePagePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasePageApi
     */
    public basePagePartialUpdate(requestParameters: BasePageApiBasePagePartialUpdateRequest, options?: any) {
        return BasePageApiFp(this.configuration).basePagePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BasePageApiBasePageReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasePageApi
     */
    public basePageRead(requestParameters: BasePageApiBasePageReadRequest, options?: any) {
        return BasePageApiFp(this.configuration).basePageRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BasePageApiBasePageUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasePageApi
     */
    public basePageUpdate(requestParameters: BasePageApiBasePageUpdateRequest, options?: any) {
        return BasePageApiFp(this.configuration).basePageUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaptchaApi - axios parameter creator
 * @export
 */
export const CaptchaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captchaList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/captcha/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaptchaApi - functional programming interface
 * @export
 */
export const CaptchaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaptchaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async captchaList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaptchaImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.captchaList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaptchaApi - factory interface
 * @export
 */
export const CaptchaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaptchaApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captchaList(options?: any): AxiosPromise<Array<CaptchaImage>> {
            return localVarFp.captchaList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaptchaApi - object-oriented interface
 * @export
 * @class CaptchaApi
 * @extends {BaseAPI}
 */
export class CaptchaApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptchaApi
     */
    public captchaList(options?: any) {
        return CaptchaApiFp(this.configuration).captchaList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductCreate: async (data: UpdateCartProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('cartCartProductCreate', 'data', data)
            const localVarPath = `/cart/cart_product/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartCartProductDelete', 'id', id)
            const localVarPath = `/cart/cart_product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cart/cart_product/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductPartialUpdate: async (id: number, data: UpdateCartProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartCartProductPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('cartCartProductPartialUpdate', 'data', data)
            const localVarPath = `/cart/cart_product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartCartProductRead', 'id', id)
            const localVarPath = `/cart/cart_product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductUpdate: async (id: number, data: UpdateCartProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cartCartProductUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('cartCartProductUpdate', 'data', data)
            const localVarPath = `/cart/cart_product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get count of items in user cart
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartInfoList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cart/info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user cart
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCartProductCreate(data: UpdateCartProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCartProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCartProductCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCartProductDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCartProductDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCartProductList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCartProductList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCartProductPartialUpdate(id: number, data: UpdateCartProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCartProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCartProductPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCartProductRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCartProductRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCartProductUpdate(id: number, data: UpdateCartProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateCartProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCartProductUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get count of items in user cart
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartInfoList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartInfoList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user cart
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductCreate(data: UpdateCartProduct, options?: any): AxiosPromise<UpdateCartProduct> {
            return localVarFp.cartCartProductCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.cartCartProductDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return localVarFp.cartCartProductList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductPartialUpdate(id: number, data: UpdateCartProduct, options?: any): AxiosPromise<UpdateCartProduct> {
            return localVarFp.cartCartProductPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductRead(id: number, options?: any): AxiosPromise<CartProduct> {
            return localVarFp.cartCartProductRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cart product.
         * @param {UpdateCartProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCartProductUpdate(id: number, data: UpdateCartProduct, options?: any): AxiosPromise<UpdateCartProduct> {
            return localVarFp.cartCartProductUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartDelete(options?: any): AxiosPromise<void> {
            return localVarFp.cartDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Get count of items in user cart
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartInfoList(page?: number, limit?: number, options?: any): AxiosPromise<CartInfo> {
            return localVarFp.cartInfoList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user cart
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartList(page?: number, limit?: number, options?: any): AxiosPromise<Cart> {
            return localVarFp.cartList(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cartCartProductCreate operation in CartApi.
 * @export
 * @interface CartApiCartCartProductCreateRequest
 */
export interface CartApiCartCartProductCreateRequest {
    /**
     * 
     * @type {UpdateCartProduct}
     * @memberof CartApiCartCartProductCreate
     */
    readonly data: UpdateCartProduct
}

/**
 * Request parameters for cartCartProductDelete operation in CartApi.
 * @export
 * @interface CartApiCartCartProductDeleteRequest
 */
export interface CartApiCartCartProductDeleteRequest {
    /**
     * A unique integer value identifying this cart product.
     * @type {number}
     * @memberof CartApiCartCartProductDelete
     */
    readonly id: number
}

/**
 * Request parameters for cartCartProductList operation in CartApi.
 * @export
 * @interface CartApiCartCartProductListRequest
 */
export interface CartApiCartCartProductListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CartApiCartCartProductList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CartApiCartCartProductList
     */
    readonly limit?: number
}

/**
 * Request parameters for cartCartProductPartialUpdate operation in CartApi.
 * @export
 * @interface CartApiCartCartProductPartialUpdateRequest
 */
export interface CartApiCartCartProductPartialUpdateRequest {
    /**
     * A unique integer value identifying this cart product.
     * @type {number}
     * @memberof CartApiCartCartProductPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCartProduct}
     * @memberof CartApiCartCartProductPartialUpdate
     */
    readonly data: UpdateCartProduct
}

/**
 * Request parameters for cartCartProductRead operation in CartApi.
 * @export
 * @interface CartApiCartCartProductReadRequest
 */
export interface CartApiCartCartProductReadRequest {
    /**
     * A unique integer value identifying this cart product.
     * @type {number}
     * @memberof CartApiCartCartProductRead
     */
    readonly id: number
}

/**
 * Request parameters for cartCartProductUpdate operation in CartApi.
 * @export
 * @interface CartApiCartCartProductUpdateRequest
 */
export interface CartApiCartCartProductUpdateRequest {
    /**
     * A unique integer value identifying this cart product.
     * @type {number}
     * @memberof CartApiCartCartProductUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCartProduct}
     * @memberof CartApiCartCartProductUpdate
     */
    readonly data: UpdateCartProduct
}

/**
 * Request parameters for cartInfoList operation in CartApi.
 * @export
 * @interface CartApiCartInfoListRequest
 */
export interface CartApiCartInfoListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CartApiCartInfoList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CartApiCartInfoList
     */
    readonly limit?: number
}

/**
 * Request parameters for cartList operation in CartApi.
 * @export
 * @interface CartApiCartListRequest
 */
export interface CartApiCartListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CartApiCartList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CartApiCartList
     */
    readonly limit?: number
}

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * 
     * @param {CartApiCartCartProductCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCartProductCreate(requestParameters: CartApiCartCartProductCreateRequest, options?: any) {
        return CartApiFp(this.configuration).cartCartProductCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiCartCartProductDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCartProductDelete(requestParameters: CartApiCartCartProductDeleteRequest, options?: any) {
        return CartApiFp(this.configuration).cartCartProductDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiCartCartProductListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCartProductList(requestParameters: CartApiCartCartProductListRequest = {}, options?: any) {
        return CartApiFp(this.configuration).cartCartProductList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiCartCartProductPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCartProductPartialUpdate(requestParameters: CartApiCartCartProductPartialUpdateRequest, options?: any) {
        return CartApiFp(this.configuration).cartCartProductPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiCartCartProductReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCartProductRead(requestParameters: CartApiCartCartProductReadRequest, options?: any) {
        return CartApiFp(this.configuration).cartCartProductRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiCartCartProductUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCartProductUpdate(requestParameters: CartApiCartCartProductUpdateRequest, options?: any) {
        return CartApiFp(this.configuration).cartCartProductUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartDelete(options?: any) {
        return CartApiFp(this.configuration).cartDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get count of items in user cart
     * @param {CartApiCartInfoListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartInfoList(requestParameters: CartApiCartInfoListRequest = {}, options?: any) {
        return CartApiFp(this.configuration).cartInfoList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user cart
     * @param {CartApiCartListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartList(requestParameters: CartApiCartListRequest = {}, options?: any) {
        return CartApiFp(this.configuration).cartList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCreate: async (data: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoriesCreate', 'data', data)
            const localVarPath = `/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesDelete', 'id', id)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPartialUpdate: async (id: number, data: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoriesPartialUpdate', 'data', data)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesRead', 'id', id)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesUpdate: async (id: number, data: Category, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoriesUpdate', 'data', data)
            const localVarPath = `/categories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCreate(data: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPartialUpdate(id: number, data: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesUpdate(id: number, data: Category, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCreate(data: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.categoriesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2007> {
            return localVarFp.categoriesList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPartialUpdate(id: number, data: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesRead(id: number, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Категория.
         * @param {Category} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesUpdate(id: number, data: Category, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoriesCreate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCreateRequest
 */
export interface CategoriesApiCategoriesCreateRequest {
    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesCreate
     */
    readonly data: Category
}

/**
 * Request parameters for categoriesDelete operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesDeleteRequest
 */
export interface CategoriesApiCategoriesDeleteRequest {
    /**
     * A unique integer value identifying this Категория.
     * @type {number}
     * @memberof CategoriesApiCategoriesDelete
     */
    readonly id: number
}

/**
 * Request parameters for categoriesList operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesListRequest
 */
export interface CategoriesApiCategoriesListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof CategoriesApiCategoriesList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CategoriesApiCategoriesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CategoriesApiCategoriesList
     */
    readonly limit?: number
}

/**
 * Request parameters for categoriesPartialUpdate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesPartialUpdateRequest
 */
export interface CategoriesApiCategoriesPartialUpdateRequest {
    /**
     * A unique integer value identifying this Категория.
     * @type {number}
     * @memberof CategoriesApiCategoriesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesPartialUpdate
     */
    readonly data: Category
}

/**
 * Request parameters for categoriesRead operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesReadRequest
 */
export interface CategoriesApiCategoriesReadRequest {
    /**
     * A unique integer value identifying this Категория.
     * @type {number}
     * @memberof CategoriesApiCategoriesRead
     */
    readonly id: number
}

/**
 * Request parameters for categoriesUpdate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesUpdateRequest
 */
export interface CategoriesApiCategoriesUpdateRequest {
    /**
     * A unique integer value identifying this Категория.
     * @type {number}
     * @memberof CategoriesApiCategoriesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesUpdate
     */
    readonly data: Category
}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {CategoriesApiCategoriesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCreate(requestParameters: CategoriesApiCategoriesCreateRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesDelete(requestParameters: CategoriesApiCategoriesDeleteRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesList(requestParameters: CategoriesApiCategoriesListRequest = {}, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesPartialUpdate(requestParameters: CategoriesApiCategoriesPartialUpdateRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesRead(requestParameters: CategoriesApiCategoriesReadRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesUpdate(requestParameters: CategoriesApiCategoriesUpdateRequest, options?: any) {
        return CategoriesApiFp(this.configuration).categoriesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryPageApi - axios parameter creator
 * @export
 */
export const CategoryPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageCreate: async (data: CategoryPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoryPageCreate', 'data', data)
            const localVarPath = `/category_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryPageDelete', 'id', id)
            const localVarPath = `/category_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/category_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPagePartialUpdate: async (id: number, data: CategoryPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryPagePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoryPagePartialUpdate', 'data', data)
            const localVarPath = `/category_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryPageRead', 'id', id)
            const localVarPath = `/category_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageUpdate: async (id: number, data: CategoryPage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryPageUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('categoryPageUpdate', 'data', data)
            const localVarPath = `/category_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryPageApi - functional programming interface
 * @export
 */
export const CategoryPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryPageCreate(data: CategoryPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPageCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryPageDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryPageList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPageList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryPagePartialUpdate(id: number, data: CategoryPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPagePartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryPageRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPageRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryPageUpdate(id: number, data: CategoryPage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryPageUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryPageApi - factory interface
 * @export
 */
export const CategoryPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryPageApiFp(configuration)
    return {
        /**
         * 
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageCreate(data: CategoryPage, options?: any): AxiosPromise<CategoryPage> {
            return localVarFp.categoryPageCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.categoryPageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2008> {
            return localVarFp.categoryPageList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPagePartialUpdate(id: number, data: CategoryPage, options?: any): AxiosPromise<CategoryPage> {
            return localVarFp.categoryPagePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageRead(id: number, options?: any): AxiosPromise<CategoryPage> {
            return localVarFp.categoryPageRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница категории.
         * @param {CategoryPage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPageUpdate(id: number, data: CategoryPage, options?: any): AxiosPromise<CategoryPage> {
            return localVarFp.categoryPageUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoryPageCreate operation in CategoryPageApi.
 * @export
 * @interface CategoryPageApiCategoryPageCreateRequest
 */
export interface CategoryPageApiCategoryPageCreateRequest {
    /**
     * 
     * @type {CategoryPage}
     * @memberof CategoryPageApiCategoryPageCreate
     */
    readonly data: CategoryPage
}

/**
 * Request parameters for categoryPageDelete operation in CategoryPageApi.
 * @export
 * @interface CategoryPageApiCategoryPageDeleteRequest
 */
export interface CategoryPageApiCategoryPageDeleteRequest {
    /**
     * A unique integer value identifying this Страница категории.
     * @type {number}
     * @memberof CategoryPageApiCategoryPageDelete
     */
    readonly id: number
}

/**
 * Request parameters for categoryPageList operation in CategoryPageApi.
 * @export
 * @interface CategoryPageApiCategoryPageListRequest
 */
export interface CategoryPageApiCategoryPageListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CategoryPageApiCategoryPageList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CategoryPageApiCategoryPageList
     */
    readonly limit?: number
}

/**
 * Request parameters for categoryPagePartialUpdate operation in CategoryPageApi.
 * @export
 * @interface CategoryPageApiCategoryPagePartialUpdateRequest
 */
export interface CategoryPageApiCategoryPagePartialUpdateRequest {
    /**
     * A unique integer value identifying this Страница категории.
     * @type {number}
     * @memberof CategoryPageApiCategoryPagePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {CategoryPage}
     * @memberof CategoryPageApiCategoryPagePartialUpdate
     */
    readonly data: CategoryPage
}

/**
 * Request parameters for categoryPageRead operation in CategoryPageApi.
 * @export
 * @interface CategoryPageApiCategoryPageReadRequest
 */
export interface CategoryPageApiCategoryPageReadRequest {
    /**
     * A unique integer value identifying this Страница категории.
     * @type {number}
     * @memberof CategoryPageApiCategoryPageRead
     */
    readonly id: number
}

/**
 * Request parameters for categoryPageUpdate operation in CategoryPageApi.
 * @export
 * @interface CategoryPageApiCategoryPageUpdateRequest
 */
export interface CategoryPageApiCategoryPageUpdateRequest {
    /**
     * A unique integer value identifying this Страница категории.
     * @type {number}
     * @memberof CategoryPageApiCategoryPageUpdate
     */
    readonly id: number

    /**
     * 
     * @type {CategoryPage}
     * @memberof CategoryPageApiCategoryPageUpdate
     */
    readonly data: CategoryPage
}

/**
 * CategoryPageApi - object-oriented interface
 * @export
 * @class CategoryPageApi
 * @extends {BaseAPI}
 */
export class CategoryPageApi extends BaseAPI {
    /**
     * 
     * @param {CategoryPageApiCategoryPageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPageApi
     */
    public categoryPageCreate(requestParameters: CategoryPageApiCategoryPageCreateRequest, options?: any) {
        return CategoryPageApiFp(this.configuration).categoryPageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryPageApiCategoryPageDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPageApi
     */
    public categoryPageDelete(requestParameters: CategoryPageApiCategoryPageDeleteRequest, options?: any) {
        return CategoryPageApiFp(this.configuration).categoryPageDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryPageApiCategoryPageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPageApi
     */
    public categoryPageList(requestParameters: CategoryPageApiCategoryPageListRequest = {}, options?: any) {
        return CategoryPageApiFp(this.configuration).categoryPageList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryPageApiCategoryPagePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPageApi
     */
    public categoryPagePartialUpdate(requestParameters: CategoryPageApiCategoryPagePartialUpdateRequest, options?: any) {
        return CategoryPageApiFp(this.configuration).categoryPagePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryPageApiCategoryPageReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPageApi
     */
    public categoryPageRead(requestParameters: CategoryPageApiCategoryPageReadRequest, options?: any) {
        return CategoryPageApiFp(this.configuration).categoryPageRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryPageApiCategoryPageUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPageApi
     */
    public categoryPageUpdate(requestParameters: CategoryPageApiCategoryPageUpdateRequest, options?: any) {
        return CategoryPageApiFp(this.configuration).categoryPageUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityCreate: async (data: City, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('cityCreate', 'data', data)
            const localVarPath = `/city/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cityDelete', 'id', id)
            const localVarPath = `/city/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/city/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityPartialUpdate: async (id: number, data: City, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cityPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('cityPartialUpdate', 'data', data)
            const localVarPath = `/city/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cityRead', 'id', id)
            const localVarPath = `/city/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityUpdate: async (id: number, data: City, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cityUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('cityUpdate', 'data', data)
            const localVarPath = `/city/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cityCreate(data: City, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<City>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cityCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cityDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cityDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cityList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cityList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cityPartialUpdate(id: number, data: City, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<City>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cityPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cityRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<City>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cityRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cityUpdate(id: number, data: City, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<City>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cityUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityApiFp(configuration)
    return {
        /**
         * 
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityCreate(data: City, options?: any): AxiosPromise<City> {
            return localVarFp.cityCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.cityDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2009> {
            return localVarFp.cityList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityPartialUpdate(id: number, data: City, options?: any): AxiosPromise<City> {
            return localVarFp.cityPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityRead(id: number, options?: any): AxiosPromise<City> {
            return localVarFp.cityRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Город.
         * @param {City} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cityUpdate(id: number, data: City, options?: any): AxiosPromise<City> {
            return localVarFp.cityUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cityCreate operation in CityApi.
 * @export
 * @interface CityApiCityCreateRequest
 */
export interface CityApiCityCreateRequest {
    /**
     * 
     * @type {City}
     * @memberof CityApiCityCreate
     */
    readonly data: City
}

/**
 * Request parameters for cityDelete operation in CityApi.
 * @export
 * @interface CityApiCityDeleteRequest
 */
export interface CityApiCityDeleteRequest {
    /**
     * A unique integer value identifying this Город.
     * @type {number}
     * @memberof CityApiCityDelete
     */
    readonly id: number
}

/**
 * Request parameters for cityList operation in CityApi.
 * @export
 * @interface CityApiCityListRequest
 */
export interface CityApiCityListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof CityApiCityList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CityApiCityList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CityApiCityList
     */
    readonly limit?: number
}

/**
 * Request parameters for cityPartialUpdate operation in CityApi.
 * @export
 * @interface CityApiCityPartialUpdateRequest
 */
export interface CityApiCityPartialUpdateRequest {
    /**
     * A unique integer value identifying this Город.
     * @type {number}
     * @memberof CityApiCityPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {City}
     * @memberof CityApiCityPartialUpdate
     */
    readonly data: City
}

/**
 * Request parameters for cityRead operation in CityApi.
 * @export
 * @interface CityApiCityReadRequest
 */
export interface CityApiCityReadRequest {
    /**
     * A unique integer value identifying this Город.
     * @type {number}
     * @memberof CityApiCityRead
     */
    readonly id: number
}

/**
 * Request parameters for cityUpdate operation in CityApi.
 * @export
 * @interface CityApiCityUpdateRequest
 */
export interface CityApiCityUpdateRequest {
    /**
     * A unique integer value identifying this Город.
     * @type {number}
     * @memberof CityApiCityUpdate
     */
    readonly id: number

    /**
     * 
     * @type {City}
     * @memberof CityApiCityUpdate
     */
    readonly data: City
}

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI {
    /**
     * 
     * @param {CityApiCityCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public cityCreate(requestParameters: CityApiCityCreateRequest, options?: any) {
        return CityApiFp(this.configuration).cityCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCityDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public cityDelete(requestParameters: CityApiCityDeleteRequest, options?: any) {
        return CityApiFp(this.configuration).cityDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCityListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public cityList(requestParameters: CityApiCityListRequest = {}, options?: any) {
        return CityApiFp(this.configuration).cityList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCityPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public cityPartialUpdate(requestParameters: CityApiCityPartialUpdateRequest, options?: any) {
        return CityApiFp(this.configuration).cityPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCityReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public cityRead(requestParameters: CityApiCityReadRequest, options?: any) {
        return CityApiFp(this.configuration).cityRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCityUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public cityUpdate(requestParameters: CityApiCityUpdateRequest, options?: any) {
        return CityApiFp(this.configuration).cityUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CloseErrorScreenApi - axios parameter creator
 * @export
 */
export const CloseErrorScreenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CloseErrorScreen} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeErrorScreenCreate: async (data: CloseErrorScreen, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('closeErrorScreenCreate', 'data', data)
            const localVarPath = `/close_error_screen/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CloseErrorScreenApi - functional programming interface
 * @export
 */
export const CloseErrorScreenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CloseErrorScreenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CloseErrorScreen} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeErrorScreenCreate(data: CloseErrorScreen, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloseErrorScreen>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeErrorScreenCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CloseErrorScreenApi - factory interface
 * @export
 */
export const CloseErrorScreenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CloseErrorScreenApiFp(configuration)
    return {
        /**
         * 
         * @param {CloseErrorScreen} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeErrorScreenCreate(data: CloseErrorScreen, options?: any): AxiosPromise<CloseErrorScreen> {
            return localVarFp.closeErrorScreenCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for closeErrorScreenCreate operation in CloseErrorScreenApi.
 * @export
 * @interface CloseErrorScreenApiCloseErrorScreenCreateRequest
 */
export interface CloseErrorScreenApiCloseErrorScreenCreateRequest {
    /**
     * 
     * @type {CloseErrorScreen}
     * @memberof CloseErrorScreenApiCloseErrorScreenCreate
     */
    readonly data: CloseErrorScreen
}

/**
 * CloseErrorScreenApi - object-oriented interface
 * @export
 * @class CloseErrorScreenApi
 * @extends {BaseAPI}
 */
export class CloseErrorScreenApi extends BaseAPI {
    /**
     * 
     * @param {CloseErrorScreenApiCloseErrorScreenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CloseErrorScreenApi
     */
    public closeErrorScreenCreate(requestParameters: CloseErrorScreenApiCloseErrorScreenCreateRequest, options?: any) {
        return CloseErrorScreenApiFp(this.configuration).closeErrorScreenCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCreate: async (data: Company, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('companyCreate', 'data', data)
            const localVarPath = `/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyDelete', 'id', id)
            const localVarPath = `/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyPartialUpdate: async (id: number, data: Company, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('companyPartialUpdate', 'data', data)
            const localVarPath = `/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyRead', 'id', id)
            const localVarPath = `/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate: async (id: number, data: Company, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('companyUpdate', 'data', data)
            const localVarPath = `/company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyCreate(data: Company, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyPartialUpdate(id: number, data: Company, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdate(id: number, data: Company, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCreate(data: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.companyCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.companyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20010> {
            return localVarFp.companyList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyPartialUpdate(id: number, data: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.companyPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyRead(id: number, options?: any): AxiosPromise<Company> {
            return localVarFp.companyRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Компания.
         * @param {Company} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdate(id: number, data: Company, options?: any): AxiosPromise<Company> {
            return localVarFp.companyUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyCreate operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyCreateRequest
 */
export interface CompanyApiCompanyCreateRequest {
    /**
     * 
     * @type {Company}
     * @memberof CompanyApiCompanyCreate
     */
    readonly data: Company
}

/**
 * Request parameters for companyDelete operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyDeleteRequest
 */
export interface CompanyApiCompanyDeleteRequest {
    /**
     * A unique integer value identifying this Компания.
     * @type {number}
     * @memberof CompanyApiCompanyDelete
     */
    readonly id: number
}

/**
 * Request parameters for companyList operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyListRequest
 */
export interface CompanyApiCompanyListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CompanyApiCompanyList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CompanyApiCompanyList
     */
    readonly limit?: number
}

/**
 * Request parameters for companyPartialUpdate operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyPartialUpdateRequest
 */
export interface CompanyApiCompanyPartialUpdateRequest {
    /**
     * A unique integer value identifying this Компания.
     * @type {number}
     * @memberof CompanyApiCompanyPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Company}
     * @memberof CompanyApiCompanyPartialUpdate
     */
    readonly data: Company
}

/**
 * Request parameters for companyRead operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyReadRequest
 */
export interface CompanyApiCompanyReadRequest {
    /**
     * A unique integer value identifying this Компания.
     * @type {number}
     * @memberof CompanyApiCompanyRead
     */
    readonly id: number
}

/**
 * Request parameters for companyUpdate operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUpdateRequest
 */
export interface CompanyApiCompanyUpdateRequest {
    /**
     * A unique integer value identifying this Компания.
     * @type {number}
     * @memberof CompanyApiCompanyUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Company}
     * @memberof CompanyApiCompanyUpdate
     */
    readonly data: Company
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CompanyApiCompanyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyCreate(requestParameters: CompanyApiCompanyCreateRequest, options?: any) {
        return CompanyApiFp(this.configuration).companyCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDelete(requestParameters: CompanyApiCompanyDeleteRequest, options?: any) {
        return CompanyApiFp(this.configuration).companyDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyList(requestParameters: CompanyApiCompanyListRequest = {}, options?: any) {
        return CompanyApiFp(this.configuration).companyList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyPartialUpdate(requestParameters: CompanyApiCompanyPartialUpdateRequest, options?: any) {
        return CompanyApiFp(this.configuration).companyPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyRead(requestParameters: CompanyApiCompanyReadRequest, options?: any) {
        return CompanyApiFp(this.configuration).companyRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdate(requestParameters: CompanyApiCompanyUpdateRequest, options?: any) {
        return CompanyApiFp(this.configuration).companyUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CreatorsPageApi - axios parameter creator
 * @export
 */
export const CreatorsPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creatorsPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/creators_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreatorsPageApi - functional programming interface
 * @export
 */
export const CreatorsPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreatorsPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creatorsPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreatorsPageApi - factory interface
 * @export
 */
export const CreatorsPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreatorsPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creatorsPageList(options?: any): AxiosPromise<CreatorsPage> {
            return localVarFp.creatorsPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreatorsPageApi - object-oriented interface
 * @export
 * @class CreatorsPageApi
 * @extends {BaseAPI}
 */
export class CreatorsPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreatorsPageApi
     */
    public creatorsPageList(options?: any) {
        return CreatorsPageApiFp(this.configuration).creatorsPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectionsApi - axios parameter creator
 * @export
 */
export const DirectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsList: async (name?: string, category?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/directions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('directionsRead', 'id', id)
            const localVarPath = `/directions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectionsApi - functional programming interface
 * @export
 */
export const DirectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async directionsList(name?: string, category?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.directionsList(name, category, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async directionsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Direction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.directionsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectionsApi - factory interface
 * @export
 */
export const DirectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsList(name?: string, category?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return localVarFp.directionsList(name, category, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        directionsRead(id: number, options?: any): AxiosPromise<Direction> {
            return localVarFp.directionsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for directionsList operation in DirectionsApi.
 * @export
 * @interface DirectionsApiDirectionsListRequest
 */
export interface DirectionsApiDirectionsListRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectionsApiDirectionsList
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof DirectionsApiDirectionsList
     */
    readonly category?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof DirectionsApiDirectionsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DirectionsApiDirectionsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DirectionsApiDirectionsList
     */
    readonly limit?: number
}

/**
 * Request parameters for directionsRead operation in DirectionsApi.
 * @export
 * @interface DirectionsApiDirectionsReadRequest
 */
export interface DirectionsApiDirectionsReadRequest {
    /**
     * A unique integer value identifying this Направление.
     * @type {number}
     * @memberof DirectionsApiDirectionsRead
     */
    readonly id: number
}

/**
 * DirectionsApi - object-oriented interface
 * @export
 * @class DirectionsApi
 * @extends {BaseAPI}
 */
export class DirectionsApi extends BaseAPI {
    /**
     * 
     * @param {DirectionsApiDirectionsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public directionsList(requestParameters: DirectionsApiDirectionsListRequest = {}, options?: any) {
        return DirectionsApiFp(this.configuration).directionsList(requestParameters.name, requestParameters.category, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DirectionsApiDirectionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectionsApi
     */
    public directionsRead(requestParameters: DirectionsApiDirectionsReadRequest, options?: any) {
        return DirectionsApiFp(this.configuration).directionsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this документ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsDownload: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentsDownload', 'id', id)
            const localVarPath = `/documents/{id}/download/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this документ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('documentsRead', 'id', id)
            const localVarPath = `/documents/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this документ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsDownload(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsDownload(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this документ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this документ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsDownload(id: number, options?: any): AxiosPromise<Document> {
            return localVarFp.documentsDownload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20012> {
            return localVarFp.documentsList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this документ.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsRead(id: number, options?: any): AxiosPromise<Document> {
            return localVarFp.documentsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for documentsDownload operation in DocumentsApi.
 * @export
 * @interface DocumentsApiDocumentsDownloadRequest
 */
export interface DocumentsApiDocumentsDownloadRequest {
    /**
     * A unique integer value identifying this документ.
     * @type {number}
     * @memberof DocumentsApiDocumentsDownload
     */
    readonly id: number
}

/**
 * Request parameters for documentsList operation in DocumentsApi.
 * @export
 * @interface DocumentsApiDocumentsListRequest
 */
export interface DocumentsApiDocumentsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DocumentsApiDocumentsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DocumentsApiDocumentsList
     */
    readonly limit?: number
}

/**
 * Request parameters for documentsRead operation in DocumentsApi.
 * @export
 * @interface DocumentsApiDocumentsReadRequest
 */
export interface DocumentsApiDocumentsReadRequest {
    /**
     * A unique integer value identifying this документ.
     * @type {number}
     * @memberof DocumentsApiDocumentsRead
     */
    readonly id: number
}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {DocumentsApiDocumentsDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsDownload(requestParameters: DocumentsApiDocumentsDownloadRequest, options?: any) {
        return DocumentsApiFp(this.configuration).documentsDownload(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiDocumentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsList(requestParameters: DocumentsApiDocumentsListRequest = {}, options?: any) {
        return DocumentsApiFp(this.configuration).documentsList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentsApiDocumentsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsRead(requestParameters: DocumentsApiDocumentsReadRequest, options?: any) {
        return DocumentsApiFp(this.configuration).documentsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DynamicApplicationsApi - axios parameter creator
 * @export
 */
export const DynamicApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsCreate: async (data: DynamicApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('dynamicApplicationsCreate', 'data', data)
            const localVarPath = `/dynamic_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dynamicApplicationsDelete', 'id', id)
            const localVarPath = `/dynamic_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsList: async (profile?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dynamic_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsPartialUpdate: async (id: number, data: DynamicApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dynamicApplicationsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('dynamicApplicationsPartialUpdate', 'data', data)
            const localVarPath = `/dynamic_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dynamicApplicationsRead', 'id', id)
            const localVarPath = `/dynamic_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsUpdate: async (id: number, data: DynamicApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dynamicApplicationsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('dynamicApplicationsUpdate', 'data', data)
            const localVarPath = `/dynamic_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicApplicationsApi - functional programming interface
 * @export
 */
export const DynamicApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DynamicApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicApplicationsCreate(data: DynamicApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicApplicationsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicApplicationsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicApplicationsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicApplicationsList(profile?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicApplicationsList(profile, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicApplicationsPartialUpdate(id: number, data: DynamicApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicApplicationsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicApplicationsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicApplicationsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicApplicationsUpdate(id: number, data: DynamicApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicApplicationsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DynamicApplicationsApi - factory interface
 * @export
 */
export const DynamicApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DynamicApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsCreate(data: DynamicApplication, options?: any): AxiosPromise<DynamicApplication> {
            return localVarFp.dynamicApplicationsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.dynamicApplicationsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsList(profile?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20013> {
            return localVarFp.dynamicApplicationsList(profile, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsPartialUpdate(id: number, data: DynamicApplication, options?: any): AxiosPromise<DynamicApplication> {
            return localVarFp.dynamicApplicationsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsRead(id: number, options?: any): AxiosPromise<DynamicApplication> {
            return localVarFp.dynamicApplicationsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {DynamicApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicApplicationsUpdate(id: number, data: DynamicApplication, options?: any): AxiosPromise<DynamicApplication> {
            return localVarFp.dynamicApplicationsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dynamicApplicationsCreate operation in DynamicApplicationsApi.
 * @export
 * @interface DynamicApplicationsApiDynamicApplicationsCreateRequest
 */
export interface DynamicApplicationsApiDynamicApplicationsCreateRequest {
    /**
     * 
     * @type {DynamicApplication}
     * @memberof DynamicApplicationsApiDynamicApplicationsCreate
     */
    readonly data: DynamicApplication
}

/**
 * Request parameters for dynamicApplicationsDelete operation in DynamicApplicationsApi.
 * @export
 * @interface DynamicApplicationsApiDynamicApplicationsDeleteRequest
 */
export interface DynamicApplicationsApiDynamicApplicationsDeleteRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof DynamicApplicationsApiDynamicApplicationsDelete
     */
    readonly id: number
}

/**
 * Request parameters for dynamicApplicationsList operation in DynamicApplicationsApi.
 * @export
 * @interface DynamicApplicationsApiDynamicApplicationsListRequest
 */
export interface DynamicApplicationsApiDynamicApplicationsListRequest {
    /**
     * 
     * @type {string}
     * @memberof DynamicApplicationsApiDynamicApplicationsList
     */
    readonly profile?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DynamicApplicationsApiDynamicApplicationsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DynamicApplicationsApiDynamicApplicationsList
     */
    readonly limit?: number
}

/**
 * Request parameters for dynamicApplicationsPartialUpdate operation in DynamicApplicationsApi.
 * @export
 * @interface DynamicApplicationsApiDynamicApplicationsPartialUpdateRequest
 */
export interface DynamicApplicationsApiDynamicApplicationsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof DynamicApplicationsApiDynamicApplicationsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DynamicApplication}
     * @memberof DynamicApplicationsApiDynamicApplicationsPartialUpdate
     */
    readonly data: DynamicApplication
}

/**
 * Request parameters for dynamicApplicationsRead operation in DynamicApplicationsApi.
 * @export
 * @interface DynamicApplicationsApiDynamicApplicationsReadRequest
 */
export interface DynamicApplicationsApiDynamicApplicationsReadRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof DynamicApplicationsApiDynamicApplicationsRead
     */
    readonly id: number
}

/**
 * Request parameters for dynamicApplicationsUpdate operation in DynamicApplicationsApi.
 * @export
 * @interface DynamicApplicationsApiDynamicApplicationsUpdateRequest
 */
export interface DynamicApplicationsApiDynamicApplicationsUpdateRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof DynamicApplicationsApiDynamicApplicationsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DynamicApplication}
     * @memberof DynamicApplicationsApiDynamicApplicationsUpdate
     */
    readonly data: DynamicApplication
}

/**
 * DynamicApplicationsApi - object-oriented interface
 * @export
 * @class DynamicApplicationsApi
 * @extends {BaseAPI}
 */
export class DynamicApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {DynamicApplicationsApiDynamicApplicationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicApplicationsApi
     */
    public dynamicApplicationsCreate(requestParameters: DynamicApplicationsApiDynamicApplicationsCreateRequest, options?: any) {
        return DynamicApplicationsApiFp(this.configuration).dynamicApplicationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicApplicationsApiDynamicApplicationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicApplicationsApi
     */
    public dynamicApplicationsDelete(requestParameters: DynamicApplicationsApiDynamicApplicationsDeleteRequest, options?: any) {
        return DynamicApplicationsApiFp(this.configuration).dynamicApplicationsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicApplicationsApiDynamicApplicationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicApplicationsApi
     */
    public dynamicApplicationsList(requestParameters: DynamicApplicationsApiDynamicApplicationsListRequest = {}, options?: any) {
        return DynamicApplicationsApiFp(this.configuration).dynamicApplicationsList(requestParameters.profile, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicApplicationsApiDynamicApplicationsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicApplicationsApi
     */
    public dynamicApplicationsPartialUpdate(requestParameters: DynamicApplicationsApiDynamicApplicationsPartialUpdateRequest, options?: any) {
        return DynamicApplicationsApiFp(this.configuration).dynamicApplicationsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicApplicationsApiDynamicApplicationsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicApplicationsApi
     */
    public dynamicApplicationsRead(requestParameters: DynamicApplicationsApiDynamicApplicationsReadRequest, options?: any) {
        return DynamicApplicationsApiFp(this.configuration).dynamicApplicationsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DynamicApplicationsApiDynamicApplicationsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicApplicationsApi
     */
    public dynamicApplicationsUpdate(requestParameters: DynamicApplicationsApiDynamicApplicationsUpdateRequest, options?: any) {
        return DynamicApplicationsApiFp(this.configuration).dynamicApplicationsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailModalsApi - axios parameter creator
 * @export
 */
export const EmailModalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'congratulation'} mailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailModalsRead: async (mailType: 'congratulation', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailType' is not null or undefined
            assertParamExists('emailModalsRead', 'mailType', mailType)
            const localVarPath = `/email_modals/{mail_type}/`
                .replace(`{${"mail_type"}}`, encodeURIComponent(String(mailType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailModalsApi - functional programming interface
 * @export
 */
export const EmailModalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailModalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'congratulation'} mailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailModalsRead(mailType: 'congratulation', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailModal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailModalsRead(mailType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailModalsApi - factory interface
 * @export
 */
export const EmailModalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailModalsApiFp(configuration)
    return {
        /**
         * 
         * @param {'congratulation'} mailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailModalsRead(mailType: 'congratulation', options?: any): AxiosPromise<EmailModal> {
            return localVarFp.emailModalsRead(mailType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for emailModalsRead operation in EmailModalsApi.
 * @export
 * @interface EmailModalsApiEmailModalsReadRequest
 */
export interface EmailModalsApiEmailModalsReadRequest {
    /**
     * 
     * @type {'congratulation'}
     * @memberof EmailModalsApiEmailModalsRead
     */
    readonly mailType: 'congratulation'
}

/**
 * EmailModalsApi - object-oriented interface
 * @export
 * @class EmailModalsApi
 * @extends {BaseAPI}
 */
export class EmailModalsApi extends BaseAPI {
    /**
     * 
     * @param {EmailModalsApiEmailModalsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailModalsApi
     */
    public emailModalsRead(requestParameters: EmailModalsApiEmailModalsReadRequest, options?: any) {
        return EmailModalsApiFp(this.configuration).emailModalsRead(requestParameters.mailType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorScreensApi - axios parameter creator
 * @export
 */
export const ErrorScreensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [product] 
         * @param {string} [category] 
         * @param {string} [content] 
         * @param {string} [isActive] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorScreensList: async (product?: string, category?: string, content?: string, isActive?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error_screens/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Настройки экрана ошибки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorScreensRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('errorScreensRead', 'id', id)
            const localVarPath = `/error_screens/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorScreensApi - functional programming interface
 * @export
 */
export const ErrorScreensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorScreensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [product] 
         * @param {string} [category] 
         * @param {string} [content] 
         * @param {string} [isActive] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorScreensList(product?: string, category?: string, content?: string, isActive?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorScreensList(product, category, content, isActive, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Настройки экрана ошибки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorScreensRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorScreenConfigModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorScreensRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorScreensApi - factory interface
 * @export
 */
export const ErrorScreensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorScreensApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [product] 
         * @param {string} [category] 
         * @param {string} [content] 
         * @param {string} [isActive] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorScreensList(product?: string, category?: string, content?: string, isActive?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20014> {
            return localVarFp.errorScreensList(product, category, content, isActive, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Настройки экрана ошибки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorScreensRead(id: number, options?: any): AxiosPromise<ErrorScreenConfigModel> {
            return localVarFp.errorScreensRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for errorScreensList operation in ErrorScreensApi.
 * @export
 * @interface ErrorScreensApiErrorScreensListRequest
 */
export interface ErrorScreensApiErrorScreensListRequest {
    /**
     * 
     * @type {string}
     * @memberof ErrorScreensApiErrorScreensList
     */
    readonly product?: string

    /**
     * 
     * @type {string}
     * @memberof ErrorScreensApiErrorScreensList
     */
    readonly category?: string

    /**
     * 
     * @type {string}
     * @memberof ErrorScreensApiErrorScreensList
     */
    readonly content?: string

    /**
     * 
     * @type {string}
     * @memberof ErrorScreensApiErrorScreensList
     */
    readonly isActive?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ErrorScreensApiErrorScreensList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ErrorScreensApiErrorScreensList
     */
    readonly limit?: number
}

/**
 * Request parameters for errorScreensRead operation in ErrorScreensApi.
 * @export
 * @interface ErrorScreensApiErrorScreensReadRequest
 */
export interface ErrorScreensApiErrorScreensReadRequest {
    /**
     * A unique integer value identifying this Настройки экрана ошибки.
     * @type {number}
     * @memberof ErrorScreensApiErrorScreensRead
     */
    readonly id: number
}

/**
 * ErrorScreensApi - object-oriented interface
 * @export
 * @class ErrorScreensApi
 * @extends {BaseAPI}
 */
export class ErrorScreensApi extends BaseAPI {
    /**
     * 
     * @param {ErrorScreensApiErrorScreensListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorScreensApi
     */
    public errorScreensList(requestParameters: ErrorScreensApiErrorScreensListRequest = {}, options?: any) {
        return ErrorScreensApiFp(this.configuration).errorScreensList(requestParameters.product, requestParameters.category, requestParameters.content, requestParameters.isActive, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ErrorScreensApiErrorScreensReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorScreensApi
     */
    public errorScreensRead(requestParameters: ErrorScreensApiErrorScreensReadRequest, options?: any) {
        return ErrorScreensApiFp(this.configuration).errorScreensRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackCreate: async (data: Feedback, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('feedbackCreate', 'data', data)
            const localVarPath = `/feedback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackDelete', 'id', id)
            const localVarPath = `/feedback/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [author] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackList: async (author?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/feedback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (author !== undefined) {
                localVarQueryParameter['author'] = author;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPartialUpdate: async (id: number, data: Feedback, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('feedbackPartialUpdate', 'data', data)
            const localVarPath = `/feedback/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackRead', 'id', id)
            const localVarPath = `/feedback/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackUpdate: async (id: number, data: Feedback, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('feedbackUpdate', 'data', data)
            const localVarPath = `/feedback/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackCreate(data: Feedback, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [author] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackList(author?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackList(author, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackPartialUpdate(id: number, data: Feedback, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackUpdate(id: number, data: Feedback, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackCreate(data: Feedback, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.feedbackDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [author] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackList(author?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20015> {
            return localVarFp.feedbackList(author, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackPartialUpdate(id: number, data: Feedback, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackRead(id: number, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Обратная связь.
         * @param {Feedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackUpdate(id: number, data: Feedback, options?: any): AxiosPromise<Feedback> {
            return localVarFp.feedbackUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for feedbackCreate operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackCreateRequest
 */
export interface FeedbackApiFeedbackCreateRequest {
    /**
     * 
     * @type {Feedback}
     * @memberof FeedbackApiFeedbackCreate
     */
    readonly data: Feedback
}

/**
 * Request parameters for feedbackDelete operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackDeleteRequest
 */
export interface FeedbackApiFeedbackDeleteRequest {
    /**
     * A unique integer value identifying this Обратная связь.
     * @type {number}
     * @memberof FeedbackApiFeedbackDelete
     */
    readonly id: number
}

/**
 * Request parameters for feedbackList operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackListRequest
 */
export interface FeedbackApiFeedbackListRequest {
    /**
     * 
     * @type {string}
     * @memberof FeedbackApiFeedbackList
     */
    readonly author?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof FeedbackApiFeedbackList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FeedbackApiFeedbackList
     */
    readonly limit?: number
}

/**
 * Request parameters for feedbackPartialUpdate operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackPartialUpdateRequest
 */
export interface FeedbackApiFeedbackPartialUpdateRequest {
    /**
     * A unique integer value identifying this Обратная связь.
     * @type {number}
     * @memberof FeedbackApiFeedbackPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Feedback}
     * @memberof FeedbackApiFeedbackPartialUpdate
     */
    readonly data: Feedback
}

/**
 * Request parameters for feedbackRead operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackReadRequest
 */
export interface FeedbackApiFeedbackReadRequest {
    /**
     * A unique integer value identifying this Обратная связь.
     * @type {number}
     * @memberof FeedbackApiFeedbackRead
     */
    readonly id: number
}

/**
 * Request parameters for feedbackUpdate operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackUpdateRequest
 */
export interface FeedbackApiFeedbackUpdateRequest {
    /**
     * A unique integer value identifying this Обратная связь.
     * @type {number}
     * @memberof FeedbackApiFeedbackUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Feedback}
     * @memberof FeedbackApiFeedbackUpdate
     */
    readonly data: Feedback
}

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @param {FeedbackApiFeedbackCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackCreate(requestParameters: FeedbackApiFeedbackCreateRequest, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackApiFeedbackDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackDelete(requestParameters: FeedbackApiFeedbackDeleteRequest, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackApiFeedbackListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackList(requestParameters: FeedbackApiFeedbackListRequest = {}, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackList(requestParameters.author, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackApiFeedbackPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackPartialUpdate(requestParameters: FeedbackApiFeedbackPartialUpdateRequest, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackApiFeedbackReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackRead(requestParameters: FeedbackApiFeedbackReadRequest, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackApiFeedbackUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackUpdate(requestParameters: FeedbackApiFeedbackUpdateRequest, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackTopicApi - axios parameter creator
 * @export
 */
export const FeedbackTopicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicCreate: async (data: FeedbackTopic, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('feedbackTopicCreate', 'data', data)
            const localVarPath = `/feedback_topic/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackTopicDelete', 'id', id)
            const localVarPath = `/feedback_topic/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/feedback_topic/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicPartialUpdate: async (id: number, data: FeedbackTopic, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackTopicPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('feedbackTopicPartialUpdate', 'data', data)
            const localVarPath = `/feedback_topic/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackTopicRead', 'id', id)
            const localVarPath = `/feedback_topic/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicUpdate: async (id: number, data: FeedbackTopic, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedbackTopicUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('feedbackTopicUpdate', 'data', data)
            const localVarPath = `/feedback_topic/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackTopicApi - functional programming interface
 * @export
 */
export const FeedbackTopicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackTopicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackTopicCreate(data: FeedbackTopic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackTopicCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackTopicDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackTopicDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackTopicList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackTopicList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackTopicPartialUpdate(id: number, data: FeedbackTopic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackTopicPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackTopicRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackTopicRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackTopicUpdate(id: number, data: FeedbackTopic, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackTopic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackTopicUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackTopicApi - factory interface
 * @export
 */
export const FeedbackTopicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackTopicApiFp(configuration)
    return {
        /**
         * 
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicCreate(data: FeedbackTopic, options?: any): AxiosPromise<FeedbackTopic> {
            return localVarFp.feedbackTopicCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.feedbackTopicDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20016> {
            return localVarFp.feedbackTopicList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicPartialUpdate(id: number, data: FeedbackTopic, options?: any): AxiosPromise<FeedbackTopic> {
            return localVarFp.feedbackTopicPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicRead(id: number, options?: any): AxiosPromise<FeedbackTopic> {
            return localVarFp.feedbackTopicRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Тема обратной связи.
         * @param {FeedbackTopic} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackTopicUpdate(id: number, data: FeedbackTopic, options?: any): AxiosPromise<FeedbackTopic> {
            return localVarFp.feedbackTopicUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for feedbackTopicCreate operation in FeedbackTopicApi.
 * @export
 * @interface FeedbackTopicApiFeedbackTopicCreateRequest
 */
export interface FeedbackTopicApiFeedbackTopicCreateRequest {
    /**
     * 
     * @type {FeedbackTopic}
     * @memberof FeedbackTopicApiFeedbackTopicCreate
     */
    readonly data: FeedbackTopic
}

/**
 * Request parameters for feedbackTopicDelete operation in FeedbackTopicApi.
 * @export
 * @interface FeedbackTopicApiFeedbackTopicDeleteRequest
 */
export interface FeedbackTopicApiFeedbackTopicDeleteRequest {
    /**
     * A unique integer value identifying this Тема обратной связи.
     * @type {number}
     * @memberof FeedbackTopicApiFeedbackTopicDelete
     */
    readonly id: number
}

/**
 * Request parameters for feedbackTopicList operation in FeedbackTopicApi.
 * @export
 * @interface FeedbackTopicApiFeedbackTopicListRequest
 */
export interface FeedbackTopicApiFeedbackTopicListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof FeedbackTopicApiFeedbackTopicList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FeedbackTopicApiFeedbackTopicList
     */
    readonly limit?: number
}

/**
 * Request parameters for feedbackTopicPartialUpdate operation in FeedbackTopicApi.
 * @export
 * @interface FeedbackTopicApiFeedbackTopicPartialUpdateRequest
 */
export interface FeedbackTopicApiFeedbackTopicPartialUpdateRequest {
    /**
     * A unique integer value identifying this Тема обратной связи.
     * @type {number}
     * @memberof FeedbackTopicApiFeedbackTopicPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FeedbackTopic}
     * @memberof FeedbackTopicApiFeedbackTopicPartialUpdate
     */
    readonly data: FeedbackTopic
}

/**
 * Request parameters for feedbackTopicRead operation in FeedbackTopicApi.
 * @export
 * @interface FeedbackTopicApiFeedbackTopicReadRequest
 */
export interface FeedbackTopicApiFeedbackTopicReadRequest {
    /**
     * A unique integer value identifying this Тема обратной связи.
     * @type {number}
     * @memberof FeedbackTopicApiFeedbackTopicRead
     */
    readonly id: number
}

/**
 * Request parameters for feedbackTopicUpdate operation in FeedbackTopicApi.
 * @export
 * @interface FeedbackTopicApiFeedbackTopicUpdateRequest
 */
export interface FeedbackTopicApiFeedbackTopicUpdateRequest {
    /**
     * A unique integer value identifying this Тема обратной связи.
     * @type {number}
     * @memberof FeedbackTopicApiFeedbackTopicUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FeedbackTopic}
     * @memberof FeedbackTopicApiFeedbackTopicUpdate
     */
    readonly data: FeedbackTopic
}

/**
 * FeedbackTopicApi - object-oriented interface
 * @export
 * @class FeedbackTopicApi
 * @extends {BaseAPI}
 */
export class FeedbackTopicApi extends BaseAPI {
    /**
     * 
     * @param {FeedbackTopicApiFeedbackTopicCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackTopicApi
     */
    public feedbackTopicCreate(requestParameters: FeedbackTopicApiFeedbackTopicCreateRequest, options?: any) {
        return FeedbackTopicApiFp(this.configuration).feedbackTopicCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackTopicApiFeedbackTopicDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackTopicApi
     */
    public feedbackTopicDelete(requestParameters: FeedbackTopicApiFeedbackTopicDeleteRequest, options?: any) {
        return FeedbackTopicApiFp(this.configuration).feedbackTopicDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackTopicApiFeedbackTopicListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackTopicApi
     */
    public feedbackTopicList(requestParameters: FeedbackTopicApiFeedbackTopicListRequest = {}, options?: any) {
        return FeedbackTopicApiFp(this.configuration).feedbackTopicList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackTopicApiFeedbackTopicPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackTopicApi
     */
    public feedbackTopicPartialUpdate(requestParameters: FeedbackTopicApiFeedbackTopicPartialUpdateRequest, options?: any) {
        return FeedbackTopicApiFp(this.configuration).feedbackTopicPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackTopicApiFeedbackTopicReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackTopicApi
     */
    public feedbackTopicRead(requestParameters: FeedbackTopicApiFeedbackTopicReadRequest, options?: any) {
        return FeedbackTopicApiFp(this.configuration).feedbackTopicRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeedbackTopicApiFeedbackTopicUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackTopicApi
     */
    public feedbackTopicUpdate(requestParameters: FeedbackTopicApiFeedbackTopicUpdateRequest, options?: any) {
        return FeedbackTopicApiFp(this.configuration).feedbackTopicUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinanceCategoryApi - axios parameter creator
 * @export
 */
export const FinanceCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/finance_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinanceCategoryApi - functional programming interface
 * @export
 */
export const FinanceCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinanceCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinanceCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financeCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinanceCategoryApi - factory interface
 * @export
 */
export const FinanceCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinanceCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeCategoryList(options?: any): AxiosPromise<FinanceCategory> {
            return localVarFp.financeCategoryList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinanceCategoryApi - object-oriented interface
 * @export
 * @class FinanceCategoryApi
 * @extends {BaseAPI}
 */
export class FinanceCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceCategoryApi
     */
    public financeCategoryList(options?: any) {
        return FinanceCategoryApiFp(this.configuration).financeCategoryList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FooterApi - axios parameter creator
 * @export
 */
export const FooterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerCreate: async (data: FooterLink, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('footerCreate', 'data', data)
            const localVarPath = `/footer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('footerDelete', 'id', id)
            const localVarPath = `/footer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/footer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerPartialUpdate: async (id: number, data: FooterLink, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('footerPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('footerPartialUpdate', 'data', data)
            const localVarPath = `/footer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('footerRead', 'id', id)
            const localVarPath = `/footer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerUpdate: async (id: number, data: FooterLink, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('footerUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('footerUpdate', 'data', data)
            const localVarPath = `/footer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FooterApi - functional programming interface
 * @export
 */
export const FooterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FooterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerCreate(data: FooterLink, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerPartialUpdate(id: number, data: FooterLink, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerUpdate(id: number, data: FooterLink, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FooterApi - factory interface
 * @export
 */
export const FooterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FooterApiFp(configuration)
    return {
        /**
         * 
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerCreate(data: FooterLink, options?: any): AxiosPromise<FooterLink> {
            return localVarFp.footerCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.footerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20017> {
            return localVarFp.footerList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerPartialUpdate(id: number, data: FooterLink, options?: any): AxiosPromise<FooterLink> {
            return localVarFp.footerPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerRead(id: number, options?: any): AxiosPromise<FooterLink> {
            return localVarFp.footerRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ссылка в футере.
         * @param {FooterLink} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerUpdate(id: number, data: FooterLink, options?: any): AxiosPromise<FooterLink> {
            return localVarFp.footerUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for footerCreate operation in FooterApi.
 * @export
 * @interface FooterApiFooterCreateRequest
 */
export interface FooterApiFooterCreateRequest {
    /**
     * 
     * @type {FooterLink}
     * @memberof FooterApiFooterCreate
     */
    readonly data: FooterLink
}

/**
 * Request parameters for footerDelete operation in FooterApi.
 * @export
 * @interface FooterApiFooterDeleteRequest
 */
export interface FooterApiFooterDeleteRequest {
    /**
     * A unique integer value identifying this Ссылка в футере.
     * @type {number}
     * @memberof FooterApiFooterDelete
     */
    readonly id: number
}

/**
 * Request parameters for footerList operation in FooterApi.
 * @export
 * @interface FooterApiFooterListRequest
 */
export interface FooterApiFooterListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof FooterApiFooterList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof FooterApiFooterList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FooterApiFooterList
     */
    readonly limit?: number
}

/**
 * Request parameters for footerPartialUpdate operation in FooterApi.
 * @export
 * @interface FooterApiFooterPartialUpdateRequest
 */
export interface FooterApiFooterPartialUpdateRequest {
    /**
     * A unique integer value identifying this Ссылка в футере.
     * @type {number}
     * @memberof FooterApiFooterPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FooterLink}
     * @memberof FooterApiFooterPartialUpdate
     */
    readonly data: FooterLink
}

/**
 * Request parameters for footerRead operation in FooterApi.
 * @export
 * @interface FooterApiFooterReadRequest
 */
export interface FooterApiFooterReadRequest {
    /**
     * A unique integer value identifying this Ссылка в футере.
     * @type {number}
     * @memberof FooterApiFooterRead
     */
    readonly id: number
}

/**
 * Request parameters for footerUpdate operation in FooterApi.
 * @export
 * @interface FooterApiFooterUpdateRequest
 */
export interface FooterApiFooterUpdateRequest {
    /**
     * A unique integer value identifying this Ссылка в футере.
     * @type {number}
     * @memberof FooterApiFooterUpdate
     */
    readonly id: number

    /**
     * 
     * @type {FooterLink}
     * @memberof FooterApiFooterUpdate
     */
    readonly data: FooterLink
}

/**
 * FooterApi - object-oriented interface
 * @export
 * @class FooterApi
 * @extends {BaseAPI}
 */
export class FooterApi extends BaseAPI {
    /**
     * 
     * @param {FooterApiFooterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerCreate(requestParameters: FooterApiFooterCreateRequest, options?: any) {
        return FooterApiFp(this.configuration).footerCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterApiFooterDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerDelete(requestParameters: FooterApiFooterDeleteRequest, options?: any) {
        return FooterApiFp(this.configuration).footerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterApiFooterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerList(requestParameters: FooterApiFooterListRequest = {}, options?: any) {
        return FooterApiFp(this.configuration).footerList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterApiFooterPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerPartialUpdate(requestParameters: FooterApiFooterPartialUpdateRequest, options?: any) {
        return FooterApiFp(this.configuration).footerPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterApiFooterReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerRead(requestParameters: FooterApiFooterReadRequest, options?: any) {
        return FooterApiFp(this.configuration).footerRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterApiFooterUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterApi
     */
    public footerUpdate(requestParameters: FooterApiFooterUpdateRequest, options?: any) {
        return FooterApiFp(this.configuration).footerUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FooterContactsApi - axios parameter creator
 * @export
 */
export const FooterContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerContactsList: async (ordering?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/footer_contacts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Контакты в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerContactsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('footerContactsRead', 'id', id)
            const localVarPath = `/footer_contacts/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FooterContactsApi - functional programming interface
 * @export
 */
export const FooterContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FooterContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerContactsList(ordering?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FooterContacts>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerContactsList(ordering, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Контакты в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerContactsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterContacts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerContactsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FooterContactsApi - factory interface
 * @export
 */
export const FooterContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FooterContactsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerContactsList(ordering?: string, options?: any): AxiosPromise<Array<FooterContacts>> {
            return localVarFp.footerContactsList(ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Контакты в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerContactsRead(id: number, options?: any): AxiosPromise<FooterContacts> {
            return localVarFp.footerContactsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for footerContactsList operation in FooterContactsApi.
 * @export
 * @interface FooterContactsApiFooterContactsListRequest
 */
export interface FooterContactsApiFooterContactsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof FooterContactsApiFooterContactsList
     */
    readonly ordering?: string
}

/**
 * Request parameters for footerContactsRead operation in FooterContactsApi.
 * @export
 * @interface FooterContactsApiFooterContactsReadRequest
 */
export interface FooterContactsApiFooterContactsReadRequest {
    /**
     * A unique integer value identifying this Контакты в футере.
     * @type {number}
     * @memberof FooterContactsApiFooterContactsRead
     */
    readonly id: number
}

/**
 * FooterContactsApi - object-oriented interface
 * @export
 * @class FooterContactsApi
 * @extends {BaseAPI}
 */
export class FooterContactsApi extends BaseAPI {
    /**
     * 
     * @param {FooterContactsApiFooterContactsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterContactsApi
     */
    public footerContactsList(requestParameters: FooterContactsApiFooterContactsListRequest = {}, options?: any) {
        return FooterContactsApiFp(this.configuration).footerContactsList(requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterContactsApiFooterContactsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterContactsApi
     */
    public footerContactsRead(requestParameters: FooterContactsApiFooterContactsReadRequest, options?: any) {
        return FooterContactsApiFp(this.configuration).footerContactsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FooterSocialsApi - axios parameter creator
 * @export
 */
export const FooterSocialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerSocialsList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/footer_socials/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Социальные сети в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerSocialsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('footerSocialsRead', 'id', id)
            const localVarPath = `/footer_socials/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FooterSocialsApi - functional programming interface
 * @export
 */
export const FooterSocialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FooterSocialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerSocialsList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerSocialsList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Социальные сети в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async footerSocialsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FooterSocials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.footerSocialsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FooterSocialsApi - factory interface
 * @export
 */
export const FooterSocialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FooterSocialsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerSocialsList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20018> {
            return localVarFp.footerSocialsList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Социальные сети в футере.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        footerSocialsRead(id: number, options?: any): AxiosPromise<FooterSocials> {
            return localVarFp.footerSocialsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for footerSocialsList operation in FooterSocialsApi.
 * @export
 * @interface FooterSocialsApiFooterSocialsListRequest
 */
export interface FooterSocialsApiFooterSocialsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof FooterSocialsApiFooterSocialsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof FooterSocialsApiFooterSocialsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FooterSocialsApiFooterSocialsList
     */
    readonly limit?: number
}

/**
 * Request parameters for footerSocialsRead operation in FooterSocialsApi.
 * @export
 * @interface FooterSocialsApiFooterSocialsReadRequest
 */
export interface FooterSocialsApiFooterSocialsReadRequest {
    /**
     * A unique integer value identifying this Социальные сети в футере.
     * @type {number}
     * @memberof FooterSocialsApiFooterSocialsRead
     */
    readonly id: number
}

/**
 * FooterSocialsApi - object-oriented interface
 * @export
 * @class FooterSocialsApi
 * @extends {BaseAPI}
 */
export class FooterSocialsApi extends BaseAPI {
    /**
     * 
     * @param {FooterSocialsApiFooterSocialsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterSocialsApi
     */
    public footerSocialsList(requestParameters: FooterSocialsApiFooterSocialsListRequest = {}, options?: any) {
        return FooterSocialsApiFp(this.configuration).footerSocialsList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FooterSocialsApiFooterSocialsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FooterSocialsApi
     */
    public footerSocialsRead(requestParameters: FooterSocialsApiFooterSocialsReadRequest, options?: any) {
        return FooterSocialsApiFp(this.configuration).footerSocialsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GloabalCategoryPageApi - axios parameter creator
 * @export
 */
export const GloabalCategoryPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gloabalCategoryPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gloabal_category_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GloabalCategoryPageApi - functional programming interface
 * @export
 */
export const GloabalCategoryPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GloabalCategoryPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gloabalCategoryPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalCategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gloabalCategoryPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GloabalCategoryPageApi - factory interface
 * @export
 */
export const GloabalCategoryPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GloabalCategoryPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gloabalCategoryPageList(options?: any): AxiosPromise<GlobalCategoryPage> {
            return localVarFp.gloabalCategoryPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GloabalCategoryPageApi - object-oriented interface
 * @export
 * @class GloabalCategoryPageApi
 * @extends {BaseAPI}
 */
export class GloabalCategoryPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GloabalCategoryPageApi
     */
    public gloabalCategoryPageList(options?: any) {
        return GloabalCategoryPageApiFp(this.configuration).gloabalCategoryPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalApplicationsApi - axios parameter creator
 * @export
 */
export const GlobalApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalApplicationsList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalApplicationsApi - functional programming interface
 * @export
 */
export const GlobalApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalApplicationsList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalApplicationsList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalApplicationsApi - factory interface
 * @export
 */
export const GlobalApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalApplicationsList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20019> {
            return localVarFp.globalApplicationsList(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for globalApplicationsList operation in GlobalApplicationsApi.
 * @export
 * @interface GlobalApplicationsApiGlobalApplicationsListRequest
 */
export interface GlobalApplicationsApiGlobalApplicationsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GlobalApplicationsApiGlobalApplicationsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GlobalApplicationsApiGlobalApplicationsList
     */
    readonly limit?: number
}

/**
 * GlobalApplicationsApi - object-oriented interface
 * @export
 * @class GlobalApplicationsApi
 * @extends {BaseAPI}
 */
export class GlobalApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {GlobalApplicationsApiGlobalApplicationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApplicationsApi
     */
    public globalApplicationsList(requestParameters: GlobalApplicationsApiGlobalApplicationsListRequest = {}, options?: any) {
        return GlobalApplicationsApiFp(this.configuration).globalApplicationsList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalCategoriesApi - axios parameter creator
 * @export
 */
export const GlobalCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalCategoriesList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global_categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalCategoriesApi - functional programming interface
 * @export
 */
export const GlobalCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalCategoriesList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalCategoriesList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalCategoriesApi - factory interface
 * @export
 */
export const GlobalCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalCategoriesList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20020> {
            return localVarFp.globalCategoriesList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for globalCategoriesList operation in GlobalCategoriesApi.
 * @export
 * @interface GlobalCategoriesApiGlobalCategoriesListRequest
 */
export interface GlobalCategoriesApiGlobalCategoriesListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof GlobalCategoriesApiGlobalCategoriesList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GlobalCategoriesApiGlobalCategoriesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GlobalCategoriesApiGlobalCategoriesList
     */
    readonly limit?: number
}

/**
 * GlobalCategoriesApi - object-oriented interface
 * @export
 * @class GlobalCategoriesApi
 * @extends {BaseAPI}
 */
export class GlobalCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {GlobalCategoriesApiGlobalCategoriesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalCategoriesApi
     */
    public globalCategoriesList(requestParameters: GlobalCategoriesApiGlobalCategoriesListRequest = {}, options?: any) {
        return GlobalCategoriesApiFp(this.configuration).globalCategoriesList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalProductsApi - axios parameter creator
 * @export
 */
export const GlobalProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsCreate: async (data: BaseProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('globalProductsCreate', 'data', data)
            const localVarPath = `/global_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalProductsDelete', 'id', id)
            const localVarPath = `/global_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/global_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsPartialUpdate: async (id: number, data: BaseProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('globalProductsPartialUpdate', 'data', data)
            const localVarPath = `/global_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalProductsRead', 'id', id)
            const localVarPath = `/global_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsUpdate: async (id: number, data: BaseProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('globalProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('globalProductsUpdate', 'data', data)
            const localVarPath = `/global_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalProductsApi - functional programming interface
 * @export
 */
export const GlobalProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalProductsCreate(data: BaseProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalProductsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalProductsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalProductsList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalProductsList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalProductsPartialUpdate(id: number, data: BaseProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalProductsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalProductsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async globalProductsUpdate(id: number, data: BaseProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.globalProductsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalProductsApi - factory interface
 * @export
 */
export const GlobalProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsCreate(data: BaseProduct, options?: any): AxiosPromise<BaseProduct> {
            return localVarFp.globalProductsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.globalProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20021> {
            return localVarFp.globalProductsList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsPartialUpdate(id: number, data: BaseProduct, options?: any): AxiosPromise<BaseProduct> {
            return localVarFp.globalProductsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsRead(id: number, options?: any): AxiosPromise<BaseProduct> {
            return localVarFp.globalProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Товар.
         * @param {BaseProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        globalProductsUpdate(id: number, data: BaseProduct, options?: any): AxiosPromise<BaseProduct> {
            return localVarFp.globalProductsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for globalProductsCreate operation in GlobalProductsApi.
 * @export
 * @interface GlobalProductsApiGlobalProductsCreateRequest
 */
export interface GlobalProductsApiGlobalProductsCreateRequest {
    /**
     * 
     * @type {BaseProduct}
     * @memberof GlobalProductsApiGlobalProductsCreate
     */
    readonly data: BaseProduct
}

/**
 * Request parameters for globalProductsDelete operation in GlobalProductsApi.
 * @export
 * @interface GlobalProductsApiGlobalProductsDeleteRequest
 */
export interface GlobalProductsApiGlobalProductsDeleteRequest {
    /**
     * A unique integer value identifying this Товар.
     * @type {number}
     * @memberof GlobalProductsApiGlobalProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for globalProductsList operation in GlobalProductsApi.
 * @export
 * @interface GlobalProductsApiGlobalProductsListRequest
 */
export interface GlobalProductsApiGlobalProductsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof GlobalProductsApiGlobalProductsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GlobalProductsApiGlobalProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GlobalProductsApiGlobalProductsList
     */
    readonly limit?: number
}

/**
 * Request parameters for globalProductsPartialUpdate operation in GlobalProductsApi.
 * @export
 * @interface GlobalProductsApiGlobalProductsPartialUpdateRequest
 */
export interface GlobalProductsApiGlobalProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Товар.
     * @type {number}
     * @memberof GlobalProductsApiGlobalProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BaseProduct}
     * @memberof GlobalProductsApiGlobalProductsPartialUpdate
     */
    readonly data: BaseProduct
}

/**
 * Request parameters for globalProductsRead operation in GlobalProductsApi.
 * @export
 * @interface GlobalProductsApiGlobalProductsReadRequest
 */
export interface GlobalProductsApiGlobalProductsReadRequest {
    /**
     * A unique integer value identifying this Товар.
     * @type {number}
     * @memberof GlobalProductsApiGlobalProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for globalProductsUpdate operation in GlobalProductsApi.
 * @export
 * @interface GlobalProductsApiGlobalProductsUpdateRequest
 */
export interface GlobalProductsApiGlobalProductsUpdateRequest {
    /**
     * A unique integer value identifying this Товар.
     * @type {number}
     * @memberof GlobalProductsApiGlobalProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {BaseProduct}
     * @memberof GlobalProductsApiGlobalProductsUpdate
     */
    readonly data: BaseProduct
}

/**
 * GlobalProductsApi - object-oriented interface
 * @export
 * @class GlobalProductsApi
 * @extends {BaseAPI}
 */
export class GlobalProductsApi extends BaseAPI {
    /**
     * 
     * @param {GlobalProductsApiGlobalProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalProductsApi
     */
    public globalProductsCreate(requestParameters: GlobalProductsApiGlobalProductsCreateRequest, options?: any) {
        return GlobalProductsApiFp(this.configuration).globalProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalProductsApiGlobalProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalProductsApi
     */
    public globalProductsDelete(requestParameters: GlobalProductsApiGlobalProductsDeleteRequest, options?: any) {
        return GlobalProductsApiFp(this.configuration).globalProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalProductsApiGlobalProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalProductsApi
     */
    public globalProductsList(requestParameters: GlobalProductsApiGlobalProductsListRequest = {}, options?: any) {
        return GlobalProductsApiFp(this.configuration).globalProductsList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalProductsApiGlobalProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalProductsApi
     */
    public globalProductsPartialUpdate(requestParameters: GlobalProductsApiGlobalProductsPartialUpdateRequest, options?: any) {
        return GlobalProductsApiFp(this.configuration).globalProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalProductsApiGlobalProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalProductsApi
     */
    public globalProductsRead(requestParameters: GlobalProductsApiGlobalProductsReadRequest, options?: any) {
        return GlobalProductsApiFp(this.configuration).globalProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalProductsApiGlobalProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalProductsApi
     */
    public globalProductsUpdate(requestParameters: GlobalProductsApiGlobalProductsUpdateRequest, options?: any) {
        return GlobalProductsApiFp(this.configuration).globalProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuideCategoryApi - axios parameter creator
 * @export
 */
export const GuideCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guide_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuideCategoryApi - functional programming interface
 * @export
 */
export const GuideCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuideCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuideCategoryApi - factory interface
 * @export
 */
export const GuideCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuideCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCategoryList(options?: any): AxiosPromise<GuideCategory> {
            return localVarFp.guideCategoryList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuideCategoryApi - object-oriented interface
 * @export
 * @class GuideCategoryApi
 * @extends {BaseAPI}
 */
export class GuideCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCategoryApi
     */
    public guideCategoryList(options?: any) {
        return GuideCategoryApiFp(this.configuration).guideCategoryList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuideCommentsApi - axios parameter creator
 * @export
 */
export const GuideCommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GuideComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsCreate: async (data: GuideComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('guideCommentsCreate', 'data', data)
            const localVarPath = `/guide_comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [guideId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsList: async (isLikedByUser?: boolean, likesCountGte?: number, guideId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guide_comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (guideId !== undefined) {
                localVarQueryParameter['guide__id'] = guideId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment__id'] = parentCommentId;
            }

            if (parentCommentIdIsnull !== undefined) {
                localVarQueryParameter['parent_comment__id__isnull'] = parentCommentIdIsnull;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile__id'] = profileId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [guideId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, guideId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideCommentsListLikes', 'id', id)
            const localVarPath = `/guide_comments/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (guideId !== undefined) {
                localVarQueryParameter['guide__id'] = guideId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment__id'] = parentCommentId;
            }

            if (parentCommentIdIsnull !== undefined) {
                localVarQueryParameter['parent_comment__id__isnull'] = parentCommentIdIsnull;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile__id'] = profileId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideCommentsRead', 'id', id)
            const localVarPath = `/guide_comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideCommentsToggleLikeCreate', 'id', id)
            const localVarPath = `/guide_comments/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideCommentsToggleLikeDelete', 'id', id)
            const localVarPath = `/guide_comments/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuideCommentsApi - functional programming interface
 * @export
 */
export const GuideCommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuideCommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GuideComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCommentsCreate(data: GuideComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCommentsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [guideId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCommentsList(isLikedByUser?: boolean, likesCountGte?: number, guideId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCommentsList(isLikedByUser, likesCountGte, guideId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [guideId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCommentsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, guideId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCommentsListLikes(id, isLikedByUser, likesCountGte, guideId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCommentsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCommentsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCommentsToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCommentsToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideCommentsToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideCommentsToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuideCommentsApi - factory interface
 * @export
 */
export const GuideCommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuideCommentsApiFp(configuration)
    return {
        /**
         * 
         * @param {GuideComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsCreate(data: GuideComment, options?: any): AxiosPromise<GuideComment> {
            return localVarFp.guideCommentsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [guideId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsList(isLikedByUser?: boolean, likesCountGte?: number, guideId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): AxiosPromise<InlineResponse20022> {
            return localVarFp.guideCommentsList(isLikedByUser, likesCountGte, guideId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [guideId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, guideId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.guideCommentsListLikes(id, isLikedByUser, likesCountGte, guideId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsRead(id: number, options?: any): AxiosPromise<GuideComment> {
            return localVarFp.guideCommentsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guideCommentsToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к статье гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideCommentsToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guideCommentsToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for guideCommentsCreate operation in GuideCommentsApi.
 * @export
 * @interface GuideCommentsApiGuideCommentsCreateRequest
 */
export interface GuideCommentsApiGuideCommentsCreateRequest {
    /**
     * 
     * @type {GuideComment}
     * @memberof GuideCommentsApiGuideCommentsCreate
     */
    readonly data: GuideComment
}

/**
 * Request parameters for guideCommentsList operation in GuideCommentsApi.
 * @export
 * @interface GuideCommentsApiGuideCommentsListRequest
 */
export interface GuideCommentsApiGuideCommentsListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly isLikedByUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly guideId?: number

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly parentCommentId?: number

    /**
     * 
     * @type {boolean}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly parentCommentIdIsnull?: boolean

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly profileId?: number

    /**
     * 
     * @type {string}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsList
     */
    readonly count?: number
}

/**
 * Request parameters for guideCommentsListLikes operation in GuideCommentsApi.
 * @export
 * @interface GuideCommentsApiGuideCommentsListLikesRequest
 */
export interface GuideCommentsApiGuideCommentsListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly guideId?: number

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly parentCommentId?: number

    /**
     * 
     * @type {string}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly parentCommentIdIsnull?: string

    /**
     * 
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly profileId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsListLikes
     */
    readonly count?: number
}

/**
 * Request parameters for guideCommentsRead operation in GuideCommentsApi.
 * @export
 * @interface GuideCommentsApiGuideCommentsReadRequest
 */
export interface GuideCommentsApiGuideCommentsReadRequest {
    /**
     * A unique integer value identifying this Комментарий к статье гида.
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsRead
     */
    readonly id: number
}

/**
 * Request parameters for guideCommentsToggleLikeCreate operation in GuideCommentsApi.
 * @export
 * @interface GuideCommentsApiGuideCommentsToggleLikeCreateRequest
 */
export interface GuideCommentsApiGuideCommentsToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Комментарий к статье гида.
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for guideCommentsToggleLikeDelete operation in GuideCommentsApi.
 * @export
 * @interface GuideCommentsApiGuideCommentsToggleLikeDeleteRequest
 */
export interface GuideCommentsApiGuideCommentsToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Комментарий к статье гида.
     * @type {number}
     * @memberof GuideCommentsApiGuideCommentsToggleLikeDelete
     */
    readonly id: number
}

/**
 * GuideCommentsApi - object-oriented interface
 * @export
 * @class GuideCommentsApi
 * @extends {BaseAPI}
 */
export class GuideCommentsApi extends BaseAPI {
    /**
     * 
     * @param {GuideCommentsApiGuideCommentsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCommentsApi
     */
    public guideCommentsCreate(requestParameters: GuideCommentsApiGuideCommentsCreateRequest, options?: any) {
        return GuideCommentsApiFp(this.configuration).guideCommentsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideCommentsApiGuideCommentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCommentsApi
     */
    public guideCommentsList(requestParameters: GuideCommentsApiGuideCommentsListRequest = {}, options?: any) {
        return GuideCommentsApiFp(this.configuration).guideCommentsList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.guideId, requestParameters.parentCommentId, requestParameters.parentCommentIdIsnull, requestParameters.profileId, requestParameters.ordering, requestParameters.page, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {GuideCommentsApiGuideCommentsListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCommentsApi
     */
    public guideCommentsListLikes(requestParameters: GuideCommentsApiGuideCommentsListLikesRequest, options?: any) {
        return GuideCommentsApiFp(this.configuration).guideCommentsListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.guideId, requestParameters.parentCommentId, requestParameters.parentCommentIdIsnull, requestParameters.profileId, requestParameters.ordering, requestParameters.page, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideCommentsApiGuideCommentsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCommentsApi
     */
    public guideCommentsRead(requestParameters: GuideCommentsApiGuideCommentsReadRequest, options?: any) {
        return GuideCommentsApiFp(this.configuration).guideCommentsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideCommentsApiGuideCommentsToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCommentsApi
     */
    public guideCommentsToggleLikeCreate(requestParameters: GuideCommentsApiGuideCommentsToggleLikeCreateRequest, options?: any) {
        return GuideCommentsApiFp(this.configuration).guideCommentsToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideCommentsApiGuideCommentsToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideCommentsApi
     */
    public guideCommentsToggleLikeDelete(requestParameters: GuideCommentsApiGuideCommentsToggleLikeDeleteRequest, options?: any) {
        return GuideCommentsApiFp(this.configuration).guideCommentsToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuideDirectionsApi - axios parameter creator
 * @export
 */
export const GuideDirectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideDirectionsList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guide_directions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideDirectionsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideDirectionsRead', 'id', id)
            const localVarPath = `/guide_directions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuideDirectionsApi - functional programming interface
 * @export
 */
export const GuideDirectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuideDirectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideDirectionsList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideDirectionsList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideDirectionsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideDirection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideDirectionsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuideDirectionsApi - factory interface
 * @export
 */
export const GuideDirectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuideDirectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideDirectionsList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20024> {
            return localVarFp.guideDirectionsList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideDirectionsRead(id: number, options?: any): AxiosPromise<GuideDirection> {
            return localVarFp.guideDirectionsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for guideDirectionsList operation in GuideDirectionsApi.
 * @export
 * @interface GuideDirectionsApiGuideDirectionsListRequest
 */
export interface GuideDirectionsApiGuideDirectionsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof GuideDirectionsApiGuideDirectionsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GuideDirectionsApiGuideDirectionsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GuideDirectionsApiGuideDirectionsList
     */
    readonly limit?: number
}

/**
 * Request parameters for guideDirectionsRead operation in GuideDirectionsApi.
 * @export
 * @interface GuideDirectionsApiGuideDirectionsReadRequest
 */
export interface GuideDirectionsApiGuideDirectionsReadRequest {
    /**
     * A unique integer value identifying this Направление.
     * @type {number}
     * @memberof GuideDirectionsApiGuideDirectionsRead
     */
    readonly id: number
}

/**
 * GuideDirectionsApi - object-oriented interface
 * @export
 * @class GuideDirectionsApi
 * @extends {BaseAPI}
 */
export class GuideDirectionsApi extends BaseAPI {
    /**
     * 
     * @param {GuideDirectionsApiGuideDirectionsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideDirectionsApi
     */
    public guideDirectionsList(requestParameters: GuideDirectionsApiGuideDirectionsListRequest = {}, options?: any) {
        return GuideDirectionsApiFp(this.configuration).guideDirectionsList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideDirectionsApiGuideDirectionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideDirectionsApi
     */
    public guideDirectionsRead(requestParameters: GuideDirectionsApiGuideDirectionsReadRequest, options?: any) {
        return GuideDirectionsApiFp(this.configuration).guideDirectionsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuidePageApi - axios parameter creator
 * @export
 */
export const GuidePageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {Array<number>} [directionsIdIn] 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageList: async (isInUserFavorites?: string, isShowOnMainPage?: string, directionsIdIn?: Array<number>, isLikedByUser?: boolean, likesCountGte?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guide_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (isShowOnMainPage !== undefined) {
                localVarQueryParameter['is_show_on_main_page'] = isShowOnMainPage;
            }

            if (directionsIdIn) {
                localVarQueryParameter['directions__id__in'] = directionsIdIn.join(COLLECTION_FORMATS.csv);
            }

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {string} [directionsIdIn] 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageListLikes: async (id: number, isInUserFavorites?: string, isShowOnMainPage?: string, directionsIdIn?: string, isLikedByUser?: string, likesCountGte?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guidePageListLikes', 'id', id)
            const localVarPath = `/guide_page/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (isShowOnMainPage !== undefined) {
                localVarQueryParameter['is_show_on_main_page'] = isShowOnMainPage;
            }

            if (directionsIdIn !== undefined) {
                localVarQueryParameter['directions__id__in'] = directionsIdIn;
            }

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guidePageRead', 'id', id)
            const localVarPath = `/guide_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleFavoriteCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guidePageToggleFavoriteCreate', 'id', id)
            const localVarPath = `/guide_page/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleFavoriteDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guidePageToggleFavoriteDelete', 'id', id)
            const localVarPath = `/guide_page/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guidePageToggleLikeCreate', 'id', id)
            const localVarPath = `/guide_page/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guidePageToggleLikeDelete', 'id', id)
            const localVarPath = `/guide_page/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuidePageApi - functional programming interface
 * @export
 */
export const GuidePageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuidePageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {Array<number>} [directionsIdIn] 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageList(isInUserFavorites?: string, isShowOnMainPage?: string, directionsIdIn?: Array<number>, isLikedByUser?: boolean, likesCountGte?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20025>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageList(isInUserFavorites, isShowOnMainPage, directionsIdIn, isLikedByUser, likesCountGte, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {string} [directionsIdIn] 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageListLikes(id: number, isInUserFavorites?: string, isShowOnMainPage?: string, directionsIdIn?: string, isLikedByUser?: string, likesCountGte?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageListLikes(id, isInUserFavorites, isShowOnMainPage, directionsIdIn, isLikedByUser, likesCountGte, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageToggleFavoriteCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageToggleFavoriteCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageToggleFavoriteDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageToggleFavoriteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guidePageToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guidePageToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuidePageApi - factory interface
 * @export
 */
export const GuidePageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuidePageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {Array<number>} [directionsIdIn] 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageList(isInUserFavorites?: string, isShowOnMainPage?: string, directionsIdIn?: Array<number>, isLikedByUser?: boolean, likesCountGte?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20025> {
            return localVarFp.guidePageList(isInUserFavorites, isShowOnMainPage, directionsIdIn, isLikedByUser, likesCountGte, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {string} [directionsIdIn] 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageListLikes(id: number, isInUserFavorites?: string, isShowOnMainPage?: string, directionsIdIn?: string, isLikedByUser?: string, likesCountGte?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.guidePageListLikes(id, isInUserFavorites, isShowOnMainPage, directionsIdIn, isLikedByUser, likesCountGte, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageRead(id: number, options?: any): AxiosPromise<GuidePage> {
            return localVarFp.guidePageRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleFavoriteCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guidePageToggleFavoriteCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleFavoriteDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guidePageToggleFavoriteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guidePageToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guidePageToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guidePageToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for guidePageList operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageListRequest
 */
export interface GuidePageApiGuidePageListRequest {
    /**
     * 
     * @type {string}
     * @memberof GuidePageApiGuidePageList
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {string}
     * @memberof GuidePageApiGuidePageList
     */
    readonly isShowOnMainPage?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof GuidePageApiGuidePageList
     */
    readonly directionsIdIn?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof GuidePageApiGuidePageList
     */
    readonly isLikedByUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof GuidePageApiGuidePageList
     */
    readonly likesCountGte?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof GuidePageApiGuidePageList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GuidePageApiGuidePageList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GuidePageApiGuidePageList
     */
    readonly limit?: number
}

/**
 * Request parameters for guidePageListLikes operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageListLikesRequest
 */
export interface GuidePageApiGuidePageListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {string}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly isShowOnMainPage?: string

    /**
     * 
     * @type {string}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly directionsIdIn?: string

    /**
     * 
     * @type {string}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly likesCountGte?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GuidePageApiGuidePageListLikes
     */
    readonly limit?: number
}

/**
 * Request parameters for guidePageRead operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageReadRequest
 */
export interface GuidePageApiGuidePageReadRequest {
    /**
     * A unique integer value identifying this Страница гида.
     * @type {number}
     * @memberof GuidePageApiGuidePageRead
     */
    readonly id: number
}

/**
 * Request parameters for guidePageToggleFavoriteCreate operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageToggleFavoriteCreateRequest
 */
export interface GuidePageApiGuidePageToggleFavoriteCreateRequest {
    /**
     * A unique integer value identifying this Страница гида.
     * @type {number}
     * @memberof GuidePageApiGuidePageToggleFavoriteCreate
     */
    readonly id: number
}

/**
 * Request parameters for guidePageToggleFavoriteDelete operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageToggleFavoriteDeleteRequest
 */
export interface GuidePageApiGuidePageToggleFavoriteDeleteRequest {
    /**
     * A unique integer value identifying this Страница гида.
     * @type {number}
     * @memberof GuidePageApiGuidePageToggleFavoriteDelete
     */
    readonly id: number
}

/**
 * Request parameters for guidePageToggleLikeCreate operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageToggleLikeCreateRequest
 */
export interface GuidePageApiGuidePageToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Страница гида.
     * @type {number}
     * @memberof GuidePageApiGuidePageToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for guidePageToggleLikeDelete operation in GuidePageApi.
 * @export
 * @interface GuidePageApiGuidePageToggleLikeDeleteRequest
 */
export interface GuidePageApiGuidePageToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Страница гида.
     * @type {number}
     * @memberof GuidePageApiGuidePageToggleLikeDelete
     */
    readonly id: number
}

/**
 * GuidePageApi - object-oriented interface
 * @export
 * @class GuidePageApi
 * @extends {BaseAPI}
 */
export class GuidePageApi extends BaseAPI {
    /**
     * 
     * @param {GuidePageApiGuidePageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageList(requestParameters: GuidePageApiGuidePageListRequest = {}, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageList(requestParameters.isInUserFavorites, requestParameters.isShowOnMainPage, requestParameters.directionsIdIn, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {GuidePageApiGuidePageListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageListLikes(requestParameters: GuidePageApiGuidePageListLikesRequest, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageListLikes(requestParameters.id, requestParameters.isInUserFavorites, requestParameters.isShowOnMainPage, requestParameters.directionsIdIn, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuidePageApiGuidePageReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageRead(requestParameters: GuidePageApiGuidePageReadRequest, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuidePageApiGuidePageToggleFavoriteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageToggleFavoriteCreate(requestParameters: GuidePageApiGuidePageToggleFavoriteCreateRequest, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageToggleFavoriteCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuidePageApiGuidePageToggleFavoriteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageToggleFavoriteDelete(requestParameters: GuidePageApiGuidePageToggleFavoriteDeleteRequest, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageToggleFavoriteDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuidePageApiGuidePageToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageToggleLikeCreate(requestParameters: GuidePageApiGuidePageToggleLikeCreateRequest, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuidePageApiGuidePageToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidePageApi
     */
    public guidePageToggleLikeDelete(requestParameters: GuidePageApiGuidePageToggleLikeDeleteRequest, options?: any) {
        return GuidePageApiFp(this.configuration).guidePageToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuideRatingApi - axios parameter creator
 * @export
 */
export const GuideRatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingCreate: async (data: GuideRating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('guideRatingCreate', 'data', data)
            const localVarPath = `/guide_rating/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideRatingDelete', 'id', id)
            const localVarPath = `/guide_rating/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [guide] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingList: async (guide?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guide_rating/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (guide !== undefined) {
                localVarQueryParameter['guide'] = guide;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingPartialUpdate: async (id: number, data: GuideRating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideRatingPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('guideRatingPartialUpdate', 'data', data)
            const localVarPath = `/guide_rating/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideRatingRead', 'id', id)
            const localVarPath = `/guide_rating/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingUpdate: async (id: number, data: GuideRating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('guideRatingUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('guideRatingUpdate', 'data', data)
            const localVarPath = `/guide_rating/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuideRatingApi - functional programming interface
 * @export
 */
export const GuideRatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuideRatingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideRatingCreate(data: GuideRating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideRatingCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideRatingDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideRatingDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [guide] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideRatingList(guide?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideRatingList(guide, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideRatingPartialUpdate(id: number, data: GuideRating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideRatingPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideRatingRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideRatingRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guideRatingUpdate(id: number, data: GuideRating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guideRatingUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuideRatingApi - factory interface
 * @export
 */
export const GuideRatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuideRatingApiFp(configuration)
    return {
        /**
         * 
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingCreate(data: GuideRating, options?: any): AxiosPromise<GuideRating> {
            return localVarFp.guideRatingCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.guideRatingDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [guide] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingList(guide?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20026> {
            return localVarFp.guideRatingList(guide, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingPartialUpdate(id: number, data: GuideRating, options?: any): AxiosPromise<GuideRating> {
            return localVarFp.guideRatingPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingRead(id: number, options?: any): AxiosPromise<GuideRating> {
            return localVarFp.guideRatingRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг страницы гида.
         * @param {GuideRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guideRatingUpdate(id: number, data: GuideRating, options?: any): AxiosPromise<GuideRating> {
            return localVarFp.guideRatingUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for guideRatingCreate operation in GuideRatingApi.
 * @export
 * @interface GuideRatingApiGuideRatingCreateRequest
 */
export interface GuideRatingApiGuideRatingCreateRequest {
    /**
     * 
     * @type {GuideRating}
     * @memberof GuideRatingApiGuideRatingCreate
     */
    readonly data: GuideRating
}

/**
 * Request parameters for guideRatingDelete operation in GuideRatingApi.
 * @export
 * @interface GuideRatingApiGuideRatingDeleteRequest
 */
export interface GuideRatingApiGuideRatingDeleteRequest {
    /**
     * A unique integer value identifying this Рейтинг страницы гида.
     * @type {number}
     * @memberof GuideRatingApiGuideRatingDelete
     */
    readonly id: number
}

/**
 * Request parameters for guideRatingList operation in GuideRatingApi.
 * @export
 * @interface GuideRatingApiGuideRatingListRequest
 */
export interface GuideRatingApiGuideRatingListRequest {
    /**
     * 
     * @type {string}
     * @memberof GuideRatingApiGuideRatingList
     */
    readonly guide?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof GuideRatingApiGuideRatingList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof GuideRatingApiGuideRatingList
     */
    readonly limit?: number
}

/**
 * Request parameters for guideRatingPartialUpdate operation in GuideRatingApi.
 * @export
 * @interface GuideRatingApiGuideRatingPartialUpdateRequest
 */
export interface GuideRatingApiGuideRatingPartialUpdateRequest {
    /**
     * A unique integer value identifying this Рейтинг страницы гида.
     * @type {number}
     * @memberof GuideRatingApiGuideRatingPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {GuideRating}
     * @memberof GuideRatingApiGuideRatingPartialUpdate
     */
    readonly data: GuideRating
}

/**
 * Request parameters for guideRatingRead operation in GuideRatingApi.
 * @export
 * @interface GuideRatingApiGuideRatingReadRequest
 */
export interface GuideRatingApiGuideRatingReadRequest {
    /**
     * A unique integer value identifying this Рейтинг страницы гида.
     * @type {number}
     * @memberof GuideRatingApiGuideRatingRead
     */
    readonly id: number
}

/**
 * Request parameters for guideRatingUpdate operation in GuideRatingApi.
 * @export
 * @interface GuideRatingApiGuideRatingUpdateRequest
 */
export interface GuideRatingApiGuideRatingUpdateRequest {
    /**
     * A unique integer value identifying this Рейтинг страницы гида.
     * @type {number}
     * @memberof GuideRatingApiGuideRatingUpdate
     */
    readonly id: number

    /**
     * 
     * @type {GuideRating}
     * @memberof GuideRatingApiGuideRatingUpdate
     */
    readonly data: GuideRating
}

/**
 * GuideRatingApi - object-oriented interface
 * @export
 * @class GuideRatingApi
 * @extends {BaseAPI}
 */
export class GuideRatingApi extends BaseAPI {
    /**
     * 
     * @param {GuideRatingApiGuideRatingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideRatingApi
     */
    public guideRatingCreate(requestParameters: GuideRatingApiGuideRatingCreateRequest, options?: any) {
        return GuideRatingApiFp(this.configuration).guideRatingCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideRatingApiGuideRatingDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideRatingApi
     */
    public guideRatingDelete(requestParameters: GuideRatingApiGuideRatingDeleteRequest, options?: any) {
        return GuideRatingApiFp(this.configuration).guideRatingDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideRatingApiGuideRatingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideRatingApi
     */
    public guideRatingList(requestParameters: GuideRatingApiGuideRatingListRequest = {}, options?: any) {
        return GuideRatingApiFp(this.configuration).guideRatingList(requestParameters.guide, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideRatingApiGuideRatingPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideRatingApi
     */
    public guideRatingPartialUpdate(requestParameters: GuideRatingApiGuideRatingPartialUpdateRequest, options?: any) {
        return GuideRatingApiFp(this.configuration).guideRatingPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideRatingApiGuideRatingReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideRatingApi
     */
    public guideRatingRead(requestParameters: GuideRatingApiGuideRatingReadRequest, options?: any) {
        return GuideRatingApiFp(this.configuration).guideRatingRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuideRatingApiGuideRatingUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuideRatingApi
     */
    public guideRatingUpdate(requestParameters: GuideRatingApiGuideRatingUpdateRequest, options?: any) {
        return GuideRatingApiFp(this.configuration).guideRatingUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HobbyApi - axios parameter creator
 * @export
 */
export const HobbyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyCreate: async (data: Hobby, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('hobbyCreate', 'data', data)
            const localVarPath = `/hobby/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hobbyDelete', 'id', id)
            const localVarPath = `/hobby/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/hobby/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyPartialUpdate: async (id: number, data: Hobby, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hobbyPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('hobbyPartialUpdate', 'data', data)
            const localVarPath = `/hobby/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hobbyRead', 'id', id)
            const localVarPath = `/hobby/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyUpdate: async (id: number, data: Hobby, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hobbyUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('hobbyUpdate', 'data', data)
            const localVarPath = `/hobby/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HobbyApi - functional programming interface
 * @export
 */
export const HobbyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HobbyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbyCreate(data: Hobby, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hobby>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbyCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbyDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbyList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20027>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbyList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbyPartialUpdate(id: number, data: Hobby, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hobby>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbyPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbyRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hobby>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbyRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbyUpdate(id: number, data: Hobby, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Hobby>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbyUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HobbyApi - factory interface
 * @export
 */
export const HobbyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HobbyApiFp(configuration)
    return {
        /**
         * 
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyCreate(data: Hobby, options?: any): AxiosPromise<Hobby> {
            return localVarFp.hobbyCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.hobbyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20027> {
            return localVarFp.hobbyList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyPartialUpdate(id: number, data: Hobby, options?: any): AxiosPromise<Hobby> {
            return localVarFp.hobbyPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyRead(id: number, options?: any): AxiosPromise<Hobby> {
            return localVarFp.hobbyRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Хобби.
         * @param {Hobby} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbyUpdate(id: number, data: Hobby, options?: any): AxiosPromise<Hobby> {
            return localVarFp.hobbyUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hobbyCreate operation in HobbyApi.
 * @export
 * @interface HobbyApiHobbyCreateRequest
 */
export interface HobbyApiHobbyCreateRequest {
    /**
     * 
     * @type {Hobby}
     * @memberof HobbyApiHobbyCreate
     */
    readonly data: Hobby
}

/**
 * Request parameters for hobbyDelete operation in HobbyApi.
 * @export
 * @interface HobbyApiHobbyDeleteRequest
 */
export interface HobbyApiHobbyDeleteRequest {
    /**
     * A unique integer value identifying this Хобби.
     * @type {number}
     * @memberof HobbyApiHobbyDelete
     */
    readonly id: number
}

/**
 * Request parameters for hobbyList operation in HobbyApi.
 * @export
 * @interface HobbyApiHobbyListRequest
 */
export interface HobbyApiHobbyListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof HobbyApiHobbyList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof HobbyApiHobbyList
     */
    readonly limit?: number
}

/**
 * Request parameters for hobbyPartialUpdate operation in HobbyApi.
 * @export
 * @interface HobbyApiHobbyPartialUpdateRequest
 */
export interface HobbyApiHobbyPartialUpdateRequest {
    /**
     * A unique integer value identifying this Хобби.
     * @type {number}
     * @memberof HobbyApiHobbyPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Hobby}
     * @memberof HobbyApiHobbyPartialUpdate
     */
    readonly data: Hobby
}

/**
 * Request parameters for hobbyRead operation in HobbyApi.
 * @export
 * @interface HobbyApiHobbyReadRequest
 */
export interface HobbyApiHobbyReadRequest {
    /**
     * A unique integer value identifying this Хобби.
     * @type {number}
     * @memberof HobbyApiHobbyRead
     */
    readonly id: number
}

/**
 * Request parameters for hobbyUpdate operation in HobbyApi.
 * @export
 * @interface HobbyApiHobbyUpdateRequest
 */
export interface HobbyApiHobbyUpdateRequest {
    /**
     * A unique integer value identifying this Хобби.
     * @type {number}
     * @memberof HobbyApiHobbyUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Hobby}
     * @memberof HobbyApiHobbyUpdate
     */
    readonly data: Hobby
}

/**
 * HobbyApi - object-oriented interface
 * @export
 * @class HobbyApi
 * @extends {BaseAPI}
 */
export class HobbyApi extends BaseAPI {
    /**
     * 
     * @param {HobbyApiHobbyCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbyApi
     */
    public hobbyCreate(requestParameters: HobbyApiHobbyCreateRequest, options?: any) {
        return HobbyApiFp(this.configuration).hobbyCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HobbyApiHobbyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbyApi
     */
    public hobbyDelete(requestParameters: HobbyApiHobbyDeleteRequest, options?: any) {
        return HobbyApiFp(this.configuration).hobbyDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HobbyApiHobbyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbyApi
     */
    public hobbyList(requestParameters: HobbyApiHobbyListRequest = {}, options?: any) {
        return HobbyApiFp(this.configuration).hobbyList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HobbyApiHobbyPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbyApi
     */
    public hobbyPartialUpdate(requestParameters: HobbyApiHobbyPartialUpdateRequest, options?: any) {
        return HobbyApiFp(this.configuration).hobbyPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HobbyApiHobbyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbyApi
     */
    public hobbyRead(requestParameters: HobbyApiHobbyReadRequest, options?: any) {
        return HobbyApiFp(this.configuration).hobbyRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HobbyApiHobbyUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbyApi
     */
    public hobbyUpdate(requestParameters: HobbyApiHobbyUpdateRequest, options?: any) {
        return HobbyApiFp(this.configuration).hobbyUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesCreate: async (data: DefaultImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesCreate', 'data', data)
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesDelete', 'id', id)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPartialUpdate: async (id: number, data: DefaultImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesPartialUpdate', 'data', data)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesRead', 'id', id)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesUpdate: async (id: number, data: DefaultImage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('imagesUpdate', 'data', data)
            const localVarPath = `/images/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesCreate(data: DefaultImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesPartialUpdate(id: number, data: DefaultImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesUpdate(id: number, data: DefaultImage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * 
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesCreate(data: DefaultImage, options?: any): AxiosPromise<DefaultImage> {
            return localVarFp.imagesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.imagesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20028> {
            return localVarFp.imagesList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesPartialUpdate(id: number, data: DefaultImage, options?: any): AxiosPromise<DefaultImage> {
            return localVarFp.imagesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesRead(id: number, options?: any): AxiosPromise<DefaultImage> {
            return localVarFp.imagesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Изображение.
         * @param {DefaultImage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesUpdate(id: number, data: DefaultImage, options?: any): AxiosPromise<DefaultImage> {
            return localVarFp.imagesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for imagesCreate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesCreateRequest
 */
export interface ImagesApiImagesCreateRequest {
    /**
     * 
     * @type {DefaultImage}
     * @memberof ImagesApiImagesCreate
     */
    readonly data: DefaultImage
}

/**
 * Request parameters for imagesDelete operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesDeleteRequest
 */
export interface ImagesApiImagesDeleteRequest {
    /**
     * A unique integer value identifying this Изображение.
     * @type {number}
     * @memberof ImagesApiImagesDelete
     */
    readonly id: number
}

/**
 * Request parameters for imagesList operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesListRequest
 */
export interface ImagesApiImagesListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ImagesApiImagesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ImagesApiImagesList
     */
    readonly limit?: number
}

/**
 * Request parameters for imagesPartialUpdate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesPartialUpdateRequest
 */
export interface ImagesApiImagesPartialUpdateRequest {
    /**
     * A unique integer value identifying this Изображение.
     * @type {number}
     * @memberof ImagesApiImagesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DefaultImage}
     * @memberof ImagesApiImagesPartialUpdate
     */
    readonly data: DefaultImage
}

/**
 * Request parameters for imagesRead operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesReadRequest
 */
export interface ImagesApiImagesReadRequest {
    /**
     * A unique integer value identifying this Изображение.
     * @type {number}
     * @memberof ImagesApiImagesRead
     */
    readonly id: number
}

/**
 * Request parameters for imagesUpdate operation in ImagesApi.
 * @export
 * @interface ImagesApiImagesUpdateRequest
 */
export interface ImagesApiImagesUpdateRequest {
    /**
     * A unique integer value identifying this Изображение.
     * @type {number}
     * @memberof ImagesApiImagesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DefaultImage}
     * @memberof ImagesApiImagesUpdate
     */
    readonly data: DefaultImage
}

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @param {ImagesApiImagesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesCreate(requestParameters: ImagesApiImagesCreateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesDelete(requestParameters: ImagesApiImagesDeleteRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesList(requestParameters: ImagesApiImagesListRequest = {}, options?: any) {
        return ImagesApiFp(this.configuration).imagesList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesPartialUpdate(requestParameters: ImagesApiImagesPartialUpdateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesRead(requestParameters: ImagesApiImagesReadRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImagesApiImagesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imagesUpdate(requestParameters: ImagesApiImagesUpdateRequest, options?: any) {
        return ImagesApiFp(this.configuration).imagesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InsuranceCategoryApi - axios parameter creator
 * @export
 */
export const InsuranceCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/insurance_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceCategoryApi - functional programming interface
 * @export
 */
export const InsuranceCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceCategoryApi - factory interface
 * @export
 */
export const InsuranceCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceCategoryList(options?: any): AxiosPromise<InsuranceCategory> {
            return localVarFp.insuranceCategoryList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceCategoryApi - object-oriented interface
 * @export
 * @class InsuranceCategoryApi
 * @extends {BaseAPI}
 */
export class InsuranceCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceCategoryApi
     */
    public insuranceCategoryList(options?: any) {
        return InsuranceCategoryApiFp(this.configuration).insuranceCategoryList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InsuranceProductsApi - axios parameter creator
 * @export
 */
export const InsuranceProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsCreate: async (data: InsuranceProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('insuranceProductsCreate', 'data', data)
            const localVarPath = `/insurance_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insuranceProductsDelete', 'id', id)
            const localVarPath = `/insurance_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsList: async (name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/insurance_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (priceGt !== undefined) {
                localVarQueryParameter['price__gt'] = priceGt;
            }

            if (priceLt !== undefined) {
                localVarQueryParameter['price__lt'] = priceLt;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceGte !== undefined) {
                localVarQueryParameter['price__gte'] = priceGte;
            }

            if (priceLte !== undefined) {
                localVarQueryParameter['price__lte'] = priceLte;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsPartialUpdate: async (id: number, data: InsuranceProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insuranceProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('insuranceProductsPartialUpdate', 'data', data)
            const localVarPath = `/insurance_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insuranceProductsRead', 'id', id)
            const localVarPath = `/insurance_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsUpdate: async (id: number, data: InsuranceProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insuranceProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('insuranceProductsUpdate', 'data', data)
            const localVarPath = `/insurance_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceProductsApi - functional programming interface
 * @export
 */
export const InsuranceProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceProductsCreate(data: InsuranceProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceProductsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceProductsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceProductsList(name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceProductsList(name, priceGt, priceLt, price, priceGte, priceLte, category, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceProductsPartialUpdate(id: number, data: InsuranceProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceProductsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceProductsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insuranceProductsUpdate(id: number, data: InsuranceProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insuranceProductsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InsuranceProductsApi - factory interface
 * @export
 */
export const InsuranceProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsCreate(data: InsuranceProduct, options?: any): AxiosPromise<InsuranceProduct> {
            return localVarFp.insuranceProductsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.insuranceProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsList(name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20029> {
            return localVarFp.insuranceProductsList(name, priceGt, priceLt, price, priceGte, priceLte, category, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsPartialUpdate(id: number, data: InsuranceProduct, options?: any): AxiosPromise<InsuranceProduct> {
            return localVarFp.insuranceProductsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsRead(id: number, options?: any): AxiosPromise<InsuranceProduct> {
            return localVarFp.insuranceProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Услуга страхования.
         * @param {InsuranceProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insuranceProductsUpdate(id: number, data: InsuranceProduct, options?: any): AxiosPromise<InsuranceProduct> {
            return localVarFp.insuranceProductsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for insuranceProductsCreate operation in InsuranceProductsApi.
 * @export
 * @interface InsuranceProductsApiInsuranceProductsCreateRequest
 */
export interface InsuranceProductsApiInsuranceProductsCreateRequest {
    /**
     * 
     * @type {InsuranceProduct}
     * @memberof InsuranceProductsApiInsuranceProductsCreate
     */
    readonly data: InsuranceProduct
}

/**
 * Request parameters for insuranceProductsDelete operation in InsuranceProductsApi.
 * @export
 * @interface InsuranceProductsApiInsuranceProductsDeleteRequest
 */
export interface InsuranceProductsApiInsuranceProductsDeleteRequest {
    /**
     * A unique integer value identifying this Услуга страхования.
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for insuranceProductsList operation in InsuranceProductsApi.
 * @export
 * @interface InsuranceProductsApiInsuranceProductsListRequest
 */
export interface InsuranceProductsApiInsuranceProductsListRequest {
    /**
     * 
     * @type {string}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly priceGt?: number

    /**
     * 
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly priceLt?: number

    /**
     * 
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly priceGte?: number

    /**
     * 
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly priceLte?: number

    /**
     * 
     * @type {string}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly category?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsList
     */
    readonly limit?: number
}

/**
 * Request parameters for insuranceProductsPartialUpdate operation in InsuranceProductsApi.
 * @export
 * @interface InsuranceProductsApiInsuranceProductsPartialUpdateRequest
 */
export interface InsuranceProductsApiInsuranceProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Услуга страхования.
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {InsuranceProduct}
     * @memberof InsuranceProductsApiInsuranceProductsPartialUpdate
     */
    readonly data: InsuranceProduct
}

/**
 * Request parameters for insuranceProductsRead operation in InsuranceProductsApi.
 * @export
 * @interface InsuranceProductsApiInsuranceProductsReadRequest
 */
export interface InsuranceProductsApiInsuranceProductsReadRequest {
    /**
     * A unique integer value identifying this Услуга страхования.
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for insuranceProductsUpdate operation in InsuranceProductsApi.
 * @export
 * @interface InsuranceProductsApiInsuranceProductsUpdateRequest
 */
export interface InsuranceProductsApiInsuranceProductsUpdateRequest {
    /**
     * A unique integer value identifying this Услуга страхования.
     * @type {number}
     * @memberof InsuranceProductsApiInsuranceProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {InsuranceProduct}
     * @memberof InsuranceProductsApiInsuranceProductsUpdate
     */
    readonly data: InsuranceProduct
}

/**
 * InsuranceProductsApi - object-oriented interface
 * @export
 * @class InsuranceProductsApi
 * @extends {BaseAPI}
 */
export class InsuranceProductsApi extends BaseAPI {
    /**
     * 
     * @param {InsuranceProductsApiInsuranceProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProductsApi
     */
    public insuranceProductsCreate(requestParameters: InsuranceProductsApiInsuranceProductsCreateRequest, options?: any) {
        return InsuranceProductsApiFp(this.configuration).insuranceProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceProductsApiInsuranceProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProductsApi
     */
    public insuranceProductsDelete(requestParameters: InsuranceProductsApiInsuranceProductsDeleteRequest, options?: any) {
        return InsuranceProductsApiFp(this.configuration).insuranceProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceProductsApiInsuranceProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProductsApi
     */
    public insuranceProductsList(requestParameters: InsuranceProductsApiInsuranceProductsListRequest = {}, options?: any) {
        return InsuranceProductsApiFp(this.configuration).insuranceProductsList(requestParameters.name, requestParameters.priceGt, requestParameters.priceLt, requestParameters.price, requestParameters.priceGte, requestParameters.priceLte, requestParameters.category, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceProductsApiInsuranceProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProductsApi
     */
    public insuranceProductsPartialUpdate(requestParameters: InsuranceProductsApiInsuranceProductsPartialUpdateRequest, options?: any) {
        return InsuranceProductsApiFp(this.configuration).insuranceProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceProductsApiInsuranceProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProductsApi
     */
    public insuranceProductsRead(requestParameters: InsuranceProductsApiInsuranceProductsReadRequest, options?: any) {
        return InsuranceProductsApiFp(this.configuration).insuranceProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsuranceProductsApiInsuranceProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceProductsApi
     */
    public insuranceProductsUpdate(requestParameters: InsuranceProductsApiInsuranceProductsUpdateRequest, options?: any) {
        return InsuranceProductsApiFp(this.configuration).insuranceProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LdapApi - axios parameter creator
 * @export
 */
export const LdapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LdapToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ldapTokenCreate: async (data: LdapToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('ldapTokenCreate', 'data', data)
            const localVarPath = `/ldap/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LdapApi - functional programming interface
 * @export
 */
export const LdapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LdapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LdapToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ldapTokenCreate(data: LdapToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ldapTokenCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LdapApi - factory interface
 * @export
 */
export const LdapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LdapApiFp(configuration)
    return {
        /**
         * 
         * @param {LdapToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ldapTokenCreate(data: LdapToken, options?: any): AxiosPromise<Token> {
            return localVarFp.ldapTokenCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ldapTokenCreate operation in LdapApi.
 * @export
 * @interface LdapApiLdapTokenCreateRequest
 */
export interface LdapApiLdapTokenCreateRequest {
    /**
     * 
     * @type {LdapToken}
     * @memberof LdapApiLdapTokenCreate
     */
    readonly data: LdapToken
}

/**
 * LdapApi - object-oriented interface
 * @export
 * @class LdapApi
 * @extends {BaseAPI}
 */
export class LdapApi extends BaseAPI {
    /**
     * 
     * @param {LdapApiLdapTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LdapApi
     */
    public ldapTokenCreate(requestParameters: LdapApiLdapTokenCreateRequest, options?: any) {
        return LdapApiFp(this.configuration).ldapTokenCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MainBannersApi - axios parameter creator
 * @export
 */
export const MainBannersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersCreate: async (data: MainBanner, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mainBannersCreate', 'data', data)
            const localVarPath = `/main_banners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mainBannersDelete', 'id', id)
            const localVarPath = `/main_banners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main_banners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersPartialUpdate: async (id: number, data: MainBanner, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mainBannersPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mainBannersPartialUpdate', 'data', data)
            const localVarPath = `/main_banners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mainBannersRead', 'id', id)
            const localVarPath = `/main_banners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersUpdate: async (id: number, data: MainBanner, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mainBannersUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mainBannersUpdate', 'data', data)
            const localVarPath = `/main_banners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainBannersApi - functional programming interface
 * @export
 */
export const MainBannersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainBannersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainBannersCreate(data: MainBanner, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainBanner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainBannersCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainBannersDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainBannersDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainBannersList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainBannersList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainBannersPartialUpdate(id: number, data: MainBanner, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainBanner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainBannersPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainBannersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainBanner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainBannersRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainBannersUpdate(id: number, data: MainBanner, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainBanner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainBannersUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainBannersApi - factory interface
 * @export
 */
export const MainBannersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainBannersApiFp(configuration)
    return {
        /**
         * 
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersCreate(data: MainBanner, options?: any): AxiosPromise<MainBanner> {
            return localVarFp.mainBannersCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.mainBannersDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20030> {
            return localVarFp.mainBannersList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersPartialUpdate(id: number, data: MainBanner, options?: any): AxiosPromise<MainBanner> {
            return localVarFp.mainBannersPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersRead(id: number, options?: any): AxiosPromise<MainBanner> {
            return localVarFp.mainBannersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Главный баннер.
         * @param {MainBanner} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainBannersUpdate(id: number, data: MainBanner, options?: any): AxiosPromise<MainBanner> {
            return localVarFp.mainBannersUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mainBannersCreate operation in MainBannersApi.
 * @export
 * @interface MainBannersApiMainBannersCreateRequest
 */
export interface MainBannersApiMainBannersCreateRequest {
    /**
     * 
     * @type {MainBanner}
     * @memberof MainBannersApiMainBannersCreate
     */
    readonly data: MainBanner
}

/**
 * Request parameters for mainBannersDelete operation in MainBannersApi.
 * @export
 * @interface MainBannersApiMainBannersDeleteRequest
 */
export interface MainBannersApiMainBannersDeleteRequest {
    /**
     * A unique integer value identifying this Главный баннер.
     * @type {number}
     * @memberof MainBannersApiMainBannersDelete
     */
    readonly id: number
}

/**
 * Request parameters for mainBannersList operation in MainBannersApi.
 * @export
 * @interface MainBannersApiMainBannersListRequest
 */
export interface MainBannersApiMainBannersListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MainBannersApiMainBannersList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MainBannersApiMainBannersList
     */
    readonly limit?: number
}

/**
 * Request parameters for mainBannersPartialUpdate operation in MainBannersApi.
 * @export
 * @interface MainBannersApiMainBannersPartialUpdateRequest
 */
export interface MainBannersApiMainBannersPartialUpdateRequest {
    /**
     * A unique integer value identifying this Главный баннер.
     * @type {number}
     * @memberof MainBannersApiMainBannersPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MainBanner}
     * @memberof MainBannersApiMainBannersPartialUpdate
     */
    readonly data: MainBanner
}

/**
 * Request parameters for mainBannersRead operation in MainBannersApi.
 * @export
 * @interface MainBannersApiMainBannersReadRequest
 */
export interface MainBannersApiMainBannersReadRequest {
    /**
     * A unique integer value identifying this Главный баннер.
     * @type {number}
     * @memberof MainBannersApiMainBannersRead
     */
    readonly id: number
}

/**
 * Request parameters for mainBannersUpdate operation in MainBannersApi.
 * @export
 * @interface MainBannersApiMainBannersUpdateRequest
 */
export interface MainBannersApiMainBannersUpdateRequest {
    /**
     * A unique integer value identifying this Главный баннер.
     * @type {number}
     * @memberof MainBannersApiMainBannersUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MainBanner}
     * @memberof MainBannersApiMainBannersUpdate
     */
    readonly data: MainBanner
}

/**
 * MainBannersApi - object-oriented interface
 * @export
 * @class MainBannersApi
 * @extends {BaseAPI}
 */
export class MainBannersApi extends BaseAPI {
    /**
     * 
     * @param {MainBannersApiMainBannersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainBannersApi
     */
    public mainBannersCreate(requestParameters: MainBannersApiMainBannersCreateRequest, options?: any) {
        return MainBannersApiFp(this.configuration).mainBannersCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MainBannersApiMainBannersDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainBannersApi
     */
    public mainBannersDelete(requestParameters: MainBannersApiMainBannersDeleteRequest, options?: any) {
        return MainBannersApiFp(this.configuration).mainBannersDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MainBannersApiMainBannersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainBannersApi
     */
    public mainBannersList(requestParameters: MainBannersApiMainBannersListRequest = {}, options?: any) {
        return MainBannersApiFp(this.configuration).mainBannersList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MainBannersApiMainBannersPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainBannersApi
     */
    public mainBannersPartialUpdate(requestParameters: MainBannersApiMainBannersPartialUpdateRequest, options?: any) {
        return MainBannersApiFp(this.configuration).mainBannersPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MainBannersApiMainBannersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainBannersApi
     */
    public mainBannersRead(requestParameters: MainBannersApiMainBannersReadRequest, options?: any) {
        return MainBannersApiFp(this.configuration).mainBannersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MainBannersApiMainBannersUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainBannersApi
     */
    public mainBannersUpdate(requestParameters: MainBannersApiMainBannersUpdateRequest, options?: any) {
        return MainBannersApiFp(this.configuration).mainBannersUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MainGuidePageApi - axios parameter creator
 * @export
 */
export const MainGuidePageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainGuidePageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main_guide_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainGuidePageApi - functional programming interface
 * @export
 */
export const MainGuidePageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainGuidePageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainGuidePageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainGuidePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainGuidePageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainGuidePageApi - factory interface
 * @export
 */
export const MainGuidePageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainGuidePageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainGuidePageList(options?: any): AxiosPromise<MainGuidePage> {
            return localVarFp.mainGuidePageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainGuidePageApi - object-oriented interface
 * @export
 * @class MainGuidePageApi
 * @extends {BaseAPI}
 */
export class MainGuidePageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainGuidePageApi
     */
    public mainGuidePageList(options?: any) {
        return MainGuidePageApiFp(this.configuration).mainGuidePageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MainNewsPageApi - axios parameter creator
 * @export
 */
export const MainNewsPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainNewsPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main_news_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainNewsPageApi - functional programming interface
 * @export
 */
export const MainNewsPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainNewsPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainNewsPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainNewsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainNewsPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainNewsPageApi - factory interface
 * @export
 */
export const MainNewsPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainNewsPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainNewsPageList(options?: any): AxiosPromise<MainNewsPage> {
            return localVarFp.mainNewsPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainNewsPageApi - object-oriented interface
 * @export
 * @class MainNewsPageApi
 * @extends {BaseAPI}
 */
export class MainNewsPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainNewsPageApi
     */
    public mainNewsPageList(options?: any) {
        return MainNewsPageApiFp(this.configuration).mainNewsPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MainPageApi - axios parameter creator
 * @export
 */
export const MainPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainPageApi - functional programming interface
 * @export
 */
export const MainPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MainPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainPageApi - factory interface
 * @export
 */
export const MainPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainPageList(options?: any): AxiosPromise<MainPage> {
            return localVarFp.mainPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainPageApi - object-oriented interface
 * @export
 * @class MainPageApi
 * @extends {BaseAPI}
 */
export class MainPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainPageApi
     */
    public mainPageList(options?: any) {
        return MainPageApiFp(this.configuration).mainPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaApplicationsApi - axios parameter creator
 * @export
 */
export const MediaApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsCreate: async (data: MediaApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mediaApplicationsCreate', 'data', data)
            const localVarPath = `/media_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaApplicationsDelete', 'id', id)
            const localVarPath = `/media_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsList: async (profile?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/media_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsPartialUpdate: async (id: number, data: MediaApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaApplicationsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mediaApplicationsPartialUpdate', 'data', data)
            const localVarPath = `/media_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaApplicationsRead', 'id', id)
            const localVarPath = `/media_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsUpdate: async (id: number, data: MediaApplication, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaApplicationsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mediaApplicationsUpdate', 'data', data)
            const localVarPath = `/media_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApplicationsApi - functional programming interface
 * @export
 */
export const MediaApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaApplicationsCreate(data: MediaApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaApplicationsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaApplicationsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaApplicationsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaApplicationsList(profile?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20031>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaApplicationsList(profile, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaApplicationsPartialUpdate(id: number, data: MediaApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaApplicationsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaApplicationsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaApplicationsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaApplicationsUpdate(id: number, data: MediaApplication, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaApplicationsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaApplicationsApi - factory interface
 * @export
 */
export const MediaApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsCreate(data: MediaApplication, options?: any): AxiosPromise<MediaApplication> {
            return localVarFp.mediaApplicationsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.mediaApplicationsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [profile] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsList(profile?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20031> {
            return localVarFp.mediaApplicationsList(profile, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsPartialUpdate(id: number, data: MediaApplication, options?: any): AxiosPromise<MediaApplication> {
            return localVarFp.mediaApplicationsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsRead(id: number, options?: any): AxiosPromise<MediaApplication> {
            return localVarFp.mediaApplicationsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Заявка на медиа услугу.
         * @param {MediaApplication} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaApplicationsUpdate(id: number, data: MediaApplication, options?: any): AxiosPromise<MediaApplication> {
            return localVarFp.mediaApplicationsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mediaApplicationsCreate operation in MediaApplicationsApi.
 * @export
 * @interface MediaApplicationsApiMediaApplicationsCreateRequest
 */
export interface MediaApplicationsApiMediaApplicationsCreateRequest {
    /**
     * 
     * @type {MediaApplication}
     * @memberof MediaApplicationsApiMediaApplicationsCreate
     */
    readonly data: MediaApplication
}

/**
 * Request parameters for mediaApplicationsDelete operation in MediaApplicationsApi.
 * @export
 * @interface MediaApplicationsApiMediaApplicationsDeleteRequest
 */
export interface MediaApplicationsApiMediaApplicationsDeleteRequest {
    /**
     * A unique integer value identifying this Заявка на медиа услугу.
     * @type {number}
     * @memberof MediaApplicationsApiMediaApplicationsDelete
     */
    readonly id: number
}

/**
 * Request parameters for mediaApplicationsList operation in MediaApplicationsApi.
 * @export
 * @interface MediaApplicationsApiMediaApplicationsListRequest
 */
export interface MediaApplicationsApiMediaApplicationsListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaApplicationsApiMediaApplicationsList
     */
    readonly profile?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MediaApplicationsApiMediaApplicationsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MediaApplicationsApiMediaApplicationsList
     */
    readonly limit?: number
}

/**
 * Request parameters for mediaApplicationsPartialUpdate operation in MediaApplicationsApi.
 * @export
 * @interface MediaApplicationsApiMediaApplicationsPartialUpdateRequest
 */
export interface MediaApplicationsApiMediaApplicationsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Заявка на медиа услугу.
     * @type {number}
     * @memberof MediaApplicationsApiMediaApplicationsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MediaApplication}
     * @memberof MediaApplicationsApiMediaApplicationsPartialUpdate
     */
    readonly data: MediaApplication
}

/**
 * Request parameters for mediaApplicationsRead operation in MediaApplicationsApi.
 * @export
 * @interface MediaApplicationsApiMediaApplicationsReadRequest
 */
export interface MediaApplicationsApiMediaApplicationsReadRequest {
    /**
     * A unique integer value identifying this Заявка на медиа услугу.
     * @type {number}
     * @memberof MediaApplicationsApiMediaApplicationsRead
     */
    readonly id: number
}

/**
 * Request parameters for mediaApplicationsUpdate operation in MediaApplicationsApi.
 * @export
 * @interface MediaApplicationsApiMediaApplicationsUpdateRequest
 */
export interface MediaApplicationsApiMediaApplicationsUpdateRequest {
    /**
     * A unique integer value identifying this Заявка на медиа услугу.
     * @type {number}
     * @memberof MediaApplicationsApiMediaApplicationsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MediaApplication}
     * @memberof MediaApplicationsApiMediaApplicationsUpdate
     */
    readonly data: MediaApplication
}

/**
 * MediaApplicationsApi - object-oriented interface
 * @export
 * @class MediaApplicationsApi
 * @extends {BaseAPI}
 */
export class MediaApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {MediaApplicationsApiMediaApplicationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApplicationsApi
     */
    public mediaApplicationsCreate(requestParameters: MediaApplicationsApiMediaApplicationsCreateRequest, options?: any) {
        return MediaApplicationsApiFp(this.configuration).mediaApplicationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApplicationsApiMediaApplicationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApplicationsApi
     */
    public mediaApplicationsDelete(requestParameters: MediaApplicationsApiMediaApplicationsDeleteRequest, options?: any) {
        return MediaApplicationsApiFp(this.configuration).mediaApplicationsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApplicationsApiMediaApplicationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApplicationsApi
     */
    public mediaApplicationsList(requestParameters: MediaApplicationsApiMediaApplicationsListRequest = {}, options?: any) {
        return MediaApplicationsApiFp(this.configuration).mediaApplicationsList(requestParameters.profile, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApplicationsApiMediaApplicationsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApplicationsApi
     */
    public mediaApplicationsPartialUpdate(requestParameters: MediaApplicationsApiMediaApplicationsPartialUpdateRequest, options?: any) {
        return MediaApplicationsApiFp(this.configuration).mediaApplicationsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApplicationsApiMediaApplicationsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApplicationsApi
     */
    public mediaApplicationsRead(requestParameters: MediaApplicationsApiMediaApplicationsReadRequest, options?: any) {
        return MediaApplicationsApiFp(this.configuration).mediaApplicationsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaApplicationsApiMediaApplicationsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApplicationsApi
     */
    public mediaApplicationsUpdate(requestParameters: MediaApplicationsApiMediaApplicationsUpdateRequest, options?: any) {
        return MediaApplicationsApiFp(this.configuration).mediaApplicationsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaCategoryApi - axios parameter creator
 * @export
 */
export const MediaCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/media_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaCategoryApi - functional programming interface
 * @export
 */
export const MediaCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaCategoryApi - factory interface
 * @export
 */
export const MediaCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaCategoryList(options?: any): AxiosPromise<MediaCategory> {
            return localVarFp.mediaCategoryList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaCategoryApi - object-oriented interface
 * @export
 * @class MediaCategoryApi
 * @extends {BaseAPI}
 */
export class MediaCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaCategoryApi
     */
    public mediaCategoryList(options?: any) {
        return MediaCategoryApiFp(this.configuration).mediaCategoryList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaPageApi - axios parameter creator
 * @export
 */
export const MediaPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/media_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaPageApi - functional programming interface
 * @export
 */
export const MediaPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaCategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaPageApi - factory interface
 * @export
 */
export const MediaPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaPageList(options?: any): AxiosPromise<MediaCategoryPage> {
            return localVarFp.mediaPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaPageApi - object-oriented interface
 * @export
 * @class MediaPageApi
 * @extends {BaseAPI}
 */
export class MediaPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaPageApi
     */
    public mediaPageList(options?: any) {
        return MediaPageApiFp(this.configuration).mediaPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaProductsApi - axios parameter creator
 * @export
 */
export const MediaProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsCreate: async (data: MediaProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mediaProductsCreate', 'data', data)
            const localVarPath = `/media_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaProductsDelete', 'id', id)
            const localVarPath = `/media_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsList: async (name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/media_products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (priceGt !== undefined) {
                localVarQueryParameter['price__gt'] = priceGt;
            }

            if (priceLt !== undefined) {
                localVarQueryParameter['price__lt'] = priceLt;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceGte !== undefined) {
                localVarQueryParameter['price__gte'] = priceGte;
            }

            if (priceLte !== undefined) {
                localVarQueryParameter['price__lte'] = priceLte;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsPartialUpdate: async (id: number, data: MediaProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mediaProductsPartialUpdate', 'data', data)
            const localVarPath = `/media_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaProductsRead', 'id', id)
            const localVarPath = `/media_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsUpdate: async (id: number, data: MediaProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mediaProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('mediaProductsUpdate', 'data', data)
            const localVarPath = `/media_products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaProductsApi - functional programming interface
 * @export
 */
export const MediaProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaProductsCreate(data: MediaProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaProductsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaProductsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaProductsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaProductsList(name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20032>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaProductsList(name, priceGt, priceLt, price, priceGte, priceLte, category, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaProductsPartialUpdate(id: number, data: MediaProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaProductsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaProductsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaProductsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mediaProductsUpdate(id: number, data: MediaProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mediaProductsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaProductsApi - factory interface
 * @export
 */
export const MediaProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsCreate(data: MediaProduct, options?: any): AxiosPromise<MediaProduct> {
            return localVarFp.mediaProductsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.mediaProductsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [priceGt] 
         * @param {number} [priceLt] 
         * @param {number} [price] 
         * @param {number} [priceGte] 
         * @param {number} [priceLte] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsList(name?: string, priceGt?: number, priceLt?: number, price?: number, priceGte?: number, priceLte?: number, category?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20032> {
            return localVarFp.mediaProductsList(name, priceGt, priceLt, price, priceGte, priceLte, category, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsPartialUpdate(id: number, data: MediaProduct, options?: any): AxiosPromise<MediaProduct> {
            return localVarFp.mediaProductsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsRead(id: number, options?: any): AxiosPromise<MediaProduct> {
            return localVarFp.mediaProductsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медиа услуга.
         * @param {MediaProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mediaProductsUpdate(id: number, data: MediaProduct, options?: any): AxiosPromise<MediaProduct> {
            return localVarFp.mediaProductsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mediaProductsCreate operation in MediaProductsApi.
 * @export
 * @interface MediaProductsApiMediaProductsCreateRequest
 */
export interface MediaProductsApiMediaProductsCreateRequest {
    /**
     * 
     * @type {MediaProduct}
     * @memberof MediaProductsApiMediaProductsCreate
     */
    readonly data: MediaProduct
}

/**
 * Request parameters for mediaProductsDelete operation in MediaProductsApi.
 * @export
 * @interface MediaProductsApiMediaProductsDeleteRequest
 */
export interface MediaProductsApiMediaProductsDeleteRequest {
    /**
     * A unique integer value identifying this Медиа услуга.
     * @type {number}
     * @memberof MediaProductsApiMediaProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for mediaProductsList operation in MediaProductsApi.
 * @export
 * @interface MediaProductsApiMediaProductsListRequest
 */
export interface MediaProductsApiMediaProductsListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly priceGt?: number

    /**
     * 
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly priceLt?: number

    /**
     * 
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly priceGte?: number

    /**
     * 
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly priceLte?: number

    /**
     * 
     * @type {string}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly category?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MediaProductsApiMediaProductsList
     */
    readonly limit?: number
}

/**
 * Request parameters for mediaProductsPartialUpdate operation in MediaProductsApi.
 * @export
 * @interface MediaProductsApiMediaProductsPartialUpdateRequest
 */
export interface MediaProductsApiMediaProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Медиа услуга.
     * @type {number}
     * @memberof MediaProductsApiMediaProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MediaProduct}
     * @memberof MediaProductsApiMediaProductsPartialUpdate
     */
    readonly data: MediaProduct
}

/**
 * Request parameters for mediaProductsRead operation in MediaProductsApi.
 * @export
 * @interface MediaProductsApiMediaProductsReadRequest
 */
export interface MediaProductsApiMediaProductsReadRequest {
    /**
     * A unique integer value identifying this Медиа услуга.
     * @type {number}
     * @memberof MediaProductsApiMediaProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for mediaProductsUpdate operation in MediaProductsApi.
 * @export
 * @interface MediaProductsApiMediaProductsUpdateRequest
 */
export interface MediaProductsApiMediaProductsUpdateRequest {
    /**
     * A unique integer value identifying this Медиа услуга.
     * @type {number}
     * @memberof MediaProductsApiMediaProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MediaProduct}
     * @memberof MediaProductsApiMediaProductsUpdate
     */
    readonly data: MediaProduct
}

/**
 * MediaProductsApi - object-oriented interface
 * @export
 * @class MediaProductsApi
 * @extends {BaseAPI}
 */
export class MediaProductsApi extends BaseAPI {
    /**
     * 
     * @param {MediaProductsApiMediaProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProductsApi
     */
    public mediaProductsCreate(requestParameters: MediaProductsApiMediaProductsCreateRequest, options?: any) {
        return MediaProductsApiFp(this.configuration).mediaProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaProductsApiMediaProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProductsApi
     */
    public mediaProductsDelete(requestParameters: MediaProductsApiMediaProductsDeleteRequest, options?: any) {
        return MediaProductsApiFp(this.configuration).mediaProductsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaProductsApiMediaProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProductsApi
     */
    public mediaProductsList(requestParameters: MediaProductsApiMediaProductsListRequest = {}, options?: any) {
        return MediaProductsApiFp(this.configuration).mediaProductsList(requestParameters.name, requestParameters.priceGt, requestParameters.priceLt, requestParameters.price, requestParameters.priceGte, requestParameters.priceLte, requestParameters.category, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaProductsApiMediaProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProductsApi
     */
    public mediaProductsPartialUpdate(requestParameters: MediaProductsApiMediaProductsPartialUpdateRequest, options?: any) {
        return MediaProductsApiFp(this.configuration).mediaProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaProductsApiMediaProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProductsApi
     */
    public mediaProductsRead(requestParameters: MediaProductsApiMediaProductsReadRequest, options?: any) {
        return MediaProductsApiFp(this.configuration).mediaProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaProductsApiMediaProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProductsApi
     */
    public mediaProductsUpdate(requestParameters: MediaProductsApiMediaProductsUpdateRequest, options?: any) {
        return MediaProductsApiFp(this.configuration).mediaProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicineCategoryApi - axios parameter creator
 * @export
 */
export const MedicineCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/medicine_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicineCategoryApi - functional programming interface
 * @export
 */
export const MedicineCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicineCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicineCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicineCategoryApi - factory interface
 * @export
 */
export const MedicineCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicineCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCategoryList(options?: any): AxiosPromise<MedicineCategory> {
            return localVarFp.medicineCategoryList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicineCategoryApi - object-oriented interface
 * @export
 * @class MedicineCategoryApi
 * @extends {BaseAPI}
 */
export class MedicineCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineCategoryApi
     */
    public medicineCategoryList(options?: any) {
        return MedicineCategoryApiFp(this.configuration).medicineCategoryList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicineCentersApi - axios parameter creator
 * @export
 */
export const MedicineCentersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинский центр.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCentersDirections: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('medicineCentersDirections', 'id', id)
            const localVarPath = `/medicine_centers/{id}/directions/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCentersList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/medicine_centers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинский центр.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCentersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('medicineCentersRead', 'id', id)
            const localVarPath = `/medicine_centers/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicineCentersApi - functional programming interface
 * @export
 */
export const MedicineCentersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicineCentersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинский центр.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineCentersDirections(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedicineDirection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineCentersDirections(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineCentersList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20033>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineCentersList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинский центр.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineCentersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicineCenter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineCentersRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicineCentersApi - factory interface
 * @export
 */
export const MedicineCentersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicineCentersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинский центр.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCentersDirections(id: number, options?: any): AxiosPromise<Array<MedicineDirection>> {
            return localVarFp.medicineCentersDirections(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCentersList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20033> {
            return localVarFp.medicineCentersList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинский центр.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineCentersRead(id: number, options?: any): AxiosPromise<MedicineCenter> {
            return localVarFp.medicineCentersRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for medicineCentersDirections operation in MedicineCentersApi.
 * @export
 * @interface MedicineCentersApiMedicineCentersDirectionsRequest
 */
export interface MedicineCentersApiMedicineCentersDirectionsRequest {
    /**
     * A unique integer value identifying this Медицинский центр.
     * @type {number}
     * @memberof MedicineCentersApiMedicineCentersDirections
     */
    readonly id: number
}

/**
 * Request parameters for medicineCentersList operation in MedicineCentersApi.
 * @export
 * @interface MedicineCentersApiMedicineCentersListRequest
 */
export interface MedicineCentersApiMedicineCentersListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof MedicineCentersApiMedicineCentersList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MedicineCentersApiMedicineCentersList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MedicineCentersApiMedicineCentersList
     */
    readonly limit?: number
}

/**
 * Request parameters for medicineCentersRead operation in MedicineCentersApi.
 * @export
 * @interface MedicineCentersApiMedicineCentersReadRequest
 */
export interface MedicineCentersApiMedicineCentersReadRequest {
    /**
     * A unique integer value identifying this Медицинский центр.
     * @type {number}
     * @memberof MedicineCentersApiMedicineCentersRead
     */
    readonly id: number
}

/**
 * MedicineCentersApi - object-oriented interface
 * @export
 * @class MedicineCentersApi
 * @extends {BaseAPI}
 */
export class MedicineCentersApi extends BaseAPI {
    /**
     * 
     * @param {MedicineCentersApiMedicineCentersDirectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineCentersApi
     */
    public medicineCentersDirections(requestParameters: MedicineCentersApiMedicineCentersDirectionsRequest, options?: any) {
        return MedicineCentersApiFp(this.configuration).medicineCentersDirections(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicineCentersApiMedicineCentersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineCentersApi
     */
    public medicineCentersList(requestParameters: MedicineCentersApiMedicineCentersListRequest = {}, options?: any) {
        return MedicineCentersApiFp(this.configuration).medicineCentersList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicineCentersApiMedicineCentersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineCentersApi
     */
    public medicineCentersRead(requestParameters: MedicineCentersApiMedicineCentersReadRequest, options?: any) {
        return MedicineCentersApiFp(this.configuration).medicineCentersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicineDirectionsApi - axios parameter creator
 * @export
 */
export const MedicineDirectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineDirectionsList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/medicine_directions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineDirectionsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('medicineDirectionsRead', 'id', id)
            const localVarPath = `/medicine_directions/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicineDirectionsApi - functional programming interface
 * @export
 */
export const MedicineDirectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicineDirectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineDirectionsList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20034>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineDirectionsList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineDirectionsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicineDirection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineDirectionsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicineDirectionsApi - factory interface
 * @export
 */
export const MedicineDirectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicineDirectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineDirectionsList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20034> {
            return localVarFp.medicineDirectionsList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Направление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineDirectionsRead(id: number, options?: any): AxiosPromise<MedicineDirection> {
            return localVarFp.medicineDirectionsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for medicineDirectionsList operation in MedicineDirectionsApi.
 * @export
 * @interface MedicineDirectionsApiMedicineDirectionsListRequest
 */
export interface MedicineDirectionsApiMedicineDirectionsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof MedicineDirectionsApiMedicineDirectionsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MedicineDirectionsApiMedicineDirectionsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MedicineDirectionsApiMedicineDirectionsList
     */
    readonly limit?: number
}

/**
 * Request parameters for medicineDirectionsRead operation in MedicineDirectionsApi.
 * @export
 * @interface MedicineDirectionsApiMedicineDirectionsReadRequest
 */
export interface MedicineDirectionsApiMedicineDirectionsReadRequest {
    /**
     * A unique integer value identifying this Направление.
     * @type {number}
     * @memberof MedicineDirectionsApiMedicineDirectionsRead
     */
    readonly id: number
}

/**
 * MedicineDirectionsApi - object-oriented interface
 * @export
 * @class MedicineDirectionsApi
 * @extends {BaseAPI}
 */
export class MedicineDirectionsApi extends BaseAPI {
    /**
     * 
     * @param {MedicineDirectionsApiMedicineDirectionsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineDirectionsApi
     */
    public medicineDirectionsList(requestParameters: MedicineDirectionsApiMedicineDirectionsListRequest = {}, options?: any) {
        return MedicineDirectionsApiFp(this.configuration).medicineDirectionsList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicineDirectionsApiMedicineDirectionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineDirectionsApi
     */
    public medicineDirectionsRead(requestParameters: MedicineDirectionsApiMedicineDirectionsReadRequest, options?: any) {
        return MedicineDirectionsApiFp(this.configuration).medicineDirectionsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicineProgramsApi - axios parameter creator
 * @export
 */
export const MedicineProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [centerId] 
         * @param {string} [hasResidence] 
         * @param {number} [directionsId] Несколько значений могут быть разделены запятыми.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineProgramsList: async (centerId?: number, hasResidence?: string, directionsId?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/medicine_programs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (centerId !== undefined) {
                localVarQueryParameter['center_id'] = centerId;
            }

            if (hasResidence !== undefined) {
                localVarQueryParameter['has_residence'] = hasResidence;
            }

            if (directionsId !== undefined) {
                localVarQueryParameter['directions__id'] = directionsId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинская программа.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineProgramsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('medicineProgramsRead', 'id', id)
            const localVarPath = `/medicine_programs/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicineProgramsApi - functional programming interface
 * @export
 */
export const MedicineProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicineProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [centerId] 
         * @param {string} [hasResidence] 
         * @param {number} [directionsId] Несколько значений могут быть разделены запятыми.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineProgramsList(centerId?: number, hasResidence?: string, directionsId?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20035>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineProgramsList(centerId, hasResidence, directionsId, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинская программа.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicineProgramsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicineProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicineProgramsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicineProgramsApi - factory interface
 * @export
 */
export const MedicineProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicineProgramsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [centerId] 
         * @param {string} [hasResidence] 
         * @param {number} [directionsId] Несколько значений могут быть разделены запятыми.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineProgramsList(centerId?: number, hasResidence?: string, directionsId?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20035> {
            return localVarFp.medicineProgramsList(centerId, hasResidence, directionsId, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Медицинская программа.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicineProgramsRead(id: number, options?: any): AxiosPromise<MedicineProgram> {
            return localVarFp.medicineProgramsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for medicineProgramsList operation in MedicineProgramsApi.
 * @export
 * @interface MedicineProgramsApiMedicineProgramsListRequest
 */
export interface MedicineProgramsApiMedicineProgramsListRequest {
    /**
     * 
     * @type {number}
     * @memberof MedicineProgramsApiMedicineProgramsList
     */
    readonly centerId?: number

    /**
     * 
     * @type {string}
     * @memberof MedicineProgramsApiMedicineProgramsList
     */
    readonly hasResidence?: string

    /**
     * Несколько значений могут быть разделены запятыми.
     * @type {number}
     * @memberof MedicineProgramsApiMedicineProgramsList
     */
    readonly directionsId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof MedicineProgramsApiMedicineProgramsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof MedicineProgramsApiMedicineProgramsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof MedicineProgramsApiMedicineProgramsList
     */
    readonly limit?: number
}

/**
 * Request parameters for medicineProgramsRead operation in MedicineProgramsApi.
 * @export
 * @interface MedicineProgramsApiMedicineProgramsReadRequest
 */
export interface MedicineProgramsApiMedicineProgramsReadRequest {
    /**
     * A unique integer value identifying this Медицинская программа.
     * @type {number}
     * @memberof MedicineProgramsApiMedicineProgramsRead
     */
    readonly id: number
}

/**
 * MedicineProgramsApi - object-oriented interface
 * @export
 * @class MedicineProgramsApi
 * @extends {BaseAPI}
 */
export class MedicineProgramsApi extends BaseAPI {
    /**
     * 
     * @param {MedicineProgramsApiMedicineProgramsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineProgramsApi
     */
    public medicineProgramsList(requestParameters: MedicineProgramsApiMedicineProgramsListRequest = {}, options?: any) {
        return MedicineProgramsApiFp(this.configuration).medicineProgramsList(requestParameters.centerId, requestParameters.hasResidence, requestParameters.directionsId, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedicineProgramsApiMedicineProgramsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicineProgramsApi
     */
    public medicineProgramsRead(requestParameters: MedicineProgramsApiMedicineProgramsReadRequest, options?: any) {
        return MedicineProgramsApiFp(this.configuration).medicineProgramsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MidicinePageApi - axios parameter creator
 * @export
 */
export const MidicinePageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        midicinePageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/midicine_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MidicinePageApi - functional programming interface
 * @export
 */
export const MidicinePageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MidicinePageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async midicinePageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicineCategoryPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.midicinePageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MidicinePageApi - factory interface
 * @export
 */
export const MidicinePageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MidicinePageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        midicinePageList(options?: any): AxiosPromise<MedicineCategoryPage> {
            return localVarFp.midicinePageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MidicinePageApi - object-oriented interface
 * @export
 * @class MidicinePageApi
 * @extends {BaseAPI}
 */
export class MidicinePageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MidicinePageApi
     */
    public midicinePageList(options?: any) {
        return MidicinePageApiFp(this.configuration).midicinePageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewAboutProjectPageApi - axios parameter creator
 * @export
 */
export const NewAboutProjectPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAboutProjectPageList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/new_about_project_page`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewAboutProjectPageApi - functional programming interface
 * @export
 */
export const NewAboutProjectPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewAboutProjectPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAboutProjectPageList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewAboutProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newAboutProjectPageList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewAboutProjectPageApi - factory interface
 * @export
 */
export const NewAboutProjectPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewAboutProjectPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAboutProjectPageList(options?: any): AxiosPromise<NewAboutProjectPage> {
            return localVarFp.newAboutProjectPageList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewAboutProjectPageApi - object-oriented interface
 * @export
 * @class NewAboutProjectPageApi
 * @extends {BaseAPI}
 */
export class NewAboutProjectPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewAboutProjectPageApi
     */
    public newAboutProjectPageList(options?: any) {
        return NewAboutProjectPageApiFp(this.configuration).newAboutProjectPageList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsCommentsApi - axios parameter creator
 * @export
 */
export const NewsCommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NewsComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsCreate: async (data: NewsComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('newsCommentsCreate', 'data', data)
            const localVarPath = `/news_comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [newsId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsList: async (isLikedByUser?: boolean, likesCountGte?: number, newsId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news_comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (newsId !== undefined) {
                localVarQueryParameter['news__id'] = newsId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment__id'] = parentCommentId;
            }

            if (parentCommentIdIsnull !== undefined) {
                localVarQueryParameter['parent_comment__id__isnull'] = parentCommentIdIsnull;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile__id'] = profileId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [newsId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, newsId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsCommentsListLikes', 'id', id)
            const localVarPath = `/news_comments/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (newsId !== undefined) {
                localVarQueryParameter['news__id'] = newsId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment__id'] = parentCommentId;
            }

            if (parentCommentIdIsnull !== undefined) {
                localVarQueryParameter['parent_comment__id__isnull'] = parentCommentIdIsnull;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile__id'] = profileId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsCommentsRead', 'id', id)
            const localVarPath = `/news_comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsCommentsToggleLikeCreate', 'id', id)
            const localVarPath = `/news_comments/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsCommentsToggleLikeDelete', 'id', id)
            const localVarPath = `/news_comments/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsCommentsApi - functional programming interface
 * @export
 */
export const NewsCommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsCommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NewsComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCommentsCreate(data: NewsComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCommentsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [newsId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCommentsList(isLikedByUser?: boolean, likesCountGte?: number, newsId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20036>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCommentsList(isLikedByUser, likesCountGte, newsId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [newsId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCommentsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, newsId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCommentsListLikes(id, isLikedByUser, likesCountGte, newsId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCommentsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCommentsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCommentsToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCommentsToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsCommentsToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsCommentsToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsCommentsApi - factory interface
 * @export
 */
export const NewsCommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsCommentsApiFp(configuration)
    return {
        /**
         * 
         * @param {NewsComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsCreate(data: NewsComment, options?: any): AxiosPromise<NewsComment> {
            return localVarFp.newsCommentsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [newsId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsList(isLikedByUser?: boolean, likesCountGte?: number, newsId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): AxiosPromise<InlineResponse20036> {
            return localVarFp.newsCommentsList(isLikedByUser, likesCountGte, newsId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [newsId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, newsId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.newsCommentsListLikes(id, isLikedByUser, likesCountGte, newsId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsRead(id: number, options?: any): AxiosPromise<NewsComment> {
            return localVarFp.newsCommentsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.newsCommentsToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsCommentsToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.newsCommentsToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for newsCommentsCreate operation in NewsCommentsApi.
 * @export
 * @interface NewsCommentsApiNewsCommentsCreateRequest
 */
export interface NewsCommentsApiNewsCommentsCreateRequest {
    /**
     * 
     * @type {NewsComment}
     * @memberof NewsCommentsApiNewsCommentsCreate
     */
    readonly data: NewsComment
}

/**
 * Request parameters for newsCommentsList operation in NewsCommentsApi.
 * @export
 * @interface NewsCommentsApiNewsCommentsListRequest
 */
export interface NewsCommentsApiNewsCommentsListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly isLikedByUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly newsId?: number

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly parentCommentId?: number

    /**
     * 
     * @type {boolean}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly parentCommentIdIsnull?: boolean

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly profileId?: number

    /**
     * 
     * @type {string}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsList
     */
    readonly count?: number
}

/**
 * Request parameters for newsCommentsListLikes operation in NewsCommentsApi.
 * @export
 * @interface NewsCommentsApiNewsCommentsListLikesRequest
 */
export interface NewsCommentsApiNewsCommentsListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly newsId?: number

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly parentCommentId?: number

    /**
     * 
     * @type {string}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly parentCommentIdIsnull?: string

    /**
     * 
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly profileId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsListLikes
     */
    readonly count?: number
}

/**
 * Request parameters for newsCommentsRead operation in NewsCommentsApi.
 * @export
 * @interface NewsCommentsApiNewsCommentsReadRequest
 */
export interface NewsCommentsApiNewsCommentsReadRequest {
    /**
     * A unique integer value identifying this Комментарий новости.
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsRead
     */
    readonly id: number
}

/**
 * Request parameters for newsCommentsToggleLikeCreate operation in NewsCommentsApi.
 * @export
 * @interface NewsCommentsApiNewsCommentsToggleLikeCreateRequest
 */
export interface NewsCommentsApiNewsCommentsToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Комментарий новости.
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for newsCommentsToggleLikeDelete operation in NewsCommentsApi.
 * @export
 * @interface NewsCommentsApiNewsCommentsToggleLikeDeleteRequest
 */
export interface NewsCommentsApiNewsCommentsToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Комментарий новости.
     * @type {number}
     * @memberof NewsCommentsApiNewsCommentsToggleLikeDelete
     */
    readonly id: number
}

/**
 * NewsCommentsApi - object-oriented interface
 * @export
 * @class NewsCommentsApi
 * @extends {BaseAPI}
 */
export class NewsCommentsApi extends BaseAPI {
    /**
     * 
     * @param {NewsCommentsApiNewsCommentsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsCommentsApi
     */
    public newsCommentsCreate(requestParameters: NewsCommentsApiNewsCommentsCreateRequest, options?: any) {
        return NewsCommentsApiFp(this.configuration).newsCommentsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsCommentsApiNewsCommentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsCommentsApi
     */
    public newsCommentsList(requestParameters: NewsCommentsApiNewsCommentsListRequest = {}, options?: any) {
        return NewsCommentsApiFp(this.configuration).newsCommentsList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.newsId, requestParameters.parentCommentId, requestParameters.parentCommentIdIsnull, requestParameters.profileId, requestParameters.ordering, requestParameters.page, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {NewsCommentsApiNewsCommentsListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsCommentsApi
     */
    public newsCommentsListLikes(requestParameters: NewsCommentsApiNewsCommentsListLikesRequest, options?: any) {
        return NewsCommentsApiFp(this.configuration).newsCommentsListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.newsId, requestParameters.parentCommentId, requestParameters.parentCommentIdIsnull, requestParameters.profileId, requestParameters.ordering, requestParameters.page, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsCommentsApiNewsCommentsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsCommentsApi
     */
    public newsCommentsRead(requestParameters: NewsCommentsApiNewsCommentsReadRequest, options?: any) {
        return NewsCommentsApiFp(this.configuration).newsCommentsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsCommentsApiNewsCommentsToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsCommentsApi
     */
    public newsCommentsToggleLikeCreate(requestParameters: NewsCommentsApiNewsCommentsToggleLikeCreateRequest, options?: any) {
        return NewsCommentsApiFp(this.configuration).newsCommentsToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsCommentsApiNewsCommentsToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsCommentsApi
     */
    public newsCommentsToggleLikeDelete(requestParameters: NewsCommentsApiNewsCommentsToggleLikeDeleteRequest, options?: any) {
        return NewsCommentsApiFp(this.configuration).newsCommentsToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsPageApi - axios parameter creator
 * @export
 */
export const NewsPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {boolean} [isShowOnMainPage] 
         * @param {Array<number>} [sectionsIdIn] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageList: async (isLikedByUser?: boolean, likesCountGte?: number, isInUserFavorites?: string, isShowOnMainPage?: boolean, sectionsIdIn?: Array<number>, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news_page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (isShowOnMainPage !== undefined) {
                localVarQueryParameter['is_show_on_main_page'] = isShowOnMainPage;
            }

            if (sectionsIdIn) {
                localVarQueryParameter['sections__id__in'] = sectionsIdIn.join(COLLECTION_FORMATS.csv);
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {string} [sectionsIdIn] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, isShowOnMainPage?: string, sectionsIdIn?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPageListLikes', 'id', id)
            const localVarPath = `/news_page/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (isShowOnMainPage !== undefined) {
                localVarQueryParameter['is_show_on_main_page'] = isShowOnMainPage;
            }

            if (sectionsIdIn !== undefined) {
                localVarQueryParameter['sections__id__in'] = sectionsIdIn;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPageRead', 'id', id)
            const localVarPath = `/news_page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleFavoriteCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPageToggleFavoriteCreate', 'id', id)
            const localVarPath = `/news_page/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleFavoriteDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPageToggleFavoriteDelete', 'id', id)
            const localVarPath = `/news_page/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPageToggleLikeCreate', 'id', id)
            const localVarPath = `/news_page/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPageToggleLikeDelete', 'id', id)
            const localVarPath = `/news_page/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsPageApi - functional programming interface
 * @export
 */
export const NewsPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {boolean} [isShowOnMainPage] 
         * @param {Array<number>} [sectionsIdIn] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageList(isLikedByUser?: boolean, likesCountGte?: number, isInUserFavorites?: string, isShowOnMainPage?: boolean, sectionsIdIn?: Array<number>, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20037>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageList(isLikedByUser, likesCountGte, isInUserFavorites, isShowOnMainPage, sectionsIdIn, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {string} [sectionsIdIn] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, isShowOnMainPage?: string, sectionsIdIn?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, isShowOnMainPage, sectionsIdIn, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageToggleFavoriteCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageToggleFavoriteCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageToggleFavoriteDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageToggleFavoriteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPageToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPageToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsPageApi - factory interface
 * @export
 */
export const NewsPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsPageApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {boolean} [isShowOnMainPage] 
         * @param {Array<number>} [sectionsIdIn] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageList(isLikedByUser?: boolean, likesCountGte?: number, isInUserFavorites?: string, isShowOnMainPage?: boolean, sectionsIdIn?: Array<number>, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20037> {
            return localVarFp.newsPageList(isLikedByUser, likesCountGte, isInUserFavorites, isShowOnMainPage, sectionsIdIn, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [isShowOnMainPage] 
         * @param {string} [sectionsIdIn] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, isShowOnMainPage?: string, sectionsIdIn?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.newsPageListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, isShowOnMainPage, sectionsIdIn, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageRead(id: number, options?: any): AxiosPromise<NewsPage> {
            return localVarFp.newsPageRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleFavoriteCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.newsPageToggleFavoriteCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleFavoriteDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.newsPageToggleFavoriteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.newsPageToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Страница новости.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPageToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.newsPageToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for newsPageList operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageListRequest
 */
export interface NewsPageApiNewsPageListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof NewsPageApiNewsPageList
     */
    readonly isLikedByUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof NewsPageApiNewsPageList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof NewsPageApiNewsPageList
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {boolean}
     * @memberof NewsPageApiNewsPageList
     */
    readonly isShowOnMainPage?: boolean

    /**
     * 
     * @type {Array<number>}
     * @memberof NewsPageApiNewsPageList
     */
    readonly sectionsIdIn?: Array<number>

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof NewsPageApiNewsPageList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof NewsPageApiNewsPageList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof NewsPageApiNewsPageList
     */
    readonly limit?: number
}

/**
 * Request parameters for newsPageListLikes operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageListLikesRequest
 */
export interface NewsPageApiNewsPageListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {string}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly isShowOnMainPage?: string

    /**
     * 
     * @type {string}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly sectionsIdIn?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof NewsPageApiNewsPageListLikes
     */
    readonly limit?: number
}

/**
 * Request parameters for newsPageRead operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageReadRequest
 */
export interface NewsPageApiNewsPageReadRequest {
    /**
     * A unique integer value identifying this Страница новости.
     * @type {number}
     * @memberof NewsPageApiNewsPageRead
     */
    readonly id: number
}

/**
 * Request parameters for newsPageToggleFavoriteCreate operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageToggleFavoriteCreateRequest
 */
export interface NewsPageApiNewsPageToggleFavoriteCreateRequest {
    /**
     * A unique integer value identifying this Страница новости.
     * @type {number}
     * @memberof NewsPageApiNewsPageToggleFavoriteCreate
     */
    readonly id: number
}

/**
 * Request parameters for newsPageToggleFavoriteDelete operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageToggleFavoriteDeleteRequest
 */
export interface NewsPageApiNewsPageToggleFavoriteDeleteRequest {
    /**
     * A unique integer value identifying this Страница новости.
     * @type {number}
     * @memberof NewsPageApiNewsPageToggleFavoriteDelete
     */
    readonly id: number
}

/**
 * Request parameters for newsPageToggleLikeCreate operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageToggleLikeCreateRequest
 */
export interface NewsPageApiNewsPageToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Страница новости.
     * @type {number}
     * @memberof NewsPageApiNewsPageToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for newsPageToggleLikeDelete operation in NewsPageApi.
 * @export
 * @interface NewsPageApiNewsPageToggleLikeDeleteRequest
 */
export interface NewsPageApiNewsPageToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Страница новости.
     * @type {number}
     * @memberof NewsPageApiNewsPageToggleLikeDelete
     */
    readonly id: number
}

/**
 * NewsPageApi - object-oriented interface
 * @export
 * @class NewsPageApi
 * @extends {BaseAPI}
 */
export class NewsPageApi extends BaseAPI {
    /**
     * 
     * @param {NewsPageApiNewsPageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageList(requestParameters: NewsPageApiNewsPageListRequest = {}, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.isShowOnMainPage, requestParameters.sectionsIdIn, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {NewsPageApiNewsPageListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageListLikes(requestParameters: NewsPageApiNewsPageListLikesRequest, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.isShowOnMainPage, requestParameters.sectionsIdIn, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageApiNewsPageReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageRead(requestParameters: NewsPageApiNewsPageReadRequest, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageApiNewsPageToggleFavoriteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageToggleFavoriteCreate(requestParameters: NewsPageApiNewsPageToggleFavoriteCreateRequest, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageToggleFavoriteCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageApiNewsPageToggleFavoriteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageToggleFavoriteDelete(requestParameters: NewsPageApiNewsPageToggleFavoriteDeleteRequest, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageToggleFavoriteDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageApiNewsPageToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageToggleLikeCreate(requestParameters: NewsPageApiNewsPageToggleLikeCreateRequest, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsPageApiNewsPageToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPageApi
     */
    public newsPageToggleLikeDelete(requestParameters: NewsPageApiNewsPageToggleLikeDeleteRequest, options?: any) {
        return NewsPageApiFp(this.configuration).newsPageToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsSectionsApi - axios parameter creator
 * @export
 */
export const NewsSectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsSectionsList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/news_sections/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рубрика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsSectionsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsSectionsRead', 'id', id)
            const localVarPath = `/news_sections/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsSectionsApi - functional programming interface
 * @export
 */
export const NewsSectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsSectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsSectionsList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20038>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsSectionsList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рубрика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsSectionsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsSection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsSectionsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsSectionsApi - factory interface
 * @export
 */
export const NewsSectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsSectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsSectionsList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20038> {
            return localVarFp.newsSectionsList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рубрика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsSectionsRead(id: number, options?: any): AxiosPromise<NewsSection> {
            return localVarFp.newsSectionsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for newsSectionsList operation in NewsSectionsApi.
 * @export
 * @interface NewsSectionsApiNewsSectionsListRequest
 */
export interface NewsSectionsApiNewsSectionsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof NewsSectionsApiNewsSectionsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof NewsSectionsApiNewsSectionsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof NewsSectionsApiNewsSectionsList
     */
    readonly limit?: number
}

/**
 * Request parameters for newsSectionsRead operation in NewsSectionsApi.
 * @export
 * @interface NewsSectionsApiNewsSectionsReadRequest
 */
export interface NewsSectionsApiNewsSectionsReadRequest {
    /**
     * A unique integer value identifying this Рубрика.
     * @type {number}
     * @memberof NewsSectionsApiNewsSectionsRead
     */
    readonly id: number
}

/**
 * NewsSectionsApi - object-oriented interface
 * @export
 * @class NewsSectionsApi
 * @extends {BaseAPI}
 */
export class NewsSectionsApi extends BaseAPI {
    /**
     * 
     * @param {NewsSectionsApiNewsSectionsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionsApi
     */
    public newsSectionsList(requestParameters: NewsSectionsApiNewsSectionsListRequest = {}, options?: any) {
        return NewsSectionsApiFp(this.configuration).newsSectionsList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsSectionsApiNewsSectionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsSectionsApi
     */
    public newsSectionsRead(requestParameters: NewsSectionsApiNewsSectionsReadRequest, options?: any) {
        return NewsSectionsApiFp(this.configuration).newsSectionsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получение информации о пользователе по токену доступа Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProfileList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение токена доступа Oauth по токену авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthTokenAccessCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/token/access/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение токена авторизации для Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthTokenAuthCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/token/auth/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * Получение информации о пользователе по токену доступа Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthProfileList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthProfileList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Получение токена доступа Oauth по токену авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthTokenAccessCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthTokenAccessCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Получение токена авторизации для Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauthTokenAuthCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauthTokenAuthCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * Получение информации о пользователе по токену доступа Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthProfileList(options?: any): AxiosPromise<UserProfile> {
            return localVarFp.oauthProfileList(options).then((request) => request(axios, basePath));
        },
        /**
         * Получение токена доступа Oauth по токену авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthTokenAccessCreate(options?: any): AxiosPromise<OauthToken> {
            return localVarFp.oauthTokenAccessCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Получение токена авторизации для Oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauthTokenAuthCreate(options?: any): AxiosPromise<OauthToken> {
            return localVarFp.oauthTokenAuthCreate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * Получение информации о пользователе по токену доступа Oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthProfileList(options?: any) {
        return OauthApiFp(this.configuration).oauthProfileList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получение токена доступа Oauth по токену авторизации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthTokenAccessCreate(options?: any) {
        return OauthApiFp(this.configuration).oauthTokenAccessCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получение токена авторизации для Oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public oauthTokenAuthCreate(options?: any) {
        return OauthApiFp(this.configuration).oauthTokenAuthCreate(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OurPeopleArticlesApi - axios parameter creator
 * @export
 */
export const OurPeopleArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesList: async (isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/our_people_articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (magazineId !== undefined) {
                localVarQueryParameter['magazine__id'] = magazineId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ourPeopleArticlesListLikes', 'id', id)
            const localVarPath = `/our_people_articles/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (magazineId !== undefined) {
                localVarQueryParameter['magazine__id'] = magazineId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ourPeopleArticlesRead', 'id', id)
            const localVarPath = `/our_people_articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ourPeopleArticlesToggleLikeCreate', 'id', id)
            const localVarPath = `/our_people_articles/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('ourPeopleArticlesToggleLikeDelete', 'id', id)
            const localVarPath = `/our_people_articles/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OurPeopleArticlesApi - functional programming interface
 * @export
 */
export const OurPeopleArticlesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OurPeopleArticlesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ourPeopleArticlesList(isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20039>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ourPeopleArticlesList(isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ourPeopleArticlesListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ourPeopleArticlesListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ourPeopleArticlesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OurPeopleArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ourPeopleArticlesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ourPeopleArticlesToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ourPeopleArticlesToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ourPeopleArticlesToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ourPeopleArticlesToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OurPeopleArticlesApi - factory interface
 * @export
 */
export const OurPeopleArticlesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OurPeopleArticlesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesList(isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20039> {
            return localVarFp.ourPeopleArticlesList(isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.ourPeopleArticlesListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesRead(id: number, options?: any): AxiosPromise<OurPeopleArticle> {
            return localVarFp.ourPeopleArticlesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.ourPeopleArticlesToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ourPeopleArticlesToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.ourPeopleArticlesToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ourPeopleArticlesList operation in OurPeopleArticlesApi.
 * @export
 * @interface OurPeopleArticlesApiOurPeopleArticlesListRequest
 */
export interface OurPeopleArticlesApiOurPeopleArticlesListRequest {
    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly magazineId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesList
     */
    readonly limit?: number
}

/**
 * Request parameters for ourPeopleArticlesListLikes operation in OurPeopleArticlesApi.
 * @export
 * @interface OurPeopleArticlesApiOurPeopleArticlesListLikesRequest
 */
export interface OurPeopleArticlesApiOurPeopleArticlesListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly magazineId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesListLikes
     */
    readonly limit?: number
}

/**
 * Request parameters for ourPeopleArticlesRead operation in OurPeopleArticlesApi.
 * @export
 * @interface OurPeopleArticlesApiOurPeopleArticlesReadRequest
 */
export interface OurPeopleArticlesApiOurPeopleArticlesReadRequest {
    /**
     * A unique integer value identifying this Статья.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesRead
     */
    readonly id: number
}

/**
 * Request parameters for ourPeopleArticlesToggleLikeCreate operation in OurPeopleArticlesApi.
 * @export
 * @interface OurPeopleArticlesApiOurPeopleArticlesToggleLikeCreateRequest
 */
export interface OurPeopleArticlesApiOurPeopleArticlesToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Статья.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for ourPeopleArticlesToggleLikeDelete operation in OurPeopleArticlesApi.
 * @export
 * @interface OurPeopleArticlesApiOurPeopleArticlesToggleLikeDeleteRequest
 */
export interface OurPeopleArticlesApiOurPeopleArticlesToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Статья.
     * @type {number}
     * @memberof OurPeopleArticlesApiOurPeopleArticlesToggleLikeDelete
     */
    readonly id: number
}

/**
 * OurPeopleArticlesApi - object-oriented interface
 * @export
 * @class OurPeopleArticlesApi
 * @extends {BaseAPI}
 */
export class OurPeopleArticlesApi extends BaseAPI {
    /**
     * 
     * @param {OurPeopleArticlesApiOurPeopleArticlesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OurPeopleArticlesApi
     */
    public ourPeopleArticlesList(requestParameters: OurPeopleArticlesApiOurPeopleArticlesListRequest = {}, options?: any) {
        return OurPeopleArticlesApiFp(this.configuration).ourPeopleArticlesList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.magazineId, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {OurPeopleArticlesApiOurPeopleArticlesListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OurPeopleArticlesApi
     */
    public ourPeopleArticlesListLikes(requestParameters: OurPeopleArticlesApiOurPeopleArticlesListLikesRequest, options?: any) {
        return OurPeopleArticlesApiFp(this.configuration).ourPeopleArticlesListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.magazineId, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OurPeopleArticlesApiOurPeopleArticlesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OurPeopleArticlesApi
     */
    public ourPeopleArticlesRead(requestParameters: OurPeopleArticlesApiOurPeopleArticlesReadRequest, options?: any) {
        return OurPeopleArticlesApiFp(this.configuration).ourPeopleArticlesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OurPeopleArticlesApiOurPeopleArticlesToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OurPeopleArticlesApi
     */
    public ourPeopleArticlesToggleLikeCreate(requestParameters: OurPeopleArticlesApiOurPeopleArticlesToggleLikeCreateRequest, options?: any) {
        return OurPeopleArticlesApiFp(this.configuration).ourPeopleArticlesToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OurPeopleArticlesApiOurPeopleArticlesToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OurPeopleArticlesApi
     */
    public ourPeopleArticlesToggleLikeDelete(requestParameters: OurPeopleArticlesApiOurPeopleArticlesToggleLikeDeleteRequest, options?: any) {
        return OurPeopleArticlesApiFp(this.configuration).ourPeopleArticlesToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageContentApi - axios parameter creator
 * @export
 */
export const PageContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [title] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContentList: async (slug?: string, title?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/page_content/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамическое содержание страницы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContentRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pageContentRead', 'id', id)
            const localVarPath = `/page_content/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageContentApi - functional programming interface
 * @export
 */
export const PageContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [title] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageContentList(slug?: string, title?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20040>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageContentList(slug, title, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамическое содержание страницы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageContentRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageContentRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageContentApi - factory interface
 * @export
 */
export const PageContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageContentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [slug] 
         * @param {string} [title] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContentList(slug?: string, title?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20040> {
            return localVarFp.pageContentList(slug, title, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамическое содержание страницы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageContentRead(id: number, options?: any): AxiosPromise<PageContent> {
            return localVarFp.pageContentRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pageContentList operation in PageContentApi.
 * @export
 * @interface PageContentApiPageContentListRequest
 */
export interface PageContentApiPageContentListRequest {
    /**
     * 
     * @type {string}
     * @memberof PageContentApiPageContentList
     */
    readonly slug?: string

    /**
     * 
     * @type {string}
     * @memberof PageContentApiPageContentList
     */
    readonly title?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PageContentApiPageContentList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PageContentApiPageContentList
     */
    readonly limit?: number
}

/**
 * Request parameters for pageContentRead operation in PageContentApi.
 * @export
 * @interface PageContentApiPageContentReadRequest
 */
export interface PageContentApiPageContentReadRequest {
    /**
     * A unique integer value identifying this Динамическое содержание страницы.
     * @type {number}
     * @memberof PageContentApiPageContentRead
     */
    readonly id: number
}

/**
 * PageContentApi - object-oriented interface
 * @export
 * @class PageContentApi
 * @extends {BaseAPI}
 */
export class PageContentApi extends BaseAPI {
    /**
     * 
     * @param {PageContentApiPageContentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageContentApi
     */
    public pageContentList(requestParameters: PageContentApiPageContentListRequest = {}, options?: any) {
        return PageContentApiFp(this.configuration).pageContentList(requestParameters.slug, requestParameters.title, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PageContentApiPageContentReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageContentApi
     */
    public pageContentRead(requestParameters: PageContentApiPageContentReadRequest, options?: any) {
        return PageContentApiFp(this.configuration).pageContentRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortalDirectionItemsApi - axios parameter creator
 * @export
 */
export const PortalDirectionItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsCreate: async (data: PortalDirectionItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('portalDirectionItemsCreate', 'data', data)
            const localVarPath = `/portal_direction_items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portalDirectionItemsDelete', 'id', id)
            const localVarPath = `/portal_direction_items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [forMainPage] 
         * @param {string} [forProjectPage] 
         * @param {string} [forTopMenu] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsList: async (forMainPage?: string, forProjectPage?: string, forTopMenu?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/portal_direction_items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (forMainPage !== undefined) {
                localVarQueryParameter['for_main_page'] = forMainPage;
            }

            if (forProjectPage !== undefined) {
                localVarQueryParameter['for_project_page'] = forProjectPage;
            }

            if (forTopMenu !== undefined) {
                localVarQueryParameter['for_top_menu'] = forTopMenu;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsPartialUpdate: async (id: number, data: PortalDirectionItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portalDirectionItemsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('portalDirectionItemsPartialUpdate', 'data', data)
            const localVarPath = `/portal_direction_items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portalDirectionItemsRead', 'id', id)
            const localVarPath = `/portal_direction_items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsUpdate: async (id: number, data: PortalDirectionItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portalDirectionItemsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('portalDirectionItemsUpdate', 'data', data)
            const localVarPath = `/portal_direction_items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortalDirectionItemsApi - functional programming interface
 * @export
 */
export const PortalDirectionItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortalDirectionItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalDirectionItemsCreate(data: PortalDirectionItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalDirectionItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalDirectionItemsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalDirectionItemsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalDirectionItemsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [forMainPage] 
         * @param {string} [forProjectPage] 
         * @param {string} [forTopMenu] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalDirectionItemsList(forMainPage?: string, forProjectPage?: string, forTopMenu?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20041>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalDirectionItemsList(forMainPage, forProjectPage, forTopMenu, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalDirectionItemsPartialUpdate(id: number, data: PortalDirectionItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalDirectionItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalDirectionItemsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalDirectionItemsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalDirectionItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalDirectionItemsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalDirectionItemsUpdate(id: number, data: PortalDirectionItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalDirectionItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalDirectionItemsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortalDirectionItemsApi - factory interface
 * @export
 */
export const PortalDirectionItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortalDirectionItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsCreate(data: PortalDirectionItem, options?: any): AxiosPromise<PortalDirectionItem> {
            return localVarFp.portalDirectionItemsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.portalDirectionItemsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [forMainPage] 
         * @param {string} [forProjectPage] 
         * @param {string} [forTopMenu] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsList(forMainPage?: string, forProjectPage?: string, forTopMenu?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20041> {
            return localVarFp.portalDirectionItemsList(forMainPage, forProjectPage, forTopMenu, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsPartialUpdate(id: number, data: PortalDirectionItem, options?: any): AxiosPromise<PortalDirectionItem> {
            return localVarFp.portalDirectionItemsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsRead(id: number, options?: any): AxiosPromise<PortalDirectionItem> {
            return localVarFp.portalDirectionItemsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
         * @param {PortalDirectionItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalDirectionItemsUpdate(id: number, data: PortalDirectionItem, options?: any): AxiosPromise<PortalDirectionItem> {
            return localVarFp.portalDirectionItemsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for portalDirectionItemsCreate operation in PortalDirectionItemsApi.
 * @export
 * @interface PortalDirectionItemsApiPortalDirectionItemsCreateRequest
 */
export interface PortalDirectionItemsApiPortalDirectionItemsCreateRequest {
    /**
     * 
     * @type {PortalDirectionItem}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsCreate
     */
    readonly data: PortalDirectionItem
}

/**
 * Request parameters for portalDirectionItemsDelete operation in PortalDirectionItemsApi.
 * @export
 * @interface PortalDirectionItemsApiPortalDirectionItemsDeleteRequest
 */
export interface PortalDirectionItemsApiPortalDirectionItemsDeleteRequest {
    /**
     * A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
     * @type {number}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsDelete
     */
    readonly id: number
}

/**
 * Request parameters for portalDirectionItemsList operation in PortalDirectionItemsApi.
 * @export
 * @interface PortalDirectionItemsApiPortalDirectionItemsListRequest
 */
export interface PortalDirectionItemsApiPortalDirectionItemsListRequest {
    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsList
     */
    readonly forMainPage?: string

    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsList
     */
    readonly forProjectPage?: string

    /**
     * 
     * @type {string}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsList
     */
    readonly forTopMenu?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsList
     */
    readonly limit?: number
}

/**
 * Request parameters for portalDirectionItemsPartialUpdate operation in PortalDirectionItemsApi.
 * @export
 * @interface PortalDirectionItemsApiPortalDirectionItemsPartialUpdateRequest
 */
export interface PortalDirectionItemsApiPortalDirectionItemsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
     * @type {number}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PortalDirectionItem}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsPartialUpdate
     */
    readonly data: PortalDirectionItem
}

/**
 * Request parameters for portalDirectionItemsRead operation in PortalDirectionItemsApi.
 * @export
 * @interface PortalDirectionItemsApiPortalDirectionItemsReadRequest
 */
export interface PortalDirectionItemsApiPortalDirectionItemsReadRequest {
    /**
     * A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
     * @type {number}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsRead
     */
    readonly id: number
}

/**
 * Request parameters for portalDirectionItemsUpdate operation in PortalDirectionItemsApi.
 * @export
 * @interface PortalDirectionItemsApiPortalDirectionItemsUpdateRequest
 */
export interface PortalDirectionItemsApiPortalDirectionItemsUpdateRequest {
    /**
     * A unique integer value identifying this Пункт \&quot;Направления портала\&quot;.
     * @type {number}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PortalDirectionItem}
     * @memberof PortalDirectionItemsApiPortalDirectionItemsUpdate
     */
    readonly data: PortalDirectionItem
}

/**
 * PortalDirectionItemsApi - object-oriented interface
 * @export
 * @class PortalDirectionItemsApi
 * @extends {BaseAPI}
 */
export class PortalDirectionItemsApi extends BaseAPI {
    /**
     * 
     * @param {PortalDirectionItemsApiPortalDirectionItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalDirectionItemsApi
     */
    public portalDirectionItemsCreate(requestParameters: PortalDirectionItemsApiPortalDirectionItemsCreateRequest, options?: any) {
        return PortalDirectionItemsApiFp(this.configuration).portalDirectionItemsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PortalDirectionItemsApiPortalDirectionItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalDirectionItemsApi
     */
    public portalDirectionItemsDelete(requestParameters: PortalDirectionItemsApiPortalDirectionItemsDeleteRequest, options?: any) {
        return PortalDirectionItemsApiFp(this.configuration).portalDirectionItemsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PortalDirectionItemsApiPortalDirectionItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalDirectionItemsApi
     */
    public portalDirectionItemsList(requestParameters: PortalDirectionItemsApiPortalDirectionItemsListRequest = {}, options?: any) {
        return PortalDirectionItemsApiFp(this.configuration).portalDirectionItemsList(requestParameters.forMainPage, requestParameters.forProjectPage, requestParameters.forTopMenu, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PortalDirectionItemsApiPortalDirectionItemsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalDirectionItemsApi
     */
    public portalDirectionItemsPartialUpdate(requestParameters: PortalDirectionItemsApiPortalDirectionItemsPartialUpdateRequest, options?: any) {
        return PortalDirectionItemsApiFp(this.configuration).portalDirectionItemsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PortalDirectionItemsApiPortalDirectionItemsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalDirectionItemsApi
     */
    public portalDirectionItemsRead(requestParameters: PortalDirectionItemsApiPortalDirectionItemsReadRequest, options?: any) {
        return PortalDirectionItemsApiFp(this.configuration).portalDirectionItemsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PortalDirectionItemsApiPortalDirectionItemsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalDirectionItemsApi
     */
    public portalDirectionItemsUpdate(requestParameters: PortalDirectionItemsApiPortalDirectionItemsUpdateRequest, options?: any) {
        return PortalDirectionItemsApiFp(this.configuration).portalDirectionItemsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PreferencesApi - axios parameter creator
 * @export
 */
export const PreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preferencesList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/preferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreferencesApi - functional programming interface
 * @export
 */
export const PreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preferencesList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Preferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preferencesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreferencesApi - factory interface
 * @export
 */
export const PreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreferencesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preferencesList(options?: any): AxiosPromise<Preferences> {
            return localVarFp.preferencesList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreferencesApi - object-oriented interface
 * @export
 * @class PreferencesApi
 * @extends {BaseAPI}
 */
export class PreferencesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public preferencesList(options?: any) {
        return PreferencesApiFp(this.configuration).preferencesList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductAttributeApi - axios parameter creator
 * @export
 */
export const ProductAttributeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeCreate: async (data: ProductAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productAttributeCreate', 'data', data)
            const localVarPath = `/product_attribute/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productAttributeDelete', 'id', id)
            const localVarPath = `/product_attribute/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [name] 
         * @param {number} [categoryId] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeList: async (ordering?: string, name?: string, categoryId?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_attribute/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category__id'] = categoryId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributePartialUpdate: async (id: number, data: ProductAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productAttributePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productAttributePartialUpdate', 'data', data)
            const localVarPath = `/product_attribute/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productAttributeRead', 'id', id)
            const localVarPath = `/product_attribute/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeUpdate: async (id: number, data: ProductAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productAttributeUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productAttributeUpdate', 'data', data)
            const localVarPath = `/product_attribute/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductAttributeApi - functional programming interface
 * @export
 */
export const ProductAttributeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductAttributeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAttributeCreate(data: ProductAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAttributeCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAttributeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAttributeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [name] 
         * @param {number} [categoryId] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAttributeList(ordering?: string, name?: string, categoryId?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20042>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAttributeList(ordering, name, categoryId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAttributePartialUpdate(id: number, data: ProductAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAttributePartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAttributeRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAttributeRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productAttributeUpdate(id: number, data: ProductAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productAttributeUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductAttributeApi - factory interface
 * @export
 */
export const ProductAttributeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductAttributeApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeCreate(data: ProductAttribute, options?: any): AxiosPromise<ProductAttribute> {
            return localVarFp.productAttributeCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productAttributeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [name] 
         * @param {number} [categoryId] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeList(ordering?: string, name?: string, categoryId?: number, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20042> {
            return localVarFp.productAttributeList(ordering, name, categoryId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributePartialUpdate(id: number, data: ProductAttribute, options?: any): AxiosPromise<ProductAttribute> {
            return localVarFp.productAttributePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeRead(id: number, options?: any): AxiosPromise<ProductAttribute> {
            return localVarFp.productAttributeRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Атрибут товара.
         * @param {ProductAttribute} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAttributeUpdate(id: number, data: ProductAttribute, options?: any): AxiosPromise<ProductAttribute> {
            return localVarFp.productAttributeUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productAttributeCreate operation in ProductAttributeApi.
 * @export
 * @interface ProductAttributeApiProductAttributeCreateRequest
 */
export interface ProductAttributeApiProductAttributeCreateRequest {
    /**
     * 
     * @type {ProductAttribute}
     * @memberof ProductAttributeApiProductAttributeCreate
     */
    readonly data: ProductAttribute
}

/**
 * Request parameters for productAttributeDelete operation in ProductAttributeApi.
 * @export
 * @interface ProductAttributeApiProductAttributeDeleteRequest
 */
export interface ProductAttributeApiProductAttributeDeleteRequest {
    /**
     * A unique integer value identifying this Атрибут товара.
     * @type {number}
     * @memberof ProductAttributeApiProductAttributeDelete
     */
    readonly id: number
}

/**
 * Request parameters for productAttributeList operation in ProductAttributeApi.
 * @export
 * @interface ProductAttributeApiProductAttributeListRequest
 */
export interface ProductAttributeApiProductAttributeListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProductAttributeApiProductAttributeList
     */
    readonly ordering?: string

    /**
     * 
     * @type {string}
     * @memberof ProductAttributeApiProductAttributeList
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ProductAttributeApiProductAttributeList
     */
    readonly categoryId?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductAttributeApiProductAttributeList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductAttributeApiProductAttributeList
     */
    readonly limit?: number
}

/**
 * Request parameters for productAttributePartialUpdate operation in ProductAttributeApi.
 * @export
 * @interface ProductAttributeApiProductAttributePartialUpdateRequest
 */
export interface ProductAttributeApiProductAttributePartialUpdateRequest {
    /**
     * A unique integer value identifying this Атрибут товара.
     * @type {number}
     * @memberof ProductAttributeApiProductAttributePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductAttribute}
     * @memberof ProductAttributeApiProductAttributePartialUpdate
     */
    readonly data: ProductAttribute
}

/**
 * Request parameters for productAttributeRead operation in ProductAttributeApi.
 * @export
 * @interface ProductAttributeApiProductAttributeReadRequest
 */
export interface ProductAttributeApiProductAttributeReadRequest {
    /**
     * A unique integer value identifying this Атрибут товара.
     * @type {number}
     * @memberof ProductAttributeApiProductAttributeRead
     */
    readonly id: number
}

/**
 * Request parameters for productAttributeUpdate operation in ProductAttributeApi.
 * @export
 * @interface ProductAttributeApiProductAttributeUpdateRequest
 */
export interface ProductAttributeApiProductAttributeUpdateRequest {
    /**
     * A unique integer value identifying this Атрибут товара.
     * @type {number}
     * @memberof ProductAttributeApiProductAttributeUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductAttribute}
     * @memberof ProductAttributeApiProductAttributeUpdate
     */
    readonly data: ProductAttribute
}

/**
 * ProductAttributeApi - object-oriented interface
 * @export
 * @class ProductAttributeApi
 * @extends {BaseAPI}
 */
export class ProductAttributeApi extends BaseAPI {
    /**
     * 
     * @param {ProductAttributeApiProductAttributeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAttributeApi
     */
    public productAttributeCreate(requestParameters: ProductAttributeApiProductAttributeCreateRequest, options?: any) {
        return ProductAttributeApiFp(this.configuration).productAttributeCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductAttributeApiProductAttributeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAttributeApi
     */
    public productAttributeDelete(requestParameters: ProductAttributeApiProductAttributeDeleteRequest, options?: any) {
        return ProductAttributeApiFp(this.configuration).productAttributeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductAttributeApiProductAttributeListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAttributeApi
     */
    public productAttributeList(requestParameters: ProductAttributeApiProductAttributeListRequest = {}, options?: any) {
        return ProductAttributeApiFp(this.configuration).productAttributeList(requestParameters.ordering, requestParameters.name, requestParameters.categoryId, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductAttributeApiProductAttributePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAttributeApi
     */
    public productAttributePartialUpdate(requestParameters: ProductAttributeApiProductAttributePartialUpdateRequest, options?: any) {
        return ProductAttributeApiFp(this.configuration).productAttributePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductAttributeApiProductAttributeReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAttributeApi
     */
    public productAttributeRead(requestParameters: ProductAttributeApiProductAttributeReadRequest, options?: any) {
        return ProductAttributeApiFp(this.configuration).productAttributeRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductAttributeApiProductAttributeUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductAttributeApi
     */
    public productAttributeUpdate(requestParameters: ProductAttributeApiProductAttributeUpdateRequest, options?: any) {
        return ProductAttributeApiFp(this.configuration).productAttributeUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductPagePreferencesApi - axios parameter creator
 * @export
 */
export const ProductPagePreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPagePreferencesList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_page_preferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPagePreferencesApi - functional programming interface
 * @export
 */
export const ProductPagePreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductPagePreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPagePreferencesList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPagePreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPagePreferencesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductPagePreferencesApi - factory interface
 * @export
 */
export const ProductPagePreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductPagePreferencesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPagePreferencesList(options?: any): AxiosPromise<ProductPagePreferences> {
            return localVarFp.productPagePreferencesList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPagePreferencesApi - object-oriented interface
 * @export
 * @class ProductPagePreferencesApi
 * @extends {BaseAPI}
 */
export class ProductPagePreferencesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPagePreferencesApi
     */
    public productPagePreferencesList(options?: any) {
        return ProductPagePreferencesApiFp(this.configuration).productPagePreferencesList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductRatingsApi - axios parameter creator
 * @export
 */
export const ProductRatingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsCreate: async (data: ProductRating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productRatingsCreate', 'data', data)
            const localVarPath = `/product_ratings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productRatingsDelete', 'id', id)
            const localVarPath = `/product_ratings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [product] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsList: async (product?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_ratings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsPartialUpdate: async (id: number, data: ProductRating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productRatingsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productRatingsPartialUpdate', 'data', data)
            const localVarPath = `/product_ratings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productRatingsRead', 'id', id)
            const localVarPath = `/product_ratings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsUpdate: async (id: number, data: ProductRating, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productRatingsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productRatingsUpdate', 'data', data)
            const localVarPath = `/product_ratings/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductRatingsApi - functional programming interface
 * @export
 */
export const ProductRatingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductRatingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRatingsCreate(data: ProductRating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRatingsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRatingsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRatingsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [product] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRatingsList(product?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20043>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRatingsList(product, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRatingsPartialUpdate(id: number, data: ProductRating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRatingsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRatingsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRatingsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productRatingsUpdate(id: number, data: ProductRating, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductRating>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productRatingsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductRatingsApi - factory interface
 * @export
 */
export const ProductRatingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductRatingsApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsCreate(data: ProductRating, options?: any): AxiosPromise<ProductRating> {
            return localVarFp.productRatingsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productRatingsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [product] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsList(product?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20043> {
            return localVarFp.productRatingsList(product, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsPartialUpdate(id: number, data: ProductRating, options?: any): AxiosPromise<ProductRating> {
            return localVarFp.productRatingsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsRead(id: number, options?: any): AxiosPromise<ProductRating> {
            return localVarFp.productRatingsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Рейтинг товара.
         * @param {ProductRating} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingsUpdate(id: number, data: ProductRating, options?: any): AxiosPromise<ProductRating> {
            return localVarFp.productRatingsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productRatingsCreate operation in ProductRatingsApi.
 * @export
 * @interface ProductRatingsApiProductRatingsCreateRequest
 */
export interface ProductRatingsApiProductRatingsCreateRequest {
    /**
     * 
     * @type {ProductRating}
     * @memberof ProductRatingsApiProductRatingsCreate
     */
    readonly data: ProductRating
}

/**
 * Request parameters for productRatingsDelete operation in ProductRatingsApi.
 * @export
 * @interface ProductRatingsApiProductRatingsDeleteRequest
 */
export interface ProductRatingsApiProductRatingsDeleteRequest {
    /**
     * A unique integer value identifying this Рейтинг товара.
     * @type {number}
     * @memberof ProductRatingsApiProductRatingsDelete
     */
    readonly id: number
}

/**
 * Request parameters for productRatingsList operation in ProductRatingsApi.
 * @export
 * @interface ProductRatingsApiProductRatingsListRequest
 */
export interface ProductRatingsApiProductRatingsListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductRatingsApiProductRatingsList
     */
    readonly product?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductRatingsApiProductRatingsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductRatingsApiProductRatingsList
     */
    readonly limit?: number
}

/**
 * Request parameters for productRatingsPartialUpdate operation in ProductRatingsApi.
 * @export
 * @interface ProductRatingsApiProductRatingsPartialUpdateRequest
 */
export interface ProductRatingsApiProductRatingsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Рейтинг товара.
     * @type {number}
     * @memberof ProductRatingsApiProductRatingsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductRating}
     * @memberof ProductRatingsApiProductRatingsPartialUpdate
     */
    readonly data: ProductRating
}

/**
 * Request parameters for productRatingsRead operation in ProductRatingsApi.
 * @export
 * @interface ProductRatingsApiProductRatingsReadRequest
 */
export interface ProductRatingsApiProductRatingsReadRequest {
    /**
     * A unique integer value identifying this Рейтинг товара.
     * @type {number}
     * @memberof ProductRatingsApiProductRatingsRead
     */
    readonly id: number
}

/**
 * Request parameters for productRatingsUpdate operation in ProductRatingsApi.
 * @export
 * @interface ProductRatingsApiProductRatingsUpdateRequest
 */
export interface ProductRatingsApiProductRatingsUpdateRequest {
    /**
     * A unique integer value identifying this Рейтинг товара.
     * @type {number}
     * @memberof ProductRatingsApiProductRatingsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ProductRating}
     * @memberof ProductRatingsApiProductRatingsUpdate
     */
    readonly data: ProductRating
}

/**
 * ProductRatingsApi - object-oriented interface
 * @export
 * @class ProductRatingsApi
 * @extends {BaseAPI}
 */
export class ProductRatingsApi extends BaseAPI {
    /**
     * 
     * @param {ProductRatingsApiProductRatingsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductRatingsApi
     */
    public productRatingsCreate(requestParameters: ProductRatingsApiProductRatingsCreateRequest, options?: any) {
        return ProductRatingsApiFp(this.configuration).productRatingsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductRatingsApiProductRatingsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductRatingsApi
     */
    public productRatingsDelete(requestParameters: ProductRatingsApiProductRatingsDeleteRequest, options?: any) {
        return ProductRatingsApiFp(this.configuration).productRatingsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductRatingsApiProductRatingsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductRatingsApi
     */
    public productRatingsList(requestParameters: ProductRatingsApiProductRatingsListRequest = {}, options?: any) {
        return ProductRatingsApiFp(this.configuration).productRatingsList(requestParameters.product, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductRatingsApiProductRatingsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductRatingsApi
     */
    public productRatingsPartialUpdate(requestParameters: ProductRatingsApiProductRatingsPartialUpdateRequest, options?: any) {
        return ProductRatingsApiFp(this.configuration).productRatingsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductRatingsApiProductRatingsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductRatingsApi
     */
    public productRatingsRead(requestParameters: ProductRatingsApiProductRatingsReadRequest, options?: any) {
        return ProductRatingsApiFp(this.configuration).productRatingsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductRatingsApiProductRatingsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductRatingsApi
     */
    public productRatingsUpdate(requestParameters: ProductRatingsApiProductRatingsUpdateRequest, options?: any) {
        return ProductRatingsApiFp(this.configuration).productRatingsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [category] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsAlcoholContentLimitsList: async (category?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/alcohol_content_limits/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreate: async (data: Product, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productsCreate', 'data', data)
            const localVarPath = `/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsDelete', 'id', id)
            const localVarPath = `/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {number} [priceLt] 
         * @param {number} [priceLte] 
         * @param {number} [priceGt] 
         * @param {number} [priceGte] 
         * @param {number} [category] 
         * @param {number} [parent] 
         * @param {number} [subcategory] 
         * @param {Array<number>} [directionsIdIn] 
         * @param {number} [propertiesId] 
         * @param {Array<number>} [propertiesIdIn] 
         * @param {Array<number>} [propertiesIdContains] 
         * @param {number} [averageRatingGte] 
         * @param {string} [multiselectPropertiesId] 
         * @param {number} [discountPriceOrPriceLte] 
         * @param {number} [discountPriceOrPriceGte] 
         * @param {number} [discountPriceOrPriceLt] 
         * @param {number} [discountPriceOrPriceGt] 
         * @param {number} [alcoholContentLte] 
         * @param {number} [alcoholContentGte] 
         * @param {number} [alcoholContentLt] 
         * @param {number} [alcoholContentGt] 
         * @param {string} [ordering] Доступные варианты: name, price, discount_percent, discount_price, created, sort_order, discount_price_or_price
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsList: async (isLikedByUser?: boolean, likesCountGte?: number, isInUserFavorites?: string, name?: string, price?: number, priceLt?: number, priceLte?: number, priceGt?: number, priceGte?: number, category?: number, parent?: number, subcategory?: number, directionsIdIn?: Array<number>, propertiesId?: number, propertiesIdIn?: Array<number>, propertiesIdContains?: Array<number>, averageRatingGte?: number, multiselectPropertiesId?: string, discountPriceOrPriceLte?: number, discountPriceOrPriceGte?: number, discountPriceOrPriceLt?: number, discountPriceOrPriceGt?: number, alcoholContentLte?: number, alcoholContentGte?: number, alcoholContentLt?: number, alcoholContentGt?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceLt !== undefined) {
                localVarQueryParameter['price__lt'] = priceLt;
            }

            if (priceLte !== undefined) {
                localVarQueryParameter['price__lte'] = priceLte;
            }

            if (priceGt !== undefined) {
                localVarQueryParameter['price__gt'] = priceGt;
            }

            if (priceGte !== undefined) {
                localVarQueryParameter['price__gte'] = priceGte;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (parent !== undefined) {
                localVarQueryParameter['parent'] = parent;
            }

            if (subcategory !== undefined) {
                localVarQueryParameter['subcategory'] = subcategory;
            }

            if (directionsIdIn) {
                localVarQueryParameter['directions__id__in'] = directionsIdIn.join(COLLECTION_FORMATS.csv);
            }

            if (propertiesId !== undefined) {
                localVarQueryParameter['properties__id'] = propertiesId;
            }

            if (propertiesIdIn) {
                localVarQueryParameter['properties__id__in'] = propertiesIdIn.join(COLLECTION_FORMATS.csv);
            }

            if (propertiesIdContains) {
                localVarQueryParameter['properties__id__contains'] = propertiesIdContains.join(COLLECTION_FORMATS.csv);
            }

            if (averageRatingGte !== undefined) {
                localVarQueryParameter['average_rating__gte'] = averageRatingGte;
            }

            if (multiselectPropertiesId !== undefined) {
                localVarQueryParameter['multiselect_properties__id'] = multiselectPropertiesId;
            }

            if (discountPriceOrPriceLte !== undefined) {
                localVarQueryParameter['discount_price_or_price__lte'] = discountPriceOrPriceLte;
            }

            if (discountPriceOrPriceGte !== undefined) {
                localVarQueryParameter['discount_price_or_price__gte'] = discountPriceOrPriceGte;
            }

            if (discountPriceOrPriceLt !== undefined) {
                localVarQueryParameter['discount_price_or_price__lt'] = discountPriceOrPriceLt;
            }

            if (discountPriceOrPriceGt !== undefined) {
                localVarQueryParameter['discount_price_or_price__gt'] = discountPriceOrPriceGt;
            }

            if (alcoholContentLte !== undefined) {
                localVarQueryParameter['alcohol_content__lte'] = alcoholContentLte;
            }

            if (alcoholContentGte !== undefined) {
                localVarQueryParameter['alcohol_content__gte'] = alcoholContentGte;
            }

            if (alcoholContentLt !== undefined) {
                localVarQueryParameter['alcohol_content__lt'] = alcoholContentLt;
            }

            if (alcoholContentGt !== undefined) {
                localVarQueryParameter['alcohol_content__gt'] = alcoholContentGt;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {number} [priceLt] 
         * @param {number} [priceLte] 
         * @param {number} [priceGt] 
         * @param {number} [priceGte] 
         * @param {number} [category] 
         * @param {number} [parent] 
         * @param {number} [subcategory] 
         * @param {string} [directionsIdIn] 
         * @param {number} [propertiesId] 
         * @param {string} [propertiesIdIn] 
         * @param {string} [propertiesIdContains] 
         * @param {number} [averageRatingGte] 
         * @param {string} [multiselectPropertiesId] 
         * @param {number} [discountPriceOrPriceLte] 
         * @param {number} [discountPriceOrPriceGte] 
         * @param {number} [discountPriceOrPriceLt] 
         * @param {number} [discountPriceOrPriceGt] 
         * @param {number} [alcoholContentLte] 
         * @param {number} [alcoholContentGte] 
         * @param {number} [alcoholContentLt] 
         * @param {number} [alcoholContentGt] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, name?: string, price?: number, priceLt?: number, priceLte?: number, priceGt?: number, priceGte?: number, category?: number, parent?: number, subcategory?: number, directionsIdIn?: string, propertiesId?: number, propertiesIdIn?: string, propertiesIdContains?: string, averageRatingGte?: number, multiselectPropertiesId?: string, discountPriceOrPriceLte?: number, discountPriceOrPriceGte?: number, discountPriceOrPriceLt?: number, discountPriceOrPriceGt?: number, alcoholContentLte?: number, alcoholContentGte?: number, alcoholContentLt?: number, alcoholContentGt?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsListLikes', 'id', id)
            const localVarPath = `/products/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (priceLt !== undefined) {
                localVarQueryParameter['price__lt'] = priceLt;
            }

            if (priceLte !== undefined) {
                localVarQueryParameter['price__lte'] = priceLte;
            }

            if (priceGt !== undefined) {
                localVarQueryParameter['price__gt'] = priceGt;
            }

            if (priceGte !== undefined) {
                localVarQueryParameter['price__gte'] = priceGte;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (parent !== undefined) {
                localVarQueryParameter['parent'] = parent;
            }

            if (subcategory !== undefined) {
                localVarQueryParameter['subcategory'] = subcategory;
            }

            if (directionsIdIn !== undefined) {
                localVarQueryParameter['directions__id__in'] = directionsIdIn;
            }

            if (propertiesId !== undefined) {
                localVarQueryParameter['properties__id'] = propertiesId;
            }

            if (propertiesIdIn !== undefined) {
                localVarQueryParameter['properties__id__in'] = propertiesIdIn;
            }

            if (propertiesIdContains !== undefined) {
                localVarQueryParameter['properties__id__contains'] = propertiesIdContains;
            }

            if (averageRatingGte !== undefined) {
                localVarQueryParameter['average_rating__gte'] = averageRatingGte;
            }

            if (multiselectPropertiesId !== undefined) {
                localVarQueryParameter['multiselect_properties__id'] = multiselectPropertiesId;
            }

            if (discountPriceOrPriceLte !== undefined) {
                localVarQueryParameter['discount_price_or_price__lte'] = discountPriceOrPriceLte;
            }

            if (discountPriceOrPriceGte !== undefined) {
                localVarQueryParameter['discount_price_or_price__gte'] = discountPriceOrPriceGte;
            }

            if (discountPriceOrPriceLt !== undefined) {
                localVarQueryParameter['discount_price_or_price__lt'] = discountPriceOrPriceLt;
            }

            if (discountPriceOrPriceGt !== undefined) {
                localVarQueryParameter['discount_price_or_price__gt'] = discountPriceOrPriceGt;
            }

            if (alcoholContentLte !== undefined) {
                localVarQueryParameter['alcohol_content__lte'] = alcoholContentLte;
            }

            if (alcoholContentGte !== undefined) {
                localVarQueryParameter['alcohol_content__gte'] = alcoholContentGte;
            }

            if (alcoholContentLt !== undefined) {
                localVarQueryParameter['alcohol_content__lt'] = alcoholContentLt;
            }

            if (alcoholContentGt !== undefined) {
                localVarQueryParameter['alcohol_content__gt'] = alcoholContentGt;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsPartialUpdate: async (id: number, data: Product, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productsPartialUpdate', 'data', data)
            const localVarPath = `/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsPriceLimitsList: async (category?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/price_limits/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsRead', 'id', id)
            const localVarPath = `/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleFavoriteCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsToggleFavoriteCreate', 'id', id)
            const localVarPath = `/products/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleFavoriteDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsToggleFavoriteDelete', 'id', id)
            const localVarPath = `/products/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsToggleLikeCreate', 'id', id)
            const localVarPath = `/products/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsToggleLikeDelete', 'id', id)
            const localVarPath = `/products/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdate: async (id: number, data: Product, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productsUpdate', 'data', data)
            const localVarPath = `/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [category] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsAlcoholContentLimitsList(category?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlcoholContentsLimit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsAlcoholContentLimitsList(category, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCreate(data: Product, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {number} [priceLt] 
         * @param {number} [priceLte] 
         * @param {number} [priceGt] 
         * @param {number} [priceGte] 
         * @param {number} [category] 
         * @param {number} [parent] 
         * @param {number} [subcategory] 
         * @param {Array<number>} [directionsIdIn] 
         * @param {number} [propertiesId] 
         * @param {Array<number>} [propertiesIdIn] 
         * @param {Array<number>} [propertiesIdContains] 
         * @param {number} [averageRatingGte] 
         * @param {string} [multiselectPropertiesId] 
         * @param {number} [discountPriceOrPriceLte] 
         * @param {number} [discountPriceOrPriceGte] 
         * @param {number} [discountPriceOrPriceLt] 
         * @param {number} [discountPriceOrPriceGt] 
         * @param {number} [alcoholContentLte] 
         * @param {number} [alcoholContentGte] 
         * @param {number} [alcoholContentLt] 
         * @param {number} [alcoholContentGt] 
         * @param {string} [ordering] Доступные варианты: name, price, discount_percent, discount_price, created, sort_order, discount_price_or_price
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsList(isLikedByUser?: boolean, likesCountGte?: number, isInUserFavorites?: string, name?: string, price?: number, priceLt?: number, priceLte?: number, priceGt?: number, priceGte?: number, category?: number, parent?: number, subcategory?: number, directionsIdIn?: Array<number>, propertiesId?: number, propertiesIdIn?: Array<number>, propertiesIdContains?: Array<number>, averageRatingGte?: number, multiselectPropertiesId?: string, discountPriceOrPriceLte?: number, discountPriceOrPriceGte?: number, discountPriceOrPriceLt?: number, discountPriceOrPriceGt?: number, alcoholContentLte?: number, alcoholContentGte?: number, alcoholContentLt?: number, alcoholContentGt?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20044>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsList(isLikedByUser, likesCountGte, isInUserFavorites, name, price, priceLt, priceLte, priceGt, priceGte, category, parent, subcategory, directionsIdIn, propertiesId, propertiesIdIn, propertiesIdContains, averageRatingGte, multiselectPropertiesId, discountPriceOrPriceLte, discountPriceOrPriceGte, discountPriceOrPriceLt, discountPriceOrPriceGt, alcoholContentLte, alcoholContentGte, alcoholContentLt, alcoholContentGt, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {number} [priceLt] 
         * @param {number} [priceLte] 
         * @param {number} [priceGt] 
         * @param {number} [priceGte] 
         * @param {number} [category] 
         * @param {number} [parent] 
         * @param {number} [subcategory] 
         * @param {string} [directionsIdIn] 
         * @param {number} [propertiesId] 
         * @param {string} [propertiesIdIn] 
         * @param {string} [propertiesIdContains] 
         * @param {number} [averageRatingGte] 
         * @param {string} [multiselectPropertiesId] 
         * @param {number} [discountPriceOrPriceLte] 
         * @param {number} [discountPriceOrPriceGte] 
         * @param {number} [discountPriceOrPriceLt] 
         * @param {number} [discountPriceOrPriceGt] 
         * @param {number} [alcoholContentLte] 
         * @param {number} [alcoholContentGte] 
         * @param {number} [alcoholContentLt] 
         * @param {number} [alcoholContentGt] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, name?: string, price?: number, priceLt?: number, priceLte?: number, priceGt?: number, priceGte?: number, category?: number, parent?: number, subcategory?: number, directionsIdIn?: string, propertiesId?: number, propertiesIdIn?: string, propertiesIdContains?: string, averageRatingGte?: number, multiselectPropertiesId?: string, discountPriceOrPriceLte?: number, discountPriceOrPriceGte?: number, discountPriceOrPriceLt?: number, discountPriceOrPriceGt?: number, alcoholContentLte?: number, alcoholContentGte?: number, alcoholContentLt?: number, alcoholContentGt?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, name, price, priceLt, priceLte, priceGt, priceGte, category, parent, subcategory, directionsIdIn, propertiesId, propertiesIdIn, propertiesIdContains, averageRatingGte, multiselectPropertiesId, discountPriceOrPriceLte, discountPriceOrPriceGte, discountPriceOrPriceLt, discountPriceOrPriceGt, alcoholContentLte, alcoholContentGte, alcoholContentLt, alcoholContentGt, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsPartialUpdate(id: number, data: Product, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [category] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsPriceLimitsList(category?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPriceLimit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsPriceLimitsList(category, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsToggleFavoriteCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsToggleFavoriteCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsToggleFavoriteDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsToggleFavoriteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdate(id: number, data: Product, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [category] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsAlcoholContentLimitsList(category?: string, page?: number, limit?: number, options?: any): AxiosPromise<AlcoholContentsLimit> {
            return localVarFp.productsAlcoholContentLimitsList(category, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreate(data: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.productsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {number} [priceLt] 
         * @param {number} [priceLte] 
         * @param {number} [priceGt] 
         * @param {number} [priceGte] 
         * @param {number} [category] 
         * @param {number} [parent] 
         * @param {number} [subcategory] 
         * @param {Array<number>} [directionsIdIn] 
         * @param {number} [propertiesId] 
         * @param {Array<number>} [propertiesIdIn] 
         * @param {Array<number>} [propertiesIdContains] 
         * @param {number} [averageRatingGte] 
         * @param {string} [multiselectPropertiesId] 
         * @param {number} [discountPriceOrPriceLte] 
         * @param {number} [discountPriceOrPriceGte] 
         * @param {number} [discountPriceOrPriceLt] 
         * @param {number} [discountPriceOrPriceGt] 
         * @param {number} [alcoholContentLte] 
         * @param {number} [alcoholContentGte] 
         * @param {number} [alcoholContentLt] 
         * @param {number} [alcoholContentGt] 
         * @param {string} [ordering] Доступные варианты: name, price, discount_percent, discount_price, created, sort_order, discount_price_or_price
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsList(isLikedByUser?: boolean, likesCountGte?: number, isInUserFavorites?: string, name?: string, price?: number, priceLt?: number, priceLte?: number, priceGt?: number, priceGte?: number, category?: number, parent?: number, subcategory?: number, directionsIdIn?: Array<number>, propertiesId?: number, propertiesIdIn?: Array<number>, propertiesIdContains?: Array<number>, averageRatingGte?: number, multiselectPropertiesId?: string, discountPriceOrPriceLte?: number, discountPriceOrPriceGte?: number, discountPriceOrPriceLt?: number, discountPriceOrPriceGt?: number, alcoholContentLte?: number, alcoholContentGte?: number, alcoholContentLt?: number, alcoholContentGt?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20044> {
            return localVarFp.productsList(isLikedByUser, likesCountGte, isInUserFavorites, name, price, priceLt, priceLte, priceGt, priceGte, category, parent, subcategory, directionsIdIn, propertiesId, propertiesIdIn, propertiesIdContains, averageRatingGte, multiselectPropertiesId, discountPriceOrPriceLte, discountPriceOrPriceGte, discountPriceOrPriceLt, discountPriceOrPriceGt, alcoholContentLte, alcoholContentGte, alcoholContentLt, alcoholContentGt, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {string} [name] 
         * @param {number} [price] 
         * @param {number} [priceLt] 
         * @param {number} [priceLte] 
         * @param {number} [priceGt] 
         * @param {number} [priceGte] 
         * @param {number} [category] 
         * @param {number} [parent] 
         * @param {number} [subcategory] 
         * @param {string} [directionsIdIn] 
         * @param {number} [propertiesId] 
         * @param {string} [propertiesIdIn] 
         * @param {string} [propertiesIdContains] 
         * @param {number} [averageRatingGte] 
         * @param {string} [multiselectPropertiesId] 
         * @param {number} [discountPriceOrPriceLte] 
         * @param {number} [discountPriceOrPriceGte] 
         * @param {number} [discountPriceOrPriceLt] 
         * @param {number} [discountPriceOrPriceGt] 
         * @param {number} [alcoholContentLte] 
         * @param {number} [alcoholContentGte] 
         * @param {number} [alcoholContentLt] 
         * @param {number} [alcoholContentGt] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, name?: string, price?: number, priceLt?: number, priceLte?: number, priceGt?: number, priceGte?: number, category?: number, parent?: number, subcategory?: number, directionsIdIn?: string, propertiesId?: number, propertiesIdIn?: string, propertiesIdContains?: string, averageRatingGte?: number, multiselectPropertiesId?: string, discountPriceOrPriceLte?: number, discountPriceOrPriceGte?: number, discountPriceOrPriceLt?: number, discountPriceOrPriceGt?: number, alcoholContentLte?: number, alcoholContentGte?: number, alcoholContentLt?: number, alcoholContentGt?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.productsListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, name, price, priceLt, priceLte, priceGt, priceGte, category, parent, subcategory, directionsIdIn, propertiesId, propertiesIdIn, propertiesIdContains, averageRatingGte, multiselectPropertiesId, discountPriceOrPriceLte, discountPriceOrPriceGte, discountPriceOrPriceLt, discountPriceOrPriceGt, alcoholContentLte, alcoholContentGte, alcoholContentLt, alcoholContentGt, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsPartialUpdate(id: number, data: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.productsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [category] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsPriceLimitsList(category?: string, page?: number, limit?: number, options?: any): AxiosPromise<ProductPriceLimit> {
            return localVarFp.productsPriceLimitsList(category, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsRead(id: number, options?: any): AxiosPromise<Product> {
            return localVarFp.productsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleFavoriteCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsToggleFavoriteCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleFavoriteDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsToggleFavoriteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Продукт.
         * @param {Product} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdate(id: number, data: Product, options?: any): AxiosPromise<Product> {
            return localVarFp.productsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productsAlcoholContentLimitsList operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsAlcoholContentLimitsListRequest
 */
export interface ProductsApiProductsAlcoholContentLimitsListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsAlcoholContentLimitsList
     */
    readonly category?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsApiProductsAlcoholContentLimitsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductsApiProductsAlcoholContentLimitsList
     */
    readonly limit?: number
}

/**
 * Request parameters for productsCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsCreateRequest
 */
export interface ProductsApiProductsCreateRequest {
    /**
     * 
     * @type {Product}
     * @memberof ProductsApiProductsCreate
     */
    readonly data: Product
}

/**
 * Request parameters for productsDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsDeleteRequest
 */
export interface ProductsApiProductsDeleteRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsDelete
     */
    readonly id: number
}

/**
 * Request parameters for productsList operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsListRequest
 */
export interface ProductsApiProductsListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsApiProductsList
     */
    readonly isLikedByUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsList
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsList
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly priceLt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly priceLte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly priceGt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly priceGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly category?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly parent?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly subcategory?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiProductsList
     */
    readonly directionsIdIn?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly propertiesId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiProductsList
     */
    readonly propertiesIdIn?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsApiProductsList
     */
    readonly propertiesIdContains?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly averageRatingGte?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsList
     */
    readonly multiselectPropertiesId?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly discountPriceOrPriceLte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly discountPriceOrPriceGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly discountPriceOrPriceLt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly discountPriceOrPriceGt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly alcoholContentLte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly alcoholContentGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly alcoholContentLt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly alcoholContentGt?: number

    /**
     * Доступные варианты: name, price, discount_percent, discount_price, created, sort_order, discount_price_or_price
     * @type {string}
     * @memberof ProductsApiProductsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly limit?: number
}

/**
 * Request parameters for productsListLikes operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsListLikesRequest
 */
export interface ProductsApiProductsListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly priceLt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly priceLte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly priceGt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly priceGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly category?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly parent?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly subcategory?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly directionsIdIn?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly propertiesId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly propertiesIdIn?: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly propertiesIdContains?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly averageRatingGte?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly multiselectPropertiesId?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly discountPriceOrPriceLte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly discountPriceOrPriceGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly discountPriceOrPriceLt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly discountPriceOrPriceGt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly alcoholContentLte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly alcoholContentGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly alcoholContentLt?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly alcoholContentGt?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProductsApiProductsListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductsApiProductsListLikes
     */
    readonly limit?: number
}

/**
 * Request parameters for productsPartialUpdate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsPartialUpdateRequest
 */
export interface ProductsApiProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Product}
     * @memberof ProductsApiProductsPartialUpdate
     */
    readonly data: Product
}

/**
 * Request parameters for productsPriceLimitsList operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsPriceLimitsListRequest
 */
export interface ProductsApiProductsPriceLimitsListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsPriceLimitsList
     */
    readonly category?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsApiProductsPriceLimitsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductsApiProductsPriceLimitsList
     */
    readonly limit?: number
}

/**
 * Request parameters for productsRead operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsReadRequest
 */
export interface ProductsApiProductsReadRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for productsToggleFavoriteCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsToggleFavoriteCreateRequest
 */
export interface ProductsApiProductsToggleFavoriteCreateRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsToggleFavoriteCreate
     */
    readonly id: number
}

/**
 * Request parameters for productsToggleFavoriteDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsToggleFavoriteDeleteRequest
 */
export interface ProductsApiProductsToggleFavoriteDeleteRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsToggleFavoriteDelete
     */
    readonly id: number
}

/**
 * Request parameters for productsToggleLikeCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsToggleLikeCreateRequest
 */
export interface ProductsApiProductsToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for productsToggleLikeDelete operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsToggleLikeDeleteRequest
 */
export interface ProductsApiProductsToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsToggleLikeDelete
     */
    readonly id: number
}

/**
 * Request parameters for productsUpdate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsUpdateRequest
 */
export interface ProductsApiProductsUpdateRequest {
    /**
     * A unique integer value identifying this Продукт.
     * @type {number}
     * @memberof ProductsApiProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Product}
     * @memberof ProductsApiProductsUpdate
     */
    readonly data: Product
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {ProductsApiProductsAlcoholContentLimitsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsAlcoholContentLimitsList(requestParameters: ProductsApiProductsAlcoholContentLimitsListRequest = {}, options?: any) {
        return ProductsApiFp(this.configuration).productsAlcoholContentLimitsList(requestParameters.category, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCreate(requestParameters: ProductsApiProductsCreateRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsDelete(requestParameters: ProductsApiProductsDeleteRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsList(requestParameters: ProductsApiProductsListRequest = {}, options?: any) {
        return ProductsApiFp(this.configuration).productsList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.name, requestParameters.price, requestParameters.priceLt, requestParameters.priceLte, requestParameters.priceGt, requestParameters.priceGte, requestParameters.category, requestParameters.parent, requestParameters.subcategory, requestParameters.directionsIdIn, requestParameters.propertiesId, requestParameters.propertiesIdIn, requestParameters.propertiesIdContains, requestParameters.averageRatingGte, requestParameters.multiselectPropertiesId, requestParameters.discountPriceOrPriceLte, requestParameters.discountPriceOrPriceGte, requestParameters.discountPriceOrPriceLt, requestParameters.discountPriceOrPriceGt, requestParameters.alcoholContentLte, requestParameters.alcoholContentGte, requestParameters.alcoholContentLt, requestParameters.alcoholContentGt, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {ProductsApiProductsListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsListLikes(requestParameters: ProductsApiProductsListLikesRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.name, requestParameters.price, requestParameters.priceLt, requestParameters.priceLte, requestParameters.priceGt, requestParameters.priceGte, requestParameters.category, requestParameters.parent, requestParameters.subcategory, requestParameters.directionsIdIn, requestParameters.propertiesId, requestParameters.propertiesIdIn, requestParameters.propertiesIdContains, requestParameters.averageRatingGte, requestParameters.multiselectPropertiesId, requestParameters.discountPriceOrPriceLte, requestParameters.discountPriceOrPriceGte, requestParameters.discountPriceOrPriceLt, requestParameters.discountPriceOrPriceGt, requestParameters.alcoholContentLte, requestParameters.alcoholContentGte, requestParameters.alcoholContentLt, requestParameters.alcoholContentGt, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsPartialUpdate(requestParameters: ProductsApiProductsPartialUpdateRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsPriceLimitsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsPriceLimitsList(requestParameters: ProductsApiProductsPriceLimitsListRequest = {}, options?: any) {
        return ProductsApiFp(this.configuration).productsPriceLimitsList(requestParameters.category, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsRead(requestParameters: ProductsApiProductsReadRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsToggleFavoriteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsToggleFavoriteCreate(requestParameters: ProductsApiProductsToggleFavoriteCreateRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsToggleFavoriteCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsToggleFavoriteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsToggleFavoriteDelete(requestParameters: ProductsApiProductsToggleFavoriteDeleteRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsToggleFavoriteDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsToggleLikeCreate(requestParameters: ProductsApiProductsToggleLikeCreateRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsToggleLikeDelete(requestParameters: ProductsApiProductsToggleLikeDeleteRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdate(requestParameters: ProductsApiProductsUpdateRequest, options?: any) {
        return ProductsApiFp(this.configuration).productsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsCommentsApi - axios parameter creator
 * @export
 */
export const ProductsCommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProductComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsCreate: async (data: ProductComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('productsCommentsCreate', 'data', data)
            const localVarPath = `/products_comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [productId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsList: async (isLikedByUser?: boolean, likesCountGte?: number, productId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products_comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product__id'] = productId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment__id'] = parentCommentId;
            }

            if (parentCommentIdIsnull !== undefined) {
                localVarQueryParameter['parent_comment__id__isnull'] = parentCommentIdIsnull;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile__id'] = profileId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [productId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, productId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsCommentsListLikes', 'id', id)
            const localVarPath = `/products_comments/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (productId !== undefined) {
                localVarQueryParameter['product__id'] = productId;
            }

            if (parentCommentId !== undefined) {
                localVarQueryParameter['parent_comment__id'] = parentCommentId;
            }

            if (parentCommentIdIsnull !== undefined) {
                localVarQueryParameter['parent_comment__id__isnull'] = parentCommentIdIsnull;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile__id'] = profileId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsCommentsRead', 'id', id)
            const localVarPath = `/products_comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsCommentsToggleLikeCreate', 'id', id)
            const localVarPath = `/products_comments/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsCommentsToggleLikeDelete', 'id', id)
            const localVarPath = `/products_comments/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsCommentsApi - functional programming interface
 * @export
 */
export const ProductsCommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsCommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProductComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCommentsCreate(data: ProductComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCommentsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [productId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCommentsList(isLikedByUser?: boolean, likesCountGte?: number, productId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20045>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCommentsList(isLikedByUser, likesCountGte, productId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [productId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCommentsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, productId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCommentsListLikes(id, isLikedByUser, likesCountGte, productId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCommentsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductComment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCommentsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCommentsToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCommentsToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCommentsToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCommentsToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsCommentsApi - factory interface
 * @export
 */
export const ProductsCommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsCommentsApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductComment} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsCreate(data: ProductComment, options?: any): AxiosPromise<ProductComment> {
            return localVarFp.productsCommentsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [productId] 
         * @param {number} [parentCommentId] 
         * @param {boolean} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsList(isLikedByUser?: boolean, likesCountGte?: number, productId?: number, parentCommentId?: number, parentCommentIdIsnull?: boolean, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): AxiosPromise<InlineResponse20045> {
            return localVarFp.productsCommentsList(isLikedByUser, likesCountGte, productId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {number} [productId] 
         * @param {number} [parentCommentId] 
         * @param {string} [parentCommentIdIsnull] 
         * @param {number} [profileId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [count] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, productId?: number, parentCommentId?: number, parentCommentIdIsnull?: string, profileId?: number, ordering?: string, page?: number, count?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.productsCommentsListLikes(id, isLikedByUser, likesCountGte, productId, parentCommentId, parentCommentIdIsnull, profileId, ordering, page, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsRead(id: number, options?: any): AxiosPromise<ProductComment> {
            return localVarFp.productsCommentsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsCommentsToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Комментарий к продукту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCommentsToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.productsCommentsToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productsCommentsCreate operation in ProductsCommentsApi.
 * @export
 * @interface ProductsCommentsApiProductsCommentsCreateRequest
 */
export interface ProductsCommentsApiProductsCommentsCreateRequest {
    /**
     * 
     * @type {ProductComment}
     * @memberof ProductsCommentsApiProductsCommentsCreate
     */
    readonly data: ProductComment
}

/**
 * Request parameters for productsCommentsList operation in ProductsCommentsApi.
 * @export
 * @interface ProductsCommentsApiProductsCommentsListRequest
 */
export interface ProductsCommentsApiProductsCommentsListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly isLikedByUser?: boolean

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly productId?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly parentCommentId?: number

    /**
     * 
     * @type {boolean}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly parentCommentIdIsnull?: boolean

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly profileId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsList
     */
    readonly count?: number
}

/**
 * Request parameters for productsCommentsListLikes operation in ProductsCommentsApi.
 * @export
 * @interface ProductsCommentsApiProductsCommentsListLikesRequest
 */
export interface ProductsCommentsApiProductsCommentsListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly productId?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly parentCommentId?: number

    /**
     * 
     * @type {string}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly parentCommentIdIsnull?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly profileId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsListLikes
     */
    readonly count?: number
}

/**
 * Request parameters for productsCommentsRead operation in ProductsCommentsApi.
 * @export
 * @interface ProductsCommentsApiProductsCommentsReadRequest
 */
export interface ProductsCommentsApiProductsCommentsReadRequest {
    /**
     * A unique integer value identifying this Комментарий к продукту.
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsRead
     */
    readonly id: number
}

/**
 * Request parameters for productsCommentsToggleLikeCreate operation in ProductsCommentsApi.
 * @export
 * @interface ProductsCommentsApiProductsCommentsToggleLikeCreateRequest
 */
export interface ProductsCommentsApiProductsCommentsToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Комментарий к продукту.
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for productsCommentsToggleLikeDelete operation in ProductsCommentsApi.
 * @export
 * @interface ProductsCommentsApiProductsCommentsToggleLikeDeleteRequest
 */
export interface ProductsCommentsApiProductsCommentsToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Комментарий к продукту.
     * @type {number}
     * @memberof ProductsCommentsApiProductsCommentsToggleLikeDelete
     */
    readonly id: number
}

/**
 * ProductsCommentsApi - object-oriented interface
 * @export
 * @class ProductsCommentsApi
 * @extends {BaseAPI}
 */
export class ProductsCommentsApi extends BaseAPI {
    /**
     * 
     * @param {ProductsCommentsApiProductsCommentsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsCommentsApi
     */
    public productsCommentsCreate(requestParameters: ProductsCommentsApiProductsCommentsCreateRequest, options?: any) {
        return ProductsCommentsApiFp(this.configuration).productsCommentsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsCommentsApiProductsCommentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsCommentsApi
     */
    public productsCommentsList(requestParameters: ProductsCommentsApiProductsCommentsListRequest = {}, options?: any) {
        return ProductsCommentsApiFp(this.configuration).productsCommentsList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.productId, requestParameters.parentCommentId, requestParameters.parentCommentIdIsnull, requestParameters.profileId, requestParameters.ordering, requestParameters.page, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {ProductsCommentsApiProductsCommentsListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsCommentsApi
     */
    public productsCommentsListLikes(requestParameters: ProductsCommentsApiProductsCommentsListLikesRequest, options?: any) {
        return ProductsCommentsApiFp(this.configuration).productsCommentsListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.productId, requestParameters.parentCommentId, requestParameters.parentCommentIdIsnull, requestParameters.profileId, requestParameters.ordering, requestParameters.page, requestParameters.count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsCommentsApiProductsCommentsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsCommentsApi
     */
    public productsCommentsRead(requestParameters: ProductsCommentsApiProductsCommentsReadRequest, options?: any) {
        return ProductsCommentsApiFp(this.configuration).productsCommentsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsCommentsApiProductsCommentsToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsCommentsApi
     */
    public productsCommentsToggleLikeCreate(requestParameters: ProductsCommentsApiProductsCommentsToggleLikeCreateRequest, options?: any) {
        return ProductsCommentsApiFp(this.configuration).productsCommentsToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsCommentsApiProductsCommentsToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsCommentsApi
     */
    public productsCommentsToggleLikeDelete(requestParameters: ProductsCommentsApiProductsCommentsToggleLikeDeleteRequest, options?: any) {
        return ProductsCommentsApiFp(this.configuration).productsCommentsToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileCreate: async (data: Profile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('profileCreate', 'data', data)
            const localVarPath = `/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileDelete', 'id', id)
            const localVarPath = `/profile/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetCurrentProfile: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/get_current_profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileImageCreate: async (image: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('profileImageCreate', 'image', image)
            const localVarPath = `/profile/image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileImageDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePartialUpdate: async (id: number, data: Profile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profilePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('profilePartialUpdate', 'data', data)
            const localVarPath = `/profile/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileRead', 'id', id)
            const localVarPath = `/profile/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdate: async (id: number, data: Profile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('profileUpdate', 'data', data)
            const localVarPath = `/profile/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileCreate(data: Profile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetCurrentProfile(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetCurrentProfile(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileImageCreate(image: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileImageCreate(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileImageDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileImageDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20046>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilePartialUpdate(id: number, data: Profile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilePartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdate(id: number, data: Profile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileCreate(data: Profile, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.profileDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetCurrentProfile(page?: number, limit?: number, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileGetCurrentProfile(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileImageCreate(image: any, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileImageCreate(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileImageDelete(options?: any): AxiosPromise<void> {
            return localVarFp.profileImageDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20046> {
            return localVarFp.profileList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePartialUpdate(id: number, data: Profile, options?: any): AxiosPromise<Profile> {
            return localVarFp.profilePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileRead(id: number, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Профиль.
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdate(id: number, data: Profile, options?: any): AxiosPromise<Profile> {
            return localVarFp.profileUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for profileCreate operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileCreateRequest
 */
export interface ProfileApiProfileCreateRequest {
    /**
     * 
     * @type {Profile}
     * @memberof ProfileApiProfileCreate
     */
    readonly data: Profile
}

/**
 * Request parameters for profileDelete operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileDeleteRequest
 */
export interface ProfileApiProfileDeleteRequest {
    /**
     * A unique integer value identifying this Профиль.
     * @type {number}
     * @memberof ProfileApiProfileDelete
     */
    readonly id: number
}

/**
 * Request parameters for profileGetCurrentProfile operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileGetCurrentProfileRequest
 */
export interface ProfileApiProfileGetCurrentProfileRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProfileApiProfileGetCurrentProfile
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProfileApiProfileGetCurrentProfile
     */
    readonly limit?: number
}

/**
 * Request parameters for profileImageCreate operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileImageCreateRequest
 */
export interface ProfileApiProfileImageCreateRequest {
    /**
     * 
     * @type {any}
     * @memberof ProfileApiProfileImageCreate
     */
    readonly image: any
}

/**
 * Request parameters for profileList operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileListRequest
 */
export interface ProfileApiProfileListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProfileApiProfileList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProfileApiProfileList
     */
    readonly limit?: number
}

/**
 * Request parameters for profilePartialUpdate operation in ProfileApi.
 * @export
 * @interface ProfileApiProfilePartialUpdateRequest
 */
export interface ProfileApiProfilePartialUpdateRequest {
    /**
     * A unique integer value identifying this Профиль.
     * @type {number}
     * @memberof ProfileApiProfilePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Profile}
     * @memberof ProfileApiProfilePartialUpdate
     */
    readonly data: Profile
}

/**
 * Request parameters for profileRead operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileReadRequest
 */
export interface ProfileApiProfileReadRequest {
    /**
     * A unique integer value identifying this Профиль.
     * @type {number}
     * @memberof ProfileApiProfileRead
     */
    readonly id: number
}

/**
 * Request parameters for profileUpdate operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileUpdateRequest
 */
export interface ProfileApiProfileUpdateRequest {
    /**
     * A unique integer value identifying this Профиль.
     * @type {number}
     * @memberof ProfileApiProfileUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Profile}
     * @memberof ProfileApiProfileUpdate
     */
    readonly data: Profile
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {ProfileApiProfileCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileCreate(requestParameters: ProfileApiProfileCreateRequest, options?: any) {
        return ProfileApiFp(this.configuration).profileCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileDelete(requestParameters: ProfileApiProfileDeleteRequest, options?: any) {
        return ProfileApiFp(this.configuration).profileDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileGetCurrentProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetCurrentProfile(requestParameters: ProfileApiProfileGetCurrentProfileRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).profileGetCurrentProfile(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileImageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileImageCreate(requestParameters: ProfileApiProfileImageCreateRequest, options?: any) {
        return ProfileApiFp(this.configuration).profileImageCreate(requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileImageDelete(options?: any) {
        return ProfileApiFp(this.configuration).profileImageDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileList(requestParameters: ProfileApiProfileListRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).profileList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfilePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profilePartialUpdate(requestParameters: ProfileApiProfilePartialUpdateRequest, options?: any) {
        return ProfileApiFp(this.configuration).profilePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileRead(requestParameters: ProfileApiProfileReadRequest, options?: any) {
        return ProfileApiFp(this.configuration).profileRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdate(requestParameters: ProfileApiProfileUpdateRequest, options?: any) {
        return ProfileApiFp(this.configuration).profileUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileEmailsApi - axios parameter creator
 * @export
 */
export const ProfileEmailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} mailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileEmailsRead: async (mailType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailType' is not null or undefined
            assertParamExists('profileEmailsRead', 'mailType', mailType)
            const localVarPath = `/profile_emails/{mail_type}/`
                .replace(`{${"mail_type"}}`, encodeURIComponent(String(mailType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileEmailsApi - functional programming interface
 * @export
 */
export const ProfileEmailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileEmailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} mailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileEmailsRead(mailType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileEmailsRead(mailType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileEmailsApi - factory interface
 * @export
 */
export const ProfileEmailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileEmailsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} mailType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileEmailsRead(mailType: string, options?: any): AxiosPromise<void> {
            return localVarFp.profileEmailsRead(mailType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for profileEmailsRead operation in ProfileEmailsApi.
 * @export
 * @interface ProfileEmailsApiProfileEmailsReadRequest
 */
export interface ProfileEmailsApiProfileEmailsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileEmailsApiProfileEmailsRead
     */
    readonly mailType: string
}

/**
 * ProfileEmailsApi - object-oriented interface
 * @export
 * @class ProfileEmailsApi
 * @extends {BaseAPI}
 */
export class ProfileEmailsApi extends BaseAPI {
    /**
     * 
     * @param {ProfileEmailsApiProfileEmailsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileEmailsApi
     */
    public profileEmailsRead(requestParameters: ProfileEmailsApiProfileEmailsReadRequest, options?: any) {
        return ProfileEmailsApiFp(this.configuration).profileEmailsRead(requestParameters.mailType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardBusinessesList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/award_businesses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Бизнес.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardBusinessesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsAwardBusinessesRead', 'id', id)
            const localVarPath = `/projects/award_businesses/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardNominationsList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/award_nominations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Номинация.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardNominationsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsAwardNominationsRead', 'id', id)
            const localVarPath = `/projects/award_nominations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [awardId] 
         * @param {number} [nominationId] 
         * @param {'nominee' | 'winner'} [status] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardParticipantsList: async (awardId?: number, nominationId?: number, status?: 'nominee' | 'winner', ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/award_participants/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (awardId !== undefined) {
                localVarQueryParameter['award__id'] = awardId;
            }

            if (nominationId !== undefined) {
                localVarQueryParameter['nomination__id'] = nominationId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Участник премии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardParticipantsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsAwardParticipantsRead', 'id', id)
            const localVarPath = `/projects/award_participants/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [businessId] 
         * @param {number} [awardId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardWinnersList: async (businessId?: number, awardId?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/award_winners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (businessId !== undefined) {
                localVarQueryParameter['business__id'] = businessId;
            }

            if (awardId !== undefined) {
                localVarQueryParameter['award__id'] = awardId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Победитель премии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardWinnersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsAwardWinnersRead', 'id', id)
            const localVarPath = `/projects/award_winners/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Премия Ассоль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsBusiness: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsAwardsBusiness', 'id', id)
            const localVarPath = `/projects/awards/{id}/business/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/awards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsPagesList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/awards_pages/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Премия Ассоль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsAwardsRead', 'id', id)
            const localVarPath = `/projects/awards/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesList: async (isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/magazines_articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (magazineId !== undefined) {
                localVarQueryParameter['magazine__id'] = magazineId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesListLikes: async (id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesArticlesListLikes', 'id', id)
            const localVarPath = `/projects/magazines_articles/{id}/list_likes/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (isLikedByUser !== undefined) {
                localVarQueryParameter['is_liked_by_user'] = isLikedByUser;
            }

            if (likesCountGte !== undefined) {
                localVarQueryParameter['likes_count__gte'] = likesCountGte;
            }

            if (isInUserFavorites !== undefined) {
                localVarQueryParameter['is_in_user_favorites'] = isInUserFavorites;
            }

            if (magazineId !== undefined) {
                localVarQueryParameter['magazine__id'] = magazineId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesArticlesRead', 'id', id)
            const localVarPath = `/projects/magazines_articles/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleFavoriteCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesArticlesToggleFavoriteCreate', 'id', id)
            const localVarPath = `/projects/magazines_articles/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleFavoriteDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesArticlesToggleFavoriteDelete', 'id', id)
            const localVarPath = `/projects/magazines_articles/{id}/toggle_favorite/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleLikeCreate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesArticlesToggleLikeCreate', 'id', id)
            const localVarPath = `/projects/magazines_articles/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleLikeDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesArticlesToggleLikeDelete', 'id', id)
            const localVarPath = `/projects/magazines_articles/{id}/toggle_like/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/magazines/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Журнал.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsMagazinesRead', 'id', id)
            const localVarPath = `/projects/magazines/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectsCardsList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/projects_cards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Карточка проекта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectsCardsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectsProjectsCardsRead', 'id', id)
            const localVarPath = `/projects/projects_cards/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsScarletSailsList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/scarlet_sails/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardBusinessesList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20047>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardBusinessesList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Бизнес.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardBusinessesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardBusiness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardBusinessesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardNominationsList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AwardNominations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardNominationsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Номинация.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardNominationsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardNominations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardNominationsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [awardId] 
         * @param {number} [nominationId] 
         * @param {'nominee' | 'winner'} [status] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardParticipantsList(awardId?: number, nominationId?: number, status?: 'nominee' | 'winner', ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20048>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardParticipantsList(awardId, nominationId, status, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Участник премии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardParticipantsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardParticipants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardParticipantsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [businessId] 
         * @param {number} [awardId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardWinnersList(businessId?: number, awardId?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20049>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardWinnersList(businessId, awardId, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Победитель премии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardWinnersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardWinners>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardWinnersRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Премия Ассоль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardsBusiness(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardBusiness>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardsBusiness(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardsList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20050>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardsList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardsPagesList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsAwardPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardsPagesList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Премия Ассоль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsAwardsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Awards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsAwardsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesList(isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20052>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesList(isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MagazineArticle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesToggleFavoriteCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesToggleFavoriteCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesToggleFavoriteDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesToggleFavoriteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesToggleLikeCreate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesToggleLikeCreate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesArticlesToggleLikeDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesArticlesToggleLikeDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20051>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Журнал.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsMagazinesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Magazine>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsMagazinesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectsCardsList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20053>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectsCardsList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Карточка проекта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsProjectsCardsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectCards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsProjectsCardsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsScarletSailsList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsArticlePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsScarletSailsList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardBusinessesList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20047> {
            return localVarFp.projectsAwardBusinessesList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Бизнес.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardBusinessesRead(id: number, options?: any): AxiosPromise<AwardBusiness> {
            return localVarFp.projectsAwardBusinessesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardNominationsList(options?: any): AxiosPromise<Array<AwardNominations>> {
            return localVarFp.projectsAwardNominationsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Номинация.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardNominationsRead(id: number, options?: any): AxiosPromise<AwardNominations> {
            return localVarFp.projectsAwardNominationsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [awardId] 
         * @param {number} [nominationId] 
         * @param {'nominee' | 'winner'} [status] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardParticipantsList(awardId?: number, nominationId?: number, status?: 'nominee' | 'winner', ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20048> {
            return localVarFp.projectsAwardParticipantsList(awardId, nominationId, status, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Участник премии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardParticipantsRead(id: number, options?: any): AxiosPromise<AwardParticipants> {
            return localVarFp.projectsAwardParticipantsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [businessId] 
         * @param {number} [awardId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardWinnersList(businessId?: number, awardId?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20049> {
            return localVarFp.projectsAwardWinnersList(businessId, awardId, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Победитель премии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardWinnersRead(id: number, options?: any): AxiosPromise<AwardWinners> {
            return localVarFp.projectsAwardWinnersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Премия Ассоль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsBusiness(id: number, options?: any): AxiosPromise<AwardBusiness> {
            return localVarFp.projectsAwardsBusiness(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20050> {
            return localVarFp.projectsAwardsList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsPagesList(options?: any): AxiosPromise<ProjectsAwardPage> {
            return localVarFp.projectsAwardsPagesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Премия Ассоль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsAwardsRead(id: number, options?: any): AxiosPromise<Awards> {
            return localVarFp.projectsAwardsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesList(isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20052> {
            return localVarFp.projectsMagazinesArticlesList(isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * List likes for a specific object.
         * @param {number} id News page id
         * @param {string} [isLikedByUser] 
         * @param {number} [likesCountGte] 
         * @param {string} [isInUserFavorites] 
         * @param {number} [magazineId] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesListLikes(id: number, isLikedByUser?: string, likesCountGte?: number, isInUserFavorites?: string, magazineId?: number, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return localVarFp.projectsMagazinesArticlesListLikes(id, isLikedByUser, likesCountGte, isInUserFavorites, magazineId, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesRead(id: number, options?: any): AxiosPromise<MagazineArticle> {
            return localVarFp.projectsMagazinesArticlesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleFavoriteCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectsMagazinesArticlesToggleFavoriteCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleFavoriteDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectsMagazinesArticlesToggleFavoriteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleLikeCreate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectsMagazinesArticlesToggleLikeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статья журнала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesArticlesToggleLikeDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectsMagazinesArticlesToggleLikeDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20051> {
            return localVarFp.projectsMagazinesList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Журнал.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsMagazinesRead(id: number, options?: any): AxiosPromise<Magazine> {
            return localVarFp.projectsMagazinesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectsCardsList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20053> {
            return localVarFp.projectsProjectsCardsList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Карточка проекта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsProjectsCardsRead(id: number, options?: any): AxiosPromise<ProjectCards> {
            return localVarFp.projectsProjectsCardsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsScarletSailsList(options?: any): AxiosPromise<ProjectsArticlePage> {
            return localVarFp.projectsScarletSailsList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for projectsAwardBusinessesList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardBusinessesListRequest
 */
export interface ProjectsApiProjectsAwardBusinessesListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardBusinessesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardBusinessesList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsAwardBusinessesRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardBusinessesReadRequest
 */
export interface ProjectsApiProjectsAwardBusinessesReadRequest {
    /**
     * A unique integer value identifying this Бизнес.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardBusinessesRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsAwardNominationsRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardNominationsReadRequest
 */
export interface ProjectsApiProjectsAwardNominationsReadRequest {
    /**
     * A unique integer value identifying this Номинация.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardNominationsRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsAwardParticipantsList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardParticipantsListRequest
 */
export interface ProjectsApiProjectsAwardParticipantsListRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsAwardParticipantsList
     */
    readonly awardId?: number

    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsAwardParticipantsList
     */
    readonly nominationId?: number

    /**
     * 
     * @type {'nominee' | 'winner'}
     * @memberof ProjectsApiProjectsAwardParticipantsList
     */
    readonly status?: 'nominee' | 'winner'

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProjectsApiProjectsAwardParticipantsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardParticipantsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardParticipantsList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsAwardParticipantsRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardParticipantsReadRequest
 */
export interface ProjectsApiProjectsAwardParticipantsReadRequest {
    /**
     * A unique integer value identifying this Участник премии.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardParticipantsRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsAwardWinnersList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardWinnersListRequest
 */
export interface ProjectsApiProjectsAwardWinnersListRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsAwardWinnersList
     */
    readonly businessId?: number

    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsAwardWinnersList
     */
    readonly awardId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProjectsApiProjectsAwardWinnersList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardWinnersList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardWinnersList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsAwardWinnersRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardWinnersReadRequest
 */
export interface ProjectsApiProjectsAwardWinnersReadRequest {
    /**
     * A unique integer value identifying this Победитель премии.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardWinnersRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsAwardsBusiness operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardsBusinessRequest
 */
export interface ProjectsApiProjectsAwardsBusinessRequest {
    /**
     * A unique integer value identifying this Премия Ассоль.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardsBusiness
     */
    readonly id: number
}

/**
 * Request parameters for projectsAwardsList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardsListRequest
 */
export interface ProjectsApiProjectsAwardsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardsList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsAwardsRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsAwardsReadRequest
 */
export interface ProjectsApiProjectsAwardsReadRequest {
    /**
     * A unique integer value identifying this Премия Ассоль.
     * @type {number}
     * @memberof ProjectsApiProjectsAwardsRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsMagazinesArticlesList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesListRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly magazineId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsMagazinesArticlesListLikes operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesListLikesRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesListLikesRequest {
    /**
     * News page id
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly isLikedByUser?: string

    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly likesCountGte?: number

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly isInUserFavorites?: string

    /**
     * 
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly magazineId?: number

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesListLikes
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsMagazinesArticlesRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesReadRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesReadRequest {
    /**
     * A unique integer value identifying this Статья журнала.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsMagazinesArticlesToggleFavoriteCreate operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesToggleFavoriteCreateRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesToggleFavoriteCreateRequest {
    /**
     * A unique integer value identifying this Статья журнала.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesToggleFavoriteCreate
     */
    readonly id: number
}

/**
 * Request parameters for projectsMagazinesArticlesToggleFavoriteDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesToggleFavoriteDeleteRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesToggleFavoriteDeleteRequest {
    /**
     * A unique integer value identifying this Статья журнала.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesToggleFavoriteDelete
     */
    readonly id: number
}

/**
 * Request parameters for projectsMagazinesArticlesToggleLikeCreate operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesToggleLikeCreateRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesToggleLikeCreateRequest {
    /**
     * A unique integer value identifying this Статья журнала.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesToggleLikeCreate
     */
    readonly id: number
}

/**
 * Request parameters for projectsMagazinesArticlesToggleLikeDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesArticlesToggleLikeDeleteRequest
 */
export interface ProjectsApiProjectsMagazinesArticlesToggleLikeDeleteRequest {
    /**
     * A unique integer value identifying this Статья журнала.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesArticlesToggleLikeDelete
     */
    readonly id: number
}

/**
 * Request parameters for projectsMagazinesList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesListRequest
 */
export interface ProjectsApiProjectsMagazinesListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsMagazinesRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsMagazinesReadRequest
 */
export interface ProjectsApiProjectsMagazinesReadRequest {
    /**
     * A unique integer value identifying this Журнал.
     * @type {number}
     * @memberof ProjectsApiProjectsMagazinesRead
     */
    readonly id: number
}

/**
 * Request parameters for projectsProjectsCardsList operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsProjectsCardsListRequest
 */
export interface ProjectsApiProjectsProjectsCardsListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProjectsApiProjectsProjectsCardsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProjectsApiProjectsProjectsCardsList
     */
    readonly limit?: number
}

/**
 * Request parameters for projectsProjectsCardsRead operation in ProjectsApi.
 * @export
 * @interface ProjectsApiProjectsProjectsCardsReadRequest
 */
export interface ProjectsApiProjectsProjectsCardsReadRequest {
    /**
     * A unique integer value identifying this Карточка проекта.
     * @type {number}
     * @memberof ProjectsApiProjectsProjectsCardsRead
     */
    readonly id: number
}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @param {ProjectsApiProjectsAwardBusinessesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardBusinessesList(requestParameters: ProjectsApiProjectsAwardBusinessesListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardBusinessesList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardBusinessesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardBusinessesRead(requestParameters: ProjectsApiProjectsAwardBusinessesReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardBusinessesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardNominationsList(options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardNominationsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardNominationsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardNominationsRead(requestParameters: ProjectsApiProjectsAwardNominationsReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardNominationsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardParticipantsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardParticipantsList(requestParameters: ProjectsApiProjectsAwardParticipantsListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardParticipantsList(requestParameters.awardId, requestParameters.nominationId, requestParameters.status, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardParticipantsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardParticipantsRead(requestParameters: ProjectsApiProjectsAwardParticipantsReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardParticipantsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardWinnersListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardWinnersList(requestParameters: ProjectsApiProjectsAwardWinnersListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardWinnersList(requestParameters.businessId, requestParameters.awardId, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardWinnersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardWinnersRead(requestParameters: ProjectsApiProjectsAwardWinnersReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardWinnersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardsBusinessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardsBusiness(requestParameters: ProjectsApiProjectsAwardsBusinessRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardsBusiness(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardsList(requestParameters: ProjectsApiProjectsAwardsListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardsList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardsPagesList(options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardsPagesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsAwardsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsAwardsRead(requestParameters: ProjectsApiProjectsAwardsReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsAwardsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesArticlesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesList(requestParameters: ProjectsApiProjectsMagazinesArticlesListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesList(requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.magazineId, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List likes for a specific object.
     * @param {ProjectsApiProjectsMagazinesArticlesListLikesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesListLikes(requestParameters: ProjectsApiProjectsMagazinesArticlesListLikesRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesListLikes(requestParameters.id, requestParameters.isLikedByUser, requestParameters.likesCountGte, requestParameters.isInUserFavorites, requestParameters.magazineId, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesArticlesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesRead(requestParameters: ProjectsApiProjectsMagazinesArticlesReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesArticlesToggleFavoriteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesToggleFavoriteCreate(requestParameters: ProjectsApiProjectsMagazinesArticlesToggleFavoriteCreateRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesToggleFavoriteCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesArticlesToggleFavoriteDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesToggleFavoriteDelete(requestParameters: ProjectsApiProjectsMagazinesArticlesToggleFavoriteDeleteRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesToggleFavoriteDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesArticlesToggleLikeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesToggleLikeCreate(requestParameters: ProjectsApiProjectsMagazinesArticlesToggleLikeCreateRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesToggleLikeCreate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesArticlesToggleLikeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesArticlesToggleLikeDelete(requestParameters: ProjectsApiProjectsMagazinesArticlesToggleLikeDeleteRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesArticlesToggleLikeDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesList(requestParameters: ProjectsApiProjectsMagazinesListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsMagazinesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsMagazinesRead(requestParameters: ProjectsApiProjectsMagazinesReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsMagazinesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsProjectsCardsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsProjectsCardsList(requestParameters: ProjectsApiProjectsProjectsCardsListRequest = {}, options?: any) {
        return ProjectsApiFp(this.configuration).projectsProjectsCardsList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectsApiProjectsProjectsCardsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsProjectsCardsRead(requestParameters: ProjectsApiProjectsProjectsCardsReadRequest, options?: any) {
        return ProjectsApiFp(this.configuration).projectsProjectsCardsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public projectsScarletSailsList(options?: any) {
        return ProjectsApiFp(this.configuration).projectsScarletSailsList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromoCodesApi - axios parameter creator
 * @export
 */
export const PromoCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promo_codes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promoCodesDelete', 'id', id)
            const localVarPath = `/promo_codes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesExport: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promo_codes/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promo_codes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesPartialUpdate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promoCodesPartialUpdate', 'id', id)
            const localVarPath = `/promo_codes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promoCodesRead', 'id', id)
            const localVarPath = `/promo_codes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesUpdate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promoCodesUpdate', 'id', id)
            const localVarPath = `/promo_codes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromoCodesApi - functional programming interface
 * @export
 */
export const PromoCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromoCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesExport(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesExport(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesPartialUpdate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesPartialUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodesUpdate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodesUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromoCodesApi - factory interface
 * @export
 */
export const PromoCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromoCodesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesCreate(options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesExport(page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesExport(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesList(page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesPartialUpdate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesPartialUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Промокод.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodesUpdate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.promoCodesUpdate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for promoCodesDelete operation in PromoCodesApi.
 * @export
 * @interface PromoCodesApiPromoCodesDeleteRequest
 */
export interface PromoCodesApiPromoCodesDeleteRequest {
    /**
     * A unique integer value identifying this Промокод.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesDelete
     */
    readonly id: number
}

/**
 * Request parameters for promoCodesExport operation in PromoCodesApi.
 * @export
 * @interface PromoCodesApiPromoCodesExportRequest
 */
export interface PromoCodesApiPromoCodesExportRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesExport
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesExport
     */
    readonly limit?: number
}

/**
 * Request parameters for promoCodesList operation in PromoCodesApi.
 * @export
 * @interface PromoCodesApiPromoCodesListRequest
 */
export interface PromoCodesApiPromoCodesListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesList
     */
    readonly limit?: number
}

/**
 * Request parameters for promoCodesPartialUpdate operation in PromoCodesApi.
 * @export
 * @interface PromoCodesApiPromoCodesPartialUpdateRequest
 */
export interface PromoCodesApiPromoCodesPartialUpdateRequest {
    /**
     * A unique integer value identifying this Промокод.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesPartialUpdate
     */
    readonly id: number
}

/**
 * Request parameters for promoCodesRead operation in PromoCodesApi.
 * @export
 * @interface PromoCodesApiPromoCodesReadRequest
 */
export interface PromoCodesApiPromoCodesReadRequest {
    /**
     * A unique integer value identifying this Промокод.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesRead
     */
    readonly id: number
}

/**
 * Request parameters for promoCodesUpdate operation in PromoCodesApi.
 * @export
 * @interface PromoCodesApiPromoCodesUpdateRequest
 */
export interface PromoCodesApiPromoCodesUpdateRequest {
    /**
     * A unique integer value identifying this Промокод.
     * @type {number}
     * @memberof PromoCodesApiPromoCodesUpdate
     */
    readonly id: number
}

/**
 * PromoCodesApi - object-oriented interface
 * @export
 * @class PromoCodesApi
 * @extends {BaseAPI}
 */
export class PromoCodesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesCreate(options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodesApiPromoCodesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesDelete(requestParameters: PromoCodesApiPromoCodesDeleteRequest, options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodesApiPromoCodesExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesExport(requestParameters: PromoCodesApiPromoCodesExportRequest = {}, options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesExport(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodesApiPromoCodesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesList(requestParameters: PromoCodesApiPromoCodesListRequest = {}, options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodesApiPromoCodesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesPartialUpdate(requestParameters: PromoCodesApiPromoCodesPartialUpdateRequest, options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesPartialUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodesApiPromoCodesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesRead(requestParameters: PromoCodesApiPromoCodesReadRequest, options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoCodesApiPromoCodesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromoCodesApi
     */
    public promoCodesUpdate(requestParameters: PromoCodesApiPromoCodesUpdateRequest, options?: any) {
        return PromoCodesApiFp(this.configuration).promoCodesUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromocodeApi - axios parameter creator
 * @export
 */
export const PromocodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodeCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/promocode/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromocodeApi - functional programming interface
 * @export
 */
export const PromocodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromocodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promocodeCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promocodeCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromocodeApi - factory interface
 * @export
 */
export const PromocodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromocodeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promocodeCreate(options?: any): AxiosPromise<PromoCode> {
            return localVarFp.promocodeCreate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromocodeApi - object-oriented interface
 * @export
 * @class PromocodeApi
 * @extends {BaseAPI}
 */
export class PromocodeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromocodeApi
     */
    public promocodeCreate(options?: any) {
        return PromocodeApiFp(this.configuration).promocodeCreate(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RestaurantScheduleApi - axios parameter creator
 * @export
 */
export const RestaurantScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleCreate: async (data: RestaurantSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('restaurantScheduleCreate', 'data', data)
            const localVarPath = `/restaurant_schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleDay: async (page?: number, limit?: number, date?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restaurant_schedule/day/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restaurantScheduleDelete', 'id', id)
            const localVarPath = `/restaurant_schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/restaurant_schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantSchedulePartialUpdate: async (id: number, data: RestaurantSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restaurantSchedulePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('restaurantSchedulePartialUpdate', 'data', data)
            const localVarPath = `/restaurant_schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restaurantScheduleRead', 'id', id)
            const localVarPath = `/restaurant_schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleUpdate: async (id: number, data: RestaurantSchedule, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('restaurantScheduleUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('restaurantScheduleUpdate', 'data', data)
            const localVarPath = `/restaurant_schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestaurantScheduleApi - functional programming interface
 * @export
 */
export const RestaurantScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestaurantScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantScheduleCreate(data: RestaurantSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantScheduleCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantScheduleDay(page?: number, limit?: number, date?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20054>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantScheduleDay(page, limit, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantScheduleDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantScheduleDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantScheduleList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20054>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantScheduleList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantSchedulePartialUpdate(id: number, data: RestaurantSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantSchedulePartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantScheduleRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantScheduleRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restaurantScheduleUpdate(id: number, data: RestaurantSchedule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestaurantSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restaurantScheduleUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RestaurantScheduleApi - factory interface
 * @export
 */
export const RestaurantScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestaurantScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleCreate(data: RestaurantSchedule, options?: any): AxiosPromise<RestaurantSchedule> {
            return localVarFp.restaurantScheduleCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleDay(page?: number, limit?: number, date?: any, options?: any): AxiosPromise<InlineResponse20054> {
            return localVarFp.restaurantScheduleDay(page, limit, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.restaurantScheduleDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20054> {
            return localVarFp.restaurantScheduleList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantSchedulePartialUpdate(id: number, data: RestaurantSchedule, options?: any): AxiosPromise<RestaurantSchedule> {
            return localVarFp.restaurantSchedulePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleRead(id: number, options?: any): AxiosPromise<RestaurantSchedule> {
            return localVarFp.restaurantScheduleRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание ресторана.
         * @param {RestaurantSchedule} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restaurantScheduleUpdate(id: number, data: RestaurantSchedule, options?: any): AxiosPromise<RestaurantSchedule> {
            return localVarFp.restaurantScheduleUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for restaurantScheduleCreate operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantScheduleCreateRequest
 */
export interface RestaurantScheduleApiRestaurantScheduleCreateRequest {
    /**
     * 
     * @type {RestaurantSchedule}
     * @memberof RestaurantScheduleApiRestaurantScheduleCreate
     */
    readonly data: RestaurantSchedule
}

/**
 * Request parameters for restaurantScheduleDay operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantScheduleDayRequest
 */
export interface RestaurantScheduleApiRestaurantScheduleDayRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleDay
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleDay
     */
    readonly limit?: number

    /**
     * 
     * @type {any}
     * @memberof RestaurantScheduleApiRestaurantScheduleDay
     */
    readonly date?: any
}

/**
 * Request parameters for restaurantScheduleDelete operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantScheduleDeleteRequest
 */
export interface RestaurantScheduleApiRestaurantScheduleDeleteRequest {
    /**
     * A unique integer value identifying this Расписание ресторана.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleDelete
     */
    readonly id: number
}

/**
 * Request parameters for restaurantScheduleList operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantScheduleListRequest
 */
export interface RestaurantScheduleApiRestaurantScheduleListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleList
     */
    readonly limit?: number
}

/**
 * Request parameters for restaurantSchedulePartialUpdate operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantSchedulePartialUpdateRequest
 */
export interface RestaurantScheduleApiRestaurantSchedulePartialUpdateRequest {
    /**
     * A unique integer value identifying this Расписание ресторана.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantSchedulePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RestaurantSchedule}
     * @memberof RestaurantScheduleApiRestaurantSchedulePartialUpdate
     */
    readonly data: RestaurantSchedule
}

/**
 * Request parameters for restaurantScheduleRead operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantScheduleReadRequest
 */
export interface RestaurantScheduleApiRestaurantScheduleReadRequest {
    /**
     * A unique integer value identifying this Расписание ресторана.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleRead
     */
    readonly id: number
}

/**
 * Request parameters for restaurantScheduleUpdate operation in RestaurantScheduleApi.
 * @export
 * @interface RestaurantScheduleApiRestaurantScheduleUpdateRequest
 */
export interface RestaurantScheduleApiRestaurantScheduleUpdateRequest {
    /**
     * A unique integer value identifying this Расписание ресторана.
     * @type {number}
     * @memberof RestaurantScheduleApiRestaurantScheduleUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RestaurantSchedule}
     * @memberof RestaurantScheduleApiRestaurantScheduleUpdate
     */
    readonly data: RestaurantSchedule
}

/**
 * RestaurantScheduleApi - object-oriented interface
 * @export
 * @class RestaurantScheduleApi
 * @extends {BaseAPI}
 */
export class RestaurantScheduleApi extends BaseAPI {
    /**
     * 
     * @param {RestaurantScheduleApiRestaurantScheduleCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantScheduleCreate(requestParameters: RestaurantScheduleApiRestaurantScheduleCreateRequest, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantScheduleCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestaurantScheduleApiRestaurantScheduleDayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantScheduleDay(requestParameters: RestaurantScheduleApiRestaurantScheduleDayRequest = {}, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantScheduleDay(requestParameters.page, requestParameters.limit, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestaurantScheduleApiRestaurantScheduleDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantScheduleDelete(requestParameters: RestaurantScheduleApiRestaurantScheduleDeleteRequest, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantScheduleDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestaurantScheduleApiRestaurantScheduleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantScheduleList(requestParameters: RestaurantScheduleApiRestaurantScheduleListRequest = {}, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantScheduleList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestaurantScheduleApiRestaurantSchedulePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantSchedulePartialUpdate(requestParameters: RestaurantScheduleApiRestaurantSchedulePartialUpdateRequest, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantSchedulePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestaurantScheduleApiRestaurantScheduleReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantScheduleRead(requestParameters: RestaurantScheduleApiRestaurantScheduleReadRequest, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantScheduleRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestaurantScheduleApiRestaurantScheduleUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestaurantScheduleApi
     */
    public restaurantScheduleUpdate(requestParameters: RestaurantScheduleApiRestaurantScheduleUpdateRequest, options?: any) {
        return RestaurantScheduleApiFp(this.configuration).restaurantScheduleUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} searchPhrase 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>} [models] 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchList: async (searchPhrase: string, page?: number, limit?: number, models?: Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>, categoryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPhrase' is not null or undefined
            assertParamExists('searchList', 'searchPhrase', searchPhrase)
            const localVarPath = `/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (searchPhrase !== undefined) {
                localVarQueryParameter['search_phrase'] = searchPhrase;
            }

            if (models) {
                localVarQueryParameter['models'] = models;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['category_id'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} searchPhrase 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>} [models] 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchList(searchPhrase: string, page?: number, limit?: number, models?: Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>, categoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchList(searchPhrase, page, limit, models, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} searchPhrase 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>} [models] 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchList(searchPhrase: string, page?: number, limit?: number, models?: Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>, categoryId?: number, options?: any): AxiosPromise<SearchResult> {
            return localVarFp.searchList(searchPhrase, page, limit, models, categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchList operation in SearchApi.
 * @export
 * @interface SearchApiSearchListRequest
 */
export interface SearchApiSearchListRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchList
     */
    readonly searchPhrase: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SearchApiSearchList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SearchApiSearchList
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>}
     * @memberof SearchApiSearchList
     */
    readonly models?: Array<'products' | 'banking_products' | 'media_products' | 'insurance_products' | 'news_pages' | 'guide_pages' | 'magazine_article'>

    /**
     * 
     * @type {number}
     * @memberof SearchApiSearchList
     */
    readonly categoryId?: number
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {SearchApiSearchListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchList(requestParameters: SearchApiSearchListRequest, options?: any) {
        return SearchApiFp(this.configuration).searchList(requestParameters.searchPhrase, requestParameters.page, requestParameters.limit, requestParameters.models, requestParameters.categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopsApi - axios parameter creator
 * @export
 */
export const ShopsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsCreate: async (data: Shop, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('shopsCreate', 'data', data)
            const localVarPath = `/shops/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopsDelete', 'id', id)
            const localVarPath = `/shops/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsList: async (ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/shops/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsPartialUpdate: async (id: number, data: Shop, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('shopsPartialUpdate', 'data', data)
            const localVarPath = `/shops/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopsRead', 'id', id)
            const localVarPath = `/shops/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsUpdate: async (id: number, data: Shop, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('shopsUpdate', 'data', data)
            const localVarPath = `/shops/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopsApi - functional programming interface
 * @export
 */
export const ShopsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsCreate(data: Shop, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Shop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsList(ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20055>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsList(ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsPartialUpdate(id: number, data: Shop, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Shop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Shop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopsUpdate(id: number, data: Shop, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Shop>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopsUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopsApi - factory interface
 * @export
 */
export const ShopsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopsApiFp(configuration)
    return {
        /**
         * 
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsCreate(data: Shop, options?: any): AxiosPromise<Shop> {
            return localVarFp.shopsCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.shopsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsList(ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20055> {
            return localVarFp.shopsList(ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsPartialUpdate(id: number, data: Shop, options?: any): AxiosPromise<Shop> {
            return localVarFp.shopsPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsRead(id: number, options?: any): AxiosPromise<Shop> {
            return localVarFp.shopsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Магазин.
         * @param {Shop} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopsUpdate(id: number, data: Shop, options?: any): AxiosPromise<Shop> {
            return localVarFp.shopsUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for shopsCreate operation in ShopsApi.
 * @export
 * @interface ShopsApiShopsCreateRequest
 */
export interface ShopsApiShopsCreateRequest {
    /**
     * 
     * @type {Shop}
     * @memberof ShopsApiShopsCreate
     */
    readonly data: Shop
}

/**
 * Request parameters for shopsDelete operation in ShopsApi.
 * @export
 * @interface ShopsApiShopsDeleteRequest
 */
export interface ShopsApiShopsDeleteRequest {
    /**
     * A unique integer value identifying this Магазин.
     * @type {number}
     * @memberof ShopsApiShopsDelete
     */
    readonly id: number
}

/**
 * Request parameters for shopsList operation in ShopsApi.
 * @export
 * @interface ShopsApiShopsListRequest
 */
export interface ShopsApiShopsListRequest {
    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof ShopsApiShopsList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ShopsApiShopsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ShopsApiShopsList
     */
    readonly limit?: number
}

/**
 * Request parameters for shopsPartialUpdate operation in ShopsApi.
 * @export
 * @interface ShopsApiShopsPartialUpdateRequest
 */
export interface ShopsApiShopsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Магазин.
     * @type {number}
     * @memberof ShopsApiShopsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Shop}
     * @memberof ShopsApiShopsPartialUpdate
     */
    readonly data: Shop
}

/**
 * Request parameters for shopsRead operation in ShopsApi.
 * @export
 * @interface ShopsApiShopsReadRequest
 */
export interface ShopsApiShopsReadRequest {
    /**
     * A unique integer value identifying this Магазин.
     * @type {number}
     * @memberof ShopsApiShopsRead
     */
    readonly id: number
}

/**
 * Request parameters for shopsUpdate operation in ShopsApi.
 * @export
 * @interface ShopsApiShopsUpdateRequest
 */
export interface ShopsApiShopsUpdateRequest {
    /**
     * A unique integer value identifying this Магазин.
     * @type {number}
     * @memberof ShopsApiShopsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Shop}
     * @memberof ShopsApiShopsUpdate
     */
    readonly data: Shop
}

/**
 * ShopsApi - object-oriented interface
 * @export
 * @class ShopsApi
 * @extends {BaseAPI}
 */
export class ShopsApi extends BaseAPI {
    /**
     * 
     * @param {ShopsApiShopsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public shopsCreate(requestParameters: ShopsApiShopsCreateRequest, options?: any) {
        return ShopsApiFp(this.configuration).shopsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShopsApiShopsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public shopsDelete(requestParameters: ShopsApiShopsDeleteRequest, options?: any) {
        return ShopsApiFp(this.configuration).shopsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShopsApiShopsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public shopsList(requestParameters: ShopsApiShopsListRequest = {}, options?: any) {
        return ShopsApiFp(this.configuration).shopsList(requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShopsApiShopsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public shopsPartialUpdate(requestParameters: ShopsApiShopsPartialUpdateRequest, options?: any) {
        return ShopsApiFp(this.configuration).shopsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShopsApiShopsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public shopsRead(requestParameters: ShopsApiShopsReadRequest, options?: any) {
        return ShopsApiFp(this.configuration).shopsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShopsApiShopsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public shopsUpdate(requestParameters: ShopsApiShopsUpdateRequest, options?: any) {
        return ShopsApiFp(this.configuration).shopsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SigninApi - axios parameter creator
 * @export
 */
export const SigninApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Signin} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinCreate: async (data: Signin, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('signinCreate', 'data', data)
            const localVarPath = `/signin/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SigninApi - functional programming interface
 * @export
 */
export const SigninApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SigninApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Signin} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signinCreate(data: Signin, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signinCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SigninApi - factory interface
 * @export
 */
export const SigninApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SigninApiFp(configuration)
    return {
        /**
         * 
         * @param {Signin} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinCreate(data: Signin, options?: any): AxiosPromise<Token> {
            return localVarFp.signinCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for signinCreate operation in SigninApi.
 * @export
 * @interface SigninApiSigninCreateRequest
 */
export interface SigninApiSigninCreateRequest {
    /**
     * 
     * @type {Signin}
     * @memberof SigninApiSigninCreate
     */
    readonly data: Signin
}

/**
 * SigninApi - object-oriented interface
 * @export
 * @class SigninApi
 * @extends {BaseAPI}
 */
export class SigninApi extends BaseAPI {
    /**
     * 
     * @param {SigninApiSigninCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SigninApi
     */
    public signinCreate(requestParameters: SigninApiSigninCreateRequest, options?: any) {
        return SigninApiFp(this.configuration).signinCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SignupApi - axios parameter creator
 * @export
 */
export const SignupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Signup} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupCreate: async (data: Signup, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('signupCreate', 'data', data)
            const localVarPath = `/signup/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignupApi - functional programming interface
 * @export
 */
export const SignupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Signup} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signupCreate(data: Signup, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signupCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SignupApi - factory interface
 * @export
 */
export const SignupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignupApiFp(configuration)
    return {
        /**
         * 
         * @param {Signup} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupCreate(data: Signup, options?: any): AxiosPromise<void> {
            return localVarFp.signupCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for signupCreate operation in SignupApi.
 * @export
 * @interface SignupApiSignupCreateRequest
 */
export interface SignupApiSignupCreateRequest {
    /**
     * 
     * @type {Signup}
     * @memberof SignupApiSignupCreate
     */
    readonly data: Signup
}

/**
 * SignupApi - object-oriented interface
 * @export
 * @class SignupApi
 * @extends {BaseAPI}
 */
export class SignupApi extends BaseAPI {
    /**
     * 
     * @param {SignupApiSignupCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignupApi
     */
    public signupCreate(requestParameters: SignupApiSignupCreateRequest, options?: any) {
        return SignupApiFp(this.configuration).signupCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SmallBannerApi - axios parameter creator
 * @export
 */
export const SmallBannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smallBannerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/small_banner/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Малый баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smallBannerRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('smallBannerRead', 'id', id)
            const localVarPath = `/small_banner/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SmallBannerApi - functional programming interface
 * @export
 */
export const SmallBannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SmallBannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smallBannerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SmallBanner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smallBannerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Малый баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smallBannerRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmallBanner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smallBannerRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SmallBannerApi - factory interface
 * @export
 */
export const SmallBannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SmallBannerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smallBannerList(options?: any): AxiosPromise<Array<SmallBanner>> {
            return localVarFp.smallBannerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Малый баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smallBannerRead(id: number, options?: any): AxiosPromise<SmallBanner> {
            return localVarFp.smallBannerRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for smallBannerRead operation in SmallBannerApi.
 * @export
 * @interface SmallBannerApiSmallBannerReadRequest
 */
export interface SmallBannerApiSmallBannerReadRequest {
    /**
     * A unique integer value identifying this Малый баннер.
     * @type {number}
     * @memberof SmallBannerApiSmallBannerRead
     */
    readonly id: number
}

/**
 * SmallBannerApi - object-oriented interface
 * @export
 * @class SmallBannerApi
 * @extends {BaseAPI}
 */
export class SmallBannerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmallBannerApi
     */
    public smallBannerList(options?: any) {
        return SmallBannerApiFp(this.configuration).smallBannerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SmallBannerApiSmallBannerReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmallBannerApi
     */
    public smallBannerRead(requestParameters: SmallBannerApiSmallBannerReadRequest, options?: any) {
        return SmallBannerApiFp(this.configuration).smallBannerRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubcategoriesApi - axios parameter creator
 * @export
 */
export const SubcategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesCreate: async (data: Subcategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('subcategoriesCreate', 'data', data)
            const localVarPath = `/subcategories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesDelete', 'id', id)
            const localVarPath = `/subcategories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesList: async (name?: string, category?: string, ordering?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subcategories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesPartialUpdate: async (id: number, data: Subcategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('subcategoriesPartialUpdate', 'data', data)
            const localVarPath = `/subcategories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesRead', 'id', id)
            const localVarPath = `/subcategories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesUpdate: async (id: number, data: Subcategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('subcategoriesUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('subcategoriesUpdate', 'data', data)
            const localVarPath = `/subcategories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubcategoriesApi - functional programming interface
 * @export
 */
export const SubcategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubcategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesCreate(data: Subcategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesCreate(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesList(name?: string, category?: string, ordering?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20056>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesList(name, category, ordering, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesPartialUpdate(id: number, data: Subcategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesPartialUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subcategoriesUpdate(id: number, data: Subcategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subcategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subcategoriesUpdate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubcategoriesApi - factory interface
 * @export
 */
export const SubcategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubcategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesCreate(data: Subcategory, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.subcategoriesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [category] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesList(name?: string, category?: string, ordering?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20056> {
            return localVarFp.subcategoriesList(name, category, ordering, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesPartialUpdate(id: number, data: Subcategory, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesRead(id: number, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Подкатегория.
         * @param {Subcategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subcategoriesUpdate(id: number, data: Subcategory, options?: any): AxiosPromise<Subcategory> {
            return localVarFp.subcategoriesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for subcategoriesCreate operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiSubcategoriesCreateRequest
 */
export interface SubcategoriesApiSubcategoriesCreateRequest {
    /**
     * 
     * @type {Subcategory}
     * @memberof SubcategoriesApiSubcategoriesCreate
     */
    readonly data: Subcategory
}

/**
 * Request parameters for subcategoriesDelete operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiSubcategoriesDeleteRequest
 */
export interface SubcategoriesApiSubcategoriesDeleteRequest {
    /**
     * A unique integer value identifying this Подкатегория.
     * @type {number}
     * @memberof SubcategoriesApiSubcategoriesDelete
     */
    readonly id: number
}

/**
 * Request parameters for subcategoriesList operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiSubcategoriesListRequest
 */
export interface SubcategoriesApiSubcategoriesListRequest {
    /**
     * 
     * @type {string}
     * @memberof SubcategoriesApiSubcategoriesList
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof SubcategoriesApiSubcategoriesList
     */
    readonly category?: string

    /**
     * Which field to use when ordering the results.
     * @type {string}
     * @memberof SubcategoriesApiSubcategoriesList
     */
    readonly ordering?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SubcategoriesApiSubcategoriesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SubcategoriesApiSubcategoriesList
     */
    readonly limit?: number
}

/**
 * Request parameters for subcategoriesPartialUpdate operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiSubcategoriesPartialUpdateRequest
 */
export interface SubcategoriesApiSubcategoriesPartialUpdateRequest {
    /**
     * A unique integer value identifying this Подкатегория.
     * @type {number}
     * @memberof SubcategoriesApiSubcategoriesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Subcategory}
     * @memberof SubcategoriesApiSubcategoriesPartialUpdate
     */
    readonly data: Subcategory
}

/**
 * Request parameters for subcategoriesRead operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiSubcategoriesReadRequest
 */
export interface SubcategoriesApiSubcategoriesReadRequest {
    /**
     * A unique integer value identifying this Подкатегория.
     * @type {number}
     * @memberof SubcategoriesApiSubcategoriesRead
     */
    readonly id: number
}

/**
 * Request parameters for subcategoriesUpdate operation in SubcategoriesApi.
 * @export
 * @interface SubcategoriesApiSubcategoriesUpdateRequest
 */
export interface SubcategoriesApiSubcategoriesUpdateRequest {
    /**
     * A unique integer value identifying this Подкатегория.
     * @type {number}
     * @memberof SubcategoriesApiSubcategoriesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Subcategory}
     * @memberof SubcategoriesApiSubcategoriesUpdate
     */
    readonly data: Subcategory
}

/**
 * SubcategoriesApi - object-oriented interface
 * @export
 * @class SubcategoriesApi
 * @extends {BaseAPI}
 */
export class SubcategoriesApi extends BaseAPI {
    /**
     * 
     * @param {SubcategoriesApiSubcategoriesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public subcategoriesCreate(requestParameters: SubcategoriesApiSubcategoriesCreateRequest, options?: any) {
        return SubcategoriesApiFp(this.configuration).subcategoriesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoriesApiSubcategoriesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public subcategoriesDelete(requestParameters: SubcategoriesApiSubcategoriesDeleteRequest, options?: any) {
        return SubcategoriesApiFp(this.configuration).subcategoriesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoriesApiSubcategoriesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public subcategoriesList(requestParameters: SubcategoriesApiSubcategoriesListRequest = {}, options?: any) {
        return SubcategoriesApiFp(this.configuration).subcategoriesList(requestParameters.name, requestParameters.category, requestParameters.ordering, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoriesApiSubcategoriesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public subcategoriesPartialUpdate(requestParameters: SubcategoriesApiSubcategoriesPartialUpdateRequest, options?: any) {
        return SubcategoriesApiFp(this.configuration).subcategoriesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoriesApiSubcategoriesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public subcategoriesRead(requestParameters: SubcategoriesApiSubcategoriesReadRequest, options?: any) {
        return SubcategoriesApiFp(this.configuration).subcategoriesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubcategoriesApiSubcategoriesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubcategoriesApi
     */
    public subcategoriesUpdate(requestParameters: SubcategoriesApiSubcategoriesUpdateRequest, options?: any) {
        return SubcategoriesApiFp(this.configuration).subcategoriesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuccessApplicationsApi - axios parameter creator
 * @export
 */
export const SuccessApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/success_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successApplicationsDelete', 'id', id)
            const localVarPath = `/success_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [formId] Форма заявки
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsExport: async (formId?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/success_applications/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (formId !== undefined) {
                localVarQueryParameter['form__id'] = formId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [formId] Форма заявки
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsList: async (formId?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/success_applications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (formId !== undefined) {
                localVarQueryParameter['form__id'] = formId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsPartialUpdate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successApplicationsPartialUpdate', 'id', id)
            const localVarPath = `/success_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successApplicationsRead', 'id', id)
            const localVarPath = `/success_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsUpdate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successApplicationsUpdate', 'id', id)
            const localVarPath = `/success_applications/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuccessApplicationsApi - functional programming interface
 * @export
 */
export const SuccessApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuccessApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [formId] Форма заявки
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsExport(formId?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsExport(formId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [formId] Форма заявки
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsList(formId?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsList(formId, page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsPartialUpdate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsPartialUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successApplicationsUpdate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successApplicationsUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuccessApplicationsApi - factory interface
 * @export
 */
export const SuccessApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuccessApplicationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsCreate(options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [formId] Форма заявки
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsExport(formId?: number, page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsExport(formId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [formId] Форма заявки
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsList(formId?: number, page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsList(formId, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsPartialUpdate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsPartialUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Динамически сформированная заявка.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successApplicationsUpdate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.successApplicationsUpdate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for successApplicationsDelete operation in SuccessApplicationsApi.
 * @export
 * @interface SuccessApplicationsApiSuccessApplicationsDeleteRequest
 */
export interface SuccessApplicationsApiSuccessApplicationsDeleteRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsDelete
     */
    readonly id: number
}

/**
 * Request parameters for successApplicationsExport operation in SuccessApplicationsApi.
 * @export
 * @interface SuccessApplicationsApiSuccessApplicationsExportRequest
 */
export interface SuccessApplicationsApiSuccessApplicationsExportRequest {
    /**
     * Форма заявки
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsExport
     */
    readonly formId?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsExport
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsExport
     */
    readonly limit?: number
}

/**
 * Request parameters for successApplicationsList operation in SuccessApplicationsApi.
 * @export
 * @interface SuccessApplicationsApiSuccessApplicationsListRequest
 */
export interface SuccessApplicationsApiSuccessApplicationsListRequest {
    /**
     * Форма заявки
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsList
     */
    readonly formId?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsList
     */
    readonly limit?: number
}

/**
 * Request parameters for successApplicationsPartialUpdate operation in SuccessApplicationsApi.
 * @export
 * @interface SuccessApplicationsApiSuccessApplicationsPartialUpdateRequest
 */
export interface SuccessApplicationsApiSuccessApplicationsPartialUpdateRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsPartialUpdate
     */
    readonly id: number
}

/**
 * Request parameters for successApplicationsRead operation in SuccessApplicationsApi.
 * @export
 * @interface SuccessApplicationsApiSuccessApplicationsReadRequest
 */
export interface SuccessApplicationsApiSuccessApplicationsReadRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsRead
     */
    readonly id: number
}

/**
 * Request parameters for successApplicationsUpdate operation in SuccessApplicationsApi.
 * @export
 * @interface SuccessApplicationsApiSuccessApplicationsUpdateRequest
 */
export interface SuccessApplicationsApiSuccessApplicationsUpdateRequest {
    /**
     * A unique integer value identifying this Динамически сформированная заявка.
     * @type {number}
     * @memberof SuccessApplicationsApiSuccessApplicationsUpdate
     */
    readonly id: number
}

/**
 * SuccessApplicationsApi - object-oriented interface
 * @export
 * @class SuccessApplicationsApi
 * @extends {BaseAPI}
 */
export class SuccessApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsCreate(options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessApplicationsApiSuccessApplicationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsDelete(requestParameters: SuccessApplicationsApiSuccessApplicationsDeleteRequest, options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessApplicationsApiSuccessApplicationsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsExport(requestParameters: SuccessApplicationsApiSuccessApplicationsExportRequest = {}, options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsExport(requestParameters.formId, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessApplicationsApiSuccessApplicationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsList(requestParameters: SuccessApplicationsApiSuccessApplicationsListRequest = {}, options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsList(requestParameters.formId, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessApplicationsApiSuccessApplicationsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsPartialUpdate(requestParameters: SuccessApplicationsApiSuccessApplicationsPartialUpdateRequest, options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsPartialUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessApplicationsApiSuccessApplicationsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsRead(requestParameters: SuccessApplicationsApiSuccessApplicationsReadRequest, options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessApplicationsApiSuccessApplicationsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessApplicationsApi
     */
    public successApplicationsUpdate(requestParameters: SuccessApplicationsApiSuccessApplicationsUpdateRequest, options?: any) {
        return SuccessApplicationsApiFp(this.configuration).successApplicationsUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ToursScheduleApi - axios parameter creator
 * @export
 */
export const ToursScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toursScheduleList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tours_schedule/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание туров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toursScheduleRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('toursScheduleRead', 'id', id)
            const localVarPath = `/tours_schedule/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ToursScheduleApi - functional programming interface
 * @export
 */
export const ToursScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ToursScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toursScheduleList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20057>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toursScheduleList(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание туров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toursScheduleRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toursScheduleRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ToursScheduleApi - factory interface
 * @export
 */
export const ToursScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ToursScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toursScheduleList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20057> {
            return localVarFp.toursScheduleList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Расписание туров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toursScheduleRead(id: number, options?: any): AxiosPromise<TourSchedule> {
            return localVarFp.toursScheduleRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for toursScheduleList operation in ToursScheduleApi.
 * @export
 * @interface ToursScheduleApiToursScheduleListRequest
 */
export interface ToursScheduleApiToursScheduleListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ToursScheduleApiToursScheduleList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ToursScheduleApiToursScheduleList
     */
    readonly limit?: number
}

/**
 * Request parameters for toursScheduleRead operation in ToursScheduleApi.
 * @export
 * @interface ToursScheduleApiToursScheduleReadRequest
 */
export interface ToursScheduleApiToursScheduleReadRequest {
    /**
     * A unique integer value identifying this Расписание туров.
     * @type {number}
     * @memberof ToursScheduleApiToursScheduleRead
     */
    readonly id: number
}

/**
 * ToursScheduleApi - object-oriented interface
 * @export
 * @class ToursScheduleApi
 * @extends {BaseAPI}
 */
export class ToursScheduleApi extends BaseAPI {
    /**
     * 
     * @param {ToursScheduleApiToursScheduleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToursScheduleApi
     */
    public toursScheduleList(requestParameters: ToursScheduleApiToursScheduleListRequest = {}, options?: any) {
        return ToursScheduleApiFp(this.configuration).toursScheduleList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ToursScheduleApiToursScheduleReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ToursScheduleApi
     */
    public toursScheduleRead(requestParameters: ToursScheduleApiToursScheduleReadRequest, options?: any) {
        return ToursScheduleApiFp(this.configuration).toursScheduleRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAgreementApi - axios parameter creator
 * @export
 */
export const UserAgreementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAgreementList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAgreementApi - functional programming interface
 * @export
 */
export const UserAgreementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAgreementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAgreementList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAgreement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAgreementList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAgreementApi - factory interface
 * @export
 */
export const UserAgreementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAgreementApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAgreementList(options?: any): AxiosPromise<UserAgreement> {
            return localVarFp.userAgreementList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAgreementApi - object-oriented interface
 * @export
 * @class UserAgreementApi
 * @extends {BaseAPI}
 */
export class UserAgreementApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementApi
     */
    public userAgreementList(options?: any) {
        return UserAgreementApiFp(this.configuration).userAgreementList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VideoHistoriesApi - axios parameter creator
 * @export
 */
export const VideoHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {UserVideoHistory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoHistoriesCreate: async (id: string, data: UserVideoHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('videoHistoriesCreate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('videoHistoriesCreate', 'data', data)
            const localVarPath = `/video_histories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoHistoriesRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('videoHistoriesRead', 'id', id)
            const localVarPath = `/video_histories/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoHistoriesApi - functional programming interface
 * @export
 */
export const VideoHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UserVideoHistory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoHistoriesCreate(id: string, data: UserVideoHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVideoHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoHistoriesCreate(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoHistoriesRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserVideoHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoHistoriesRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoHistoriesApi - factory interface
 * @export
 */
export const VideoHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoHistoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UserVideoHistory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoHistoriesCreate(id: string, data: UserVideoHistory, options?: any): AxiosPromise<UserVideoHistory> {
            return localVarFp.videoHistoriesCreate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoHistoriesRead(id: string, options?: any): AxiosPromise<UserVideoHistory> {
            return localVarFp.videoHistoriesRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for videoHistoriesCreate operation in VideoHistoriesApi.
 * @export
 * @interface VideoHistoriesApiVideoHistoriesCreateRequest
 */
export interface VideoHistoriesApiVideoHistoriesCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof VideoHistoriesApiVideoHistoriesCreate
     */
    readonly id: string

    /**
     * 
     * @type {UserVideoHistory}
     * @memberof VideoHistoriesApiVideoHistoriesCreate
     */
    readonly data: UserVideoHistory
}

/**
 * Request parameters for videoHistoriesRead operation in VideoHistoriesApi.
 * @export
 * @interface VideoHistoriesApiVideoHistoriesReadRequest
 */
export interface VideoHistoriesApiVideoHistoriesReadRequest {
    /**
     * 
     * @type {string}
     * @memberof VideoHistoriesApiVideoHistoriesRead
     */
    readonly id: string
}

/**
 * VideoHistoriesApi - object-oriented interface
 * @export
 * @class VideoHistoriesApi
 * @extends {BaseAPI}
 */
export class VideoHistoriesApi extends BaseAPI {
    /**
     * 
     * @param {VideoHistoriesApiVideoHistoriesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoHistoriesApi
     */
    public videoHistoriesCreate(requestParameters: VideoHistoriesApiVideoHistoriesCreateRequest, options?: any) {
        return VideoHistoriesApiFp(this.configuration).videoHistoriesCreate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoHistoriesApiVideoHistoriesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoHistoriesApi
     */
    public videoHistoriesRead(requestParameters: VideoHistoriesApiVideoHistoriesReadRequest, options?: any) {
        return VideoHistoriesApiFp(this.configuration).videoHistoriesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WineCartInstuctionApi - axios parameter creator
 * @export
 */
export const WineCartInstuctionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wineCartInstuctionList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/wine_cart_instuction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WineCartInstuctionApi - functional programming interface
 * @export
 */
export const WineCartInstuctionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WineCartInstuctionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wineCartInstuctionList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineCartInstruction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wineCartInstuctionList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WineCartInstuctionApi - factory interface
 * @export
 */
export const WineCartInstuctionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WineCartInstuctionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wineCartInstuctionList(options?: any): AxiosPromise<WineCartInstruction> {
            return localVarFp.wineCartInstuctionList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WineCartInstuctionApi - object-oriented interface
 * @export
 * @class WineCartInstuctionApi
 * @extends {BaseAPI}
 */
export class WineCartInstuctionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineCartInstuctionApi
     */
    public wineCartInstuctionList(options?: any) {
        return WineCartInstuctionApiFp(this.configuration).wineCartInstuctionList(options).then((request) => request(this.axios, this.basePath));
    }
}


