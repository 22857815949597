import { action, makeObservable, observable } from 'mobx';
import { ProductComment, ProductCommentChildren } from 'services';
import { api } from 'utils/api';
import AbstractStore from '../AbstractStore';

interface FetchSubCommentsParams {
  productId: number;
  parentId: number;
  count?: number;
}

export interface ProductCommentWithChildren extends ProductComment {
  children?: ProductCommentChildren[];
}

class ProductCommentStore extends AbstractStore {
  comments: ProductCommentWithChildren[] = [];

  commentsCount = 0;

  newCommentsIds: number[] = [];

  constructor() {
    super();
    makeObservable(this, {
      ...this.annotations,
      comments: observable,
      commentsCount: observable,
      addComment: action,
      fetchComments: action,
      fetchNextComments: action,
      setComments: action,
      insertNewComment: action,
      fetchSubComments: action,
      addSubComments: action,
      toggleLikePost: action,
      toggleLikeDelete: action,
    });
  }

  fetchComments(productId: number, count: number) {
    this.fetchData(() => {
      return api.comments.productsCommentsList({ productId, parentCommentIdIsnull: true, count }).then(({ data }) => {
        this.setComments(data.results);
        this.setCommentsCount(data.count);
      });
    });
  }

  fetchSubComments(params: FetchSubCommentsParams) {
    this.fetchData(() => {
      return api.comments
        .productsCommentsList({
          productId: params.productId,
          parentCommentId: params.parentId,
          count: params.count,
          ordering: 'created',
        })
        .then(({ data: { results } }) => {
          this.addSubComments(results);
        });
    });
  }

  addSubComments(comments: ProductCommentChildren[]) {
    if (comments?.[0]?.id) {
      this.setComments(
        this.comments.map((item) => {
          if (item.id === comments[0].parentComment) {
            item.children = comments;
          }
          return item;
        }),
      );
    }
  }

  addComment(comment: ProductComment) {
    this.fetchData(() => {
      return api.comments
        .productsCommentsCreate({
          data: comment,
        })
        .then((res) => {
          if (res.data.parentComment) {
            this.fetchSubComments({
              productId: Number(res.data.product),
              count: 999,
              parentId: res.data.parentComment,
            });
          } else {
            this.insertNewComment(res.data);
          }
        });
    });
  }

  fetchNextComments(productId: number, page: number, count: number) {
    this.fetchData(() => {
      return api.comments
        .productsCommentsList({ productId, parentCommentIdIsnull: true, page, count })
        .then(({ data: { results } }) => {
          this.setNextComments(results);
        });
    });
  }

  setComments(comments: ProductComment[]) {
    this.comments = comments;
  }

  private setCommentsCount(value: number) {
    this.commentsCount = value;
  }

  setNextComments(comments: ProductComment[]) {
    this.comments = [...this.comments, ...comments];
  }

  insertNewComment(comment: ProductComment) {
    let updatedComments = [];
    if (comment.parentComment) {
      updatedComments = this.comments.map((c) => {
        if (c.id === comment.parentComment) c.children = [...(c.children || []), comment];
        return c;
      });
    } else {
      updatedComments = [comment, ...this.comments];
    }
    if (comment.id) this.newCommentsIds = [...this.newCommentsIds, comment.id];
    if (updatedComments) this.setComments(updatedComments);
  }

  toggleLikePost(id: number, parendId?: number | null) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.fetchData(() => {
      return api.comments.productsCommentsToggleLikeCreate({ id }).then(() => {
        this.setComments(
          parendId
            ? this.comments.map((c) => {
                if (c.id === parendId && c.children) {
                  c.children = c.children.map((ch) => {
                    if (ch.id === id) {
                      ch.likesCount = ch.likesCount ? ch.likesCount + 1 : 1;
                      ch.isLikedByUser = true;
                    }
                    return ch;
                  });
                }
                return c;
              })
            : this.comments.map((c) => {
                if (c.id !== id) return c;
                c.likesCount = c.likesCount ? c.likesCount + 1 : 1;
                c.isLikedByUser = true;
                return c;
              }),
        );
      });
    });
  }

  toggleLikeDelete(id: number, parendId?: number | null) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.fetchData(() => {
      return api.comments.productsCommentsToggleLikeDelete({ id }).then(() => {
        this.setComments(
          parendId
            ? this.comments.map((c) => {
                if (c.id === parendId && c.children) {
                  c.children = c.children.map((ch) => {
                    if (ch.id === id) {
                      ch.likesCount = ch.likesCount ? ch.likesCount - 1 : 1;
                      ch.isLikedByUser = false;
                    }
                    return ch;
                  });
                }
                return c;
              })
            : this.comments.map((c) => {
                if (c.id !== id) return c;
                c.likesCount = c.likesCount ? c.likesCount - 1 : 1;
                c.isLikedByUser = false;
                return c;
              }),
        );
      });
    });
  }

  cleanUp = () => {
    this.comments = [];
    this.commentsCount = 0;
    this.newCommentsIds = [];
  };
}

export default ProductCommentStore;
