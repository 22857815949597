import React from 'react';
import { AboutProjectPeopleItem } from 'web';
import { HtmlRenderer } from 'utils/htmlParser';
import { useDeviceState } from 'hooks/useDeviceProvider';
import { StImage, StImageWrapper, StItem, StText, StTitle, StSubtitle } from './styled';

interface Props {
  people: AboutProjectPeopleItem;
}

export const ProjectGroupItem: React.FC<Props> = (props) => {
  const { isLessTablet } = useDeviceState();

  return (
    <StItem>
      <StImageWrapper>
        <StImage src={props.people.image} />
      </StImageWrapper>
      <StTitle>{props.people.header}</StTitle>
      <StSubtitle>{props.people.subheader}</StSubtitle>
      {!isLessTablet && (
        <StText>
          <HtmlRenderer html={props.people.text} />
        </StText>
      )}
    </StItem>
  );
};
