import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils/constants';
import Icon from 'components/base-ui/icon';

export const StSmallElement = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 475px;
  }
`;

export const StImageWrapper = styled.div<{ bg: string; showPointer: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 201px;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 16px;
  background: url(${(props) => props.bg});
  background-size: cover;
  cursor: ${(props) => (props.showPointer ? 'pointer' : 'unset')};

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 475px;
    height: 268px;
  }
`;

export const StText = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: justify;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: ${COLORS.gray3};
  p {
    margin: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    text-align: unset;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    width: 475px;
  }
`;

export const StHeader = styled(StText)`
  font-weight: 700;
  margin: 16px 0 8px 0;
`;

export const StPlayIcon = styled(Icon)`
  width: 60px;
  height: 60px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  z-index: 999;
`;
