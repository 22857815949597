import React from 'react';
import { isBoolean } from 'lodash';
import { ReactComponent as SvgIconArrow } from 'assets/icons/icon_arrow.svg';
import { StButton } from './styles';
import { ButtonProps, ButtonTypes } from './types';
import { colors } from './constants';

export const Button: React.FC<ButtonProps> = ({
  name,
  type = ButtonTypes.Primary,
  children,
  suffix = true,
  onClick,
  width,
}) => {
  return (
    <StButton colors={colors[type]} buttonType={type} onClick={onClick} width={width}>
      {children ?? name}
      {isBoolean(suffix) && suffix && <SvgIconArrow />}
      {!isBoolean(suffix) && Boolean(suffix) && suffix}
    </StButton>
  );
};
