import styled from 'styled-components';
import { BASIC_FONT_FAMILY, BREAKPOINTS, COLORS } from 'utils/constants';
import { TextButton } from 'components/base-ui/button';
import BreadcrumbsWrapper from 'components/base-ui/breadcrumbs-wrapper';

export const Container = styled.div`
  font-family: ${BASIC_FONT_FAMILY};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const BreadcrumbsContainer = styled(BreadcrumbsWrapper)``;
export const Covers = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.mobile}) {
    padding: 0 16px;
    gap: 8px;

    width: unset;
    display: inline-grid;
    grid-template-columns: repeat(2, 140px);
  }

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    justify-content: space-around;

    padding: 0 16px 0 88px;
    gap: 8px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desktop}) {
    justify-content: space-around;
    width: 1600px;
    :after {
      content: '';
      margin-right: auto;
    }
    padding: 0;
    gap: 8px;
  }
`;
export const CoverWrapper = styled.div``;
export const ButtonWrapper = styled.div`
  margin-top: 44px;
  margin-bottom: 76px;
`;
export const Button = styled(TextButton)<{ disabled: boolean }>`
  color: ${(props) => (!props.disabled ? COLORS.secondary : COLORS.gray5)};
  font-size: 14px;
  border: 1px solid ${(props) => (!props.disabled ? COLORS.secondary : COLORS.gray5)};
  width: 160px;
  height: 48px;
  text-transform: uppercase;
`;

export const StTitle = styled.div`
  padding: 0 16px;
  margin-bottom: 8px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${COLORS.azure};
`;
