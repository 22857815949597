import styled from 'styled-components';
import { ArrowButton } from 'components/base-ui/button';
import { BREAKPOINTS, COLORS } from 'utils/constants';

export const StActions = styled.div<{showActions: boolean}>`
  display: ${props => (props.showActions ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    justify-content: flex-start;
  }
`;

export const StDetailButton = styled.button`
  width: 188px;
  height: 48px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 26px;
  border: 1px solid ${COLORS.azure};
  background-color: ${COLORS.white};
  color: ${COLORS.azure};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  :active {
    background-color: transparent;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    :hover {
      background-color: ${COLORS.primary};
      color: ${COLORS.white};
    }
  }
`;
