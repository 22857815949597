import React, { useRef, useState } from 'react';
import { Slide, SlideshowRef } from 'react-slideshow-image';
import Section from 'components/base-ui/section';
import { useDeviceType } from 'utils/hooks';
import { SliderProps } from './types';
import { Item } from './item';
import 'react-slideshow-image/dist/styles.css';
import { Dot, Dots } from './item/styles';

export const BigSlider: React.FC<SliderProps> = ({ items, isActive, interval }) => {
  const slideRef = useRef<SlideshowRef>(null);
  const device = useDeviceType();
  const [currentIndex, setCurrentIndex] = useState(0);

  const onClick = (index: number) => {
    slideRef.current?.goTo(index);
    setCurrentIndex(index);
  };

  return (
    <Section noFirstOfTypeOffset compact>
      <Slide
        arrows={false}
        canSwipe={device === 'mobile' || device === 'tablet'}
        ref={slideRef}
        duration={interval ? interval * 1000 : 5000}
      >
        {items.map((item, index) => (
          <div className="each-slide-effect">
            <Item key={index} {...item} slidesCount={items.length} currentIndex={index} onClick={onClick} />
          </div>
        ))}
      </Slide>
      <Dots>
        {Array(items.length)
          .fill(0)
          .map((item, index) => (
            <Dot key={index} active={index === currentIndex} onClick={() => onClick(index)} />
          ))}
      </Dots>
    </Section>
  );
};
