import styled from 'styled-components';
import { COLORS } from 'utils/constants';
import { ColorsProps, ButtonTypes } from './types';

export const StButton = styled.button<{ colors: ColorsProps; buttonType?: ButtonTypes; width?: string }>`
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 12px 24px;
  color: ${(props) => props.colors.color};
  background: ${(props) => props.colors.background};
  font-size: 16px;
  line-height: 24px;
  border: ${(props) => (props.buttonType === ButtonTypes.Secondary ? `1px solid ${props.colors.color}` : 'none')};
  border-radius: 26px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: ${({ width }) => width ?? 'fit-content'};

  :hover {
    color: ${(props) => props.colors.howerColor};
    background: ${(props) => props.colors.howerBackground};
    transition: all cubic-bezier(0.55, 0.055, 0.675, 0.19);
    border: ${(props) =>
      props.buttonType === ButtonTypes.Ghost || props.buttonType === ButtonTypes.GhostInverse
        ? `1px solid ${props.colors.color}`
        : 'none'};

    svg {
      fill: ${(props) => props.colors.howerColor};
    }
  }

  :disabled {
    color: ${COLORS.placeholderGray};
    background: ${COLORS.greenGray};
  }

  svg {
    fill: ${(props) => props.colors.color};
  }
`;
