import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { HeadingPreferences, ParagraphValue, RelatedItem } from 'web';

import { RelatedGuidePage } from 'services';

import { useGuideStore } from 'store';

import { api } from 'utils/api';
import { BREAKPOINTS, DEFAULT_PREFERENCES } from 'utils/constants';
import { Block, BlockTypes } from 'utils/blocks';

import Breadcrumbs from 'components/base-ui/breadcrumbs';
import BaseSection from 'components/base-ui/section';
import { RelatedItems } from 'components/base-ui/related-items';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import { autorun } from 'mobx';
import { debounce } from 'lodash';
import Banner from './banner';
import Actions from './actions';
import Content from './content';
import { LikeModal } from '../../base-ui/like-modal';
import { LikeModalContent } from '../../base-ui/like-modal-content';
import { useDeviceType } from '../../../utils/hooks';
import { useGuideCommentsStore } from '../../../store/guide-comments';
import GuideComments from '../../guide/comments';

const Div = styled.div`
  height: 100%;
  position: relative;
`;

const BreadcrumbsWrapper = styled.div`
  top: 56px;
  left: 0;
  width: 100%;
  z-index: 100;
  position: absolute;
  margin-top: 12px;
  padding: 0 16px 0 0;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 0 32px 0 32px;
  }
  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 0 32px 0 136px;
  }
`;

const Section = styled.section`
  width: 100%;
  overflow-x: hidden;
`;

const LoadingScreen = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
const BlogItem: React.FC = observer(() => {
  const [preferences, setPreferences] = useState<HeadingPreferences>(DEFAULT_PREFERENCES);
  const [likeModalNewsId, setLikeModalNewsId] = React.useState<number | null>(null);
  const [showLikeModal, setShowLikeModal] = React.useState(false);
  const history = useHistory<{ pathname: string; scroll: boolean }>();
  const store = useGuideStore();
  const device = useDeviceType();
  const { commentsCount } = useGuideCommentsStore();

  const { trackPageView } = useMatomo();

  const { guidePageId } = useParams<{ guidePageId: string }>();

  const likesLimit = React.useMemo(() => {
    if (device === 'mobile') return 70;
    return 40;
  }, [device]);

  // guides will probably have their own preferences
  useEffect(() => {
    api.productPagePreferences.productPagePreferencesList().then((res) =>
      setPreferences(() => ({
        articlesTitle: res.data.articlesTitle || DEFAULT_PREFERENCES.articlesTitle,
        mayInterestTitle: res.data.mayInterestTitle || DEFAULT_PREFERENCES.mayInterestTitle,
      })),
    );
  }, [setPreferences]);

  useEffect(() => {
    document.title = `Гид: ${store.page?.title}`;
    if (store.page?.title) {
      trackPageView({});
    }
  }, [store.page, store.page?.title]);

  useEffect(
    () =>
      autorun(() => {
        store.fetchPage(+guidePageId);
      }),
    [guidePageId, store],
  );

  useEffect(() => {
    return function willUnmount() {
      store.resetPage();
    };
  }, []);

  const mainImage = useMemo(() => {
    const blocks = (store.page?.content as unknown as Block[]) || [];
    const [mainImageBlock] = blocks.filter((b) => b.type === BlockTypes.GuideMainImage);
    return mainImageBlock ? (mainImageBlock.value as string) : '';
  }, [store.page]);

  const content = useMemo(() => {
    const blocks = (store.page?.content as unknown as Block[]) || [];
    const [contentBlock] = blocks.filter((b) => b.type === BlockTypes.GuideMainContent);
    return contentBlock ? (contentBlock.value as ParagraphValue) : ({ text: '', header: '' } as ParagraphValue);
  }, [store.page]);

  const getRelatedItem = (relatedPages: RelatedGuidePage): RelatedItem => {
    const res: RelatedItem = { id: Number(relatedPages.id) } as RelatedItem;
    const content = relatedPages.content as unknown as Block[];
    const previewImage = content.find((c) => c.type === BlockTypes.PreviewImage);
    const preview = content.find((c) => c.type === BlockTypes.Preview);
    res.date = relatedPages.lastPublishedAt ?? undefined;
    res.image = previewImage ? String(previewImage.value) : '';
    res.description = preview ? (preview.value as ParagraphValue).text : '';
    res.title = preview ? (preview.value as ParagraphValue).header : '';
    res.link = `/blogs/${res.id}`;
    return res;
  };

  // Отключаем скролл к комментариям
  /* useEffect(() => {
    const commentsDivElement = document.getElementById('guideCommentsDivId');
    if (history.location.state?.scroll) {
      debounce(() => {
        commentsDivElement?.scrollIntoView({ block: 'start' });
      }, 300)();
    }
  }, [history.location.pathname, commentsCount]); */

  const handleShowModal = useCallback((id: number) => {
    setLikeModalNewsId(id);
    setShowLikeModal(true);
  }, []);

  return (
    <Div>
      <BreadcrumbsWrapper>
        <Breadcrumbs white={true} />
      </BreadcrumbsWrapper>

      <Section>
        <Banner image={mainImage} />
      </Section>

      <BaseSection marginless paddingless compact noMinHeight>
        <Actions onShowModal={handleShowModal} />
      </BaseSection>

      <BaseSection heading={content.header} compact borderless paddingless transparent>
        <Content value={content.text} />
      </BaseSection>

      <div id="guideCommentsDivId">
        <BaseSection heading={`Комментарии (${commentsCount})`} compact borderless paddingless transparent>
          <GuideComments guideId={guidePageId} />
        </BaseSection>
      </div>

      {store.relatedGuides.length > 0 && (
        <BaseSection heading={preferences.mayInterestTitle} compact>
          <RelatedItems items={store.relatedGuides.map(getRelatedItem)} />
        </BaseSection>
      )}

      <LikeModal visible={showLikeModal} onClose={() => setShowLikeModal(false)}>
        <LikeModalContent cardType="guide" id={likeModalNewsId} limitPerPage={likesLimit} />
      </LikeModal>
    </Div>
  );
});

export default BlogItem;
