import React, { useMemo } from 'react';

import { HugeSliderValue } from 'web';

import { BigSlider } from 'components/main/big-slider';
import Slider from '../../main/slider';
import { Animation } from '../../main/animation';

interface HugeSliderRendererProps {
  value: {
    images: HugeSliderValue[];
    isActive: boolean | null;
    periodicity: number | null;
    type: 'animated' | 'slides';
    file: string;
  };
}

const HugeSliderRenderer: React.FC<HugeSliderRendererProps> = ({ value }) => {
  const preparedItems = useMemo(() => {
    return value.images.map((el) => ({
      image: el.image,
      description: el.description,
      title: el.header,
      link: el.buttonLink,
      showModal: el.showModel,
      buttonAction: el.buttonAction,
      dynamicForm: el.dynamicForm,
    }));
  }, [value]);

  return (
    <>
      {value.type === 'slides' && (
        <BigSlider isActive={value.isActive} interval={value.periodicity} items={preparedItems} />
      )}
      {value.type === 'animated' && <Animation file={value.file} />}
    </>
  );
};

export default HugeSliderRenderer;
