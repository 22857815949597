import React, { useCallback, useMemo, useState } from 'react';

import { NewsItemProps } from 'web';

import { useNewsStore, useProfileStore } from 'store';

import { formatDate, SHORT_DATE_FORMAT } from 'utils/date';
import { COLORS } from 'utils/constants';

import { useHistory } from 'react-router-dom';
import { IconViews } from 'components/base-ui/icon-views';
import Icon from 'components/base-ui/icon';
import { IconLikeWithTooltip } from 'components/base-ui/icon-like-with-tooltip';
import { Feedback } from 'components/feedback';
import { BaseModal } from 'components/base-ui/base-modal';
import {
  ActionIcon,
  ActionIconClickable,
  Actions,
  ActionText,
  ImageWrapper,
  ActionsWrapper,
  StButton,
  StContainer,
  StImage,
  Title,
  StDate,
  StButtonWrapper,
} from './styled';
import { useDeviceType } from '../../../../../utils/hooks';

interface ItemProps {
  data: NewsItemProps;
  handleShowModal?: (newsId: number) => void;
  isSearch?: boolean;
}

const Item: React.FC<ItemProps> = ({ data, handleShowModal, isSearch }) => {
  const history = useHistory();
  const profileStore = useProfileStore();
  const to = useMemo(() => `/news/${data.id}`, [data.id]);
  const newsStore = useNewsStore();
  const device = useDeviceType();

  const [showForm, setShowForm] = useState(false);

  const cardClickHandler = useCallback(
    (ev) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      if (!ev.target.closest('BUTTON')) history.push(`/news/${data.id}`);
    },
    [data.id],
  );

  const toggleLike = useCallback(() => {
    if (data.isLiked) {
      newsStore.handleLikeDelete(data.id, profileStore.profile?.id!);
    } else {
      newsStore.handleLikePost(data.id, profileStore.profile);
    }
  }, [data.id, data.isLiked]);

  const goToComments = useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    history.push({ pathname: `/news/${data.id}`, state: { scroll: true } });
  }, []);

  const onShowModal = React.useCallback(() => {
    if (handleShowModal) {
      handleShowModal(data.id);
    }
  }, [data.id, handleShowModal]);

  const applicationFormButtonText = useMemo(() => {
    return data.applicationForm?.text && data.applicationForm?.text.length > 0 ? data.applicationForm.text : 'заказать';
  }, [data.applicationForm]);

  return (
    <StContainer haveButton={!!data.applicationForm?.dynamicApplicationForm}>
      <ImageWrapper onClick={cardClickHandler}>
        <StImage src={data.image ?? ''} />
      </ImageWrapper>

      <div>
        <Title to={to}>{data.paragraph?.header}</Title>
        <ActionsWrapper>
          <Actions>
            <IconLikeWithTooltip
              isLiked={Boolean(data.isLiked)}
              onClick={toggleLike}
              likedUsers={data.likeAuthors ?? []}
              likesCount={data.likesCount ?? 0}
              newsId={data.id}
              onShowModal={onShowModal}
            />
            <ActionIcon>
              <IconViews height="24px" width="24px" color={COLORS.gray4} />
            </ActionIcon>
            <ActionText>{data.viewsCount ?? 0}</ActionText>
            {!!data.commentsCount && data.commentsCount > 0 && (
              <>
                <ActionIconClickable onClick={goToComments}>
                  <Icon name="iconChatBubbleOutline" />
                </ActionIconClickable>
                <ActionText>{data.commentsCount}</ActionText>
              </>
            )}
          </Actions>
          {!isSearch && <StDate>{formatDate(data.date, SHORT_DATE_FORMAT)}</StDate>}
        </ActionsWrapper>
        {data?.applicationForm && device !== 'mobile' && device !== 'tablet' && (
          <>
            <StButtonWrapper>
              <StButton onClick={() => setShowForm(true)}>{applicationFormButtonText}</StButton>
            </StButtonWrapper>
            <BaseModal isVisible={showForm} onClose={() => setShowForm(false)}>
              <Feedback formId={data.applicationForm.dynamicApplicationForm} />
            </BaseModal>
          </>
        )}
      </div>
    </StContainer>
  );
};

export default Item;
